import React from 'react'
import {Popup} from "../../components/Popup/Popup";
interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  url: string | undefined
}

export const ImageReuploadUrlPopup: React.FC<Props> = ({onClose, url}) => {
  return <Popup onClose={onClose}>
    <div>
      <h1>url</h1>
      <div>{url}</div>
    </div>
  </Popup>
};

export default ImageReuploadUrlPopup;
