import React from "react";
import styles from "./Input.module.scss";
import Switch, {SwitchChangeEventHandler} from 'rc-switch';
import "rc-switch/assets/index.css"

// Learn more here -
// http://react-component.github.io/switch/

interface Props {
  title?: string;
  inputClassName?: string,
  disabled?: boolean,
  className?: string,
  defaultValue?: boolean,
  onChange: SwitchChangeEventHandler
}

export const SwitchInput: React.FC<Props> = ({
                                               disabled,
                                               className,
                                               defaultValue,
                                               title,
                                               onChange
                                             }) => {
  const classList = [
    styles.switchRow,
    className
  ].join(' ')

  return <div className={classList}>
    {title && <div>{title}</div>}
    <Switch
      defaultChecked={defaultValue}
      onChange={onChange}
      onClick={onChange}
      disabled={disabled}
    />
  </div>
};
