import {Meta} from '../utils'
import { PromoCode } from '../models/PromoCode'
import {mapValues, sortBy, groupBy, without, omit} from 'lodash'


export type ContextPropsState = {
  promoCodeById: {[key: string]: Meta<PromoCode>},
  promoCodeIds: Meta<string[]>
}

export const initialState: ContextPropsState = {
  promoCodeById: {},
  promoCodeIds: {},
};

type PatternsAction = 
  | { type: "FETCH_ALL_PROMOCODES" }
  | { type: "FETCH_ALL_PROMOCODES_SUCCESS", data: PromoCode[] }
  | { type: "FETCH_ALL_PROMOCODES_ERROR", error: any }

  | { type: "UPDATE_PROMOCODE", id: string }
  | { type: "UPDATE_PROMOCODE_SUCCESS", id: string, data: PromoCode }
  | { type: "UPDATE_PROMOCODE_ERROR", id: string, error: any }

  | { type: "DELETE_PROMOCODE", id: string }
  | { type: "DELETE_PROMOCODE_SUCCESS", id: string }
  | { type: "DELETE_PROMOCODE_ERROR", id: string, error: any }

  | { type: "CREATE_PROMOCODE" }
  | { type: "CREATE_PROMOCODE_SUCCESS", data: PromoCode }
  | { type: "CREATE_PROMOCODE_ERROR", error: any }

export const reducer = (state: ContextPropsState, action: PatternsAction):ContextPropsState => {
  // FETCH
  if( "FETCH_ALL_PROMOCODES" === action.type) {
    const newPromoCodeIds: Meta<string[]> = {
      data: state.promoCodeIds?.data,
      meta: {
        ...state.promoCodeIds.meta,
        fetching: true
      }
    }
    return {
      ...state,
      promoCodeIds: newPromoCodeIds,
    };
  }
  if( "FETCH_ALL_PROMOCODES_SUCCESS" === action.type) {
    if(action.data == null) return state;

    const newPromoCodeIds: Meta<string[]> = {
      data: action.data.map(p => p.id),
      meta: {
        ...state.promoCodeIds.meta,
        fetching: false
      }
    }
     
    const promoCodeById = mapValues(groupBy(action.data, p => p.id), p => ({data: p[0]}) );
    return {
      ...state,
      promoCodeById,
      promoCodeIds: newPromoCodeIds,
    }
  }
  if( "FETCH_ALL_PROMOCODES_ERROR" === action.type) {
    const newPromoCodeIds: Meta<string[]> = {
      data: state.promoCodeIds?.data,
      meta: {
        ...state.promoCodeIds.meta,
        fetching: false,
        error: action.error
      }
    }
    return {
      ...state,
      promoCodeIds: newPromoCodeIds,
    };
  }

  // UPDATE
  if( "UPDATE_PROMOCODE" === action.type) {
    if(action.id == null) return state;

    const currentPromoCode = state.promoCodeById[action.id];
    const newPromoCode: Meta<PromoCode> = {
      data: currentPromoCode?.data,
      meta: {
        ...(currentPromoCode?.meta || {}),
        updating: true
      }
    }

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.id]: newPromoCode
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById
    };
  }
  if( "UPDATE_PROMOCODE_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentPromoCode = state.promoCodeById[action.id];
    const newPromoCode: Meta<PromoCode> = {
      data: action.data,
      meta: {
        ...(currentPromoCode?.meta || {}),
        updating: false
      }
    }

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.id]: newPromoCode
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById
    };
  }
  if( "UPDATE_PROMOCODE_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentPromoCode = state.promoCodeById[action.id];
    const newPromoCode: Meta<PromoCode> = {
      data: currentPromoCode?.data,
      meta: {
        ...(currentPromoCode?.meta || {}),
        updating: false,
        error: action.error
      }
    }

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.id]: newPromoCode
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById
    };
  }


  // DELETE
  if( "DELETE_PROMOCODE" === action.type) {
    if(action.id == null) return state;

    const currentPromoCode = state.promoCodeById[action.id];
    const newPromoCode: Meta<PromoCode> = {
      data: currentPromoCode?.data,
      meta: {
        ...(currentPromoCode?.meta || {}),
        deleting: true
      }
    }

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.id]: newPromoCode
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById
    };
  }
  if( "DELETE_PROMOCODE_SUCCESS" === action.type) {
    if(action.id == null) return state;

    const newPromoCodeById = omit(state.promoCodeById, [action.id])
    const newPromoCodeIds = {
      data: without(state.promoCodeIds.data, action.id),
      meta: state.promoCodeIds.meta
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById,
      promoCodeIds: newPromoCodeIds
    };
  }
  if( "DELETE_PROMOCODE_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentProduct = state.promoCodeById[action.id];
    const newProduct: Meta<PromoCode> = {
      data: currentProduct?.data,
      meta: {
        ...(currentProduct?.meta || {}),
        deleting: false,
        error: action.error
      }
    }

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.id]: newProduct
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById
    };
  }


  // CREATE
  if( "CREATE_PROMOCODE" === action.type) {
    return state
  }
  if( "CREATE_PROMOCODE_SUCCESS" === action.type) {
    if(action.data == null) return state

    const newPromoCodeById = {
      ...state.promoCodeById,
      [action.data.id]: {
        data: action.data
      }
    }

    const newPromoCodeIds = {
      data: sortBy(([...(state.promoCodeIds?.data || []), action.data.id]), id => newPromoCodeById?.[id]?.data?.code || ""),
      meta: state.promoCodeIds.meta
    }

    return {
      ...state,
      promoCodeById: newPromoCodeById,
      promoCodeIds: newPromoCodeIds,
    };
  }
  if( "CREATE_PROMOCODE_ERROR" === action.type) {
    return state
  }


  return state;
}
