import React from 'react'
import {Lightbox} from "react-modal-image";
import { parsePhoneNumber } from 'libphonenumber-js';

import {Product} from '../Product/Product'
import { Scrollable } from '../Scrollable/Scrollable'
import {ResendEmailPopup} from '../../popups/ResendEmailPopup/ResendEmailPopup'

import { Order } from '../../models/Order.model'
import { formatPrice, getFireDataUrl, getFireStorageUrl, Meta } from '../../utils'
import { Button } from '../Button/Button'
import ShippingBillingInfo from '../ShippingInfo/ShippingInfo';
import { FirebaseDBLink, FirebaseStorageLink, SidebarHeader } from '../SidebarHeader/SidebarHeader'
import {MessagingSection} from "./MessagingSection/MessagingSection"
import { InfoCard, InfoCardHeader, InfoCardRow } from '../InfoCard/InfoCard';

import { useProductsContext } from '../../contexts/products.context';
import { useOrdersContext } from '../../contexts/orders.context';
import { EmailIcon } from '../../toneIcons';
import ConfirmChangeStatusPopUp from '../../popups/ConfirmChangeStatusPopUp/ConfirmChangeStatusPopUp';

import SplitOrderPopup from '../../popups/SplitOrderPopup/SplitOrderPopup';
import JoinOrderPopup from '../../popups/JoinOrderPopup/JoinOrderPopup'

import {SentEmailsSection} from "./MessagingSection/SentEmailsSection"
import ImageReuploadUrlPopup from '../../popups/ImageReuploadUrlPopup/ImageReuploadUrlPopup';
import { firebaseBuckets } from '../../services/firebase';
import {PrivateNotesSection} from "./MessagingSection/PrivateNotesSection";

import styles from './OrderSidebar.module.scss'
interface OrderSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void,
  data?: Meta<Order>
  updateProduct: (orderId: string, productId: string, data: {[key: string]: any}) => void
}

export const OrderSidebar: React.FC<OrderSidebarProps> = ({data, onClose, updateProduct}) => {

  const dataId = data?.data?.id
  const storageUrl = (firebaseBuckets.orders && dataId) ? getFireStorageUrl(firebaseBuckets.orders, dataId) : undefined

  return <div className={styles.container}>
    <SidebarHeader title={data?.data?.id || ""} onClose={onClose}>
      <FirebaseDBLink href={getFireDataUrl(`orders/${dataId}`)} />
      <FirebaseStorageLink href={storageUrl} />
    </SidebarHeader>

    {data?.data != null && <Scrollable innerClassName={styles.scrollable}>
      <OrderSidebarWithData data={data?.data} updateProduct={updateProduct} />
    </Scrollable>}
  </div>
}

interface OrderSidebarDataProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: Order;
  updateProduct: (orderId: string, productId: string, data: {[key: string]: any}) => void
}

type PopupStatus = {
  status: "splitOrder" | "doNotPrint" | "notPrintable" | "joinOrder" | "reUploadUrl" | "sendShippedEmail" | "sendOrderConfirmEmail"
  data?: any
}

const OrderSidebarWithData: React.FC<OrderSidebarDataProps> = ({data, updateProduct}) => {
  const [, {fetchProducts}] = useProductsContext();
  const [, {printifyOrder, unPrintifyOrder, setOrderStatus, splitOrder, joinOrder, generateImageReupload, resendOrderConfirmation, resendShippedEmail}] = useOrdersContext()

  const [popup, setShowPopup] = React.useState<PopupStatus>()
  const [previewImage, setPreviewImage] = React.useState<string>();
  const [error, setError] = React.useState("");

  React.useEffect(() => {
    if(fetchProducts) fetchProducts()
  }, [fetchProducts])


  function wrapInErrorHandling(fn: (orderId: string) => Promise<any> ) {
    return async () => {
      setError("");
      try {
        await fn(data.id)
      } catch(error) {
        setError(error.message || error.details || error.error ||"Unknown error occurred");
      }
    }
  }


  const onSplitOrderHandler = async (productIds: string[]) => {
    if (data.id != null) {
      setError("");
      try {
        await splitOrder(data.id, productIds)
      } catch(error) {
        setError(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };

  const onJoinOrderHandler = async (joinOrderId: string) => {
    if (data.id != null && joinOrderId !== data.id) {
      setError("");
      try {
        await joinOrder(data.id, joinOrderId)
      } catch(error) {
        setError(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };

  const confirmDoNotPrintStatusHandler = async () => {
    if (data.id != null) {
      setError("");
      try {
        await setOrderStatus(data.id, "doNotPrint")
      } catch(error) {
        setError(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };


  const confirmNotPrintableStatusHandler = async () => {
    if (data.id != null) {
      setError?.("");
      try {
        await setOrderStatus(data.id, "notPrintable")
      } catch(error) {
        setError?.(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };

  const confirmSendShippedHandler = async () => {
    if (data.id != null) {
      console.log("confirmSendShippedHandler")
      setError?.("");
      try {
        await resendShippedEmail(data.id, true)
      } catch(error) {
        setError?.(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };

  const confirmSendOrderConfirmHandler = async () => {
    if (data.id != null) {
      console.log("confirmSendOrderConfirmHandler")
      setError?.("");
      try {
        await resendOrderConfirmation(data.id)
      } catch(error) {
        setError?.(error.message || error.details || error.error ||"Unknown error occurred");
      }
      setShowPopup(undefined)
    }
  };

  const generateImageReuploadHandler = async () => {
    const url = await generateImageReupload(data.id)
    setShowPopup({status: 'reUploadUrl', data: url})
  }

  const printifyHandler = wrapInErrorHandling( printifyOrder )
  const unprintifyHandler = wrapInErrorHandling( unPrintifyOrder )
  const products = data.products ? Object.entries(data.products) : []

  const phoneNumber = data.phone ? parsePhoneNumber(data.phone, data.billing?.countryCode || data.shipping?.countryCode) : undefined

  return <>
    <PrivateNotesSection order={data}/>
    <MessagingSection order={data}/>
    <SentEmailsSection orderId={data.id}/>
    <OrderButtons data={data} setError={setError} setShowPopup={setShowPopup} />

    {error && <div>{error}</div>}

    <div className={styles.products}>
      {products.map( ([id, product]) => {
        return <Product
          key={id}
          orderId={data.id}
          order={data}
          product={product}
          onClickImage={setPreviewImage}
          onUpdate={updateData => updateProduct(data.id, id, updateData)} />
      })}
    </div>

    {data.status === "cropped" && <div className={styles.processButtons}>
      {error && <div>{error}</div>}
      <Button title="Add to printing queue" className={styles.accept} onClick={printifyHandler}>Printify</Button>
      <Button title="Remove from printing queue" className={styles.reject} onClick={unprintifyHandler}>Un-printify</Button>
    </div>}

    <InfoCard>
      <InfoCardHeader>BUYER INFO</InfoCardHeader>
      <InfoCardRow text="UID" value={data.uid} />
      <InfoCardRow text="Name" value={data.name} />
      <InfoCardRow text="Phone" value={phoneNumber?.formatInternational()} />
      <InfoCardRow text="Email">
        <a href={`mailto: ${data.email}`} className={styles.customEmail} target="_blank" rel="noopener noreferrer">
          <EmailIcon/>
          {data.email}
        </a>
      </InfoCardRow>
    </InfoCard>

    <ShippingBillingInfo shipping={data.shipping} billing={data.billing} />

    <InfoCard>
      <InfoCardHeader>PRICING INFO</InfoCardHeader>
      <InfoCardRow text="Item Discount Price" value={formatPrice(data.chargedPriceDetails?.itemsDiscountPrice)} />
      <InfoCardRow text="Shipping Price" value={formatPrice(data.chargedPriceDetails?.shippingDiscountPrice)} />
      <InfoCardRow text="Tax" value={formatPrice(data.chargedPriceDetails?.tax)} />
      <InfoCardRow text="Total" value={formatPrice(data.chargedPriceDetails?.total)} />
    </InfoCard>

    <Button onClick={generateImageReuploadHandler}>Generate Image re-upload url</Button>

    {previewImage && <div>
      <Lightbox small={previewImage} large={previewImage} onClose={() => setPreviewImage(undefined)} />
    </div>}

    {/*  poups */}
    {popup?.status === 'reUploadUrl' && <ImageReuploadUrlPopup onClose={() => setShowPopup(undefined)} url={popup.data}  />}
    {popup?.status === 'splitOrder' && <SplitOrderPopup onSubmit={onSplitOrderHandler} onClose={() => setShowPopup(undefined)} order={data} />}
    {popup?.status === 'joinOrder' && <JoinOrderPopup onSubmit={onJoinOrderHandler} onClose={() => setShowPopup(undefined)} order={data} />}
    {popup?.status === 'doNotPrint' && <ConfirmChangeStatusPopUp newStatus="doNotPrint" onSubmit={confirmDoNotPrintStatusHandler} onClose={() => setShowPopup(undefined)} text={"Mark do not print"} order={data} />}
    {popup?.status === 'notPrintable' && <ConfirmChangeStatusPopUp newStatus="notPrintable" onSubmit={confirmNotPrintableStatusHandler} onClose={() => setShowPopup(undefined)} text={"Mark not printable"} order={data} />}
    {popup?.status === 'sendShippedEmail' && <ResendEmailPopup text="Send Shipped Email" onSubmit={confirmSendShippedHandler} onClose={() => setShowPopup(undefined)} order={data} />}
    {popup?.status === 'sendOrderConfirmEmail' && <ResendEmailPopup text="Send Order Confirmation Email" onSubmit={confirmSendOrderConfirmHandler} onClose={() => setShowPopup(undefined)} order={data} />}
  </>
}

interface OrderButtonsProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: Order;
  setError: React.Dispatch<React.SetStateAction<string>>
  setShowPopup:  React.Dispatch<React.SetStateAction<PopupStatus | undefined>>
}

const OrderButtons:React.FC<OrderButtonsProps> = ({data, setError, setShowPopup}) => {
  const [, {markCroppedOrder, undoCroppedOrder, markPrintedOrder, undoPrintedOrder, markPackagedOrder, undoPackagedOrder, markShippedOrder, undoShippedOrder, undoOrderStatus, markEmailedOrder, undoEmailedOrder, moveImages}] = useOrdersContext()

  const lastStatus = data.statusHistory && data.statusHistory[data.statusHistory?.length - 1]

  function wrapInErrorHandling(fn: (orderId: string) => Promise<any> ) {
    return async () => {
      setError?.("");
      try {
        await fn(data.id)
      } catch(error) {
        setError?.(error.message || error.details || error.error ||"Unknown error occurred");
      }
    }
  }

  const undoDoNotPrintOrderHandler = wrapInErrorHandling( id => undoOrderStatus(id, "doNotPrint") )
  const undoNotPrintableOrderHandler = wrapInErrorHandling( id => undoOrderStatus(id, "notPrintable") )

  const markCroppedOrderHandler = wrapInErrorHandling( markCroppedOrder )
  const undoCroppedOrderHandler = wrapInErrorHandling( undoCroppedOrder )

  const markPrintedOrderHandler = wrapInErrorHandling( markPrintedOrder )
  const undoPrintedOrderHandler = wrapInErrorHandling( undoPrintedOrder )

  const markPackagedOrderHandler = wrapInErrorHandling( markPackagedOrder )
  const undoPackagedOrderHandler = wrapInErrorHandling( undoPackagedOrder )

  const markShippedOrderHandler = wrapInErrorHandling( markShippedOrder )
  const undoShippedOrderHandler = wrapInErrorHandling( undoShippedOrder )

  const markEmailedOrderHandler = wrapInErrorHandling( markEmailedOrder )
  const undoEmailedOrderHandler = wrapInErrorHandling( undoEmailedOrder )

  const moveImagesHandler = wrapInErrorHandling( moveImages )

  return <div className={styles.container}>
    <details>
    <summary>Actions</summary>
    <Button title="Move Images Again" className={styles.accept} onClick={moveImagesHandler}>Move Images</Button>

    { data.status === "doNotPrint" && <div className={styles.processButtons}>
      <Button title="Undo Do Not Print" className={styles.reject} onClick={undoDoNotPrintOrderHandler}>Undo Do Not Print</Button>
    </div>}
    { data.status === "notPrintable" && <div className={styles.processButtons}>
      <Button title="Undo Not Printable" className={styles.reject} onClick={undoNotPrintableOrderHandler}>Undo Not Printable</Button>
    </div>}

    { data.status === "emailed" && <div className={styles.processButtons}>
        <Button title="Undo Emailed" className={styles.reject} onClick={undoEmailedOrderHandler}>Undo Emailed</Button>
        <div>marked emailed by: {lastStatus?.email}</div>
      </div>
    }

    { data.status !== "doNotPrint" && <div className={styles.processButtons}>
        <Button title="Mark do not print" className={styles.accept} onClick={ () => setShowPopup({status: "doNotPrint"}) }>Set Do Not Print</Button>
      </div>
    }
    { data.status === "toVerify" && <div className={styles.processButtons}>
        <Button title="Mark not printable" className={styles.accept} onClick={ () => setShowPopup({status: "notPrintable"}) }>Set Not Printable</Button>
      </div>
    }

    { (data.status !== "emailed" && data.status === "toVerify" ) && <div className={styles.processButtons}>
        <Button title="Mark Emailed" className={styles.accept} onClick={ markEmailedOrderHandler }>Set Emailed</Button>
      </div>
    }

    { data.status === "accepted" && <div className={styles.processButtons}>
        <Button title="Mark cropped" className={styles.accept} onClick={markCroppedOrderHandler}>Set Cropped</Button>
      </div>
    }

    { (data.status === "cropped" || data.status === "reprint") && <div className={styles.processButtons}>
        <Button title="Undo Cropped" className={styles.reject} onClick={undoCroppedOrderHandler}>Undo Cropped</Button>
        <Button title="Mark Printed" className={styles.accept} onClick={markPrintedOrderHandler}>Set Printed</Button>
      </div>
    }

    { data.status === "printed" && <div className={styles.processButtons}>
        <Button title="Undo Printed" className={styles.reject} onClick={undoPrintedOrderHandler}>Undo Printed</Button>
        <Button title="Mark Packaged" className={styles.accept} onClick={markPackagedOrderHandler}>Set Packaged</Button>
      </div>
    }

    { data.status === "packaged" && <div className={styles.processButtons}>
        <Button title="Undo Packaged" className={styles.reject} onClick={undoPackagedOrderHandler}>Undo Packaged</Button>
        <Button title="Mark Shipped" className={styles.accept} onClick={markShippedOrderHandler}>Set Shipped</Button>
      </div>
    }


    { data.status === "shipped" && <div className={styles.processButtons}>
        <Button title="Undo Shipped" className={styles.reject} onClick={undoShippedOrderHandler}>Undo Shipped</Button>
      </div>
    }

    <Button title="Split Order" className={styles.accept} onClick={() => setShowPopup({status: "splitOrder"})}>Split order</Button>
    <Button title="Join Order" className={styles.accept} onClick={() => setShowPopup({status: "joinOrder"})}>Join order</Button>


    <div>
      <Button title="Send Receipt Email" className={styles.accept} onClick={() => setShowPopup({status: "sendOrderConfirmEmail"})}>Send Receipt Email</Button>
      <Button title="Send Shipped Email" className={styles.accept} onClick={() => setShowPopup({status: "sendShippedEmail"})}>Send Shipped Email</Button>
    </div>
    </details>
  </div>
}
