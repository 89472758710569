import React from 'react'

import styles from './CheckboxInput.module.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  error?: string | boolean;
}

export const CheckboxInput: React.FC<InputProps> = ({value, checked, type, disabled, name, title, onChange, className}) => {
  const classList = [
    className,
    styles.container
  ]
  return <label className={classList.join(' ')}>
    <Checkbox name={name} type={type} value={value} disabled={disabled} checked={checked} onChange={onChange}  />
    <span>{title}</span>
  </label>
}

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {

}

const Checkbox: React.FC<CheckboxProps> = ({name, value, type, checked, onChange, disabled}) => {
  return <>
    {onChange && <input type={type || "checkbox"} name={name} value={value} checked={checked || false} onChange={onChange} className={styles.inputCheckbox} disabled={disabled} />}
    <span className={[styles.checkbox, checked ? styles.checked : null].join(' ')}>
      <span className={styles.checkmark}>&#10003;</span>
    </span>
  </>
}