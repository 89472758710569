import React from 'react'
import classNames from 'classnames'

import styles from './SquareSelect.module.scss'

export const SquareSelect: React.FC<React.SelectHTMLAttributes<HTMLSelectElement>> = ({onChange, value, children, className}) => {
  return <div className={classNames(styles.select, className)}>
    <select onChange={onChange} value={value}>
      {children}
    </select>
  </div>
}