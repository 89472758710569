import React from 'react'

import styles from './Page404.module.scss'

interface Page404Props {
  msg?: string
}

export const Page404: React.FC<Page404Props> = ({msg}) => {
  return <div className={styles.container}>
    <span role="img" aria-label="warning">⚠️</span>
    <div>Well, something went catastrophically wrong</div>
    {msg != null && <div>{msg}</div>}
  </div>
}