import React from 'react'
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";

import Table from '../../components/Table/Table';
import CellHeader from '../../components/CellHeader/CellHeader';

import { useQuery } from '../../hooks/useQuery';

import Cell from '../../components/Cell/Cell';
import { Meta, shouldRefetch } from '../../utils';
import { usePromoCodesContext } from '../../contexts/promoCodes.context';
import { PromoCode } from '../../models/PromoCode';
import { Button } from '../../components/Button/Button';
import CreatePromoCodePopup from '../../components/CreatePopup/CreatePromoCode';
import { SidebarContainer } from '../../components/SidebarContainer/SidebarContainer';
import { PromoCodeSidebar } from '../../components/PromoCodeSidebar/PromoCodeSidebar';
import DeletePopup from '../../popups/DeletePopup/DeletePopup';
import { TableHeader } from '../../components/TableHeader/TableHeader';

import styles from './PromoCodes.module.scss'
import { Page404 } from '../../components/Page404/Page404';

export const PromoCodes = () => {
  const [{promoCodeById, promoCodeIds}, {fetchPromoCodes, createPromoCode, updatePromoCode, deletePromoCode}] = usePromoCodesContext();
  const history = useHistory();
  const [promoCodeToCreate, setPromoCodeToCreate] = React.useState<boolean>();
  const [promoCodeIdToDelete, setPromoCodeIdToDelete] = React.useState<string | undefined>();

  const query = useQuery()

  const shouldFetchPromoCodes = shouldRefetch(promoCodeIds)
  React.useEffect(() => {
    if(fetchPromoCodes && shouldFetchPromoCodes) fetchPromoCodes()
  }, [fetchPromoCodes, shouldFetchPromoCodes])

  const createConfirmationHandler = async (data: Partial<PromoCode>) => {
    await createPromoCode(data)
    setPromoCodeToCreate(false)
  };

  const deleteConfirmationHandler = async (id: string) => {
    await deletePromoCode(id)
    history.push({pathname: history.location.pathname})
    setPromoCodeIdToDelete(undefined)
  };

  const columns = "max-content max-content max-content max-content max-content minmax(max-content, 1fr)"

  const selectedPromoCodeId = query.get('promoCodeId')
  const selectedPromoCode = selectedPromoCodeId != null ? promoCodeById?.[selectedPromoCodeId] : undefined

  const style = {
    '--sidepanel-width': (selectedPromoCodeId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;

  return <div style={style} className={styles.container}>
    <Helmet>
      <title>Promo Codes - FriendlyFace</title>
    </Helmet>
    <TableHeader>
      <Button onClick={() => setPromoCodeToCreate(true)}>Create Code</Button>
    </TableHeader>
    {promoCodeIds.meta?.error && <Page404 msg={promoCodeIds?.meta?.errorMsg} />}
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader sticky>Code</CellHeader>
        <CellHeader>Percentage Paid of Total</CellHeader>
        <CellHeader>Static Amount</CellHeader>
        <CellHeader>Application level</CellHeader>
        <CellHeader>Uses</CellHeader>
        <CellHeader>Description</CellHeader>
        {promoCodeIds?.data?.map((id, index) => {
          const promoCodeMeta = promoCodeById?.[id];
          if(promoCodeMeta == null) return null;
          return <Cells index={index} data={promoCodeMeta} key={id} active={selectedPromoCodeId === id} onClickRow={() => history.push(`?promoCodeId=${id}`)} />
        })}
      </Table>
    </div>

    {promoCodeToCreate && <CreatePromoCodePopup onSubmit={createConfirmationHandler} onClose={() => setPromoCodeToCreate(false)}/>}

    {selectedPromoCodeId && <SidebarContainer updating={selectedPromoCode?.meta?.updating}>
      <PromoCodeSidebar data={selectedPromoCode} key={selectedPromoCodeId}
        onClose={() => history.push({pathname: history.location.pathname})}
        onUpdate={d => updatePromoCode(selectedPromoCodeId, d)}
        onDelete={() => setPromoCodeIdToDelete(selectedPromoCodeId)} />
    </SidebarContainer>}

    {promoCodeIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(promoCodeIdToDelete)} onClose={() => setPromoCodeIdToDelete(undefined)} />}
  </div>
}


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  index:number
  data: Meta<PromoCode>,
  onClickRow: any,
  active: boolean,
}

const Cells:React.FC<Props> = ({index, data, active, onClickRow}) => {
  const props = {
    index: index,
    deleting: data.meta?.deleting,
    active: active,
    className: styles.pointer,
    onClick: () => onClickRow(),
  }

  const promoCode = data?.data
  const hasMaxUses = promoCode?.maxUses && !isNaN(promoCode?.maxUses)
  const disabledIcon = promoCode?.disabled ? "🚫" : "✅"
  return <>
    <Cell sticky {...props}>{disabledIcon} {promoCode?.code}</Cell>
    <Cell {...props}>{promoCode?.m}</Cell>
    <Cell {...props}>{promoCode?.b}</Cell>
    <Cell {...props}>{promoCode?.applicationLevel} level</Cell>
    <Cell {...props}>{(!hasMaxUses) ? "N/A" : `${promoCode?.currentUses || 0}/${promoCode?.maxUses}`}</Cell>
    <Cell {...props}>{promoCode?.description}</Cell>
  </>
};