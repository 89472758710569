import React from 'react'

import styles from './SidebarContainer.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  updating?: boolean
}

export const SidebarContainer: React.FC<Props> = ({children, updating}) => {
  return <div className={styles.container}>
    <div className={[styles.saving, updating ? styles.active : null].join(' ')}>Saving...</div>
    <div className={styles.content}>
      {children}
    </div>
  </div>
}