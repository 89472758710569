import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "rc-tooltip/assets/bootstrap.css"
import firebase from "firebase";
import { Toaster } from 'react-hot-toast';

import './index.css';
import * as serviceWorker from './serviceWorker';
import { Home } from './pages/Home/Home'
import { Login } from './pages/Auth/Login'
import { Signup } from './pages/Auth/Signup';
import {defaultColorStyle} from './utils'
import {AuthProvider} from './contexts/auth.context'
import { AuthedRoute, UnAuthedRoute } from './routes'
import { Logout } from './pages/Auth/Logout';
import { Admin } from './components/Admin/Admin'


const App = () => {
  const style = defaultColorStyle();
  return <React.StrictMode>
    <AuthProvider>
      <div style={style}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <AuthedRoute path="/admin" component={Admin} />
            <UnAuthedRoute path="/login" component={Login} redirectTo="/admin" />
            <UnAuthedRoute path="/signup" component={Signup} redirectTo="/admin" />
            <AuthedRoute path="/logout" component={Logout} />
            <Route>
              <Redirect from='*' to='/'/>
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </AuthProvider>
    <Toaster position="bottom-center" />
  </React.StrictMode>
}

ReactDOM.render(<App />, document.getElementById('root'));

// hacking callable function to work with express
(firebase.functions() as any)._url = function (name: string): string {
  return `/api/${name}`;
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

