import {Meta} from '../utils'
import {mapValues, sortBy, groupBy, without} from 'lodash'

import { Feature } from '../models/Feature.model'

export type ContextPropsState = {
  featureById: { [key: string]: Meta<Feature> },
  featureIds: Meta<string[]> | undefined,
}

export const initialState: ContextPropsState = {
  featureById: {},
  featureIds: undefined,
};

type FeaturesAction = 
  | { type: "FETCH_FEATURE", id: string }
  | { type: "FETCH_FEATURE_SUCCESS", id: string, data: Feature }
  | { type: "FETCH_FEATURE_ERROR", id: string, error: any }

  | { type: "FETCH_ALL_FEATURES" }
  | { type: "FETCH_ALL_FEATURES_SUCCESS", data: Feature[] }
  | { type: "FETCH_ALL_FEATURES_ERROR", error: any }

  | { type: "UPDATE_FEATURE", id: string }
  | { type: "UPDATE_FEATURE_SUCCESS", id: string, data: Feature }
  | { type: "UPDATE_FEATURE_ERROR", id: string, error: any }

  | { type: "CREATE_FEATURE"}
  | { type: "CREATE_FEATURE_SUCCESS", data: Feature }
  | { type: "CREATE_FEATURE_ERROR", error: any }

  | { type: "DELETE_FEATURE", id: string }
  | { type: "DELETE_FEATURE_SUCCESS", id: string }
  | { type: "DELETE_FEATURE_ERROR", id: string, error: any }

export const reducer = (state: ContextPropsState, action: FeaturesAction):ContextPropsState => {
  // FETCH
  if( "FETCH_FEATURE" === action.type) {
    if(action.id == null) return state;

    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: currentFeature?.data,
      meta: {
        ...(currentFeature?.meta || {}),
        fetching: true
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }
  if( "FETCH_FEATURE_SUCCESS" === action.type) {
    if(action.data == null) return state;
    if(action.id == null) return state;

    const currentFeature = state.featureById?.[action.id];

    const newFeature: Meta<Feature> = {
      data: action.data,
      meta: {
        ...(currentFeature?.meta || {}),
        fetching: false
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }
  if( "FETCH_FEATURE_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: currentFeature?.data,
      meta: {
        ...(currentFeature?.meta || {}),
        fetching: false,
        error: true,
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }

  // FETCH
  if( "FETCH_ALL_FEATURES" === action.type) {
    const featureIds = {
      meta: {
        error: false,
        fetching: true
      }
    }
    return {
      ...state,
      featureIds
    }
  }
  if( "FETCH_ALL_FEATURES_SUCCESS" === action.type) {
    if(action.data == null) return state;
      
  const featureById = mapValues(groupBy(action.data, p => p.id), p => ({data: p[0]}) );
    const featureIds = {
      data: sortBy(action.data, p => p.id).map(p => p.id),
      meta: {
        error: false,
      }
    }
    return {
      ...state,
      featureById,
      featureIds,
    }
  }
  if( "FETCH_ALL_FEATURES_ERROR" === action.type) {
    const featureIds = {
      meta: {
        error: true,
        fetching: false
      }
    }
    return {
      ...state,
      featureIds
    }
  }

  // UPDATE
  if("UPDATE_FEATURE" === action.type) {
    if(action.id == null) return state;

    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: currentFeature?.data,
      meta: {
        ...(currentFeature?.meta || {}),
        updating: true
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }
  if("UPDATE_FEATURE_SUCCESS" === action.type) {
    if(action.id == null) return state
    if(action.data == null) return state

    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: action.data,
      meta: {
        ...(currentFeature?.meta || {}),
        updating: false
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }

    return {
      ...state,
      featureById: newFeatureById,
    }
  }
  if("UPDATE_FEATURE_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: currentFeature?.data,
      meta: {
        ...(currentFeature?.meta || {}),
        updating: false,
        error: action.error
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }


  // CREATE
  if("CREATE_FEATURE" === action.type) {
    return state;
  }

  if("CREATE_FEATURE_SUCCESS" === action.type) {
    if(action.data == null) return state

    const newFeatureById = {
      ...state.featureById,
      [action.data.id]: {
        data: action.data
      }
    }
    const newFeatureIds = sortBy(([...(state.featureIds?.data || []), action.data.id]), id => newFeatureById?.[id]?.data?.id || "")
    return {
      ...state,
      featureById: newFeatureById,
      featureIds: {
        ...state.featureIds,
        data: newFeatureIds,
      }
    }
  }
  if("CREATE_FEATURE_ERROR" === action.type) {
    return state;
  }

  // DELETE
  if("DELETE_FEATURE" === action.type) {
    if(action.id == null) return state;
    
    const currentFeature = state.featureById?.[action.id];
    const newFeature: Meta<Feature> = {
      data: currentFeature?.data,
      meta: {
        ...(currentFeature?.meta || {}),
        deleting: true
      }
    }
    const newFeatureById = {
      ...state.featureById,
      [action.id]: newFeature
    }
    return {
      ...state,
      featureById: newFeatureById
    }
  }
  if("DELETE_FEATURE_SUCCESS" === action.type) {
    if(action.id == null) return state;

    const newFeatureById = {...state.featureById}
    delete newFeatureById[action.id]
    const newFeatureIds = without(state.featureIds?.data, action.id)

    return {
      ...state,
      featureById: newFeatureById,
      featureIds: {
        ...state.featureIds,
        data: newFeatureIds
      }
    }
  }
  if("DELETE_FEATURE_ERROR" === action.type) {
    return state;
  }

  return state;
}
