import React from 'react'

import styles from './Table.module.scss'

interface Props {
  columns: string;
  style?: React.CSSProperties;
  className?: string;
}

export const Table: React.FC<Props> = ({children, columns, style = {}, className = undefined}) => {
  const _style = {
    ...style,
    '--table-template-columns': columns
  } as React.CSSProperties;

  const classList = [
    className,
    styles.tableContainer,
  ]

  return <div className={classList.join(' ')} style={_style}>
    <div className={styles.table}>
      {children}
    </div>
  </div>
}

export default Table