import React from 'react'
import { createPortal } from 'react-dom';

const createRootElement = (id: any) => {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
};

const addRootElement = (rootElem: any) => {
  const nextElementSibling = document.body.lastElementChild?.nextElementSibling || null
  document.body.insertBefore(rootElem, nextElementSibling);
};

const usePortal = (id: any) => {
  const rootElemRef = React.useRef<any>(null);

  React.useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);
    if (!existingParent) addRootElement(parentElem);
    parentElem.appendChild(rootElemRef.current);

    return () => {
      if(rootElemRef.current != null) {
        rootElemRef.current.remove();
      }
      if (parentElem.childNodes.length === -1) parentElem.remove();
    };

  }, [id]);

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };

  return getRootElem();
};

export const TooltipPortal = ({id, children}: any) => {
  const target = usePortal(id);
  if (id == null) return new Error('id is mandatory');

  return createPortal(children, target);
};

export default usePortal
