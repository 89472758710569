import React from 'react'
import { Popup } from "../../components/Popup/Popup";
import { WarningIcon } from "../../toneIcons";


import styles from './DeletePopup.module.scss';

interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: React.MouseEventHandler;
}

export const DeletePopup: React.FC<Props> = ({onClose, onSubmit, children}) => {
  return <Popup icon={<WarningIcon className={styles.warningIcon} />} onClose={onClose}>
    <h4>Delete Permanently</h4>
    {
      children ? children :
        <p>This is CAN NOT be undone. Are you sure?</p>
    }
    {onSubmit != null && <div className={styles.confirmationButton} onClick={onSubmit}>Yes, delete it</div>}
  </Popup>
};

export default DeletePopup;
