import {getMasksStorage} from "./firebase";
import firebase from "firebase";
import { v4 as uuidv4 } from 'uuid';

import {Feature} from '../models/Feature.model'
import { authedDelete, authedGet, authedPost } from "./functions.service";

class FeaturesService {
  private static instance: FeaturesService;

  get storage(): firebase.storage.Reference {
    return getMasksStorage().ref()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new FeaturesService();
    }
    return this.instance;
  }

  public async fetchAll(): Promise<Feature[]> {
    const {features} = await authedGet(`/api/features/admin`);
    return features.map(Feature.from);
  }

  public async fetch(id: string): Promise<Feature> {
    const data = await authedGet(`/api/features/admin/${id}`);
    return Feature.from(data)
  }

  public async update(id: string, data: Feature): Promise<Feature> {
    const feature = await authedPost(`/api/features/admin/${id}`, data);
    return Feature.from(feature)
  }

  public async delete(id: string) {
    return await authedDelete(`/api/features/admin/${id}`);
  }

  public async uploadFile(id: string, file: File) {
    const imageId = uuidv4()
    const filename = `${imageId}_${file.name}`
    const childPath = `${id}/${filename}`
    await this.storage.child(childPath).put(file)
    await this.storage.child(childPath).getDownloadURL()
    return await authedPost(`/api/features/admin/${id}/generatePublicFiles/${imageId}`, {filename: file.name})
  }

  public async deleteImage(id: string, imageId: string) {
    return await authedPost(`/api/features/admin/${id}/deleteImage/${imageId}`)
  }

  public async create(data: Feature) {
    const feature = await authedPost(`/api/features/admin`, data);
    return Feature.from(feature)
  }
}

export const featuresService = FeaturesService.getInstance();
