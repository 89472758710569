import React from "react";
import {qrcode} from "pure-svg-code";
import classNames from "classnames";
import JsBarcode from 'jsbarcode';

import {Price} from "../../components/Price/Price";
import {Button} from "../../components/Button/Button";
import { Loading } from "../../components/Loading/Loading";
import { Spacer } from "../../components/Spacer/Spacer";

import {useQuery} from "../../hooks/useQuery";

import {useOrdersContext} from "../../contexts/orders.context";
import {useProductsContext} from "../../contexts/products.context";
import { useSocialContext } from "../../contexts/social.context";

import {Order, OrderProduct, PriceCalculationResult, IQuntifiedExtrasParam, OrderShipping} from "../../models/Order.model";
import { getDashboardStorageUrls, getOrdersStorageUrls } from "../../services/firebase";

import { Print } from "../../toneIcons";

import {formatPrice, remoteAsset} from "../../utils"
import { usePatternsContext } from "../../contexts/patterns.context";

import styles from "./ReceiptPage.module.scss";


export const ReceiptPage = () => {
  const [{orderById}, {fetchOrder}] = useOrdersContext();
  const [, {fetchProducts}] = useProductsContext();
  const [, {fetchPatterns}] = usePatternsContext();
  const [order, setOrder] = React.useState<Order | undefined>(undefined);

  const query = useQuery();
  const orderId = query.get("orderId");

  React.useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
      fetchProducts();
    }
  }, [orderId, fetchProducts, fetchOrder]);

  React.useEffect(() => {
    orderId && setOrder(orderById?.[orderId]?.data);
  }, [orderById, orderId]);

  React.useEffect(() => {
    if(fetchPatterns) fetchPatterns();
  }, [fetchPatterns])

  if(orderId == null) return <Loading />

  return <>
    <Button onClick={() => window.print()} className={styles.printButton}><Print /></Button>

    {order && <ReceiptPageWithData order={order} />}
  </>
};

interface ReceiptPageWithDataProps {
  order: Order,
}

export const ReceiptPageWithData: React.FC<ReceiptPageWithDataProps> = ({order}) => {
  const products = React.useMemo(() => order?.products != null ? Object.values(order?.products) : undefined, [order])
  const orderId = order.id;

  return <div className={styles.container}>
    <TopSection order={order} />
    {order && <BottomSection products={products} orderId={orderId} order={order} />}
  </div>
};

interface PriceRowProps {
  title: string,
  value: string
}

const PriceRow: React.FC<PriceRowProps> = ({title, value}) => {
  return <div className={styles.priceRow}>
    <b>{title}</b>
    <span>{value}</span>
  </div>
}

interface BottomSectionProps {
  orderId: string
  products: OrderProduct[] | undefined,
  order: Order,
}

const BottomSection: React.FC<BottomSectionProps> = ({products, orderId, order}) => {
  const laundryBagExtra = {
    name: "Laundry Bag",
    id: 'laundryBag',
    quantity: 1,
    url: remoteAsset("laundryBag-min.jpeg")
  }

  const discount = formatPrice(order.chargedPriceDetails?.itemsDiscountAmount) || ""
  const shipping = formatPrice(order.chargedPriceDetails?.shippingDiscountPrice) || ""
  const tax = formatPrice(order.chargedPriceDetails?.tax) || ""
  const completeTotal = formatPrice(order.chargedPriceDetails?.total) || ""

  return <section className={styles.section}>
    <div className={styles.products}>
      {products?.map( product => <ProductRow orderId={orderId} data={product} priceDetails={order?.chargedPriceDetails} key={product.id} />)}

      <ExtraRow orderId={orderId} data={laundryBagExtra} priceDetails={order?.chargedPriceDetails} />
      <Spacer />
      <div>
        <div className={styles.url}>
          <span className={styles.http}>https://</span>
          <span>FriendlyFace.com</span>
        </div>
        <Barcode value={ "s/" + orderId} />
      </div>
    </div>

    <div className={styles.shipping}>
      {order?.billing && <Location title="Bill to:" data={order?.billing}/>}
      {order?.shipping && <Location title="Ship to:" data={order?.shipping}/>}

      <div className={styles.priceRows}>
        <PriceRow title="Order #" value={orderId} />
        <PriceRow title="Date" value= {order?.createdOn?.toDateString()} />
        <PriceRow title="Discount:" value= {discount} />
        <PriceRow title="Shipping:" value= {shipping} />
        <PriceRow title="Tax:" value= {tax} />
        <PriceRow title="Total:" value= {completeTotal} />
      </div>

      <Spacer />
      <p>For sanitary reasons, we cannot accept returns of masks or gaiters. However, if you feel like your mask was made in error, please reach out and we’ll help you out.</p>
    </div>
  </section>
}

interface TopSectionProps {
  order: Order
}

const TopSection: React.FC<TopSectionProps> = ({order}) => {
  const [{shippingHq, companyName}] = useSocialContext();
  const svgString = qrcode({
    content: "https://friendlyface.listens.in/",
    padding: 0,
    width: 80,
    height: 80,
    color: "#000",
    background: "#ffffff",
    ecl: "M"
  })

  const slicedName = order?.name?.split(" ")
  const name = slicedName?.slice(0, Math.max(slicedName.length - 1, 1)).join(' ');

  return <section className={styles.section}>
    <h1>Hi, {name || "friend"}!</h1>

    <div className={styles.qrContainer}>
      <div dangerouslySetInnerHTML={{"__html": svgString}} className={styles.qrcode}  />
      <span>How can we improve?</span>
    </div>

    <Location data={shippingHq} title={companyName} className={styles.contactUs} />
  </section>
}

interface LocationProps extends React.InputHTMLAttributes<HTMLDivElement> {
  title?: string
  data: OrderShipping
}

const Location: React.FC<LocationProps> = ({className, data, title}) => {
  return <div className={classNames(styles.location, className)}>
  {title && <b className={styles.locationTitle}>{title}</b>}
  <p className={styles.locationAddress}>{data?.line1} {data?.line2}</p>
  <p className={styles.locationRegion}>{data?.city}, {data?.state} {data?.postal_code}</p>
</div>
}


interface ProductRowProps {
  orderId: string
  data: OrderProduct
  priceDetails?: PriceCalculationResult
}


const ProductRow: React.FC<ProductRowProps> = ({data, priceDetails, orderId}) => {
  const [{productById}] = useProductsContext()
  const [{patternById}] = usePatternsContext()
  const [images, setImages] = React.useState<any>({});

  const productId = data.id
  const pattern = data.patternId ? patternById[data.patternId] : undefined;

  const fetchImages = React.useCallback(async (orderId, productId) => {
    const [orderImages, dashboardImages] = await Promise.all( [getOrdersStorageUrls(orderId, productId), getDashboardStorageUrls(orderId, productId)] )
    const images = {
      ...orderImages,
      ...dashboardImages
    }
    setImages(images)
  }, [])

  React.useEffect(() => {
    if (orderId && productId != null) {
      fetchImages(orderId, productId)
    }
  }, [orderId, productId, fetchImages]);

  const price = priceDetails?.items[productId]

  const maskId = data.maskId;
  if(maskId == null) return null;
  const product = productById[maskId];
  if(product == null) return <Loading />

  const imgUrl = images[ data.rawFaceFileName || 'raw_face.jpg' ]
  const canHaveFace = product.data?.categoryId === 'mask' || product.data?.categoryId === 'gaiter' || product.data?.categoryId === 'mug'

  const patternUrl = pattern?.data?.s88Url;
  const patternHex = pattern?.data?.hex

  return <div className={styles.product}>
    {canHaveFace && <div className={styles.box}>
      <img alt="face" src={imgUrl}/>
    </div>}
    {!canHaveFace && <div className={styles.box}>
      {product.data?.url && <img alt="product" src={product.data?.url}/>}
    </div>}
    {patternUrl && <div className={styles.box}>
      <img alt="pattern" src= {patternUrl} />
      {pattern?.data?.name && <div className={styles.boxTitle}>{pattern?.data?.name}</div>}
    </div>}
    {!patternUrl && patternHex && <div className={styles.box}>
      {pattern?.data?.name && <div className={styles.boxTitle}>{pattern?.data?.name}</div>}  
    </div>}
    {!patternUrl && !patternHex && <div className={styles.box} />}

    <div className={styles.productText}>
      <p className={styles.productTitle}>{product.data?.displayName}</p>
      <p className={styles.productSubTitle}>{data.enhance ? "+ Retouching" : undefined}</p>
    </div>

    <Price className={styles.price}>{formatPrice(price?.unitDiscountPrice || 0)}</Price>
  </div>
};

interface ExtrasRowProps {
  orderId: string
  data: IQuntifiedExtrasParam
  priceDetails?: PriceCalculationResult
}

const ExtraRow: React.FC<ExtrasRowProps> = ({data, priceDetails}) => {
  const price = priceDetails?.extras?.[data.id];

  return <div className={classNames(styles.product)}>
    <div className={styles.box}>
      {data?.url && <img alt="face" src={data?.url}/>}
    </div>
    <div className={styles.box} />

    <div className={styles.productText}>
      <p className={styles.productTitle}>{data?.name} {data.quantity > 1 ? `x${data.quantity}` : undefined}</p>
      <p className={styles.productSubTitle}>{data.description}</p>
    </div>

    <Price className={styles.price}>{formatPrice(price?.unitDiscountPrice || 0)}</Price>
  </div>
};

interface BarcodeProps {
  value: string
}

const Barcode: React.FC<BarcodeProps> = ({value}) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if(ref.current) {
      const options = {
        displayValue: false,
        background: "white",
        height: 30,
        width: 1,
      }
      JsBarcode(ref.current, value, options)
    }
    
  }, [ref, value])

  return <div className={styles.barcode}>
    <svg ref={ref} />
  </div>
}