import React from 'react'
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";

import { useQuery } from '../../hooks/useQuery';
import { TableHeader } from '../../components/TableHeader/TableHeader';

import styles from './Features.module.scss'
import Table from '../../components/Table/Table';
import CellHeader from '../../components/CellHeader/CellHeader';
import { useFeaturesContext } from '../../contexts/features.context';
import { Meta, shouldRefetch } from '../../utils';
import Cell from '../../components/Cell/Cell';
import { Feature as FeatureType } from '../../models/Feature.model'
import DeletePopup from '../../popups/DeletePopup/DeletePopup';
import { SidebarContainer } from '../../components/SidebarContainer/SidebarContainer';
import CreateFeaturePopup from '../../components/CreatePopup/CreateFeature';
import { Button } from '../../components/Button/Button';
import {FeatureSidebar} from '../../components/FeatureSidebar/FeatureSidebar'


export const Features = () => {
  const [{featureIds, featureById}, {fetchFeatures, deleteFeature, createFeature}] = useFeaturesContext()
  const query = useQuery()
  const history = useHistory();
  const [featureToCreate, setFeatureToCreate] = React.useState<boolean>();
  const [featureIdToDelete, setFeatureIdToDelete] = React.useState<string | undefined>();

  const shouldFetchFeatures = shouldRefetch(featureIds)
  React.useEffect(() => {
    if(shouldFetchFeatures) {
      fetchFeatures()
    }
  }, [fetchFeatures, shouldFetchFeatures])

  const selectedFeatureId = query.get('featureId')
  const selectedFeature = selectedFeatureId != null ? featureById?.[selectedFeatureId] : undefined

  const deleteConfirmationHandler = async (id: string) => {
    await deleteFeature(id)
    history.push({pathname: history.location.pathname})
    setFeatureIdToDelete(undefined)
  };

  const createConfirmationHandler = async (data: Partial<FeatureType>) => {
    await createFeature(data)
    setFeatureToCreate(false)
  };


  const style = {
    '--sidepanel-width': (selectedFeatureId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;
  const columns = "max-content max-content minmax(max-content, 1fr)"

  return <div className={styles.container} style={style}>
    <Helmet>
      <title>Features - FriendlyFace</title>
    </Helmet>
    <TableHeader>
      <Button onClick={() => setFeatureToCreate(true)}>Create Feature</Button>
    </TableHeader>
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader>NAME</CellHeader>
        <CellHeader>ID</CellHeader>
        <CellHeader>Description</CellHeader>

        {featureIds?.data?.map((id, index) => {
          const feature = featureById?.[id];
          if(feature == null) return null;
          return <FeaturesCells index={index}
                        data={feature}
                        key={id}
                        onClickRow={() => history.push(`?featureId=${id}`)}
                        active={selectedFeatureId === id}
                        onDelete={setFeatureIdToDelete} />
        })}

      </Table>
    </div>

    {featureToCreate && <CreateFeaturePopup onSubmit={createConfirmationHandler} onClose={() => setFeatureToCreate(false)}/>}
    {featureIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(featureIdToDelete)} onClose={() => setFeatureIdToDelete(undefined)} />}

    {selectedFeatureId && <SidebarContainer updating={selectedFeature?.meta?.updating}>
      <FeatureSidebar
        featureId={selectedFeatureId}
        key={selectedFeatureId}
        onClose={() => history.push({pathname: history.location.pathname})}
        onDelete={() => setFeatureIdToDelete(selectedFeatureId)} />
    </SidebarContainer>}
  </div>
}

interface FeatureCellsProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number,
  data: Meta<FeatureType>,
  onClickRow: any,
  active: boolean,
  onDelete: any
}

const FeaturesCells: React.FC<FeatureCellsProps> = ({index, data, onClickRow, active}) => {
  const props = {
    index: index,
    active: active,
    deleting: false,
    onClick: () => onClickRow(),
    className: styles.pointer
  }

  const disabledIcon = data.data?.disabled ? "🚫" : "✅"

  return <>
    <Cell {...props}>{disabledIcon} {data.data?.name}</Cell>
    <Cell {...props}>{data.data?.id}</Cell>
    <Cell {...props}>{data.data?.description}</Cell>
  </>
};