import React from 'react'
import classNames from 'classnames'
import { clamp } from 'lodash'

import styles from './Range.module.scss'

interface RangeProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: number,
  onUpdateValue: (val: number) => void,
  setIsRotating?: React.Dispatch<React.SetStateAction<boolean>>,
  showCircle?: boolean,
  direction?: "horizontal" | "vertical",
  lines?: number[]
}

interface TouchPosition {
  x: number,
  y: number,
  boundW?: number,
  boundH?: number,
  startValue?: number,
}

const elWidth = 5 + 5 + 1;

export const Range: React.FC<RangeProps> = ({className, value, min, max, onUpdateValue, setIsRotating, showCircle=true, direction = "horizontal", lines = [0.5]}) => {
  const requestRef = React.useRef<number>(null)
  const [dragStartPosition, setDragStartPosition] = React.useState<TouchPosition>();
  const [dragingPosition, setDragingPosition] = React.useState<TouchPosition>();
  const minNumber = Number.parseInt(`${min}`) || 0;
  const maxNumber = Number.parseInt(`${max}`) || 0;

  const length = maxNumber - minNumber + 1;

  const range = Array.from({length: length}, (_, i) => i + minNumber);
  const transformScale = 100*(value - minNumber)/(maxNumber - minNumber);

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    const position = {
      x: touch.screenX,
      y: touch.screenY,
      startValue: value
    }
    if(setIsRotating) setIsRotating(true)
    setDragStartPosition(position)
  }
  const onTouchEnd = () => {
    if(setIsRotating) setIsRotating(false)
    setDragStartPosition(undefined)
    setDragingPosition(undefined)

    if(requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const position = {
      x: e.screenX,
      y: e.screenY,
      startValue: value,
    }
    
    if(setIsRotating) setIsRotating(true)
    setDragStartPosition(position)
  }
  const onMouseUp = () => {
    if(setIsRotating) setIsRotating(false)
    setDragStartPosition(undefined)
    setDragingPosition(undefined)

    if(requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }

  const onMouseLeave = () => {
    console.log("onMouseLeave")
    if(setIsRotating) setIsRotating(false)
    setDragStartPosition(undefined)
    setDragingPosition(undefined)

    if(requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }

  const shouldAttacMove = dragStartPosition !== undefined
  React.useEffect(() => {
    const mouseMove = (e:any) => {
      const position = {x: e.screenX, y: e.screenY}

      if(requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      (requestRef.current as any) = requestAnimationFrame(() => {
        setDragingPosition(position);
      })
    }
    const touchMove = (e:any) => {
      const touch = e.touches[0];
      const position = {x: touch.screenX, y: touch.screenY}
    
      if(requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      (requestRef.current as any) = requestAnimationFrame(() => { setDragingPosition(position); })
    }
    if(shouldAttacMove) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('touchmove', touchMove);
      return () => {
        window.removeEventListener('mousemove', mouseMove)
        window.addEventListener('touchmove', touchMove);
      } 
    }
  }, [shouldAttacMove])

  const startValue = dragStartPosition?.startValue || 0
  let dragDiff
  if(direction === 'horizontal' && dragingPosition?.x != null && dragStartPosition?.x != null ) {
    dragDiff = dragingPosition.x - dragStartPosition.x
  } else if(direction === 'vertical' && dragingPosition?.y != null && dragStartPosition?.y != null ) {
    dragDiff = dragingPosition.y - dragStartPosition.y
  }
  const newValue =  dragDiff ? clamp(dragDiff/elWidth + startValue, minNumber, maxNumber) : value
  React.useEffect(() => {
    onUpdateValue(newValue);
  }, [newValue, onUpdateValue])
  
  let transform
  if(direction === 'horizontal') transform = `translate(${-transformScale}%, 0)`
  else if(direction === 'vertical') transform = `translate(0, ${-transformScale}%)`

  const classList = classNames(
    className,
    styles.range,
    styles[direction],
    dragingPosition != null ? styles.dragging : undefined
  )

  return <div className={classList} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseLeave={onMouseLeave}>
    {showCircle && <div className={styles.valueCircle}>{value?.toFixed(1)}</div>}
    <div className={styles.inputRange}>
      {lines.map(i => <LineWithCircle direction={direction} offset={i} key={i} />)}
      {showCircle && <div className={styles.inputRangeLines} style={{transform}}>
        {range.map(r => {
          const className = classNames(
            styles.line,
            (r % 10 === 0) ? styles.bigLine : undefined
          )
          return <div key={r} className={className} />
        })}
      </div>}
    </div>
  </div>
}

interface LineWithCircle {
  offset: number,
  direction: "horizontal" | "vertical",
}

const LineWithCircle: React.FC<LineWithCircle> = ({direction, offset}) => {
  let lineStyle = {}
  const ratio = 100 * offset;
  if(direction === 'horizontal') {
    lineStyle = {
      bottom: "0",
      left: `${ratio}%`,
    }
  } else if(direction === 'vertical') {
    lineStyle = {
      right: "0",
      top: `${ratio}%`,
    }
  }

  return <div className={styles.inputRangeCenterLine} style={lineStyle} />
}