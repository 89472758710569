import React, {FormEventHandler} from "react"
import firebase from "firebase"
import classNames from "classnames"

import {Input, Textarea} from "../../../components/Input/Input";
import {Button} from "../../../components/Button/Button"
import {StringMap} from "../../../utils"
import {GiftCard, GiftCardWId} from "../../../models/GiftCard.model"


import styles from "./GiftCardForm.module.scss"
import {SwitchInput} from "../../../components/Input/SwitchInput"
import moment from "moment"
import {CheckboxFields} from "./CheckboxFields"

interface Props {
  onSuccess: (details: Partial<GiftCard>) => void,
  giftCard?: GiftCardWId
}

export const GiftCardForm: React.FC<Props> = ({onSuccess, giftCard}) => {
  const [errors, setErrors] = React.useState<StringMap>({});
  const [expirable, setExpirable] = React.useState(!!giftCard?.expiresOn);
  const [values, setValues] = React.useState<Partial<GiftCard & { validFor?: number }>>({
    type: giftCard?.type || "electronic",
    to: giftCard?.to || "",
    from: giftCard?.from || "",
    message: giftCard?.message || "",
    emailToBeSentOn: giftCard?.emailToBeSentOn,
    expiresOn: giftCard?.expiresOn,
    email: giftCard?.email || "",
    currentValue: giftCard?.currentValue || 0,
  });

  const isElectronicGift = values?.type === "electronic"
  const createdDate = moment(giftCard?.createdOn.toDate())

  const submitHandler: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()

    onSuccess({
      ...values,
      currentValue: giftCard ? giftCard.currentValue : values.currentValue,
      expiresOn: expirable ? values.expiresOn : undefined,
      type: values?.type || "electronic"
    })
  }

  const onChange = (e: any) => {
    const name = e.target.name
    let value = e.target.value
    if (name === "emailToBeSentOn") {
      value = firebase.firestore.Timestamp.fromDate(new Date(value));
    }
    if (name === "expiresOn") {
      value = firebase.firestore.Timestamp.fromDate(
        moment(giftCard?.createdOn.toDate()).endOf("d").add(value, "d").toDate()
      )
    }
    setErrors(errors => ({...errors, [name]: ""}));
    setValues(values => ({...values, [name]: value || ""}))
  }

  const props = (name: string) => ({name, error: errors[name], onChange, value: (values as any)[name]})

  const dateValue = values.emailToBeSentOn?.toDate?.()?.toISOString()?.substr(0, 10);
  const expiresOnValue = moment(values?.expiresOn?.toDate()).diff(createdDate, "d");
  const expiresOnVisibleDate = moment(values.expiresOn?.toDate()).format("Do MMM YY")

  return <form className={styles.container} onSubmit={submitHandler}>
    <div className={styles.type}>
      <span className={classNames(values.type === "electronic" && styles.selected)}
            onClick={() => setValues(values => ({...values, type: "electronic"}))}>Electronic</span>
      <span className={classNames(values.type === "classic" && styles.selected)}
            onClick={() => setValues(values => ({...values, type: "classic"}))}>Classic</span>
    </div>

    <Input {...props("to")} title="To" required/>
    <Input {...props("from")} title="From" required/>
    <Textarea {...props("message")} title="Personal Message (Optional)"/>
    {!giftCard && <Input {...props("currentValue")} title="Value (in cents)" type="number" required/>}

    {
      isElectronicGift && <>
        <Input {...props("emailToBeSentOn")} value={dateValue} type="date" required
               title="Email to be sent on"/>
        <Input {...props("email")} type="email" title="Recipient’s email *" required/>
      </>
    }

    <CheckboxFields giftCard={giftCard}/>
    <SwitchInput title="Expirable" onChange={setExpirable} defaultValue={expirable}/>

    {
      expirable && <Input {...props("expiresOn")}
                          value={expiresOnValue}
                          className={styles.validForInput}
                          type="number" required
                          title={`Expires in days (${expiresOnVisibleDate})`}
      />
    }
    <Button disabled={false} type="submit">Save</Button>
  </form>
}
