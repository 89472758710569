import Tooltip from 'rc-tooltip'
import React from 'react'
import {Delete, FirebaseDB, FirebaseStorage} from '../../toneIcons'
import { Spacer } from '../Spacer/Spacer'

import styles from './SidebarHeader.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void,
  title?: string
}

export const SidebarHeader: React.FC<Props> = ({children, title, onClose}) => {
  return <header className={styles.header}>
    {title != null && <div className={styles.title}>
      {title}
    </div>}
    {children}
    <Spacer />
    {onClose != null && <SidebarHeaderButton onClick={onClose}>
      <Delete />
    </SidebarHeaderButton>}
  </header>
}

export const SidebarHeaderButton: React.FC<React.HtmlHTMLAttributes<HTMLButtonElement>> = ({onClick, title, children}) => {
  if(title == null) {
    return <button className={styles.closeButton} onClick={onClick}>
      {children}
    </button>
  }
  return <button className={styles.closeButton} onClick={onClick}>
    {children}
  </button>
}

export const SidebarHeaderLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({href, title, children}) => {
  if(title == null) {
    return <a className={styles.closeButton} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  }
  return <Tooltip placement="bottom" overlay={title}>
    <a className={styles.closeButton} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  </Tooltip>
}

interface LinkProps {
  href: string | undefined
}

export const FirebaseDBLink: React.FC<LinkProps> = ({href}) => {
  if(href == null) return null
  return <SidebarHeaderLink href={href} title="FirebaseDB Link"> <FirebaseDB /> </SidebarHeaderLink>
}

export const FirebaseStorageLink: React.FC<LinkProps> = ({href}) => {
  if(href == null) return null
  return <SidebarHeaderLink href={href} title="FirebaseDB Storage"> <FirebaseStorage /> </SidebarHeaderLink>
}