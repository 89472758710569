import {getMasksStorage} from "./firebase";
import firebase from "firebase";
import { Product } from "../models/Product";
import { authedDelete, authedGet, authedPost } from "./functions.service";

class ProductsService {
  private static instance: ProductsService;

  get storage(): firebase.storage.Reference {
    return getMasksStorage().ref()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ProductsService();
    }
    return this.instance;
  }

  public async getAll(): Promise<Product[]> {
    const {products} = await authedGet(`/api/products/admin`);
    return products.map(Product.from) || []
  }

  public async get(id: string): Promise<Product> {
    const product = await authedGet(`/api/products/admin/${id}`);
    return Product.from(product)
  }

  public async update(id: string, data: Partial<Product>): Promise<Product> {
    const product = await authedPost(`/api/products/admin/${id}`, data);
    return Product.from(product)
  }

  public async uploadFile(id: string, file: File) {
    const childPath = `${id}/${file.name}`
    await this.storage.child(childPath).put(file)
    await this.storage.child(childPath).getDownloadURL()
    await authedPost(`/api/products/generatePublicMaskFile/${id}`, {filename: file.name})
  }

  public async delete(id: string): Promise<void> {
    return await authedDelete(`/api/products/admin/${id}`)
  }

  public async create(data: Product): Promise<Product> {
    const product = await authedPost(`/api/products/admin`, data);
    return Product.from(product)
  }
}

export const productsService = ProductsService.getInstance();
