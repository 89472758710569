import React from 'react'
import classNames from 'classnames'
import { Toast as ToastModel } from '../../models/Toast.model'

import styles from './Toast.module.scss'

interface ToastProps {
  data: ToastModel,
  shown: boolean
}
export const Toast: React.FC<ToastProps> = ({data, shown}) => {
  const classList = classNames(
    shown ? styles.shown : undefined,
    styles.container,
  )
  return <div className={classList} key={data.id}>
    <div className={styles.content}>{data.text}</div>
  </div>
}