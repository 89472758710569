import React from 'react'
import { ProductAchor, ProductAnchorValue } from '../../models/Product'

import styles from './NineCircles.module.scss'
interface NineCirclesProps extends React.HTMLAttributes<HTMLInputElement> {
  title: string,
  name: string,
  anchor: ProductAchor
}

export const NineCircles: React.FC<NineCirclesProps> = ({title, anchor, onChange, name, className}) => {
  const horizontal = anchor?.horizontal;
  const vertical = anchor?.vertical;

  const _onChange = (horizontal: ProductAnchorValue, vertical: ProductAnchorValue) => () => {
    const event = {
      currentTarget: {
        name: name,
        value: {
          horizontal, vertical
        } as any
      }
    } as React.FormEvent<HTMLInputElement>;
    
    onChange?.(event)
  }

  return <div className={className}>
    <div>{title}</div>
    <div className={styles.circles}>
      <div className={[styles.circle, (horizontal === 'start' && vertical === 'start') ? styles.selected : undefined ].join(" ")} onClick={_onChange("start", "start")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'center' && vertical === 'start') ? styles.selected : undefined ].join(" ")} onClick={_onChange("center", "start")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'end' && vertical === 'start') ? styles.selected : undefined ].join(" ")} onClick={_onChange("end", "start")} />

      <div className={styles.vDash} />
      <div className={styles.spacer} />
      <div className={styles.vDash} />
      <div className={styles.spacer} />
      <div className={styles.vDash} />
      
      <div className={[styles.circle, (horizontal === 'start' && vertical === 'center') ? styles.selected : undefined ].join(" ")} onClick={_onChange("start", "center")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'center' && vertical === 'center') ? styles.selected : undefined ].join(" ")} onClick={_onChange("center", "center")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'end' && vertical === 'center') ? styles.selected : undefined ].join(" ")} onClick={_onChange("end", "center")} />

      <div className={styles.vDash} />
      <div className={styles.spacer} />
      <div className={styles.vDash} />
      <div className={styles.spacer} />
      <div className={styles.vDash} />

      <div className={[styles.circle, (horizontal === 'start' && vertical === 'end') ? styles.selected : undefined ].join(" ")} onClick={_onChange("start", "end")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'center' && vertical === 'end') ? styles.selected : undefined ].join(" ")} onClick={_onChange("center", "end")} />
      <div className={styles.dash} />
      <div className={[styles.circle, (horizontal === 'end' && vertical === 'end') ? styles.selected : undefined ].join(" ")} onClick={_onChange("end", "end")} />
    </div>
  </div>
}