import React from 'react'
import {Link} from 'react-router-dom'

import styles from './Home.module.scss'

export const Home = () => {
  return <div className={styles.container}>
    <div className={styles.content}>
      <h1 className={styles.heading}>dashboard<br/><span>with great power comes great responsibility</span></h1>
      <Link to="/login" className={styles.button}>Login</Link>
    </div>
  </div>
}
