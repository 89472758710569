import React from 'react'
import classNames from 'classnames'

import styles from './SquareQuery.module.scss'


interface SquareQueryProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string | undefined,
  onQuerySubmit: (value: string | undefined) => void
}

export const SquareQuery: React.FC<SquareQueryProps> = ({onQuerySubmit, value, className}) => {
  const [internalValue, setInternalValue] = React.useState(value)
  const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onQuerySubmit(internalValue)
  }

  return <form className={classNames(styles.query, className)} onSubmit={_onSubmit}>
    <input value={internalValue || ""} onChange={(e) => setInternalValue(e.target.value)} />
    <input type="submit" value="Search" className={styles.submitButton} />
  </form>
}