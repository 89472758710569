import {Meta} from '../utils'
import {Product} from '../models/Product'
import {mapValues, sortBy, groupBy, without, omit} from 'lodash'


export type ContextPropsState = {
  productById: { [key: string]: Meta<Product> },
  productIds: Meta<string[]>,
}

export const initialState: ContextPropsState = {
  productIds: {},
  productById: {},
};

type PatternsAction = 
  | { type: "FETCH_ALL_PRODUCTS" }
  | { type: "FETCH_ALL_PRODUCTS_SUCCESS", products: Product[] }
  | { type: "FETCH_ALL_PRODUCTS_ERROR", error: any }

  | { type: "UPDATE_PRODUCT", id: string }
  | { type: "UPDATE_PRODUCT_SUCCESS", id: string, data: Product }
  | { type: "UPDATE_PRODUCT_ERROR", id: string, error: any }

  | { type: "DELETE_PRODUCT", id: string }
  | { type: "DELETE_PRODUCT_SUCCESS", id: string }
  | { type: "DELETE_PRODUCT_ERROR", id: string, error: any }

  | { type: "CREATE_PRODUCT" }
  | { type: "CREATE_PRODUCT_SUCCESS", data: Product }
  | { type: "CREATE_PRODUCT_ERROR", error: any }

export const reducer = (state: ContextPropsState, action: PatternsAction):ContextPropsState => {
  // FETCH
  if( "FETCH_ALL_PRODUCTS" === action.type) {
    const newProductIds: Meta<string[]> = {
      data: state.productIds?.data,
      meta: {
        ...state.productIds.meta,
        fetching: true
      }
    }
    return {
      ...state,
      productIds: newProductIds,
    };
  }
  if( "FETCH_ALL_PRODUCTS_SUCCESS" === action.type) {
    if(action.products == null) return state;

    const newProductIds: Meta<string[]> = {
      data: sortBy(action.products, p => p.order).map(p => p.id),
      meta: {
        ...state.productIds.meta,
        fetching: false
      }
    }
     
    const productById = mapValues(groupBy(action.products, p => p.id), p => ({data: p[0]}) );
    return {
      ...state,
      productById,
      productIds: newProductIds,
    }
  }
  if( "FETCH_ALL_PRODUCTS_ERROR" === action.type) {
    const newProductIds: Meta<string[]> = {
      data: state.productIds?.data,
      meta: {
        ...state.productIds.meta,
        fetching: false,
        error: action.error
      }
    }
    return {
      ...state,
      productIds: newProductIds,
    };
  }

  // UPDATE
  if( "UPDATE_PRODUCT" === action.type) {
    if(action.id == null) return state;

    const currentProduct = state.productById[action.id];
    const newProduct: Meta<Product> = {
      data: currentProduct?.data,
      meta: {
        ...(currentProduct?.meta || {}),
        updating: true
      }
    }

    const newProductById = {
      ...state.productById,
      [action.id]: newProduct
    }

    return {
      ...state,
      productById: newProductById
    };
  }
  if( "UPDATE_PRODUCT_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentProduct = state.productById[action.id];
    const newProduct: Meta<Product> = {
      data: action.data,
      meta: {
        ...(currentProduct?.meta || {}),
        updating: false
      }
    }

    const newProductById = {
      ...state.productById,
      [action.id]: newProduct
    }

    return {
      ...state,
      productById: newProductById
    };
  }
  if( "UPDATE_PRODUCT_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentProduct = state.productById[action.id];
    const newProduct: Meta<Product> = {
      data: currentProduct?.data,
      meta: {
        ...(currentProduct?.meta || {}),
        updating: false,
        error: action.error
      }
    }

    const newProductById = {
      ...state.productById,
      [action.id]: newProduct
    }

    return {
      ...state,
      productById: newProductById
    };
  }


  // DELETE
  if( "DELETE_PRODUCT" === action.type) {
    if(action.id == null) return state;

    const currentProduct = state.productById[action.id];
    const newProduct: Meta<Product> = {
      data: currentProduct?.data,
      meta: {
        ...(currentProduct?.meta || {}),
        deleting: true
      }
    }

    const newProductById = {
      ...state.productById,
      [action.id]: newProduct
    }

    return {
      ...state,
      productById: newProductById
    };
  }
  if( "DELETE_PRODUCT_SUCCESS" === action.type) {
    if(action.id == null) return state;

    const newProductById = omit(state.productById, [action.id])
    const newProductIds = {
      data: without(state.productIds.data, action.id),
      meta: state.productIds.meta
    }

    return {
      ...state,
      productById: newProductById,
      productIds: newProductIds,
    };
  }
  if( "DELETE_PRODUCT_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentProduct = state.productById[action.id];
    const newProduct: Meta<Product> = {
      data: currentProduct?.data,
      meta: {
        ...(currentProduct?.meta || {}),
        deleting: false,
        error: action.error
      }
    }

    const newProductById = {
      ...state.productById,
      [action.id]: newProduct
    }

    return {
      ...state,
      productById: newProductById
    };
  }


  // CREATE
  if( "CREATE_PRODUCT" === action.type) {
    return state
  }
  if( "CREATE_PRODUCT_SUCCESS" === action.type) {
    if(action.data == null) return state

    const newProductById = {
      ...state.productById,
      [action.data.id]: {
        data: action.data
      }
    }

    const newProductIds = {
      data: sortBy(([...(state.productIds?.data || []), action.data.id]), id => newProductById?.[id]?.data?.order || ""),
      meta: state.productIds.meta
    }

    return {
      ...state,
      productById: newProductById,
      productIds: newProductIds,
    };
  }
  if( "CREATE_PRODUCT_ERROR" === action.type) {
    return state
  }


  return state;
}
