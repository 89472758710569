import React from 'react'
import {groupBy, mapValues, omit, without} from 'lodash'

import {Meta} from '../utils'

import {Review} from "../models/Review";
import {reviewsService} from "../services/reviews.service";

type ContextPropsState = {
  reviewsById: { [key: string]: Meta<Review> },
  reviewIds: string[],
}
type ContextPropsActions = {
  fetchReviews: () => void,
  updateReview: (id: string, data: Partial<Review>) => void,
  deleteReview: (id: string) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialState = {
  reviewsById: {},
  reviewIds: [],
};
const initialActions = {
  fetchReviews: () => {
    throw new Error("fetchReviews not implemented")
  },
  updateReview: () => {
    throw new Error("updateReview not implemented")
  },
  deleteReview: () => {
    throw new Error("deleteReview not implemented")
  },
}

export const ReviewsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const ReviewsProvider = ({children}: any) => {
  const [reviewsById, setReviewsById] = React.useState<{ [key: string]: Meta<Review> }>({});
  const [reviewIds, setReviewIds] = React.useState<string[]>([]);

  const fetchReviews = React.useCallback(async () => {
    const reviews = await reviewsService.getAll()
    if (reviews) {
      setReviewsById(mapValues(groupBy(reviews, p => p.id), p => ({data: p[0]})));
      setReviewIds(reviews.map(r => r.id));
    }
  }, [setReviewsById, setReviewIds]);

  const updateReview = React.useCallback(async (id, data) => {
    setReviewsById(reviewsById => {
      const currentReview = reviewsById?.[id]
      return {
        ...reviewsById,
        [id]: {
          data: currentReview?.data,
          meta: {
            ...(currentReview?.meta || {}),
            updating: true
          }
        }
      }
    })
    await reviewsService.update(id, data)
    setReviewsById(reviewsById => {
      const currentReview = reviewsById?.[id]
      return {
        ...reviewsById,
        [id]: {
          data: {...currentReview.data, ...data},
          meta: {
            ...(currentReview?.meta || {}),
            updating: false
          }
        }
      }
    })
  }, [])

  const deleteReview = React.useCallback(async (id) => {
    await reviewsService.delete(id)
    setReviewsById(reciewsById => omit(reciewsById, [id]))
    setReviewIds(ids => without(ids, id))
  }, [])

  const value: ContextProps = [{
    reviewIds,
    reviewsById
  }, {
    fetchReviews,
    updateReview,
    deleteReview
  }]

  return <ReviewsContext.Provider value={value}>
    {children}
  </ReviewsContext.Provider>
}

export const useReviewsContext = () => React.useContext(ReviewsContext)
