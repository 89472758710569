import {getDB, getTmpStorage} from "./firebase";
import firebase from "firebase";
import { Order } from "../models/Order.model";
import { authedGet, authedPost } from "./functions.service";
import { OrderStatus } from "../../../api/src/models/statusHistory";

export type Email = {
  id: string,
  status: string,
  receivedAt: string,
  subject: string
}

class OrdersService {
  private static instance: OrdersService;

  get storage(): firebase.storage.Reference {
    return getTmpStorage().ref()
  }

  get collection(): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return getDB().collection("orders");
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new OrdersService();
    }
    return this.instance;
  }

  public async getAll(query?: string, status?: string, perPage?:number, prevPageId?: string, nextPageId?: string) {
    const params:any = {}
    if (prevPageId != null) {
      params.prevPageId = prevPageId
    }
    if (nextPageId != null) {
      params.nextPageId = nextPageId
    }
    if (perPage != null) {
      params.perPage = perPage
    }
    if (status != null) {
      params.status = status
    }
    if (query != null) {
      params.query = query
    }
    const res = await authedGet(`/api/admin/orders`, params)

    return {
      prevPageId: res.prevPageId,
      nextPageId: res.nextPageId,
      orders: res.orders?.map(Order.from),
      perPage: res.perPage
    }

  }

  public async get(id: string) {
    const order = await authedGet(`/api/admin/orders/${id}`)
    return Order.from(order)
  }

  public async getOrderToVerify() {
    const order = await authedGet(`/api/admin/orders/toVerify`)
    return Order.from(order)
  }

  public async update(orderId: string, data: any) {
    await this.collection.doc(orderId).update(data)
    return await this.get(orderId)
  }

  public async updateProduct(orderId: string, productId: string, data: any) {
    await authedPost(`/api/orders/${orderId}/products/${productId}`, data);
    return await this.get(orderId)
  }

  public async delete(orderId: string) {
    return this.collection.doc(orderId).delete()
  }

  public async moveImages(orderId: string) {
    return authedPost(`/api/admin/orders/${orderId}/moveImages`, {});
  }

  public async resendOrderConfirmation(orderId: string) {
    return authedPost(`/api/admin/orders/${orderId}/sendConfirmEmail`, {});
  }

  public async resendShippedEmail(orderId: string, force: boolean) {
    return authedPost(`/api/admin/orders/${orderId}/sendShippedEmail`, {force});
  }
  
  public async resendBadSelfieEmail(orderId: string) {
    return authedPost(`/api/admin/orders/${orderId}/sendBadSelfieEmail`, {});
  }

  public async resendNoSelfieEmail(orderId: string) {
    return authedPost(`/api/admin/orders/${orderId}/sendNoSelfieEmail`, {});
  }

  public async createShippingLabel(orderId: string, force = false) {
    return authedPost(`/api/admin/orders/${orderId}/createShippingLabel`, {orderId, force});
  }

  public async cloneOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/clone`, {});
    return this.get(orderId)
  }

  public async deleteOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/delete`, {});
    return this.get(orderId)
  }

  public async approveOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/approve`, {});
    return this.get(orderId)
  }

  public async markPrintedOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/printed`, {});
    return this.get(orderId)
  }

  public async rejectOrderProduct(orderId: string, productId: string, data: {[key: string] : any}) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/reject`, data);
    return await this.get(orderId)
  }

  public async unsureOrderProduct(orderId: string, productId: string, data: {[key: string] : any}) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/unsure`, data);
    return await this.get(orderId)
  }

  public async reprintOrderProduct(orderId: string, productId: string, data: {[key: string] : any}) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/reprint`, data);
    return await this.get(orderId)
  }

  public async eraseRotatedMeta(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/eraseRotatedMeta`);
    return await this.get(orderId)
  }

  public async eraseIOSFaceOval(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/eraseIOSFaceOval`);
    return await this.get(orderId)
  }

  public async printifyOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/printify`);
    return await this.get(orderId)
  }

  public async unprintifyOrderProduct(orderId: string, productId: string) {
    await authedPost(`/api/orders/${orderId}/products/${productId}/unprintify`);
    return await this.get(orderId)
  }

  public async splitOrder(orderId: string, productIds: string[]) {
    const {splitOrderId} = await authedPost(`/api/admin/orders/${orderId}/split`, {productIds});
    const order = await this.get(orderId)
    const splitOrder = await this.get(splitOrderId)
    return {order, splitOrder}
  }

  public async joinOrder(orderId: string, joinOrderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/join`, {joinOrderId});
    return await this.get(orderId)
  }

  public async generateImageReupload(orderId: string) {
    return await authedGet(`/api/admin/orders/${orderId}/reuploadUrl`);
  }

  public async cropOrder(orderId: string) {
    return authedPost(`/api/admin/orders/${orderId}/crop`, {});
  }

  public async printifyOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/printify`, {});
    return await this.get(orderId)
  }

  public async unprintifyOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/unPrintify`, {});
    return await this.get(orderId)
  }


  public async markCroppedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markCropped`, {});
    return await this.get(orderId)
  }

  public async undoCroppedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoCropped`, {});
    return await this.get(orderId)
  }

  public async markPrintedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markPrinted`, {});
    return await this.get(orderId)
  }

  public async undoPrintedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoPrinted`, {});
    return await this.get(orderId)
  }

  public async markPackagedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markPackaged`, {});
    return await this.get(orderId)
  }

  public async undoPackagedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoPackaged`, {});
    return await this.get(orderId)
  }

  public async markShippedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markShipped`, {});
    return await this.get(orderId)
  }

  public async undoShippedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoShipped`, {});
    return await this.get(orderId)
  }

  public async markDoNotPrintOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markDoNotPrint`, {});
    return await this.get(orderId)
  }

  public async undoDoNotPrintOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoDoNotPrint`, {});
    return await this.get(orderId)
  }

  public async markEmailedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/markEmailed`, {});
    return await this.get(orderId)
  }

  public async undoEmailedOrder(orderId: string) {
    await authedPost(`/api/admin/orders/${orderId}/undoEmailed`, {});
    return await this.get(orderId)
  }

  public async setOrderStatus(orderId: string, status: OrderStatus) {
    await authedPost(`/api/admin/orders/${orderId}/setStatus`, { status });
    return await this.get(orderId)
  }

  public async undoOrderStatus(orderId: string, status: OrderStatus) {
    await authedPost(`/api/admin/orders/${orderId}/undoStatus`, { status });
    return await this.get(orderId)
  }

  public async uploadFile(orderId: string, productId: string, uid: string, file: File) {
    const dateValue = new Date().valueOf()
    const fileName = `${dateValue}.jpg`
    const childPath = `/${uid}/${dateValue}.jpg`

    await this.storage.child(childPath).put(file)
    await this.storage.child(childPath).getDownloadURL()
    authedPost(`/api/admin/orders/${orderId}/setRawFile`, { productId, uid, fileName });
  }

  public async sendTextMessage(orderId: string, phoneNumber: string, message: string){
    await authedPost(`/api/admin/messaging/sendMessage`, { orderId, phoneNumber, message });
    return await this.get(orderId)
  }

  public async correctFormat(orderId: string) {
    const orderRef = await this.collection.doc(orderId).get()
    const order = orderRef.data();
    if (order?.product) {
      const newProduct = {...order?.product}
      if (order?.faceOval) {
        newProduct.faceOval = order?.faceOval
      }
      const products = {[order?.product.id]: newProduct}
      return this.collection.doc(orderId).update({products})
    }
  }

  public async getSentEmails(orderId: string): Promise<Email[]> {
    return authedGet(`/api/admin/orders/${orderId}/sent-emails`);
  }
}

export const ordersService = OrdersService.getInstance();
