export class Pattern {
  constructor(
    public id: string,
    public name: string,
    public disabled: boolean,
    public url?: string,
    public tileUrl?: string,
    public smallTileUrl?: string,
    public hex?: string,
    public order?: number,
    public tileWidth?: number,
    public tileHeight?: number,
    public smallTileWidth?: number,
    public smallTileHeight?: number,
    public linkedPatternId?: string,
    public group?: string,
    public s88Url?: string
    ) {}
  
  static from = (data: any) => {
    const tileWidth = data.tileWidth != null ? Number.parseInt(data.tileWidth) : undefined;
    const tileHeight = data.tileHeight != null ? Number.parseInt(data.tileHeight) : undefined;
    const smallTileWidth = data.smallTileWidth != null ? Number.parseInt(data.smallTileWidth) : undefined;
    const smallTileHeight = data.smallTileHeight != null ? Number.parseInt(data.smallTileHeight) : undefined;
    const order = data.order != null ? Number.parseInt(data.order) : undefined;
    const disabled =  Boolean(data.disabled);
    const id = data.id

    return new Pattern(id, data.name, disabled, data.url, data.tileUrl, data.smallTileUrl, data.hex, order, tileWidth, tileHeight, smallTileWidth, smallTileHeight, data.linkedPatternId, data.group, data.s88Url)
  }
}