import {getDB} from "./firebase";
import firebase from "firebase";
import { authedPost } from "./functions.service";
import { PrintGroup } from "../models/PrintGroup";

class PrintGroupsService {
  private static instance: PrintGroupsService;

  get collection(): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return getDB().collection("printGroup");
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new PrintGroupsService();
    }
    return this.instance;
  }

  public async getAll() {
    const res = await this.collection.orderBy("createdOn", "desc").get()
    const printGroups = res?.docs?.map(doc => this.transform(doc)) || [];
    return printGroups;
  }

  public async get(id: string) {
    const data = await this.collection.doc(id).get()
    return this.transform(data)
  }

  public async update(id: string, data: any) {
    await this.collection.doc(id).update(data)
    return this.get(id)
  }

  public async delete(id: string) {
    return this.collection.doc(id).delete()
  }

  public async create(patternData: any) {
    const doc = this.collection.doc()
    await doc.set(patternData)
    return await this.get(doc.id)
  }
  

  public async closeGroup(printGroupId: string) {
    await authedPost(`/api/printGroups/${printGroupId}/close`, {});
    return await this.get(printGroupId)
  }

  public async openGroup(printGroupId: string) {
    await authedPost(`/api/printGroups/${printGroupId}/open`, {});
    return await this.get(printGroupId)
  }

  public async genPrintGroupAsync(printGroupId: string) {
    await authedPost(`/api/printGroups/genImageAsync`, { printGroupId } );
    return await this.get(printGroupId)
  }

  private transform = (docSnapshot: firebase.firestore.DocumentSnapshot): PrintGroup => {
    const data = docSnapshot.data() as any;
    return {
      ...data,
      id: docSnapshot.id,
      createdOn: new Date(data.createdOn.toMillis())
    } as PrintGroup
  };
}

export const printGroupsService = PrintGroupsService.getInstance();
