import React from 'react'
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";

import { Meta, shouldRefetch } from '../../utils'

import { Product as ProductType } from '../../models/Product'
import { useQuery } from '../../hooks/useQuery';

import DeletePopup from '../../popups/DeletePopup/DeletePopup';

import { CreateMaskPopup } from '../../components/CreatePopup/CreateMask';
import { SidebarContainer } from '../../components/SidebarContainer/SidebarContainer';
import Table from '../../components/Table/Table';
import CellHeader from '../../components/CellHeader/CellHeader';
import Cell from '../../components/Cell/Cell';
import { ProductSidebar } from '../../components/ProductSidebar/ProductSidebar';
import { Button } from '../../components/Button/Button';
import { TableHeader } from '../../components/TableHeader/TableHeader';

import { useProductsContext } from '../../contexts/products.context'
import { usePatternsContext } from '../../contexts/patterns.context';

import styles from './Products.module.scss'
import { useFeaturesContext } from '../../contexts/features.context';

export const Products = () => {
  const [{productIds, productById}, {fetchProducts, createProduct, updateProduct, deleteProduct}] = useProductsContext()
  const [{patternIds}, {fetchPatterns}] = usePatternsContext()
  const [{featureIds}, {fetchFeatures}] = useFeaturesContext()
  const history = useHistory();
  const [productToCreate, setProductToCreate] = React.useState<boolean>();
  const [productIdToDelete, setProductIdToDelete] = React.useState<string | undefined>();

  const query = useQuery()

  const shouldFetchProducts = shouldRefetch(productIds)
  React.useEffect(() => {
    if(fetchProducts && shouldFetchProducts) { fetchProducts() }
  }, [fetchProducts, shouldFetchProducts])

  const shouldFetchPatterns = shouldRefetch(patternIds)
  React.useEffect(() => {
    if(fetchPatterns && shouldFetchPatterns) { fetchPatterns() }
  }, [fetchPatterns, shouldFetchPatterns])

  const shouldFetchFeatures = shouldRefetch(featureIds)
  React.useEffect(() => {
    if(fetchFeatures && shouldFetchFeatures) { fetchFeatures() }
  }, [fetchFeatures, shouldFetchFeatures])

  const createConfirmationHandler = async (data: Partial<ProductType>) => {
    await createProduct(data)
    setProductToCreate(false)
  };

  const deleteConfirmationHandler = async (id: string) => {
    await deleteProduct(id)
    history.push({pathname: history.location.pathname})
    setProductIdToDelete(undefined)
  };


  const selectedProductId = query.get('productId')
  const selectedPattern = selectedProductId != null ? productById?.[selectedProductId] : undefined

  const style = {
    '--sidepanel-width': (selectedProductId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;

  const columns = "max-content max-content minmax(max-content, 1fr)"

  return <div className={styles.container} style={style}>
    <Helmet>
      <title>Products - FriendlyFace</title>
    </Helmet>
    <TableHeader>
      <Button onClick={() => setProductToCreate(true)}>Create Product</Button>
    </TableHeader>
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader>Name</CellHeader>
        <CellHeader>Category Id</CellHeader>
        <CellHeader>Size + (inches)</CellHeader>
        {productIds?.data?.map((id, index) => {
          const product = productById?.[id];
          if(product == null) return null;
          return <ProductCells index={index}
                        product={product}
                        key={id}
                        onClickRow={() => history.push(`?productId=${id}`)}
                        active={selectedProductId === id}
                        onDelete={setProductIdToDelete} />
        })}

      </Table>
    </div>


    {productToCreate && <CreateMaskPopup onSubmit={createConfirmationHandler} onClose={() => setProductToCreate(false)}/>}
    {productIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(productIdToDelete)} onClose={() => setProductIdToDelete(undefined)} />}

    {selectedProductId && <SidebarContainer updating={selectedPattern?.meta?.updating}>
      <ProductSidebar
        data={selectedPattern} key={selectedProductId}
        onClose={() => history.push({pathname: history.location.pathname})}
        onUpdate={d => updateProduct(selectedProductId, d)}
        onDelete={() => setProductIdToDelete(selectedProductId)} />
    </SidebarContainer>}
  </div>
}


interface ProductCellsProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number,
  product: Meta<ProductType>,
  onClickRow: any,
  active: boolean,
  onDelete: any
}


const ProductCells: React.FC<ProductCellsProps> = ({index, product, onClickRow, active}) => {
  const props = {
    index: index,
    active: active,
    deleting: false,
    onClick: () => onClickRow(),
    className: styles.pointer
  }

  const disabledIcon = product.data?.disabled ? "🚫" : "✅"

  return <>
    <Cell {...props}>{disabledIcon} {product.data?.name}</Cell>
    <Cell {...props}>{product.data?.printType}</Cell>
    <Cell {...props}>{product.data?.width}x{product.data?.height} ({product.data?.seamTop?.toFixed(3)},{product.data?.seamRight?.toFixed(3)},{product.data?.seamBottom?.toFixed(3)},{product.data?.seamLeft?.toFixed(3)})</Cell>
  </>
};