import React from 'react'
import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/database';    // for realtime database

import { Loading } from '../../components/Loading/Loading';


export const Logout = () => {
  React.useEffect(() => {
    (async () => {
      await firebase.auth().signOut()
    })()
  }, [])
  return <Loading />
}