import {getDB} from "./firebase";
import firebase from "firebase";
import {GiftCard, GiftCardWId} from "../models/GiftCard.model"
import {authedPost} from "./functions.service"

class GiftCardsService {
  private static instance: GiftCardsService;

  get collection(): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return getDB().collection("giftCards");
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new GiftCardsService();
    }
    return this.instance;
  }

  public async getAll() {
    const res = await this.collection
      .orderBy("createdOn", "desc")
      .get();

    return res.docs.map(doc => {
      return {id: doc.id, ...doc.data()} as GiftCardWId
    });
  }

  public async create(data: Partial<GiftCard>) {
    const giftCard = await authedPost("/api/giftcards", {
      ...data,
      emailToBeSentOn: data.emailToBeSentOn?.toDate(),
      expiresOn: data.expiresOn?.toDate()
    });

    return {
      ...giftCard,
      createdOn: firebase.firestore.Timestamp.fromMillis(giftCard.createdOn),
      expiresOn: giftCard.expiresOn ? firebase.firestore.Timestamp.fromMillis(giftCard.expiresOn) : undefined,
      emailToBeSentOn: giftCard.emailToBeSentOn ? firebase.firestore.Timestamp.fromMillis(giftCard.emailToBeSentOn) : undefined,
    }
  }

  public async update(id: string, data: Partial<GiftCard>) {
    return this.collection.doc(id).set(data, {merge: true})
  }
}

export const giftCardsService = GiftCardsService.getInstance();
