import Color from 'color'
import { keyBy } from "lodash";
import {Face, Position} from './models/face.model'

const bezierSpline = require('@freder/bezier-spline');

export const defaultColorStyle = (initial = {}) => {
  return {
    ...initial,
    ...colorStyle("#B0D3D9", 'primary'),
    ...colorStyle("#4C51A5", 'secondary'),
  }
}

export type StringMap = { [key: string]: string };

/**
 * creates a nested object from a dot separated path
 * the object is terminated by an empty object at the end of the chain
 * 
 * @param dotPath - a dot separated path ex: `a.b.c` to create an nested object out of -> { "a": { "b": { "c": {} } } }
 * @param terminalObj - (optional) object to put at the end of the dot path, default {}
 * @returns { obj: "the generated object", last: "a reference to the deepest nested object in the chain" }
 * 
 */
export const objectifyPath = (dotPath: string, terminalObj?: any) => {
  const keys = dotPath.split(".")

  const terminal = terminalObj == null ? {} : terminalObj

  const initObj: any = {}
  const last = keys.reduce( (acc, current, idx) => {
    acc[current] = (idx + 1 === keys.length) ? terminal : {}
    return acc[current];
  }, initObj)

  return {obj:initObj, last}
}

export const byId = <T extends { id: string }>(array: Array<T>) => {
  return keyBy(array, obj => obj.id)
}

export const colorStyle = (color: string, prefix: string, initial = {}) => {
  const style: any = initial;

  const onWhite = Color('#f9f9f9');
  const onBlack = Color('#1E1E1E');

  style[`--${prefix}-color`] = color;
  try {
    style[`--${prefix}-w98-color`] = Color(color).mix(onWhite, 0.98).hex();
    style[`--${prefix}-b98-color`] = Color(color).mix(onBlack, 0.98).hex();
    style[`--${prefix}-w98-text-color`] = Color(color).mix(onWhite, 0.98).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b98-text-color`] = Color(color).mix(onBlack, 0.98).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w95-color`] = Color(color).mix(onWhite, 0.95).hex();
    style[`--${prefix}-b95-color`] = Color(color).mix(onBlack, 0.95).hex();
    style[`--${prefix}-w95-text-color`] = Color(color).mix(onWhite, 0.95).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b95-text-color`] = Color(color).mix(onBlack, 0.95).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w90-color`] = Color(color).mix(onWhite, 0.9).hex();
    style[`--${prefix}-b90-color`] = Color(color).mix(onBlack, 0.9).hex();
    style[`--${prefix}-w90-text-color`] = Color(color).mix(onWhite, 0.9).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b90-text-color`] = Color(color).mix(onBlack, 0.9).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w80-color`] = Color(color).mix(onWhite, 0.8).hex();
    style[`--${prefix}-b80-color`] = Color(color).mix(onBlack, 0.8).hex();
    style[`--${prefix}-w80-text-color`] = Color(color).mix(onWhite, 0.8).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b80-text-color`] = Color(color).mix(onBlack, 0.8).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w70-color`] = Color(color).mix(onWhite, 0.7).hex();
    style[`--${prefix}-b70-color`] = Color(color).mix(onBlack, 0.7).hex();
    style[`--${prefix}-w70-text-color`] = Color(color).mix(onWhite, 0.7).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b70-text-color`] = Color(color).mix(onBlack, 0.7).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w60-color`] = Color(color).mix(onWhite, 0.6).hex();
    style[`--${prefix}-b60-color`] = Color(color).mix(onBlack, 0.6).hex();
    style[`--${prefix}-w60-text-color`] = Color(color).mix(onWhite, 0.6).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b60-text-color`] = Color(color).mix(onBlack, 0.6).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w50-color`] = Color(color).mix(onWhite, 0.5).hex();
    style[`--${prefix}-b50-color`] = Color(color).mix(onBlack, 0.5).hex();
    style[`--${prefix}-w50-text-color`] = Color(color).mix(onWhite, 0.5).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b50-text-color`] = Color(color).mix(onBlack, 0.5).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w40-color`] = Color(color).mix(onWhite, 0.4).hex();
    style[`--${prefix}-b40-color`] = Color(color).mix(onBlack, 0.4).hex();
    style[`--${prefix}-w40-text-color`] = Color(color).mix(onWhite, 0.4).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b40-text-color`] = Color(color).mix(onBlack, 0.4).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w30-color`] = Color(color).mix(onWhite, 0.3).hex();
    style[`--${prefix}-b30-color`] = Color(color).mix(onBlack, 0.3).hex();
    style[`--${prefix}-w30-text-color`] = Color(color).mix(onWhite, 0.3).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b30-text-color`] = Color(color).mix(onBlack, 0.3).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w20-color`] = Color(color).mix(onWhite, 0.2).hex();
    style[`--${prefix}-b20-color`] = Color(color).mix(onBlack, 0.2).hex();
    style[`--${prefix}-w20-text-color`] = Color(color).mix(onWhite, 0.2).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b20-text-color`] = Color(color).mix(onBlack, 0.2).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-w10-color`] = Color(color).mix(onWhite, 0.1).hex();
    style[`--${prefix}-b10-color`] = Color(color).mix(onBlack, 0.1).hex();
    style[`--${prefix}-w10-text-color`] = Color(color).mix(onWhite, 0.1).isLight() ? '#333' : '#fefefe';
    style[`--${prefix}-b10-text-color`] = Color(color).mix(onBlack, 0.1).isLight() ? '#333' : '#fefefe';

    style[`--${prefix}-a80-color`] = Color(color).alpha(0.8).toString();
    style[`--${prefix}-a70-color`] = Color(color).alpha(0.7).toString();

    style[`--${prefix}-text-color`] = Color(color).isLight() ? '#333' : '#fefefe';
  } catch(error) {}
  return style;
}

export interface Pagination {
  prevPageId?: string,
  nextPageId?: string,
  queryPrevPageId?: string,
  queryNextPageId?: string,
  perPage?: number
}

export interface ApiStatus {
  fetching?: boolean,
  deleting?: boolean,
  updating?: boolean,
  error?: boolean,
  errorMsg?: string,
  prevPageId?: string,
  nextPageId?: string,
}

export interface Meta<T> {
  data?: T,
  meta?: ApiStatus & Pagination
}

export interface QueryMeta<T, S> {
  data?: T,
  meta?: ApiStatus & Pagination,
  query?: S
}

export function formatPrice(priceInCents?: number, noCents?: boolean): string | undefined {
  if (priceInCents == null) {
    return undefined
  }
  const roundedCents = Math.round(priceInCents)


  if (noCents && roundedCents % 100 === 0) {
    return (roundedCents / 100).toFixed(0)
  } else {
    return (roundedCents / 100).toFixed(2)
  }
}

export const remoteAsset = (path: string) => `https://assets.friendlyface.com/${path}`


export function isSomething<T>(arg: T | undefined | null): arg is T {
  return arg !== null && arg !== undefined && !Number.isNaN(arg)
}

export const splitCard = (code: string) => {
  return code.match(/.{4}/g)?.join(" ");
}

export const faceWidthBound = (face: Face) => {
  if(face.leftEar?.x == null || face.rightEar?.x == null) return null;
  return Math.abs(face.rightEar?.x - face.leftEar?.x) + 40
}

export const faceHeightBound = (face: Face) => {
  if(face.chin?.y == null || face.eyeY == null) return null;
  return face.chin?.y - (face.eyeY - 100)
}

export const rotate = (p?: Position, c?: Position, angle?: number) => {
  if(c == null || p == null || angle == null) return undefined;
  const radians = (Math.PI / 180) * angle
  const cos = Math.cos(radians);
  const sin = Math.sin(radians)
  const dx = p.x - c.x
  const dy = p.y - c.y
  const nx = (cos * dx) - (sin * dy) + c.x
  const ny = (cos * dy) + (sin * dx) + c.y

  return {x: nx, y: ny}
}


export const getSilhouetteShape = (points: Position[]) => {
  const asArray = points.map( (p) => [p.x, p.y, 0] )
  asArray.push( [points[0].x, points[0].y, 0] )
  const controlPoints = bezierSpline.getControlPoints(asArray);
  const combined = bezierSpline.combinePoints(asArray, controlPoints);
  const segments = bezierSpline.getSegments(combined);

  const svgPathList = segments.map( (p:any, idx: number) => {
    const first = p[0]
    const c1 = p[1]
    const c2 = p[2]
    const second = p[3]

    const points = `C${c1[0]},${c1[1]} ${c2[0]},${c2[1]} ${second[0]},${second[1]}`

    if (idx === 0) {
      return `M${first[0]},${first[1]} ${points}`
    } else if (idx >= segments.length - 1) { 
      return ""
    } else {
      return points
    }
  })
  svgPathList.push("Z")
  return svgPathList.join(" ")
}

export const getSilhouetteShapePoints = (points: Position[]) => {
  const asArray = points.map( (p) => [p.x, p.y, 0] )
  asArray.push( [points[0].x, points[0].y, 0] )
  const controlPoints = bezierSpline.getControlPoints(asArray);
  const combined = bezierSpline.combinePoints(asArray, controlPoints);
  const segments: Segment[] = bezierSpline.getSegments(combined);

  const firstSegment = segments[0]
  const firstPoint = firstSegment?.[0]

  const svgPathList = segments.map( (p:any, idx: number) => {
    const c1 = p[1]
    const c2 = p[2]
    const second = p[3]
    return `C${c1[0]},${c1[1]} ${c2[0]},${c2[1]} ${second[0]},${second[1]}`
  })
  return [ `M${firstPoint[0]}, ${firstPoint[1]}`, ...svgPathList]
}

export const movePointByHorizontal = (point?: Position, center?: Position, magnitude?: number, rotation?: number): (Position | undefined) => {
  if(point == null || center == null || magnitude == null || rotation == null) return undefined;
  const rotatedPoint = rotate(point, center, rotation);
  const correctedRotatedPoint = rotatedPoint ? { x: rotatedPoint.x + magnitude, y: rotatedPoint.y } : undefined
  return rotate(correctedRotatedPoint, center, -rotation);
}

export const movePointToHorizontal = (point?: Position, center?: Position, magnitude?: number, rotation?: number): (Position | undefined) => {
  if(point == null || center == null || magnitude == null || rotation == null) return undefined;
  const rotatedPoint = rotate(point, center, rotation);
  const correctedRotatedPoint = rotatedPoint ? { x: magnitude, y: rotatedPoint.y } : undefined
  return rotate(correctedRotatedPoint, center, -rotation);
}

export function shouldRefetch<T>(obj: Meta<T> | undefined) {
  if(obj == null) return true;
  else if(obj.meta?.fetching) return false;
  else if(obj.meta?.error) return false;
  else if(obj.data != null) return false;
  else return true;
}

export const toastErrorOptions = {
  style: {
    color: '#FF4D4C',
    background: "#FCB3B8"
  }
}

export function isNotNull<T>(arg: T | undefined | null): arg is T {
  return !(arg === null || arg === undefined);
}


export type ArrayPoint = [number, number, number]
export type Segment = [ArrayPoint, ArrayPoint, ArrayPoint, ArrayPoint]


export const getFireStorageUrl = (bucket: string, path: string) => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
  return `https://console.cloud.google.com/storage/browser/${bucket}/${path}?project=${projectId}`;
}

export const getFireDataUrl = (path: string) => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID
  return `https://console.cloud.google.com/firestore/data/${path}?project=${projectId}`
}