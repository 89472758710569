import {getDB} from "./firebase";
import firebase from "firebase";
import {Referral} from "../models/referral";

class ReferralsService {
  private static instance: ReferralsService;

  get collection(): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return getDB().collection("referrals");
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ReferralsService();
    }
    return this.instance;
  }

  public async getAll() {
    const res = await this.collection
      .orderBy("createdOn", "desc")
      .get();

    return res.docs.map(doc => {
      return doc.data() as Referral
    });
  }
}

export const referralsService = ReferralsService.getInstance();
