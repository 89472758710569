import React from 'react'

import { Scrollable } from '../Scrollable/Scrollable'

import { getFireDataUrl, shouldRefetch } from '../../utils'
import { FirebaseDBLink, SidebarHeader } from '../SidebarHeader/SidebarHeader'

import { useFeaturesContext } from '../../contexts/features.context';

import styles from './FeatureSidebar.module.scss'
import { Feature } from '../../models/Feature.model'
import { Button } from '../Button/Button';
import { useForm } from '../../hooks/useForm';
import { CheckboxInput } from '../CheckboxInput/CheckboxInput';
import { Input, Textarea } from '../Input/Input';
import { AddSizedImage, UpdatableSizedImage } from '../UpdatableImage/UpdatableImage';
import { sortBy } from 'lodash';
import DeletePopup from '../../popups/DeletePopup/DeletePopup';

interface FeatureSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  featureId: string,
  onClose?: () => void,
  onDelete: (id: string) => void,

}

export const FeatureSidebar: React.FC<FeatureSidebarProps> = ({featureId, onClose, onDelete}) => {
  const [{featureById}, {fetchFeature}] = useFeaturesContext()

  const data = featureById?.[featureId]
  const shouldFetch = shouldRefetch(data)
  React.useEffect(() => {
    if(shouldFetch && featureId != null && fetchFeature) {
      fetchFeature(featureId)
    }
  }, [shouldFetch, featureId, fetchFeature])

  return <div className={styles.container}>
    <SidebarHeader title={data?.data?.id ? data?.data?.id : ""} onClose={onClose}>
      <FirebaseDBLink href={getFireDataUrl(`features/${featureId}`)} />
    </SidebarHeader>

    {data?.data != null && <Scrollable innerClassName={styles.scrollable}>
      <FeatureSidebarWithData data={data?.data} onDelete={onDelete} />
    </Scrollable>}
  </div>
}

interface OrderSidebarDataProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: Feature;
  onDelete: (id: string) => void,
}

const FeatureSidebarWithData: React.FC<OrderSidebarDataProps> = ({data, onDelete}) => {
  const [, {updateFeature, deleteImage, addImage}] = useFeaturesContext()
  const [featureImageIdToDelete, setFeatureImageIdToDelete] = React.useState<string | undefined>();
  const featureId = data.id
  const {values, onChange, onDebounceChange} = useForm({
    name: data.name || "",
    description: data.description || "",
    slug: data.slug,
    price: data.price,
    oldPrice: data.oldPrice,
    order: data.order,
    disabled: [String(data.disabled || "false")],
    autoSet: [String(data.autoSet || "false")],
    isGroup: [String(data.isGroup || "false")],
    appearsOnHome: [String(data.appearsOnHome || "false")],
  }, (_, name, value) => {
    if(name === 'price' || name === 'oldPrice' || name === 'value'  || name === 'order') {
      const numberValue = Number.parseInt(value)
      updateFeature(featureId, {[name]: Number.isNaN(numberValue) ? null : numberValue})
    } else if(name === 'slug') {
      updateFeature(featureId, {[name]: String(value)})
    } else if(name === 'disabled' || name === 'autoSet' || name === 'isGroup' || name === 'appearsOnHome') {
      const newValue = value?.indexOf("true") >= 0
      updateFeature(featureId, {[name]: newValue})
    } else updateFeature(featureId, {[name]: value})
  })

  const onAddImage = (file?: File) => {
    if(featureId != null && file != null) {
      addImage(featureId, file)
    }
  }

  const deleteConfirmationHandler = async (imageId: string) => {
    await deleteImage(featureId, imageId)
    setFeatureImageIdToDelete(undefined)
  };

  const sizedImageKeys = data.sizedImages ? sortBy(Object.keys(data.sizedImages), id => data.sizedImages?.[id].order) : undefined

  return <>
    <CheckboxInput title="Is Disabled"
           name="disabled"
           value="true"
           checked={values.disabled?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />
    <Input title="Name"
           name="name"
           value={values.name}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Slug"
           name="slug"
           value={values.slug}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Order"
           name="order"
           value={values.order}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Price (in cents)"
           name="price"
           value={values.price}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Old Price (in cents)"
           name="oldPrice"
           value={values.oldPrice}
           className={styles.input}
           onChange={onDebounceChange} />
    
    <Textarea title="Description"
           name="description"
           value={values.description}
           className={styles.input}
           onChange={onDebounceChange} />

    <CheckboxInput title="Auto set"
           name="autoSet"
           value="true"
           checked={values.autoSet?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />

    <CheckboxInput title="Is Group"
           name="isGroup"
           value="true"
           checked={values.isGroup?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />
    <CheckboxInput title="Appears on Home"
           name="appearsOnHome"
           value="true"
           checked={values.appearsOnHome?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />

    {sizedImageKeys?.map(id => {
      const image = data.sizedImages?.[id]
      if(image == null) return null;
      return <UpdatableSizedImage key={id} data={image} onDeleteImage={() => setFeatureImageIdToDelete(id)} />
    })}
    {data.isGroup && <AddSizedImage onAddImage={onAddImage} />}
    
    <div>
      <Button onClick={() => onDelete(data.id)}>Delete</Button>
    </div>

    {featureImageIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(featureImageIdToDelete)} onClose={() => setFeatureImageIdToDelete(undefined)} />}
  </>
}