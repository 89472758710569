import React from 'react'
import { Popup } from "../../components/Popup/Popup";
import { Order, OrderStatus } from '../../models/Order.model';
import { WarningIcon } from "../../toneIcons";


import styles from './ConfirmChangeStatusPopUp.module.scss';

interface Props {
  text: string
  order?: Order
  newStatus: OrderStatus,
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: React.MouseEventHandler;
}

export const ConfirmChangeStatusPopUp: React.FC<Props> = ({text, order, onClose, onSubmit, newStatus, children}) => {
  return <Popup icon={<WarningIcon className={styles.warningIcon} />} onClose={onClose}>
    <h4>{text} { order?.status || "" }</h4>
    {order?.status != null && <h4>to {newStatus}</h4>}
    {children ? children : <p>Are you sure?</p>}
    {onSubmit != null && <div className={styles.confirmationButton} onClick={onSubmit}>Yes, change it</div>}
  </Popup>
};

export default ConfirmChangeStatusPopUp;
