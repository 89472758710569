import React from 'react'

import styles from './Scrollable.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  innerClassName?: string
}

/**
 * Scrollable always takes as much width and height as possible and doesn't respect margins you give it.
 */
export const Scrollable: React.FC<Props> = ({className, innerClassName, children}) => {
  return <div className={[className, styles.container].join(' ')}>
    <div className={[styles.content, innerClassName].join(' ')}>
      {children}
    </div>
  </div>
}