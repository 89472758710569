import React from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom";
import {useAuthContext} from './contexts/auth.context'
import { Loading } from './components/Loading/Loading';


interface AuthedRouteProps extends RouteProps {
  component: any,
  redirectTo?: string,
}

export const AuthedRoute: React.FC<AuthedRouteProps> = ({component: Component, redirectTo = '/login', ...rest}) => {
  const [state] = useAuthContext()
  return <Route {...rest} render={(props) => {
    if(state?.initialising) {
      return <Loading />
    }
    if(state?.user != null) {
      return <Component {...props} />
    } else {
      return <Redirect to={{pathname: '/login', state: {from: props.location}}} />
    }
  }} />
}

export const UnAuthedRoute: React.FC<AuthedRouteProps> = ({component: Component, redirectTo, ...rest}) => {
  const [state] = useAuthContext()

  return <Route {...rest} render={(props) => {
    if(state?.initialising) {
      return <Loading />
    }
    if(state?.user != null) {
      return <Redirect to={{pathname: redirectTo, state: {from: props.location}}} />
    } else {
      return <Component {...props} />
    }
  }} />
}