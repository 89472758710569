import React, { useReducer } from 'react'
import { reducer, initialState, ContextPropsState } from './patterns.reducer'

import {patternsService,} from "../services/patterns.service";
import { Pattern } from '../models/Pattern.model'


type ContextPropsActions = {
  fetchPattern: (id: string) => void,
  fetchPatterns: () => void,
  updatePattern: (id: string, data: Partial<Pattern>) => void,
  createPattern: (data:Partial<Pattern>) => void,
  deletePattern: (id: string) => void,
  addFile: (id: string, file: File) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchPattern: () => { throw new Error("fetchPattern not implemented") },
  fetchPatterns: () => { throw new Error("fetchPatterns not implemented") },
  updatePattern: () => { throw new Error("updatePattern not implemented") },
  createPattern: () => { throw new Error("createPattern not implemented") },
  deletePattern: () => { throw new Error("createPattern not implemented") },
  addFile: () => { throw new Error("addFile not implemented") },
}

export const PatternsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const PatternsProvider = ({children}: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchPattern = React.useCallback(async (id: string) => {
    try {
      dispatch({type: "FETCH_PATTERN", id})
      const pattern = await patternsService.fetch(id)
      dispatch({type: "FETCH_PATTERN_SUCCESS", id, data: pattern})
    } catch(error) {
      dispatch({type: "FETCH_PATTERN_ERROR", id, error})
    }
  }, [])

  const fetchPatterns = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_PATTERNS"})
      const patterns = await patternsService.fetchAll()
      dispatch({type: "FETCH_ALL_PATTERNS_SUCCESS", data: patterns})
    } catch(error) {
      dispatch({type: "FETCH_ALL_PATTERNS_ERROR", error})
    }
  }, [])

  const updatePattern = React.useCallback(async (id: string, data: Partial<Pattern>) => {
    try {
      dispatch({type: "UPDATE_PATTERN", id})
      const newPattern = await patternsService.update(id, data)
      dispatch({type: "UPDATE_PATTERN_SUCCESS", id, data: newPattern})
    } catch(error) {
      dispatch({type: "UPDATE_PATTERN_ERROR", id, error})
    }
  }, [])

  const createPattern = React.useCallback(async (data: Partial<Pattern>) => {
    try {
      dispatch({type: "CREATE_PATTERN"})
      const pattern = await patternsService.create(data)
      dispatch({type: "CREATE_PATTERN_SUCCESS", data: pattern})

    } catch(error) {
      dispatch({type: "CREATE_PATTERN_ERROR", error})
    }
  }, [])

  const deletePattern = React.useCallback(async (id: string) => {
    try {
      dispatch({type: "DELETE_PATTERN", id})
      await patternsService.delete(id)
      dispatch({type: "DELETE_PATTERN_SUCCESS", id})

    } catch(error) {
      dispatch({type: "DELETE_PATTERN_ERROR", id, error})
    }
  }, []) 

  const addFile = React.useCallback(async (id: string, file: File) => {
    try {
      dispatch({type: "UPDATE_PATTERN", id})
      const newPattern = await patternsService.uploadFile(id, file)
      dispatch({type: "UPDATE_PATTERN_SUCCESS", id, data: newPattern})
    } catch(error) {
      dispatch({type: "UPDATE_PATTERN_ERROR", id, error})
    }
  }, [])

  const value:ContextProps = [state, {
    fetchPattern,
    fetchPatterns,
    updatePattern,
    createPattern,
    deletePattern,
    addFile,
  }]

  return <PatternsContext.Provider value={value}>
    {children}
  </PatternsContext.Provider>
}

export const usePatternsContext = () => React.useContext(PatternsContext)