import React from 'react'
import classnames from 'classnames'

import styles from './RoundedIconText.module.scss'

interface Props {
  className?: string
  src?: string
  alt?: string
  text?: string
  title?: string
  background?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void
}

export const RoundedIconText: React.FC<Props> = ({title, src, text, alt, className, background, children, onClick}) => {
  return <div className={[styles.container, className].join(" ")}>
    {title != null && <div className={styles.title}>{title}</div>}
    <div className={classnames(styles.box, onClick ? styles.clickable : undefined)} style={{background}} onClick={onClick}>
      {src && <img src={src} alt={alt} />}
      {text && <div className={styles.text}><span>{text}</span></div>}
      {children}
    </div>
  </div>
}