import React from 'react'
import classNames from 'classnames'

import {Image, Upload} from '../../toneIcons'

import styles from './FaceImage.module.scss'
interface FaceImageProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string
  src: string
  onClickImage?: (src: string) => void
  onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const FaceImage: React.FC<FaceImageProps> = ({name, src, onClickImage, onUpload}) => {
  return <div className={styles.container}>
    <div className={styles.badge}>{name}</div>
    <img src={src} alt={name} />

    <div className={styles.targets}>
      {onClickImage && <div className={styles.largeImage} onClick={() => onClickImage(src)}>
        <Image />
        <div>view</div>
      </div>}
      {onUpload && <label className={styles.uploadImage} >
        <input type='file' onChange={onUpload} className={styles.dNone} multiple={false} accept="image/jpg,image/jpeg,image/png"/>
        <Upload />
        <div>upload</div>
      </label>}
    </div>
  </div>
}

interface FaceMissingImageProps extends React.HTMLAttributes<HTMLDivElement> {
  name?: string
  onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const FaceMissingImage: React.FC<FaceMissingImageProps> = ({name, onUpload}) => {
  return <div className={ classNames(styles.container, styles.emptyContainer) }>
    <div className={styles.badge}>{name}</div>

    <div className={styles.targets}>
      {onUpload && <label className={styles.uploadImage} >
        <input type='file' onChange={onUpload} className={styles.dNone} multiple={false} accept="image/jpg,image/jpeg,image/png"/>
        <Upload />
        <div>upload</div>
      </label>}
    </div>
  </div>
}