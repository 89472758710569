import {Meta, QueryMeta} from '../utils'
import {Order} from '../models/Order.model'
import { groupBy, mapValues, omit, without } from 'lodash';

type Query = {
  status?: string,
  query?: string
}

export type ContextPropsState = {
  orderById: {[key: string]: Meta<Order>},
  orderIds: QueryMeta<string[], Query>
}

export const initialState: ContextPropsState = {
  orderById: {},
  orderIds: {}
};

type Paginated = {
  perPage?: number,
  prevPageId?: string,
  nextPageId?: string
}

type OrdersAction = 
  | ({ type: "FETCH_ALL_ORDERS", status?: string } & Paginated & Query)
  | ({ type: "FETCH_ALL_ORDERS_SUCCESS", status?: string, data: Order[]} & Paginated & Query)
  | ({ type: "FETCH_ALL_ORDERS_ERROR", status?: string, error: any } & Paginated & Query)

  | { type: "FETCH_ORDER", id: string }
  | { type: "FETCH_ORDER_SUCCESS", id: string, data: Order }
  | { type: "FETCH_ORDER_ERROR", id: string, error: any }

  | { type: "CREATE_ORDER_SUCCESS", id: string, data: Order }

  | { type: "UPDATE_ORDER", id: string }
  | { type: "UPDATE_ORDER_SUCCESS", id: string, data: Order }
  | { type: "UPDATE_PARTIAL_ORDER_SUCCESS", id: string, data: Partial<Order> }
  | { type: "UPDATE_ORDER_ERROR", id: string, error: any }

  | { type: "DELETE_ORDER", id: string }
  | { type: "DELETE_ORDER_SUCCESS", id: string }
  | { type: "DELETE_ORDER_ERROR", id: string, error: any }

export const reducer = (state: ContextPropsState, action: OrdersAction): ContextPropsState => {
  // FETCH
  if( "FETCH_ALL_ORDERS" === action.type) {
    const newOrderIds: QueryMeta<string[], Query> = {
      data: state.orderIds?.data,
      meta: {
        ...state.orderIds.meta,
        perPage: action.perPage,
        nextPageId: undefined,
        prevPageId: undefined,
        queryPrevPageId: action.prevPageId,
        queryNextPageId: action.nextPageId,
        fetching: true
      },
      query: {
        status: action.status,
        query: action.query,
      }
    }
    return {
      ...state,
      orderIds: newOrderIds,
    };
  }
  if( "FETCH_ALL_ORDERS_SUCCESS" === action.type) {
    if(action.data == null) return state;

    const orderById = {
      ...state.orderById,
      ...mapValues(groupBy(action.data, p => p.id), p => ({data: p[0]}) )
    }
    const orderIds: QueryMeta<string[], Query> = {
      data: action.data.map(p => p.id),
      meta: {
        ...state.orderIds.meta,
        perPage: action.perPage,
        nextPageId: action.nextPageId,
        prevPageId: action.prevPageId,
        fetching: false
      },
      query: {
        status: action.status,
        query: action.query,
      }
    }

    return {
      ...state,
      orderById,
      orderIds,
    };
  }
  if( "FETCH_ALL_ORDERS_ERROR" === action.type) {
    const newOrderIds: QueryMeta<string[], Query> = {
      data: state.orderIds?.data,
      meta: {
        ...state.orderIds.meta,
        perPage: action.perPage,
        nextPageId: undefined,
        prevPageId: undefined,
        fetching: false,
        error: true,
        errorMsg: action.error.message || action.error.details || action.error.error || "Unknown error occurred"
      },
      query: {
        status: action.status,
        query: action.query,
      }
    }
    return {
      ...state,
      orderIds: newOrderIds,
    };
  }

  // FETCH ORDER
  if( "FETCH_ORDER" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder?.data,
      meta: {
        ...(currentOrder?.meta || {}),
        fetching: true
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }
  if( "FETCH_ORDER_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: action.data,
      meta: {
        ...(currentOrder?.meta || {}),
        fetching: false
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }
  if( "FETCH_ORDER_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder.data,
      meta: {
        ...(currentOrder?.meta || {}),
        fetching: false,
        error: true,
        errorMsg: action.error.message || action.error.details || action.error.error || "Unknown error occurred"
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }

  // UPDATE ORDER
  if( "UPDATE_ORDER" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder?.data,
      meta: {
        ...(currentOrder?.meta || {}),
        updating: true
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }

  if( "CREATE_ORDER_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: action?.data,
      meta: {
        ...(currentOrder?.meta || {}),
        updating: false
      }
    }

    const newOrderIds: QueryMeta<string[], Query> = {
      ...(state.orderIds || {}),
      data: [...(state.orderIds?.data || []), action.id],
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderIds: newOrderIds,
      orderById: newOrderById
    }
  }

  if( "UPDATE_ORDER_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: action?.data,
      meta: {
        ...(currentOrder?.meta || {}),
        updating: false
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }

  if( "UPDATE_PARTIAL_ORDER_SUCCESS" === action.type) {
    if(action.id == null || action.data == null) return state;

    const currentOrder = state.orderById[action.id];
    
    const newOrder: Meta<Order> = {
      data: Object.assign({}, currentOrder.data, action.data),
      meta: {
        ...(currentOrder?.meta || {}),
        updating: false
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }
  if( "UPDATE_ORDER_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder.data,
      meta: {
        ...(currentOrder?.meta || {}),
        updating: false,
        error: true,
        errorMsg: action.error.message || action.error.details || action.error.error || "Unknown error occurred"
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }


  // DELETE
  if("DELETE_ORDER" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder.data,
      meta: {
        ...(currentOrder?.meta || {}),
        deleting: true
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }
  if("DELETE_ORDER_SUCCESS" === action.type) {
    if(action.id == null) return state;

    const newOrderById = omit(state.orderById, [action.id])
    const newOrderIds = {
      data: without(state.orderIds.data, action.id),
      meta: state.orderIds.meta
    }
    
    return {
      ...state,
      orderById: newOrderById,
      orderIds: newOrderIds,
    }
  }
  if("DELETE_ORDER_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentOrder = state.orderById[action.id];
    const newOrder: Meta<Order> = {
      data: currentOrder.data,
      meta: {
        ...(currentOrder?.meta || {}),
        deleting: false
      }
    }
    
    const newOrderById = {
      ...state.orderById,
      [action.id]: newOrder
    }

    return {
      ...state,
      orderById: newOrderById
    }
  }

  return state;
}
