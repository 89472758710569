import React from "react";
import {Helmet} from "react-helmet";

import Table from "../../components/Table/Table"
import CellHeader from "../../components/CellHeader/CellHeader"
import {useGiftCardsContext} from "../../contexts/giftCard.context"
import {formatPrice, Meta, splitCard} from "../../utils"
import Cell from "../../components/Cell/Cell"
import classNames from "classnames"
import {GiftCardWId} from "../../models/GiftCard.model"
import moment from "moment"
import {Link, useLocation, useHistory} from "react-router-dom"
import {DateTimeAgo} from "../../components/DateTimeAgo/DateTimeAgo"
import {useQuery} from "../../hooks/useQuery"
import {SidebarContainer} from "../../components/SidebarContainer/SidebarContainer"
import queryString from "query-string"
import {GiftCardsSidebar} from "./GiftCardsSidebar/GiftCardsSidebar";


import {Button} from "../../components/Button/Button"
import {TableHeader} from "../../components/TableHeader/TableHeader"

import styles from "./GiftCardsPage.module.scss";
interface GiftCardsPageProps {
}

export const GiftCardsPage: React.FC<GiftCardsPageProps> = () => {
  const [{giftCardIds, giftCardsById}, {fetchGiftCards}] = useGiftCardsContext();
  const [giftCardToCreate, setGiftCardToCreate] = React.useState<boolean>();

  const query = useQuery()
  const location = useLocation()
  const history = useHistory()

  const selectedId = query.get('id') || undefined
  const selectedGiftCard = selectedId ? giftCardsById?.[selectedId] : undefined

  const closeSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    id: undefined,
  })

  const closeSidebar = () => {
    history.push({pathname: history.location.pathname, search: closeSearch})
    setGiftCardToCreate(false)
  }

  const createNew = () => {
    setGiftCardToCreate(true);
    history.push({pathname: history.location.pathname, search: closeSearch})
  }

  React.useEffect(() => {
    fetchGiftCards();
  }, [fetchGiftCards]);

  const style = {
    '--sidepanel-width': (selectedId || giftCardToCreate) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;

  const columns = "max-content 200px max-content max-content max-content max-content minmax(max-content, 1fr)"

  return <div className={styles.container} style={style}>
    <Helmet>
      <title>Gift Cards</title>
    </Helmet>
    <TableHeader>
      <Button onClick={createNew}>Create New Gift card</Button>
    </TableHeader>
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader>Code</CellHeader>
        <CellHeader>Details</CellHeader>
        <CellHeader>Created</CellHeader>
        <CellHeader>Purchased In</CellHeader>
        <CellHeader>Shipped/Emailed</CellHeader>
        <CellHeader>Expiration</CellHeader>
        <CellHeader>Usage History</CellHeader>

        {giftCardIds?.map((id, index) => {
          const giftCard = giftCardsById?.[id];
          if (giftCard == null) return null;

          const search = "?" + queryString.stringify({
            ...queryString.parse(location.search),
            id: id
          })

          return <Cells index={index}
                        metaEntity={giftCard}
                        onClickRow={() => history.push({pathname: location.pathname, search})}
                        active={selectedId === id}
                        key={id}/>
        })}
      </Table>
    </div>

    {(selectedId || giftCardToCreate) && <SidebarContainer updating={selectedGiftCard?.meta?.updating}>
      <GiftCardsSidebar key={selectedId}
                        data={selectedGiftCard}
                        onClose={closeSidebar}/>
    </SidebarContainer>}
  </div>
};

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number,
  metaEntity: Meta<GiftCardWId>,
  active: boolean,
  onClickRow: () => void
}


const Cells: React.FC<CellProps> = ({index, active, metaEntity, onClickRow}) => {
  const giftCard = metaEntity.data;
  if (!giftCard) return null;

  const sortedUsageHistory = Object.entries(giftCard.usageHistory || {}).sort((obj1, obj2) => {
    const bdate = new Date(obj2[1].date).getTime()
    const adate = new Date(obj1[1].date).getTime()
    return adate - bdate
  })

  const props = {
    index: index,
    deleting: metaEntity.meta?.deleting,
    active: active,
    className: classNames(styles.cell, styles.columns, giftCard.currentValue <= 0 && styles.faded, giftCard.expired && styles.faded),
    onClick: () => onClickRow(),
  }

  const emailToBeSentOn = giftCard.emailToBeSentOn ? moment(giftCard.emailToBeSentOn.toMillis?.()).format("MMM Do, YY") : undefined
  const expiresOn = giftCard.expiresOn ? moment(giftCard.expiresOn.toMillis?.()).format("MMM Do, YY") : undefined
  const splitGiftCardCode = giftCard?.code ? splitCard(giftCard?.code.toUpperCase()) : undefined
  return <>
    <Helmet>
      <title>Gift Cards</title>
    </Helmet>
    <Cell {...props} >
      <div><b className={styles.code}>{splitGiftCardCode}</b><span className={styles.codeType}>{giftCard.type === "electronic" ? "⚡" : "🏛️"}</span></div>
      <div><b>$</b>{formatPrice(giftCard?.originalValue)} value</div>
      <div><b>$</b>{formatPrice(giftCard?.currentValue)} left</div>
    </Cell>

    <Cell {...props} >
      {giftCard?.email
        ? <div><b>To: </b> <a href={`mailto:${giftCard?.email}`}>{giftCard?.to}</a></div>
        : <div><b>To: </b> {giftCard?.to}</div>
      }
      <div><b>From: </b> {giftCard?.from}</div>
      <div title={giftCard?.message}><b>Message: </b> {giftCard?.message?.substr(0, 30).concat("...")}</div>
    </Cell>
    <Cell {...props} >
      <DateTimeAgo date={new Date(giftCard.createdOn.toMillis())}/>
    </Cell>
    <Cell {...props} >
      <div><Link to={`/admin?q=${giftCard?.purchasedInOrderId}`}>{giftCard?.purchasedInOrderId}</Link></div>
    </Cell>
    <Cell {...props} >
      {giftCard.type === "electronic" && <div>{(giftCard?.emailSent ? "✅" : "⬜")} {emailToBeSentOn}</div>}
      {giftCard.type === "classic" && <div>{(giftCard?.shipped ? "✅" : "⬜")}</div>}
    </Cell>
    <Cell {...props} className={classNames(props.className, styles.center)}>
      <div>{giftCard.expiresOn ? (giftCard?.expired ? "✅" : "⬜") : "-"} {expiresOn}</div>
    </Cell>
    <Cell {...props} >
      {
        sortedUsageHistory.map((usageHistory, i) => {
          return <div className={styles.usageArea} key={i}>
            <p><b>Used In Order(id): </b> <Link to={`/admin?q=${usageHistory[0]}`}>{usageHistory[0]}</Link></p>
            <p><b>Initial: </b> <span>{formatPrice(usageHistory[1]?.initial)}</span></p>
            <p><b>Consumed: </b> <span>{formatPrice(usageHistory[1]?.consumed)}</span></p>
            <p><b>Left: </b> <span>{formatPrice(usageHistory[1]?.left)}</span></p>
          </div>
        })
      }
    </Cell>
  </>
};

