import React from 'react'
import {Popup} from "../../components/Popup/Popup";


import {CheckboxInput} from '../../components/CheckboxInput/CheckboxInput'
import { Order } from '../../models/Order.model';
import { useForm } from '../../hooks/useForm';

import styles from './SplitOrderPopup.module.scss';
interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (productIds: string[]) => void;
  order: Order
}

export const SplitOrderPopup: React.FC<Props> = ({onClose, onSubmit, order}) => {
  const {values, onChange} = useForm({
    splitOrders: []
  })

  const productIds = order?.products ? Object.keys(order?.products) : undefined
  return <Popup onClose={onClose}>
    <form>
      <h1>Split out</h1>
      {productIds?.map(id => {
        return <div key={id}>
          <CheckboxInput title={`Order #${id}`}
            name="splitOrders"
            value={id}
            checked={values.splitOrders?.indexOf(id) >= 0}
            className={styles.input}
            onChange={onChange} />
        </div>
      })}

      {onSubmit != null &&
      <div className={styles.confirmationButton} onClick={() => onSubmit(values.splitOrders)}>Yes, split order</div>}
    </form>
  </Popup>
};

export default SplitOrderPopup;
