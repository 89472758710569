import React from 'react'
import {Link} from 'react-router-dom'
import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/database';    // for realtime database

import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { firebaseClient } from '../../services/firebase';

import styles from './Auth.module.scss'
interface SignupInput {
  email?: string,
  password?: string,
  password2?: string,
}
interface SignupErrorInput {
  global?: string,
  email?: string | boolean,
  password?: string | boolean,
  password2?: string | boolean,
}

export const Signup = () => {
  const [values, setValues] = React.useState<SignupInput>({});
  const [errors, setErrors] = React.useState<SignupErrorInput>({});

  const onChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setValues(prev => ({...prev, [name]: value}))
  }

  const onSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    e.stopPropagation();

    if(values.email && values.password) {
      try {
        const tennantId = firebaseClient.tennantId
        firebase.auth().tenantId = tennantId as string //"dashboard-e4cd0"
        await firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
      } catch(error) {
        if (error.code === 'auth/email-already-in-use') {
          setErrors({
            email: "The email address is already in use by another account.",
          });
        }
      }
    }
  }

  let isSubmitEnabled = true
  if(values.email == null || values.email.length === 0) isSubmitEnabled = false
  if(values.password == null || values.password.length === 0) isSubmitEnabled = false
  if(values.password2 == null || values.password2.length === 0) isSubmitEnabled = false
  if(values.password2 !== values.password) isSubmitEnabled = false

  return <div className={styles.container} onSubmit={onSubmit}>
    <h1>Signup</h1>
    <form className={styles.authBox}>
      <Input name="email"
        value={values.email}
        error={errors.email}
        title="Email"
        type="email"
        className={styles.input}
        onChange={onChange} />

      <Input name="password"
        value={values.password}
        error={errors.password}
        title="Password"
        type="password"
        className={styles.input}
        onChange={onChange} />

      <Input name="password2"
        value={values.password2}
        error={errors.password2}
        title="Confirm password"
        type="password"
        className={styles.input}
        onChange={onChange} />

      <Button type="submit" className={styles.submit} disabled={!isSubmitEnabled}>Signup</Button>
      <Link to="/login">Already have an account?</Link>
    </form>
  </div>
}