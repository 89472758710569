import { CSSProperties } from "react";
import React from "react";
import { createPortal } from 'react-dom';
import { useKey } from "../../hooks/useKey"
import usePortal from "../../hooks/usePortal";

import styles from './Popup.module.scss';

const PopupPortal: React.FC<{ id?: string }> = ({id, children}) => {
  const target = usePortal(id || 'smiley-modal-portal');
  if(target != null) {
    return createPortal(children, target);
  } else {
    return null
  }
};

interface Props {
  popupClassName?: string;
  popupContentClassName?: string;
  icon?: any;
  onSubmit? : React.FormEventHandler;
  style?: CSSProperties;
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
}

export const Popup: React.FC<Props> = ({popupClassName, popupContentClassName, onClose, icon, onSubmit, children, style}) => {
  React.useEffect(() => {
    document.body.classList.add("popupShown");
    return () => {
      document.body.classList.remove("popupShown")
    }
  }, []);
  useKey(['Escape'], onClose);

  const Tag = onSubmit != null ? 'form' : 'div';
  const props: any = {};
  if (onSubmit) {
    props['onSubmit'] = onSubmit
  }

  return <PopupPortal>
    <div className={[styles.container, popupClassName].join(' ')} style={style}>
      <div className={styles.background} onClick={onClose}/>
      <Tag className={styles.content} {...props}>
        {icon != null && <div className={styles.circle}>
          {icon}
        </div>}
        <div className={[styles.contentBody, popupContentClassName].join(" ")}>
          {children}
        </div>
      </Tag>
    </div>
  </PopupPortal>
};
