import React, {CSSProperties, MouseEventHandler} from "react"
import classNames from "classnames";

import styles from "./MaskType.module.scss"
interface Props {
  onClick?: MouseEventHandler;
  title: string;
  subtitle?: string;
  src?: string;
  price?: string;
  additional?: boolean;
  className?: string;
  selected?: boolean;
}

export const MaskType: React.FC<Props> = ({className, onClick, src, title, subtitle, price, selected, additional = false}) => {
  const style = src ?{backgroundImage: `url("${src}")`} : {} as CSSProperties

  return <div className={classNames(styles.container, selected ? styles.selected : undefined, className)} style={style} onClick={onClick}>
    <div className={styles.bottom}>
      {((title?.length || 0) > 0) && <span className={styles.title}>{title}</span>}
      {((subtitle?.length || 0) > 0) && <span className={styles.subtitle}>{subtitle}</span>}
    </div>

    {
      price && <div className={styles.priceContainer}>
        {additional && <span>+</span>}
        <span className={styles.price}>{price}</span>
      </div>
    }
  </div>
}