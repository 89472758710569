import React from 'react'

import { useForm } from '../../hooks/useForm'

import { useProductsContext } from '../../contexts/products.context'

import { Product, PatternSlot, getPrintType } from '../../models/Product'

import { Meta, isNotNull, formatPrice, getFireDataUrl } from '../../utils'

import { FirebaseDBLink, SidebarHeader } from '../SidebarHeader/SidebarHeader'
import { Input, Textarea } from '../Input/Input'
import { CheckboxInput } from '../CheckboxInput/CheckboxInput'
import { Scrollable } from '../Scrollable/Scrollable'
import { Button } from '../Button/Button'
import { MaskLayout } from '../MaskLayout/MaskLayout'
import { usePatternsContext } from '../../contexts/patterns.context'
import { NineCircles } from '../NineCircles/NineCircles'
import { SegmentedControl } from '../SegmentedControl/SegmentedControl'
import { UpdatableImage } from '../UpdatableImage/UpdatableImage'
import {XMarkCircleFill} from '../../toneIcons'

import styles from './ProductSidebar.module.scss'
import { useFeaturesContext } from '../../contexts/features.context'
import { Spacer } from '../Spacer/Spacer'
import { without } from 'lodash'

interface ProductSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void,
  onUpdate: (data: Partial<Product>) => void,
  onDelete: (id: string) => void,
  data?: Meta<Product>
}

export const ProductSidebar: React.FC<ProductSidebarProps> = ({onClose, onUpdate, onDelete, data}) => {
  const dataId = data?.data?.id
  return <div className={styles.container}>
    <SidebarHeader title={data?.data?.name?.toUpperCase()} onClose={onClose}>
      <FirebaseDBLink href={dataId ? getFireDataUrl(`masks/${dataId}`) : undefined} />
    </SidebarHeader>


    {data?.data != null && <Scrollable>
      <ProductSidebarWithData data={data?.data} onUpdate={onUpdate} onDelete={onDelete} />
    </Scrollable>}
  </div>
}

interface ProductSidebarDataProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: Product,
  onUpdate: (data: any) => void,
  onDelete: (id: string) => void,
}

const ProductSidebarWithData: React.FC<ProductSidebarDataProps> = ({data, onUpdate, onDelete}) => {
  const [, {addFile}] = useProductsContext()
  const [{patternById}] = usePatternsContext()
  const [{featureById}] = useFeaturesContext()

  const {values, onChange, onDebounceChange} = useForm({
    name: data.name || "",
    displayName: data.displayName || "",
    shortName: data?.shortName || "",
    size: data.size || "",
    order: data.order,
    price: data.price,
    oldPrice: data.oldPrice,
    value: data.value,
    width: data.width,
    height: data.height,
    seamTop: data.seamTop,
    seamRight: data.seamRight,
    seamBottom: data.seamBottom,
    seamLeft: data.seamLeft,
    insetTop: data.insetTop,
    insetRight: data.insetRight,
    insetBottom: data.insetBottom,
    insetLeft: data.insetLeft,
    weight: data.weight,
    disabled: [String(data.disabled || "false")],
    allowsShrinkPatternToFit: [String(data.allowsShrinkPatternToFit || "false")],
    faceAnchor: data.faceAnchor,
    maskAnchor: data.maskAnchor,
    patternSlots: data.patternSlots,
    eyeLine: data.eyeLine,
    description: data.description,
    taxCode: data.taxCode
  }, (_, name, value) => {
    if(name === 'price' || name === 'oldPrice' || name === 'value') {
      const numberValue = Number.parseInt(value)
      onUpdate({[name]: Number.isNaN(numberValue) ? null : numberValue})
    } else if(name === 'order') {
      const numberValue = Number.parseInt(value)
      onUpdate({[name]: Number.isNaN(numberValue) ? -1 : numberValue})
    } else if(name === 'width' || name === 'height' || name === 'seamTop' || name === 'seamRight' || name === 'seamBottom' || name === 'seamLeft' || name === 'weight') {
      const numberValue = Number.parseFloat(value)
      onUpdate({[name]: Number.isNaN(numberValue) ? null : numberValue})
    } else if(name === 'disabled' || name === 'allowsShrinkPatternToFit') {
      const newValue = value?.indexOf("true") >= 0
      onUpdate({[name]: newValue})
    } else onUpdate({[name]: value})
  })

  const addPatternSlot = () => {
    if((data.patternSlots?.length || 0) > 0) {
      const addingSlot: PatternSlot = {
        anchor: {
          vertical: data.maskAnchor?.vertical || "center",
          horizontal: "end"
        },
        transform: "rotate(-180)"
      }
      const patternSlots = [...(data.patternSlots || []), addingSlot]
      onUpdate({patternSlots})
    } else {
      const addingSlot: PatternSlot = {
        anchor: {
          vertical: data.maskAnchor?.vertical || "center",
          horizontal: "start"
        }
      }
      onUpdate({patternSlots: [addingSlot]})
    }
  }

  const updateSlotAnchor = (index: number) => (e: any) => {
    const patternSlots = data.patternSlots?.map( (s, i) => {
      if(i === index) {
        return {...s, [e.currentTarget.name]: e.currentTarget.value}
      }
      return s
    })

    onUpdate({patternSlots})
  }

  const deleteSlotAnchor = (index: number) => () => {
    const patternSlots = data.patternSlots?.filter( (s, i) => i !== index)
    onUpdate({patternSlots})
  }

  const onAddImage = (id: string, file?: File) => {
    if(id != null && file != null) {
      addFile(id, file)
    }
  }  
  
  const removeFeature = (id: string) => {
    const newIds = without(data.featureIds, id) || []
    onUpdate({featureIds: newIds})
  }
  const addFeature = (id: string) => {
    const newIds = [...without(data.featureIds, id), id]
    const feature = featureById[id]
    const updateObj: Partial<Product> = {featureIds: newIds}
    if(feature?.data?.isGroup) {
      updateObj.printType = getPrintType(feature.data.slug)
    }
    onUpdate(updateObj)
  }

  const features = data.featureIds?.map(id => featureById?.[id]).filter(isNotNull);
  const isSewable = features?.find(f => f.data?.slug === 'sewable') != null

  return <>
    <CheckboxInput title="Is Disabled"
           name="disabled"
           value="true"
           checked={values.disabled?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />

    <UpdatableImage src={data.url} onAddImage={onAddImage} id={data?.id} />
    
    <Input title="Name"
           name="name"
           value={values.name}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Display Name"
           name="displayName"
           value={values.displayName}
           className={styles.input}
           onChange={onDebounceChange} />

    <Textarea title="Description"
           name="description"
           value={values.description}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Short Name"
           name="shortName"
           value={values.shortName}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Price (in cents)"
           name="price"
           value={values.price}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Old Price (in cents)"
           name="oldPrice"
           value={values.oldPrice}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Value for gift cards(in cents)"
           name="value"
           value={values.value}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Tax code"
           name="taxCode"
           value={values.taxCode}
           className={styles.input}
           onChange={onDebounceChange} />

    <SegmentedControl name="size"
      checkedValue={values.size}
      values={[
        {value: "small", title: "Small"},
        {value: "medium", title: "Medium"},
        {value: "large", title: "Large"},
      ]}
      onChange={onChange} />

    <Input title="Order"
           name="order"
           value={values.order}
           className={styles.input}
           onChange={onDebounceChange} />
    
    <Input title="Width (inches)"
           name="width"
           value={values.width}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Height (inches)"
           name="height"
           value={values.height}
           className={styles.input}
           onChange={onDebounceChange} />

    <Features featureIds={data.featureIds} addFeature={addFeature} removeFeature={removeFeature} />

    {isSewable && <>
      <Input title="Seam Top (inches)"
            name="seamTop"
            value={values.seamTop}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Seam Right (inches)"
            name="seamRight"
            value={values.seamRight}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Seam Bottom (inches)"
            name="seamBottom"
            value={values.seamBottom}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Seam Left (inches)"
            name="seamLeft"
            value={values.seamLeft}
            className={styles.input}
            onChange={onDebounceChange} />


      <Input title="Inset Top (inches)"
            name="insetTop"
            value={values.insetTop}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Inset Right (inches)"
            name="insetRight"
            value={values.insetRight}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Inset Bottom (inches)"
            name="insetBottom"
            value={values.insetBottom}
            className={styles.input}
            onChange={onDebounceChange} />
      <Input title="Inset Left (inches)"
            name="insetLeft"
            value={values.insetLeft}
            className={styles.input}
            onChange={onDebounceChange} />
    </>}

    <Input title="Weight (grams)"
           name="weight"
           value={values.weight}
           className={styles.input}
           onChange={onDebounceChange} />

    <CheckboxInput title="Allows Shrinking Pattern to fit width"
           name="allowsShrinkPatternToFit"
           value="true"
           checked={values.allowsShrinkPatternToFit?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />
    
    
    <div className={styles.anchors}>
      <NineCircles title="Mask anchor"
        anchor={values.maskAnchor}
        className={styles.anchor}
        onChange={onChange}
        name="maskAnchor" />
      <NineCircles title="Face anchor"
        anchor={values.faceAnchor}
        className={styles.anchor}
        onChange={onChange}
        name="faceAnchor" />
    </div>
    {data.patternSlots?.map((slot, index) => {
      return <div key={index}>
        <div>Slot {index}</div>
        <Button onClick={deleteSlotAnchor(index)}>Delete slot {index}</Button>

        <NineCircles title="Mask anchor"
          anchor={slot.anchor}
          className={styles.anchor}
          onChange={updateSlotAnchor(index)}
          name={"anchor"} />
      </div>
    })}
    <Button onClick={addPatternSlot}>Add Pattern Slot</Button>
    <MaskLayout data={data} className={styles.mask} patternIdBySlot={[]} patternById={patternById} />

    <div>
      <Button onClick={() => onDelete(data.id)}>Delete</Button>
    </div>
  </>
}


interface FeaturesProps {
  featureIds: string[] | undefined,
  removeFeature: (id: string) => void,
  addFeature: (id: string) => void,
}
const Features: React.FC<FeaturesProps> = ({featureIds, addFeature, removeFeature}) => {
  const [featureId, setFeatureId] = React.useState<string>();
  const [{featureById}] = useFeaturesContext()
  const hasFeatures = featureIds != null && featureIds.length > 0

  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation();

    if(featureId) {
      addFeature(featureId)
      setFeatureId("")
    }
  }

  return <div className={styles.features}>
    {!hasFeatures && <div>No features added</div>}
    {hasFeatures && featureIds?.map(id => {
      const feature = featureById[id]
      if(feature?.data == null) return null;
      return <div key={id} className={styles.feature}>
        <div className={styles.featureContent}>
          <div className={styles.featureName}>{feature.data.name} - {feature.data.id}</div>
          <div className={styles.featureDescription}>{feature.data.description}</div>
          {(feature.data.price || 0) > 0 && <div className={styles.featurePrice}>
            <span>${formatPrice(feature.data.price)}</span>
            {(feature.data.oldPrice || 0) > 0 && <span> &larr; <span className={styles.oldPrice}>(${formatPrice(feature.data.oldPrice)})</span></span>}
          </div>}
        </div>
        <Spacer />
        <div className={styles.removeFeature} onClick={() => removeFeature(id)}>
          <XMarkCircleFill />
        </div>
      </div>
    })}
    <form onSubmit={onSubmit}>
      <Input title="Feature id to add" name="featureId" value={featureId} onChange={e => setFeatureId(e.target.value)} className={styles.input} />
    </form>
  </div>
}