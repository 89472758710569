import React, { FormEvent } from 'react'
import * as qz from 'qz-tray';
import toast from 'react-hot-toast';

import { Input } from '../Input/Input';

import { useProductsContext } from '../../contexts/products.context';
import { useOrdersContext } from '../../contexts/orders.context';

import styles from './Verifier.module.scss'

export const Verifier = () => {
  const [shippingLabelPrinter, setShippingLabelPrinter] = React.useState<string>();
  const [, setReceiptPrinter] = React.useState<string>();
  const [value, setValue] = React.useState<string>()
  const [{productById}] = useProductsContext()
  const [, {fetchOrder, createShippingLabel, markPackagedOrder, markPrintedOrderProduct, reprintOrderProduct}] = useOrdersContext()
  
  const style = {
    '--sidepanel-width': 'var(--sidepanel-width-opened)'
  } as React.CSSProperties;

  const printLabel = async (shippoUrl: string) => {
    try {
      var config = qz.configs.create(shippingLabelPrinter, {
        size: { width: 4.00, height: 6.00 }, units: 'in',
        colorType: 'grayscale',
        interpolation: "nearest-neighbor",
        scaleContent: "false"
      });
      var data = [{
        type: 'pdf',
        data: shippoUrl
      }];
      await qz.print(config, data)
      setValue("")
    } catch(error) {
      console.error(error)
      toast.error(error.message || error.details || error.error ||"Unknown error occurred")
    };
  }

  React.useEffect(() => {
    const fn = async() => {
      try {
        await qz.websocket.connect();
        const printers: string[] = await qz.printers.find()
        const zebra = printers.find(p => p.toLowerCase().startsWith('zebra'))
        const brother = printers.find(p => p.toLowerCase().startsWith('brother'))
        setReceiptPrinter(brother);
        setShippingLabelPrinter(zebra);
      } catch(error) {
        console.error(error)
        toast.error(error.message || error.details || error.error ||"Unknown error occurred")
      }
    }
    fn();

    return () => {
      try {
        if(qz.websocket.isActive()) {
          qz.websocket.disconnect()
        }
      } catch(error) {
        console.error(error)
        toast.error(error.message || error.details || error.error ||"Unknown error occurred")
      }
    }    
  }, [setShippingLabelPrinter, setReceiptPrinter])

  const submitBarcode = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    
    const splitValue = value?.split("/")
    if(splitValue?.length === 2 && splitValue?.[0] === 's') {
      //s === generate shipping label
      const orderId = splitValue[1];
      const order = await fetchOrder(orderId);

      if(order?.shippoTransaction?.label_url != null) {
        await printLabel(order.shippoTransaction.label_url)
      } else {
        const shippoTransaction = await createShippingLabel(orderId);
        await printLabel(shippoTransaction.label_url)
      }
      await markPackagedOrder(orderId)
    } else if(splitValue?.length === 3 && splitValue?.[0] === 'o') {
      //o === view the order
      const orderId = splitValue[1];
      // const product = splitValue[2];
      window.open(`/admin?orderId=${orderId}`)?.focus()
      setValue("")
    } else if(splitValue?.length === 4 && splitValue?.[0] === 'p') {
      //p === this came off the printer need to wait for another scan
      const orderId = splitValue[1];
      const productId = splitValue[2];
      const status = splitValue[3];
      if(status === 'a') {
        await markPrintedOrderProduct(orderId, productId)
        setValue("")
      } else if(status === 'r') {
        await reprintOrderProduct(orderId, productId, {usureText: "Barcode Scanner deemed bad"})
        setValue("")
      } else if(status === 'v') {
        window.open(`/admin?orderId=${orderId}`)?.focus()
        setValue("")
      } else if(status === 'p') {
        window.open(`/admin/receipt?orderId=${orderId}`)?.focus()
        setValue("")
      }
    } else if(splitValue?.length === 2 && splitValue?.[0] === 'r') {
      //r === I want the receipt printed
      const orderId = splitValue[1];
      const order = await fetchOrder(orderId);

      if(order && productById) {
        // const page = renderToString(<ReceiptPageWithData order={order} productById={productById} />)
        // await printReceipt(page)
      }
      
      // await printReceipt(orderId)
    } else if(splitValue?.length === 1 && splitValue?.[0]?.length === 34) {
      //this looks like a shippo/usps tracking id
      console.log(splitValue[0])
    }
  }
  return <div style={style} className={styles.container}>
    <div className={styles.content}>
      <div>BARCODES</div>
      <form onSubmit={submitBarcode}>
        <Input title="barcode" value={value || ""} onChange={e => setValue(e.target.value)} autoFocus />
      </form>
    </div>
  </div>
}