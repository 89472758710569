import React from 'react'

import { Range } from '../Range/Range';

import styles from './RotateSelfie.module.scss'
import { useOrdersContext } from '../../contexts/orders.context';
import { OrderProduct } from '../../models/Order.model';
import { getDashboardStorageUrls, getOrdersStorageUrls } from '../../services/firebase';
import { useDebouncedCallback } from 'use-debounce/lib';
interface RotateSelfieContainerProps {
  orderId: string,
  productId: string
}

export const RotateSelfieContainer: React.FC<RotateSelfieContainerProps> = ({orderId, productId}) => {
  const [{orderById}, {fetchOrder, updateOrderProduct }] = useOrdersContext()

  React.useEffect(() => {
    if(fetchOrder) fetchOrder(orderId)
  }, [fetchOrder, orderId])

  const order = orderById[orderId];
  const product = order?.data?.products?.[productId]

  const updateRotation = React.useCallback((rotation) => {
    if(updateOrderProduct) {
      updateOrderProduct(orderId, productId, {[`correctedRollAngle`]: rotation})
    }
  }, [orderId, productId, updateOrderProduct])

  if(product == null) return null;

  return <RotateSelfieProduct orderId={orderId} product={product} updateRotation={updateRotation} />
}



interface RotateSelfieProductProps {
  orderId: string,
  product: OrderProduct,
  updateRotation: (rotation: number) => void
}

export const RotateSelfieProduct: React.FC<RotateSelfieProductProps> = ({orderId, product, updateRotation}) => {
  const [imageSize, setImageSize] = React.useState<{[key in "width" | "height"] : number}>()
  const [images, setImages] = React.useState<any>({});

  const productId = product.id

  const fetchImages = React.useCallback(async (orderId, productId) => {
    const [orderImages, dashboardImages] = await Promise.all( [getOrdersStorageUrls(orderId, productId), getDashboardStorageUrls(orderId, productId)] )
    const images = {
      ...orderImages,
      ...dashboardImages
    }

    setImages(images)
  }, [])

  React.useEffect(() => {
    if (orderId != null && productId != null) {
      fetchImages(orderId, productId)
    }
  }, [orderId, productId, fetchImages]);

  const faceImage = images[ product.rawFaceFileName ||'raw_face.jpg']

  React.useEffect(() => {
    if(faceImage != null) {
      const img = new Image();
      img.onload = () => {
        setImageSize({width: img.width, height: img.height})
      }
      img.src = faceImage;
    }
  }, [faceImage])

  if(imageSize?.width == null) return null;
  if(imageSize?.height == null) return null;

  return <RotateSelfieImage url={faceImage} width={imageSize?.width} height={imageSize?.height} initialValue={product.correctedRollAngle || 0} onUpdate={updateRotation} />
}

interface RotateSelfieImageProps {
  url: string,
  width: number,
  height: number,
  initialValue: number,
  onUpdate: (rotation: number) => void
}

export const RotateSelfieImage: React.FC<RotateSelfieImageProps> = ({initialValue, url, width, height, onUpdate}) => {
  const [rotation, setRotation] = React.useState<number>(initialValue);

  const debounced = useDebouncedCallback((value) => onUpdate(value), 800);

  const updateFace = React.useCallback((value) => {
    setRotation(value)
    debounced.callback(value)
  }, [debounced])

  const transform = [
    `translate(${width/2}, ${height/2})`,
    `rotate(${-rotation})`,
    `translate(${-width/2}, ${-height/2})`
  ]

  const SIZE = 72
  const lines = Array.from({length: SIZE}, (_, i) => i)

  return <>
    {width && height && <svg viewBox={`0 0 ${width} ${height}`} className={styles.svg}>
      <g transform={transform.join(' ')}>
        {url && <image width={width} height={height} href={url} />}
      </g>]
      
      <g strokeWidth={2} stroke={"rgba(0,0,0,0.2)"} className={styles.smallLines}>
        {lines.map(l => {
          return <React.Fragment key={l}>
            <line x1={l*width/SIZE} x2={l*width/SIZE} y1={0} y2={height} />
            <line x1={0} x2={width} y1={l*height/SIZE} y2={l*height/SIZE} />
          </React.Fragment>
        })}
      </g>
      {/* <g strokeWidth={2} stroke={ isRotating ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0)"} className={styles.smallLines}>
        {lines.map(l => {
          if(l % 3 !== 0) {
            return <React.Fragment key={l}>
              <line x1={l*width/SIZE} x2={l*width/SIZE} y1={0} y2={height} />
              <line x1={0} x2={width} y1={l*height/SIZE} y2={l*height/SIZE} />
            </React.Fragment>
          }
          else return null;
        })}
      </g> */}
      {/* <g strokeWidth={2} stroke={"rgba(255,255,255,.4)"}>
        <line x1={0} x2={width} y1={height/3} y2={height/3} />
        <line x1={0} x2={width} y1={2*height/3} y2={2*height/3} />

        <line x1={width/3} x2={width/3} y1={0} y2={height} />
        <line x1={2*width/3} x2={2*width/3} y1={0} y2={height} />
      </g> */}
    </svg>}

    {rotation != null && <Range value={rotation} min={-45} max={45} className={styles.range} onUpdateValue={updateFace} />}
  </>
}


export const CenterXSelfieContainer: React.FC<RotateSelfieContainerProps> = ({orderId, productId}) => {
  const [{orderById}, {fetchOrder, updateOrderProduct}] = useOrdersContext()

  React.useEffect(() => {
    if(fetchOrder) fetchOrder(orderId)
  }, [fetchOrder, orderId])

  const order = orderById[orderId];
  const product = order?.data?.products?.[productId]

  const update = React.useCallback((value) => {
    if(updateOrderProduct) {
      updateOrderProduct(orderId, productId, {[`correctedCenterX`]: value})
    }
  }, [orderId, productId, updateOrderProduct])

  if(product == null) return null;

  return <CenterXSelfieProduct orderId={orderId} product={product} update={update} />
}

export const EyeBotYSelfieContainer: React.FC<RotateSelfieContainerProps> = ({orderId, productId}) => {
  const [{orderById}, {fetchOrder, updateOrderProduct}] = useOrdersContext()

  React.useEffect(() => {
    if(fetchOrder) fetchOrder(orderId)
  }, [fetchOrder, orderId])

  const order = orderById[orderId];
  const product = order?.data?.products?.[productId]

  const updateRotation = React.useCallback((rotation) => {
    if(updateOrderProduct) {
      updateOrderProduct(orderId, productId, {[`correctedEyeBotY`]: rotation})
    }
  }, [orderId, productId, updateOrderProduct])

  if(product == null) return null;

  return <RotateSelfieProduct orderId={orderId} product={product} updateRotation={updateRotation} />
}




interface CenterXSelfieProductProps {
  orderId: string,
  product: OrderProduct,
  update: (rotation: number) => void
}

export const CenterXSelfieProduct: React.FC<CenterXSelfieProductProps> = ({orderId, product, update}) => {
  const [imageSize, setImageSize] = React.useState<{[key in "width" | "height"] : number}>()
  const [images, setImages] = React.useState<any>({});

  const productId = product.id

  const fetchImages = React.useCallback(async (orderId, productId) => {
    const [orderImages, dashboardImages] = await Promise.all( [getOrdersStorageUrls(orderId, productId), getDashboardStorageUrls(orderId, productId)] )
    const images = {
      ...orderImages,
      ...dashboardImages
    }

    setImages(images)
  }, [])

  React.useEffect(() => {
    if (orderId != null && productId != null) {
      fetchImages(orderId, productId)
    }
  }, [orderId, productId, fetchImages]);

  const faceImage = images[ product.rawFaceFileName ||'raw_face.jpg']

  React.useEffect(() => {
    if(faceImage != null) {
      const img = new Image();
      img.onload = () => {
        setImageSize({width: img.width, height: img.height})
      }
      img.src = faceImage;
    }
  }, [faceImage])

  if(imageSize?.width == null) return null;
  if(imageSize?.height == null) return null;

  const rotation = product.correctedRollAngle || product.rotatedMeta?.rollAngle || 0
  const initialValue = product.correctedCenterX || product.rotatedMeta?.centerX || 0
  return <CenterXSelfieProductImage url={faceImage} width={imageSize?.width} height={imageSize?.height} rotation={rotation} initialValue={initialValue} onUpdate={update} />
}

interface CenterXSelfieProductImageProps {
  url: string,
  width: number,
  height: number,
  initialValue: number,
  rotation: number,
  onUpdate: (rotation: number) => void
}


export const CenterXSelfieProductImage: React.FC<CenterXSelfieProductImageProps> = ({initialValue, url, rotation, width, height, onUpdate}) => {
  const [value, setValue] = React.useState<number>(initialValue);

  const debounced = useDebouncedCallback((value) => onUpdate(value), 800);

  const updateFace = React.useCallback((value) => {
    setValue(value)
    debounced.callback(value)
  }, [debounced])

  const transform = [
    `translate(${width/2}, ${height/2})`,
    `rotate(${-rotation})`,
    `translate(${-width/2}, ${-height/2})`
  ]

  return <>
    {width && height && <svg viewBox={`0 0 ${width} ${height}`} className={styles.svg}>
      <g transform={transform.join(' ')}>
        {url && <image width={width} height={height} href={url} />}
      </g>]
      <line x1={value} x2={value} y1={0} y2={height} stroke="red" strokeWidth={2} />
    </svg>}

    {value != null && <Range value={value} min={0} max={width} className={styles.range} onUpdateValue={updateFace} />}
  </>
}