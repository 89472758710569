import React from 'react'

import { FirebaseDBLink, SidebarHeader } from '../SidebarHeader/SidebarHeader'
import { Input } from '../Input/Input'
import { CheckboxInput } from '../CheckboxInput/CheckboxInput'
import { Scrollable } from '../Scrollable/Scrollable'
import { Button } from '../Button/Button'
import { SegmentedControl } from '../SegmentedControl/SegmentedControl'

import { getFireDataUrl, Meta } from '../../utils'
import { PromoCode } from '../../models/PromoCode'
import { useForm } from '../../hooks/useForm'

import styles from './PromoCodeSidebar.module.scss'

interface PromoCodeSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void,
  onUpdate: (data: Partial<PromoCode>) => void,
  onDelete: (id: string) => void,
  data?: Meta<PromoCode>
}

export const PromoCodeSidebar: React.FC<PromoCodeSidebarProps> = ({onClose, onUpdate, onDelete, data}) => {
  const dataId = data?.data?.id
  return <div className={styles.container}>
    <SidebarHeader title={data?.data?.code?.toUpperCase()} onClose={onClose}>
      <FirebaseDBLink href={dataId ? getFireDataUrl(`promoCodes/${dataId}`) : undefined} />
    </SidebarHeader>

    {data?.data != null && <Scrollable>
      <PromoCodeSidebarWidthData data={data?.data} onUpdate={onUpdate} onDelete={onDelete} />
    </Scrollable>}
  </div>
}

interface PromoCodeSidebarDataProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: PromoCode,
  onUpdate: (data: any) => void,
  onDelete: (id: string) => void,
}

const PromoCodeSidebarWidthData: React.FC<PromoCodeSidebarDataProps> = ({data, onUpdate, onDelete}) => {
  const {values, onChange, onDebounceChange} = useForm({
    code: data.code || "",
    m: data.m,
    b: data.b,
    description: data.description,
    applicationLevel: data.applicationLevel,
    maxUses: data.maxUses,
    currentUses: data.currentUses,
    shippingOff: [String(data.shippingOff || "false")],
    disabled: [String(data.disabled || "false")],
    nForOneFree: data.nForOneFree,
  }, (_, name, value) => {
    if(name === 'b') {
      const numberValue = Number.parseInt(value)
      if(!Number.isNaN(numberValue)) {
        onUpdate({[name]: numberValue})
      }
    } else if(name === 'm') {
      const numberValue = Number.parseFloat(value)
      if(!Number.isNaN(numberValue)) {
        onUpdate({[name]: numberValue})
      }
    } else if(name === 'disabled' ||  name === 'shippingOff') {
      const newValue = value?.indexOf("true") >= 0
      onUpdate({[name]: newValue})
    } else if(name === 'maxUses' || name === 'currentUses') {
      if(value.length === 0) {
        onUpdate({[name]: null})
      } else {
        const numberValue = Number.parseInt(value)
        if(!Number.isNaN(numberValue)) {
          onUpdate({[name]: numberValue})
        }
      }
    } else if(name === 'nForOneFree') {
      const numberValue = Number.parseFloat(value)
      if(!Number.isNaN(numberValue)) {
        onUpdate({[name]: numberValue})
      } else if(value == null || value.length === 0) {
        onUpdate({[name]: null})
      }
    } else onUpdate({[name]: value})
  })

  return <>
    <CheckboxInput title="Is Disabled"
           name="disabled"
           value="true"
           checked={values.disabled?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />
    <CheckboxInput title="Is Shipping Off"
          name="shippingOff"
          value="true"
          checked={values.shippingOff?.indexOf("true") >= 0}
          className={styles.input}
          onChange={onChange} />
    <Input title="Code"
           name="code"
           value={values.code}
           className={styles.input}
           disabled
           onChange={onDebounceChange} />
    <Input title="M"
           name="m"
           value={values.m}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="B"
           name="b"
           value={values.b}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Description"
           name="description"
           value={values.description}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Max uses"
           name="maxUses"
           value={values.maxUses}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="Current uses"
           name="currentUses"
           disabled
           value={values.currentUses || 0}
           className={styles.input}
           onChange={onDebounceChange} />

    <Input title="N For One Free"
           name="nForOneFree"
           value={values.nForOneFree || ""}
           className={styles.input}
           onChange={onDebounceChange} />

    <SegmentedControl name="applicationLevel"
      checkedValue={values.applicationLevel}
      values={[{value: "order", title: "Order"}, {value: "item", title: "Item"}]}
      onChange={onChange} />
    
    <Button onClick={() => onDelete(data.id)}>Delete</Button>
  </>
}