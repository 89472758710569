import React from "react";
import styles from "./MessagingSection.module.scss";
import {useOrdersContext} from "../../../contexts/orders.context"
import {Button} from "../../Button/Button"
import {Order} from "../../../models/Order.model";
import { Textarea } from "../../Input/Input";

interface Props {
  order: Order
}

export const PrivateNotesSection: React.FC<Props> = ({order}) => {
  const [, {updateOrder}] = useOrdersContext()
  const [messageError, setMessageError] = React.useState("");
  const [messageText, setMessageText] = React.useState(order.notes || "");

  const submitHandler = (e) => {
    e.preventDefault();

    if (!messageText) {
      setMessageError("This can't be empty broooo!")
      return;
    }

    updateOrder(
      order.id,
      {
        notes: messageText
      }
    )
  }

  const textChangeHandler = e => {
    setMessageError("")
    setMessageText(e.target.value)
  }

  return <div className={styles.container}>
    <details>
      <summary>Private notes</summary>
      {
        <form onSubmit={submitHandler} className={styles.form}>
          <Textarea onChange={textChangeHandler}
                    value={messageText}
                    rows={5}
          />
          <span className={styles.error}>{messageError}</span>
          <Button type={"submit"}>Save</Button>
        </form>
      }

    </details>
  </div>
};
