import React from 'react'
import { Link , useLocation, Redirect} from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'
import { set, get, sortBy } from 'lodash'
import toast from 'react-hot-toast';
import * as bezierSpline from '@freder/bezier-spline'

import { useOrdersContext } from '../../contexts/orders.context';
import {getDashboardStorageUrls, getOrdersStorageUrls} from "../../services/firebase";

import { Point } from '../../models/Point';
import { OrderProduct } from '../../models/Order.model';

import { Circle } from '../../components/Circle/Circle';
import { Spacer } from '../../components/Spacer/Spacer';

import { useQuery } from '../../hooks/useQuery';
import { EyeIcon, MouthIcon, FaceOvalIcon, XMarkFill, MoveIcon } from '../../toneIcons';

import styles from './FacePoints.module.scss'
import {isNotNull} from '../../utils'
import { useDebouncedCallback } from 'use-debounce/lib'

interface FacePointsContainerProps {
  orderId: string,
  productId: string
}

export const FacePointsContainer: React.FC<FacePointsContainerProps> = ({orderId, productId}) => {
  const location = useLocation()
  const query = useQuery()

  const metaKey = query.get('metaKey')
  const object = query.get('object')
  const mirror = query.get('mirror')
  const move = query.get('move')
  const pathname = location.pathname

  const queryObj = queryString.parse(location.search);
  
  let shouldRedirect = false;
  if(!metaKey || ['arMeta', 'rotatedMeta'].indexOf(metaKey) < 0) {
    queryObj.metaKey = 'rotatedMeta'
    shouldRedirect = true
  } else if(!object || ['faceOval', 'eyes', 'mouth'].indexOf(object) < 0) {
    queryObj.object = 'faceOval'
    shouldRedirect = true
  }

  if(shouldRedirect) {
    return <Redirect to={{pathname: pathname, search: `?${queryString.stringify(queryObj)}`}} />
  } else {
    const search = "?" + queryString.stringify({
      ...queryString.parse(location.search),
      orderId: orderId,
      editProductId: undefined,
      editType: undefined,
    })

    let filteredMetaKey: "rotatedMeta" | "arMeta" = "rotatedMeta"
    if(metaKey === 'arMeta') {
      filteredMetaKey = "arMeta"
    }

    return <FacePoints orderId={orderId} productId={productId} object={object || "faceOval"} mirror={mirror || undefined} move={move || undefined} metaKey={filteredMetaKey} editing back={`/admin${search}`} />
  }  
}

interface FacePointsProps {
  orderId: string
  productId: string
  object: string
  metaKey: "rotatedMeta" | "arMeta"
  editing: boolean
  back?: string
  mirror?: string
  move?: string
}

export const FacePoints: React.FC<FacePointsProps> = ({orderId, productId, object, metaKey, editing, back, mirror, move}) => {
  const [imageSize, setImageSize] = React.useState<{[key in "width" | "height"] : number}>()
  const [images, setImages] = React.useState<any>({});
  const [{orderById}, {fetchOrder, updateOrderProduct}] = useOrdersContext()
  const [canvasTranslate, setCanvasTranslate] = React.useState<Point>({x: 0, y: 0});
  const order = orderById?.[orderId]?.data
  const product = order?.products?.[productId];

  React.useEffect(() => {
    if(fetchOrder) fetchOrder(orderId)
  }, [fetchOrder, orderId])

  const fetchImages = React.useCallback(async (orderId, productId) => {
    const [orderImages, dashboardImages] = await Promise.all( [getOrdersStorageUrls(orderId, productId), getDashboardStorageUrls(orderId, productId)] )
    const images = {
      ...orderImages,
      ...dashboardImages
    }
    setImages(images)
  }, [])

  React.useEffect(() => {
    if (orderId != null && productId != null) {
      fetchImages(orderId, productId)
    }
  }, [orderId, productId, fetchImages]);

  let faceImage: any;
  if(metaKey === 'rotatedMeta') {
    faceImage = images['rotated_face.jpg']
  } else if(metaKey === 'arMeta') {
    faceImage = images['ar_raw_face.jpg']
  }

  React.useEffect(() => {
    if(faceImage != null) {
      const img = new Image();
      img.onload = () => {
        setImageSize({width: img.width, height: img.height})
      }
      img.src = faceImage;
    }
  }, [faceImage])

  const onUpdate = async (data: any) => {
    await updateOrderProduct(orderId, productId, data)
    // await updateOrder(orderId, data)
  }

  const isMoving = move === 'true'

  const onMoveCanvas= React.useCallback((p: Point) => {
    setCanvasTranslate(p)
  }, [setCanvasTranslate])

  const transform = [
    `translate(${canvasTranslate.x},${canvasTranslate.y})`,
  ]

  return <div className={styles.container}>
    {back && <Link className={styles.backButton} to={back}>
      <XMarkFill />
    </Link>}
    {imageSize?.width && imageSize?.height && <svg viewBox={`0 0 ${imageSize.width} ${imageSize.height}`} preserveAspectRatio="xMidYMid slice" key={metaKey}>
      <g transform={transform.filter(a => a).join(" ")}>
        {faceImage && <image width={imageSize.width} height={imageSize.height} href={faceImage} />}
        {faceImage && editing && product && metaKey && <FacePointsWithProduct product={product} imageSize={imageSize} selectedMetaKey={metaKey} onUpdate={onUpdate} object={object} isMoving={isMoving} isMirroring={mirror === "true"} />}
      </g> 
    </svg>}
    {isMoving && <MovingOverlay value={canvasTranslate} onUpdateValue={onMoveCanvas} /> }
    <div className={styles.buttons}>
      <FaceObjectButton text="move" tag="true" query="move" icon={<MoveIcon />} selectedTag={move} />
      <FaceObjectButton text="mirror" tag="true" query="mirror" icon={"M"} selectedTag={mirror} />
      <FaceObjectButton text="faceOval" tag="faceOval" query="object" icon={<FaceOvalIcon />} selectedTag={object} />
      <FaceObjectButton text="eyes" tag="eyes" query="object" icon={<EyeIcon />} selectedTag={object} />
      <FaceObjectButton text="mouth" tag="mouth" query="object" icon={<MouthIcon />} selectedTag={object} />
    </div>
    <div className={styles.imageButtons}>
      <ImageButton title="AR" tag="arMeta" src={images['ar_raw_face.jpg']} selectedTag={metaKey} />
      <ImageButton title="PH" tag="rotatedMeta" src={images[ product?.rawFaceFileName || 'rotated_face.jpg']} selectedTag={metaKey} />
    </div>
  </div>
}

interface FaceObjectButtonProps {
  icon: any,
  text: string,
  tag: string,
  query: string,
  selectedTag: string | undefined,
}

const FaceObjectButton: React.FC<FaceObjectButtonProps> = ({icon, text, tag, query, selectedTag}) => {
  const location = useLocation()

  const search = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    [query]: selectedTag === tag ? undefined : tag,
  })

  return <Link className={classNames(styles.button, (selectedTag === tag) ? styles.selected : undefined)} to={search}>
    <Spacer />
    {icon}
    <Spacer />
    <div className={styles.buttonText}>{text}</div>
  </Link>
}


interface ImageButtonProps {
  title: string,
  src: string,
  tag: string,
  selectedTag: string | undefined,
}


const ImageButton: React.FC<ImageButtonProps> = ({title, src, tag, selectedTag}) => {
  const location = useLocation()
  if(src == null) return null;

  const search = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    metaKey: tag,
  })

  return <Link className={classNames(styles.imageButton, (selectedTag === tag) ? styles.selected : undefined)} to={search}>
    <div className={styles.imageButtonTitle}>{title}</div>
    <img src={src} alt={title} />
  </Link>
}

interface FacePointsWithProductProps extends React.HTMLAttributes<SVGCircleElement> {
  product: OrderProduct,
  imageSize: { width: number, height: number },
  onUpdate: (data: any) => Promise<void>,
  object: string,
  selectedMetaKey: "arMeta" | "rotatedMeta",
  isMoving: boolean,
  isMirroring: boolean
}


const FacePointsWithProduct: React.FC<FacePointsWithProductProps> = ({imageSize, product, onUpdate, object, selectedMetaKey, isMoving, isMirroring}) => {
  const [selected, setSelected] = React.useState<[string | undefined, string | undefined]>([undefined, undefined]);
  // const [selectedPointId, setSelectedPointId] = React.useState<string>();
  // const [mirroredPointId, setMirroredPointId] = React.useState<string>();
  const [tmpValues, setTmpValues] = React.useState({});
  
  const debounced = useDebouncedCallback((values) => {
    if(values == null || Object.keys(values).length <= 0) return;
    let newValues = {}

    for(var [key, value] of Object.entries(values)) {
      newValues = set(newValues, key, value)
    }
    toast("saving")
    onUpdate(newValues)
    toast("saved")
  }, 1000);

  const updatePoint = React.useCallback((values) => {
    setTmpValues(v => ({...v, ...values}))
  }, [setTmpValues])

  
  React.useEffect(() => {
    debounced.callback?.(tmpValues)
  }, [tmpValues, debounced])
  
  const {faceObjectKeys, faceObjects} = React.useMemo(() => {
    const faceOval = product[selectedMetaKey]?.faceOval
    const faceOvalKeys = faceOval ? Object.keys(faceOval) : undefined
    const sortedKeys = sortBy(faceOvalKeys, key => faceOval?.[key]?.o).map(key => `${selectedMetaKey}.faceOval.${key}`)

    const faceObjectKeys = ["faceOval", "mouth", "eyes"]
    const faceObjects = {
      eyes: [`${selectedMetaKey}.cEyeL`, `${selectedMetaKey}.cEyeR`],
      mouth: [`${selectedMetaKey}.cMouthL`, `${selectedMetaKey}.cMouthR`],
      faceOval: sortedKeys
    }
    return {faceObjectKeys, faceObjects}
  }, [product, selectedMetaKey])

  const eyeBotY = product.correctedEyeBotY != null ? product.correctedEyeBotY : product[selectedMetaKey]?.eyeBotY
  const centerX = product.correctedCenterX != null ? product.correctedCenterX : product[selectedMetaKey]?.centerX

  React.useEffect(() => {
    setSelected([undefined, undefined])
  }, [object, setSelected])

  React.useEffect(() => {
    if(!isMirroring) {
      setSelected(selected => [selected[0], undefined])
    }
  }, [isMirroring])

  const selectedFaceObject: string[] | undefined = faceObjects[object];
  const keyFn = React.useCallback((e: KeyboardEvent) => {
    if(selected == null) return;
    const [selectedId, mirroredId] = selected
    if(selectedId == null) return;
    if(e.key === 'Tab') {
      e.preventDefault()
      e.stopPropagation()

      const selectedFaceObjectLength = selectedFaceObject?.length || 0
      if(selectedFaceObject != null && selectedId != null) {
        const index = selectedFaceObject.indexOf(selectedId) 
        if(index >= 0) {
          const nextIndex = (index + (e.shiftKey ? -1 : 1) + selectedFaceObjectLength) % selectedFaceObjectLength
          const nextKey = selectedFaceObject[nextIndex]

          const mirroredNextKeyIndex = (selectedFaceObjectLength - nextIndex) % selectedFaceObjectLength
          const mirroredNextKey = selectedFaceObject[mirroredNextKeyIndex]
          if(mirroredNextKey === nextKey || !isMirroring) {
            setSelected([nextKey, undefined])
          } else {
            setSelected([nextKey, mirroredNextKey])
          }
          
        }
      }
    } else if(e.key === "Escape") {
      e.preventDefault()
      e.stopPropagation()
      setSelected([undefined, undefined])
    } else if(e.key === "ArrowUp") {
      e.preventDefault()
      e.stopPropagation()

      const shiftKey = e.shiftKey
      
      const {x, y} = get(tmpValues, selectedId) || get(product, selectedId);
      const newY = y + (shiftKey ? -10 : -1)
      const newValue = {[selectedId]: {x, y: newY}}
      if(selectedId && mirroredId !== selectedId && mirroredId != null) {
        const mirroredX = 2 * (centerX || 0) - newValue[selectedId].x
        newValue[mirroredId] = {y: newY, x: mirroredX}
      }
      updatePoint(newValue)
    } else if(e.key === "ArrowDown") {
      e.preventDefault()
      e.stopPropagation()

      const shiftKey = e.shiftKey
      const {x, y} = get(tmpValues, selectedId) || get(product, selectedId);
      const newY = y + (shiftKey ? 10 : 1)
      const newValue = {[selectedId]: {x, y: newY}}
      if(mirroredId && mirroredId !== selectedId && mirroredId != null) {
        const mirroredX = 2 * (centerX || 0) - newValue[selectedId].x
        newValue[mirroredId] = {y: newY, x: mirroredX}
      }
      updatePoint(newValue)
    } else if(e.key === "ArrowRight") {
      e.preventDefault()
      e.stopPropagation()

      const shiftKey = e.shiftKey
      const {x, y} = get(tmpValues, selectedId) || get(product, selectedId);
      const newValue = {[selectedId]: {y, x: x + (shiftKey ? 10 : 1)}}
      if(mirroredId && mirroredId !== selectedId) {
        const mirroredX = 2 * (centerX || 0) - newValue[selectedId].x
        newValue[mirroredId] = {y, x: mirroredX}
      }
      updatePoint(newValue)
    } else if(e.key === "ArrowLeft") {
      e.preventDefault()
      e.stopPropagation()

      const shiftKey = e.shiftKey
      const {x, y} = get(tmpValues, selectedId) || get(product, selectedId);
      const newValue = {[selectedId]: {y, x: x + (shiftKey ? -10 : -1)}}
      if(mirroredId && mirroredId !== selectedId) {
        const mirroredX = 2 * (centerX || 0) - newValue[selectedId].x
        newValue[mirroredId] = {y, x: mirroredX}
      }
      updatePoint(newValue)
    }
  }, [selected, setSelected, selectedFaceObject, product, tmpValues, centerX, updatePoint, isMirroring])

  React.useEffect(() => {
    if(selected != null) {
      document.addEventListener("keydown", keyFn, false);
    }
    return () => {
      document.removeEventListener("keydown", keyFn, false);
    }
  }, [keyFn, selected])

  const onMouseDown = (id: string) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => {
    let mirroredPointId;
    const selectedFaceObjectLength = selectedFaceObject?.length || 0
    if(selectedFaceObject != null && isMirroring) {
      const index = selectedFaceObject.indexOf(id) 
      if(index >= 0) {
        const mirroredPointIdIndex = (selectedFaceObjectLength - index) % selectedFaceObjectLength
        mirroredPointId = selectedFaceObject[mirroredPointIdIndex]
      }
    }
    if(mirroredPointId === id) {
      setSelected([id, undefined])
    } else {
      setSelected([id, mirroredPointId])
    }
    
  }

  const faceOvalKeys = faceObjects?.faceOval
  const eyesKeys = faceObjects?.eyes
  const mouthKeys = faceObjects?.mouth
  return <>
    {eyeBotY && <line x1={0} x2={imageSize.width} y1={eyeBotY} y2={eyeBotY} stroke="red" strokeWidth={5} />}
    {centerX && <line x1={centerX} x2={centerX} y1={0} y2={imageSize.height} stroke="red" strokeWidth={5} />}

    {mouthKeys != null && <ConnectedPath pointKeys={mouthKeys} product={product} tmpValues={tmpValues} /> }
    {eyesKeys != null && <ConnectedPath pointKeys={eyesKeys} product={product} tmpValues={tmpValues} /> }
    {faceOvalKeys != null && <FaceOvalPath pointKeys={faceOvalKeys} product={product} tmpValues={tmpValues} canvasWidth={imageSize.width} canvasHeight={imageSize.height} />}
    <ConnectedPath pointKeys={faceOvalKeys} product={product} />
    <ConnectedPath pointKeys={faceOvalKeys} product={product} tmpValues={tmpValues} />
    {faceObjectKeys?.map((objectKey) => {
      return <Points
        key={objectKey}
        pointKeys={faceObjects?.[objectKey]}
        product={product}
        tmpValues={tmpValues}
        canvasWidth={imageSize.width}
        canvasHeight={imageSize.height}
        selectedPointId={selected[0]}
        mirroredPointId={selected[1]}
        selectable={!isMoving && object === objectKey}
        onMouseDown={onMouseDown}
      />
    })}
  </>
}

interface PointsProps {
  pointKeys: [string],
  product: OrderProduct,
  tmpValues: {[key: string]: any} | undefined,
  canvasWidth: number,
  canvasHeight: number,
  selectedPointId: string | undefined,
  mirroredPointId: string | undefined,
  selectable: boolean,
  onMouseDown: (key: string) => (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void,
}

const Points: React.FC<PointsProps> = ({pointKeys, product, tmpValues, canvasWidth, canvasHeight, selectedPointId, mirroredPointId, selectable, onMouseDown}) => {
  return <>
    {pointKeys.map(pointKey => {
      const pointValue = get(tmpValues, pointKey) || get(product, pointKey)
      let selectedFill
      if(mirroredPointId === pointKey) selectedFill = "red"
      if(!pointValue) return null;
      return <Circle key={pointKey}
        point={pointValue}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        isSelected={selectedPointId === pointKey || mirroredPointId === pointKey}
        selectedFill={selectedFill}
        isAnythingSelected={selectedPointId != null}
        onMouseDown={selectable ? onMouseDown(pointKey) : undefined} />
    })}
  </>
}

interface FaceOvalPathProps {
  pointKeys: string[],
  product: OrderProduct,
  tmpValues?: {[key: string]: any},
  canvasWidth: number,
  canvasHeight: number
}

const FaceOvalPath: React.FC<FaceOvalPathProps> = ({pointKeys, product, tmpValues, canvasWidth, canvasHeight}) => {
  const asArray = pointKeys.map(key => {
    const p = (get(tmpValues, key) || get(product, key)) as Point
    return [p.x, p.y, 0] as ArrayPoint
  })
  if(asArray == null || asArray.length <= 0) return null
  asArray.push( asArray[0] )
  const controlPoints = bezierSpline.getControlPoints(asArray);
  const combined = bezierSpline.combinePoints(asArray, controlPoints);
  const segments = bezierSpline.getSegments(combined);

  const svgPathList = segments.map( (p, idx) => {
    const first = p[0]
    const c1 = p[1]
    const c2 = p[2]
    const second = p[3]

    const points = `C${c1[0]},${c1[1]} ${c2[0]},${c2[1]} ${second[0]},${second[1]}`

    if (idx === 0) {
      return `M${first[0]},${first[1]} ${points}`
    } else if (idx >= segments.length - 1) { 
      return ""
    } else {
      return points
    }
  })
  
  svgPathList.push("Z")
  const svgPathStr = svgPathList.join(" ")

  const fill = "rgba(0,0,0,0.4)"
  const d1 = `M0,0 L${canvasWidth},0 L${canvasWidth},${canvasHeight} L${0},${canvasHeight} L0,0 Z`
  return <path d={`${d1} ${svgPathStr}`} fill={fill} fillRule="evenodd" className={styles.facePath} />
}

interface ConnectedPathProps {
  pointKeys: string[],
  product: OrderProduct,
  tmpValues?: {[key: string]: any},
}

const ConnectedPath: React.FC<ConnectedPathProps> = ({pointKeys, product, tmpValues}) => {
  if(pointKeys.length === 0) return null;
  const asArray = pointKeys.map(key => {
    const p = (get(tmpValues, key) || get(product, key)) as Point | undefined
    if(!p) return null;
    return [p.x, p.y, 0] as ArrayPoint
  }).filter(isNotNull)
  
  if(asArray == null || asArray.length <= 0) return null
  asArray.push( asArray[0] )
  const controlPoints = bezierSpline.getControlPoints(asArray);
  const combined = bezierSpline.combinePoints(asArray, controlPoints);
  const segments = bezierSpline.getSegments(combined);

  const svgPathList = segments.map( (p, idx) => {
    const first = p[0]
    const c1 = p[1]
    const c2 = p[2]
    const second = p[3]

    const points = `C${c1[0]},${c1[1]} ${c2[0]},${c2[1]} ${second[0]},${second[1]}`

    if (idx === 0) {
      return `M${first[0]},${first[1]} ${points}`
    } else if (idx >= segments.length - 1) { 
      return ""
    } else {
      return points
    }
  })
  
  svgPathList.push("Z")
  const svgPathStr = svgPathList.join(" ")
  const stroke = "var(--primary-a80-color)"
  return <path d={`${svgPathStr}`} stroke={stroke} fill="none" strokeWidth="3" fillRule="evenodd" className={styles.facePath} />
}



interface TouchPosition {
  x: number,
  y: number,
  boundW?: number,
  boundH?: number,
  startValue?: Point,
}

interface MovingOverlayProps {
  value: Point,
  onUpdateValue: (p: Point) => void,
}

const MovingOverlay: React.FC<MovingOverlayProps> = ({value, onUpdateValue}) => {
  const requestRef = React.useRef<number>(null)
  const [dragStartPosition, setDragStartPosition] = React.useState<TouchPosition>();
  const [dragingPosition, setDragingPosition] = React.useState<TouchPosition>();

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    const position = {
      x: touch.screenX,
      y: touch.screenY,
      startValue: value
    }
    setDragStartPosition(position)
  }
  const onTouchEnd = () => {
    setDragStartPosition(undefined)
    setDragingPosition(undefined)

    if(requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const position = {
      x: e.screenX,
      y: e.screenY,
      startValue: value,
    }
    
    setDragStartPosition(position)
  }
  const onMouseUp = () => {
    setDragStartPosition(undefined)
    setDragingPosition(undefined)

    if(requestRef.current) {
      cancelAnimationFrame(requestRef.current)
    }
  }

  const shouldAttacMove = dragStartPosition !== undefined
  React.useEffect(() => {
    const mouseMove = (e:any) => {
      const position = {x: e.screenX, y: e.screenY}

      if(requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      (requestRef.current as any) = requestAnimationFrame(() => {
        setDragingPosition(position);
      })
    }
    const touchMove = (e:any) => {
      const touch = e.touches[0];
      const position = {x: touch.screenX, y: touch.screenY}
    
      if(requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
      (requestRef.current as any) = requestAnimationFrame(() => { setDragingPosition(position); })
    }
    if(shouldAttacMove) {
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('touchmove', touchMove);
      return () => {
        window.removeEventListener('mousemove', mouseMove)
        window.addEventListener('touchmove', touchMove);
      } 
    }
  }, [shouldAttacMove])

  let dragDiffX: number | undefined = undefined;
  let dragDiffY: number | undefined = undefined;

  const startValue = dragStartPosition?.startValue

  if(dragingPosition?.x != null && dragStartPosition?.x) { dragDiffX = dragingPosition.x - dragStartPosition.x + (startValue?.x || 0); }
  if(dragingPosition?.y != null && dragStartPosition?.y) { dragDiffY = dragingPosition.y - dragStartPosition.y + (startValue?.y || 0); }

  React.useEffect(() => {
    if(dragDiffX != null && dragDiffY) {
      onUpdateValue({x: dragDiffX, y: dragDiffY});
    }
  }, [dragDiffX, dragDiffY, onUpdateValue])
  
  return <div className={styles.movingOverlay} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onMouseDown={onMouseDown} onMouseUp={onMouseUp} />
}