import React from 'react'
import * as _ from 'lodash'
import {useDebouncedCallback} from 'use-debounce';

type ValuesState = {[key: string]: any}

/**
 * 
 * @param initialState inital state to set the form with
 * @param valuesChangeCallback callback when a value changes via onChange or onDebounceChange, (<new values>, <name that changes>, <value that changed>) => void
 */

 //TODO: fix this nightmare
export const useForm = (initialState: ValuesState, valuesChangeCallback?: (ValuesState: any, n: any, v:any, n2?: any, v2?: any) => void) => {
  const [values, setValues] = React.useState<ValuesState>(initialState);
  const debounced = useDebouncedCallback((values, name, value, name2, value2) => valuesChangeCallback?.(values, name, value, name2, value2), 400);

  const onChange = (e: any) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const checked = e.currentTarget.checked;
    const type = e.currentTarget.type;

    
    if(type === 'checkbox') {
      if(checked) {
        setValues(values => ({...values, [name]: _.concat( (values[name] || []), [value])}))
        const newValue = _.concat( (values[name] || []), [value])
        valuesChangeCallback?.( ({...values, [name]: newValue}) , name, newValue)
      } else {
        setValues(values => ({...values, [name]: _.without((values[name] || []), value)}))
        const newValue = _.without((values[name] || []), value)
        valuesChangeCallback?.( ({...values, [name]: newValue}) , name, newValue)
      }
    } else if(type === 'radio') {
      if(checked) {
        setValues(values => ({...values, [name]: value}))
        valuesChangeCallback?.( ({...values, [name]: value}) , name, value)
      }
    } else {
      setValues(values => ({...values, [name]: value}))
      valuesChangeCallback?.( ({...values, [name]: value}) , name, value)
    }
  }
  const onDebounceChange = (e: any) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const name2 = e.currentTarget.name2;
    const value2 = e.currentTarget.value2;

    setValues(values => _.set({...values}, name, value))
    setValues(values => _.set({...values}, name2, value2))
    debounced.callback?.( _.set(values, name, value), name, value, name2, value2)
  }

  return { values, onChange, onDebounceChange }
}