import {Meta} from '../utils'
import {groupBy, mapValues} from 'lodash'
import {Referral} from "../models/referral";


export type ContextPropsState = {
  referralByCode: { [key: string]: Meta<Referral> },
  referralCodes: Meta<string[]>
}

export const initialState: ContextPropsState = {
  referralByCode: {},
  referralCodes: {},
};

type PatternsAction =
  | { type: "FETCH_ALL_REFERRALS" }
  | { type: "FETCH_ALL_REFERRALS_SUCCESS", data: Referral[] }
  | { type: "FETCH_ALL_REFERRALS_ERROR", error: any }

// | { type: "UPDATE_REFERRAL", id: string }
// | { type: "UPDATE_REFERRAL_SUCCESS", id: string, data: Referral }
// | { type: "UPDATE_REFERRAL_ERROR", id: string, error: any }
//
// | { type: "DELETE_REFERRAL", id: string }
// | { type: "DELETE_REFERRAL_SUCCESS", id: string }
// | { type: "DELETE_REFERRAL_ERROR", id: string, error: any }
//
// | { type: "CREATE_REFERRAL" }
// | { type: "CREATE_REFERRAL_SUCCESS", data: Referral }
// | { type: "CREATE_REFERRAL_ERROR", error: any }

export const reducer = (state: ContextPropsState, action: PatternsAction): ContextPropsState => {
  // FETCH
  if ("FETCH_ALL_REFERRALS" === action.type) {
    const newPromoCodeIds: Meta<string[]> = {
      data: state.referralCodes?.data,
      meta: {
        ...state.referralCodes.meta,
        fetching: true
      }
    }
    return {
      ...state,
      referralCodes: newPromoCodeIds,
    };
  }
  if ("FETCH_ALL_REFERRALS_SUCCESS" === action.type) {
    if (action.data == null) return state;

    const newPromoCodeIds: Meta<string[]> = {
      data: action.data.map(p => p.code),
      meta: {
        ...state.referralCodes.meta,
        fetching: false
      }
    }

    const referralByCode = mapValues(groupBy(action.data, p => p.code), p => ({data: p[0]}));
    return {
      ...state,
      referralByCode,
      referralCodes: newPromoCodeIds,
    }
  }
  if ("FETCH_ALL_REFERRALS_ERROR" === action.type) {
    const newPromoCodeIds: Meta<string[]> = {
      data: state.referralCodes?.data,
      meta: {
        ...state.referralCodes.meta,
        fetching: false,
        error: action.error
      }
    }
    return {
      ...state,
      referralCodes: newPromoCodeIds,
    };
  }

  // UPDATE
  // if( "UPDATE_REFERRAL" === action.type) {
  //   if(action.id == null) return state;
  //
  //   const currentPromoCode = state.referralByCode[action.id];
  //   const newPromoCode: Meta<Referral> = {
  //     data: currentPromoCode?.data,
  //     meta: {
  //       ...(currentPromoCode?.meta || {}),
  //       updating: true
  //     }
  //   }
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.id]: newPromoCode
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById
  //   };
  // }
  // if( "UPDATE_REFERRAL_SUCCESS" === action.type) {
  //   if(action.id == null || action.data == null) return state;
  //
  //   const currentPromoCode = state.referralByCode[action.id];
  //   const newPromoCode: Meta<Referral> = {
  //     data: action.data,
  //     meta: {
  //       ...(currentPromoCode?.meta || {}),
  //       updating: false
  //     }
  //   }
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.id]: newPromoCode
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById
  //   };
  // }
  // if( "UPDATE_REFERRAL_ERROR" === action.type) {
  //   if(action.id == null) return state;
  //
  //   const currentPromoCode = state.referralByCode[action.id];
  //   const newPromoCode: Meta<Referral> = {
  //     data: currentPromoCode?.data,
  //     meta: {
  //       ...(currentPromoCode?.meta || {}),
  //       updating: false,
  //       error: action.error
  //     }
  //   }
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.id]: newPromoCode
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById
  //   };
  // }


  // DELETE
  // if( "DELETE_REFERRAL" === action.type) {
  //   if(action.id == null) return state;
  //
  //   const currentPromoCode = state.referralByCode[action.id];
  //   const newPromoCode: Meta<Referral> = {
  //     data: currentPromoCode?.data,
  //     meta: {
  //       ...(currentPromoCode?.meta || {}),
  //       deleting: true
  //     }
  //   }
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.id]: newPromoCode
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById
  //   };
  // }
  // if( "DELETE_REFERRAL_SUCCESS" === action.type) {
  //   if(action.id == null) return state;
  //
  //   const newPromoCodeById = omit(state.referralByCode, [action.id])
  //   const newPromoCodeIds = {
  //     data: without(state.referralCodes.data, action.id),
  //     meta: state.referralCodes.meta
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById,
  //     referralCodes: newPromoCodeIds
  //   };
  // }
  // if( "DELETE_REFERRAL_ERROR" === action.type) {
  //   if(action.id == null) return state;
  //
  //   const currentProduct = state.referralByCode[action.id];
  //   const newProduct: Meta<Referral> = {
  //     data: currentProduct?.data,
  //     meta: {
  //       ...(currentProduct?.meta || {}),
  //       deleting: false,
  //       error: action.error
  //     }
  //   }
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.id]: newProduct
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById
  //   };
  // }
  //
  //
  // // CREATE
  // if( "CREATE_REFERRAL" === action.type) {
  //   return state
  // }
  // if( "CREATE_REFERRAL_SUCCESS" === action.type) {
  //   if(action.data == null) return state
  //
  //   const newPromoCodeById = {
  //     ...state.referralByCode,
  //     [action.data.id]: {
  //       data: action.data
  //     }
  //   }
  //
  //   const newPromoCodeIds = {
  //     data: sortBy(([...(state.referralCodes?.data || []), action.data.id]), id => newPromoCodeById?.[id]?.data?.code || ""),
  //     meta: state.referralCodes.meta
  //   }
  //
  //   return {
  //     ...state,
  //     referralByCode: newPromoCodeById,
  //     referralCodes: newPromoCodeIds,
  //   };
  // }
  // if( "CREATE_REFERRAL_ERROR" === action.type) {
  //   return state
  // }


  return state;
}
