import React from 'react'
import { Popup } from "../../components/Popup/Popup";

import { WarningIcon } from "../../toneIcons";
import { useForm } from '../../hooks/useForm';
import { defaultColorStyle } from '../../utils';
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput';
import { Textarea } from '../../components/Input/Input';

import styles from './RejectPhotoPopup.module.scss';
interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (data: any) => void;
}


export const RejectPhotoPopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const {values, onDebounceChange, onChange} = useForm({
    rejectionText: undefined,
    rejectionReasons: []
  })

  const style = defaultColorStyle()

  return <Popup icon={<WarningIcon className={styles.warningIcon} />} style={style} onClose={onClose}>
    <div>
      <Textarea name="rejectionText" placeholder="Why? (WILL BE SENT TO CUSTOMER)" title="Why? (WILL BE SENT TO CUSTOMER)" onChange={onDebounceChange} value={values.rejectionText} />

      <CheckboxInput title="Head tilted too far back"
           name="rejectionReasons"
           value="headTiltedTooFarBack"
           checked={values.rejectionReasons?.indexOf("headTiltedTooFarBack") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Lighting is bad"
           name="rejectionReasons"
           value="lighting"
           checked={values.rejectionReasons?.indexOf("lighting") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Too much light ghostly"
           name="rejectionReasons"
           value="tooMuchLight"
           checked={values.rejectionReasons?.indexOf("tooMuchLight") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Too dark"
           name="rejectionReasons"
           value="tooDark"
           checked={values.rejectionReasons?.indexOf("tooDark") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Face is Obstructued"
           name="rejectionReasons"
           value="obstuctedFace"
           checked={values.rejectionReasons?.indexOf("obstuctedFace") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Face has glasses"
           name="rejectionReasons"
           value="glasses"
           checked={values.rejectionReasons?.indexOf("glasses") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Moved after measuring"
           name="rejectionReasons"
           value="movedAfterMeasuring"
           checked={values.rejectionReasons?.indexOf("movedAfterMeasuring") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Low quality Image"
           name="rejectionReasons"
           value="imageQuality"
           checked={values.rejectionReasons?.indexOf("imageQuality") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Camera not nose level"
           name="rejectionReasons"
           value="cameraPosition"
           checked={values.rejectionReasons?.indexOf("cameraPosition") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Need measurements"
           name="rejectionReasons"
           value="needMeasurements"
           checked={values.rejectionReasons?.indexOf("needMeasurements") >= 0}
           className={styles.input}
           onChange={onChange} />
      <CheckboxInput title="Not looking straight at the camera"
           name="rejectionReasons"
           value="turnedHead"
           checked={values.rejectionReasons?.indexOf("turnedHead") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Too close to the camera"
           name="rejectionReasons"
           value="tooClose"
           checked={values.rejectionReasons?.indexOf("tooClose") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Face is blue from the screen"
           name="rejectionReasons"
           value="blueFace"
           checked={values.rejectionReasons?.indexOf("blueFace") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Face is yellow from the bulbs"
           name="rejectionReasons"
           value="yellowFace"
           checked={values.rejectionReasons?.indexOf("yellowFace") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Dirt spots on iamge"
           name="rejectionReasons"
           value="dirtyCamera"
           checked={values.rejectionReasons?.indexOf("dirtyCamera") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Image too small"
           name="rejectionReasons"
           value="smallImage"
           checked={values.rejectionReasons?.indexOf("smallImage") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Face is too small"
           name="rejectionReasons"
           value="smallFace"
           checked={values.rejectionReasons?.indexOf("smallFace") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Image is a screenshot"
           name="rejectionReasons"
           value="imageIsScreenshot"
           checked={values.rejectionReasons?.indexOf("imageIsScreenshot") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Waiting on images"
           name="rejectionReasons"
           value="waitingOnImages"
           checked={values.rejectionReasons?.indexOf("waitingOnImages") >= 0}
           className={styles.input}
           onChange={onChange} />
       <CheckboxInput title="Awesome facial expression 😜 (compliment sandwitch)"
           name="rejectionReasons"
           value="awesomeFacialExpression"
           checked={values.rejectionReasons?.indexOf("awesomeFacialExpression") >= 0}
           className={styles.input}
           onChange={onChange} />
    </div>

    {onSubmit != null && <div className={styles.confirmationButton} onClick={() => onSubmit(values)}>Yes, reject it</div>}
  </Popup>
};

export default RejectPhotoPopup;