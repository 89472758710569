import React from 'react'
import toast from 'react-hot-toast';
import { ContextPropsState, initialState, reducer } from './referrals.reducer'

import { toastErrorOptions } from '../utils';
import {referralsService} from "../services/referral.service";

type ContextPropsActions = {
  fetchReferrals: () => void,
  // updatePromoCode: (id: string, data: Partial<PromoCode>) => void,
  // createPromoCode: (data:any) => void,
  // deletePromoCode: (id: string) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchReferrals: () => { throw new Error("fetchReferrals not implemented") },
  // updatePromoCode: () => { throw new Error("updatePromoCode not implemented") },
  // createPromoCode: () => { throw new Error("createPromoCode not implemented") },
  // deletePromoCode: () => { throw new Error("deletePromoCode not implemented") },
}

export const ReferralsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const ReferralsProvider = ({children}: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const fetchReferrals = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_REFERRALS"})
      const promoCodes = await referralsService.getAll()
      dispatch({type: "FETCH_ALL_REFERRALS_SUCCESS", data: promoCodes})
    } catch(error) {
      dispatch({type: "FETCH_ALL_REFERRALS_ERROR", error})
      toast.error(`error fetching promo codes`, toastErrorOptions)
    }
  }, [])

  // const updatePromoCode = React.useCallback(async (id, data) => {
  //   try {
  //     dispatch({type: "UPDATE_PROMOCODE", id})
  //     const newData = await referralsService.update(id, data)
  //     dispatch({type: "UPDATE_PROMOCODE_SUCCESS", id, data: newData})
  //   } catch(error) {
  //     dispatch({type: "UPDATE_PROMOCODE_ERROR", id, error})
  //     toast.error(`error updating promo code ${id}`, toastErrorOptions)
  //   }
  // }, [])
  //
  // const createPromoCode = React.useCallback(async (data) => {
  //   try {
  //     dispatch({type: "CREATE_PROMOCODE"})
  //     const promoCode = await referralsService.create(data)
  //     dispatch({type: "CREATE_PROMOCODE_SUCCESS", data: promoCode})
  //   } catch(error) {
  //     dispatch({type: "CREATE_PROMOCODE_ERROR", error})
  //     toast.error(`error creating promo code`, toastErrorOptions)
  //   }
  // }, [])
  //
  // const deletePromoCode = React.useCallback(async (id) => {
  //   try {
  //     dispatch({type: "DELETE_PROMOCODE", id})
  //     await referralsService.delete(id)
  //     dispatch({type: "DELETE_PROMOCODE_SUCCESS", id})
  //   } catch(error) {
  //     dispatch({type: "DELETE_PROMOCODE_ERROR", id, error})
  //     toast.error(`error deleting promo code ${id}`, toastErrorOptions)
  //   }
  // }, [])

  const value:ContextProps = [state, {
    fetchReferrals,
    // updatePromoCode,
    // createPromoCode,
    // deletePromoCode,
  }]

  return <ReferralsContext.Provider value={value}>
    {children}
  </ReferralsContext.Provider>
}

export const useReferralsContext = () => React.useContext(ReferralsContext)
