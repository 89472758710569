import React from 'react'
import { Popup } from "../Popup/Popup";
import { PlusIcon } from "../../toneIcons";
import { Input } from '../Input/Input';
import {defaultColorStyle} from '../../utils'

import { Button } from '../Button/Button';
import { Product } from '../../models/Product';

import styles from './CreatePopup.module.scss';

interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (data: Partial<Product>) => void;
}

export const CreateMaskPopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const [values, setValues] = React.useState<Partial<Product>>({disabled: true})

  const style = defaultColorStyle()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name = e.target.name

    if(name === 'weight') {
      const numberValue = Number.parseFloat(value)
      if(!Number.isNaN(numberValue)) {
        setValues(values => ({...values, [name]: numberValue}))
      }
    } else {
      setValues(values => ({...values, [name]: value}))
    }
  }

  const isValid = values.weight != null && values.name != null

  const _onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation();
    if(isValid) {
      onSubmit(values);
    }
  }

  return <Popup icon={<PlusIcon className={styles.icon} />} onClose={onClose} style={style}>
    <h4>Create Product</h4>
    <form onSubmit={_onSubmit} className={styles.form}>
      <Input name="name" value={values.name} onChange={onChange} title="Name" />
      <Input name="weight" value={values.weight} onChange={onChange} title="Weight" />
      {onSubmit != null && <Button type="submit" disabled={!isValid} className={styles.submit}>Create</Button>}
    </form>
  </Popup>
};

export default CreateMaskPopup;
