import React from 'react'
import queryString from 'query-string'
import {Link, useLocation, useHistory} from "react-router-dom";
import classNames from 'classnames'

import { PaginatorChevronLeft, PaginatorChevronRight } from '../../toneIcons'
import { SquareSelect } from '../SquareSelect/SquareSelect';

import styles from './TablePaginator.module.scss'

interface TablePaginatorProps extends React.HTMLAttributes<HTMLDivElement> {
  nextPageId: string | undefined | null,
  prevPageId: string | undefined | null,
  perPage: number,
  perPageDefault?: number,
}


export const TablePaginator: React.FC<TablePaginatorProps> = ({perPage, nextPageId, prevPageId, perPageDefault = 50}) => {
  
  const location = useLocation()
  const history = useHistory()
  const prevSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    prevPageId: prevPageId || undefined,
    nextPageId: undefined,
    perPage: perPage,
  })

  const nextSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    prevPageId: undefined,
    nextPageId: nextPageId || undefined,
    perPage: perPage,
  })

  const onChange = (e: any) => {
    const perPage = Number.parseInt(e.target.value)
    const query = "?" + queryString.stringify({
      ...queryString.parse(location.search),
      prevPageId: undefined,
      nextPageId: undefined,
      perPage: (perPage === perPageDefault) ? undefined : perPage,
    })
    history.push(query)
  }

  return <div className={styles.container}>
    {perPage != null && <div className={styles.label}>
      <span>SHOW</span>
      <SquareSelect value={perPage} onChange={onChange}>
        {Array.from({length: 3}, (_, i) => <option key={i} value={perPageDefault*(i+1)}>{perPageDefault*(i+1)}</option>)}
      </SquareSelect>

    </div>}
    {prevPageId && <Link className={classNames(styles.prev, prevPageId ? styles.active : undefined)} to={prevSearch}>
      <PaginatorChevronLeft />
      <span>PREV</span>
    </Link>}
    {!prevPageId && <div className={styles.prev}>
      <PaginatorChevronLeft />
      <span>PREV</span>
    </div>}
    
    {nextPageId && <Link className={classNames(styles.next, nextPageId ? styles.active : undefined)} to={nextSearch}>
      <span>NEXT</span>
      <PaginatorChevronRight />
    </Link>}

    {!nextPageId && <div className={styles.next}>
      <span>NEXT</span>
      <PaginatorChevronRight />
    </div>}
  </div>
}