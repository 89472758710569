import React from 'react'
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import {Pattern, CreatePattern} from '../../components/Pattern/Pattern'
import { CreatePatternPopup } from '../../components/CreatePopup/CreatePattern'
import { SidebarContainer } from '../../components/SidebarContainer/SidebarContainer';
import { Scrollable } from '../../components/Scrollable/Scrollable';
import { PatternSidebar } from '../../components/PatternSidebar/PatternSidebar';

import {Pattern as PatternType} from "../../models/Pattern.model";
import {useQuery} from '../../hooks/useQuery'
import { usePatternsContext } from '../../contexts/patterns.context';
import DeletePopup from '../../popups/DeletePopup/DeletePopup';

import styles from './Patterns.module.scss'

export const Patterns = () => {
  const [{patternById, patternIdsByGroup, patternGroups}, {fetchPatterns, createPattern, deletePattern}] = usePatternsContext()
  const [patternToCreate, setPatternToCreate] = React.useState<boolean>();
  const [patternIdToDelete, setPatternIdToDelete] = React.useState<string | undefined>();
  const query = useQuery()
  const history = useHistory();

  React.useEffect(() => {
    fetchPatterns()
  }, [fetchPatterns])

  const createConfirmationHandler = async (data: Partial<PatternType>) => {
    await createPattern(data)
    setPatternToCreate(false)
  };

  const deleteConfirmationHandler = async (id: string) => {
    await deletePattern(id)
    history.push({pathname: history.location.pathname})
    setPatternIdToDelete(undefined)
  };

  const selectedPatternId = query.get('patternId')
  const selectedPattern = selectedPatternId != null ? patternById?.[selectedPatternId] : undefined

  const style = {
    '--sidepanel-width': (selectedPatternId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;

  return <div className={styles.container} style={style}>
    <Helmet>
      <title>Patterns - FriendlyFac</title>
    </Helmet>
    <div className={styles.content}>
      <Scrollable innerClassName={styles.patterns}>
        <CreatePattern onClick={() => setPatternToCreate(true)} className={styles.pattern} />

        {patternGroups?.map(group => {
          const patternIds = patternIdsByGroup[group];
          return <div key={group} className={styles.patternGroup}>
            <div className={styles.patternGroupTitle}>{group}</div>
            <div className={styles.patternGroupContent}>
              {patternIds?.map(id => {
                const pattern = patternById?.[id];
                if(pattern == null) return null;
                return <Pattern key={id}
                  data={pattern}
                  isSelected={selectedPatternId === id}
                  isAnythingSelected={selectedPattern != null}
                  className={styles.pattern}
                  onClick={() => history.push(`?patternId=${id}`)}
                />
              })}
            </div>
          </div>
        })}
      </Scrollable>
    </div>

    {patternToCreate && <CreatePatternPopup onSubmit={createConfirmationHandler} onClose={() => setPatternToCreate(false)}/>}

    {patternIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(patternIdToDelete)} onClose={() => setPatternIdToDelete(undefined)} />}

    {selectedPatternId && <SidebarContainer updating={selectedPattern?.meta?.updating}>
      <PatternSidebar
        patternId={selectedPatternId}
        key={selectedPatternId}
        onClose={() => history.push({pathname: history.location.pathname})}
        onDelete={() => setPatternIdToDelete(selectedPatternId)} />
    </SidebarContainer>}
  </div>
}