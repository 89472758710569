import React from 'react'
import classnames from 'classnames'
import { Popup } from "../../components/Popup/Popup";
import {without} from 'lodash'

import { useProductsContext } from '../../contexts/products.context';

import styles from './FeaturesPopup.module.scss';
import { useFeaturesContext } from '../../contexts/features.context';
import { defaultColorStyle, Meta, shouldRefetch } from '../../utils';
import { Feature as FeatureType } from '../../models/Feature.model';
import { Spacer } from '../../components/Spacer/Spacer';
import { formatPrice } from '../../utils'
import { OrderProduct } from '../../models/Order.model';
import { Button } from '../../components/Button/Button';


interface Props {
  orderProduct?: OrderProduct,
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (ids: string[] | undefined) => void;
}

export const FeaturesPopup: React.FC<Props> = ({onClose, onSubmit, orderProduct}) => {
  const [{productById}] = useProductsContext()
  const [{featureIds, featureById}, {fetchFeatures}] = useFeaturesContext()
  const [selectedFeatureIds, setSelectedFeatureIds] = React.useState(orderProduct?.featureIds)

  const product = orderProduct?.maskId ? productById?.[orderProduct?.maskId] : undefined;

  const shouldFetchFeatures = shouldRefetch(featureIds)
  React.useEffect(() => {
    if(fetchFeatures && shouldFetchFeatures) { fetchFeatures() }
  }, [fetchFeatures, shouldFetchFeatures])


  const onToggle = (id: string) => {
    setSelectedFeatureIds(ids => {
      if(ids && ids?.indexOf(id) >= 0) {
        return without(ids, id)
      } else {
        return [...(ids || []), id]
      }
    })
  }

  if(product?.data == null) return null;
  const style = defaultColorStyle();

  return <Popup onClose={onClose} style={style}>
    <div className={styles.container}>
      {product.data.featureIds?.map(id => {
        const feature = featureById?.[id];
        if(feature?.data == null) return null;
        const selected = selectedFeatureIds?.find(fId => fId === id) != null;
        return <Feature key={id} feature={feature} selected={selected} onSelect={onToggle} />
      })}
    </div>
    <Button onClick={() => onSubmit(selectedFeatureIds)}>Submit</Button>
  </Popup>
};

interface FeatureProps { 
  feature: Meta<FeatureType>,
  selected: boolean,
  onSelect: (id: string) => void,
}

const Feature: React.FC<FeatureProps> = ({feature, selected, onSelect}) => {
  const selectFeature = (e: any) => {
    if(feature.data?.id) {
      onSelect(feature.data?.id)
    }
  }

  
  return <div className={ classnames(styles.feature, selected ? styles.selected : undefined)} onClick={selectFeature}>
    <div className={styles.featureContent}>
      <div className={styles.featureName}>{feature.data?.name}</div>
      <div className={styles.featureDescription}>{feature.data?.description}</div>
    </div>
    <Spacer />
    {feature.data?.price != null && <div className={styles.featurePrice}>
      ${formatPrice(feature.data?.price)}
    </div>}
  </div>
}

export default FeaturesPopup;