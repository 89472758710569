import React from "react";
import moment from "moment"
import classNames from "classnames"

import {ordersService, Email} from "../../../services/orders.service"
import styles from "./MessagingSection.module.scss";
interface Props {
  orderId: string
}

export const SentEmailsSection: React.FC<Props> = ({orderId}) => {
  const [sentEmails, setSentEmails] = React.useState<Email[]>([]);

  React.useEffect(() => {
    ordersService.getSentEmails(orderId).then(emails => {
      setSentEmails(emails)
    })
  }, [orderId]);

  return <div className={styles.container}>
    {
      <details className={styles.details}>
        <summary>Sent Emails</summary>

        {sentEmails.map(sentEmail => {
          const classes = classNames(styles.message, styles.messageSent)

          return <div className={classes} key={sentEmail.id}>
            <a href={`https://account.postmarkapp.com/servers/5557665/streams/outbound/messages/${sentEmail.id}`}
               target="_blank" rel="noopener noreferrer">{sentEmail.subject}</a>
            <span className={styles.metadata}>
                    {sentEmail.status} {moment(sentEmail.receivedAt).fromNow()}
                </span>
          </div>
        })}
      </details>
    }
  </div>
};
