import React from 'react'
import {Popup} from "../../components/Popup/Popup";

import { Order } from '../../models/Order.model';
import { Input } from '../../components/Input/Input';

import styles from './JoinOrderPopup.module.scss';


interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (orderId: string) => void;
  order: Order
}

export const JoinOrderPopup: React.FC<Props> = ({onClose, onSubmit, order}) => {
  const [value, setValue] = React.useState<string>("")

  return <Popup onClose={onClose}>
    <form>
      <h1>Join with (this keeps the current order and deletes the order you input)</h1>
      <Input name="orderId" value={value} onChange={(e) => setValue(e.target.value)} title="OrderId to delete" />

      {onSubmit != null &&
      <div className={styles.confirmationButton} onClick={() => onSubmit(value)}>Yes, join order</div>}
    </form>
  </Popup>
};

export default JoinOrderPopup;
