import React from 'react'
import { Popup } from "../Popup/Popup";
import { PlusIcon } from "../../toneIcons";
import { Input } from '../Input/Input';
import {defaultColorStyle} from '../../utils'

import { Button } from '../Button/Button';
import { PromoCode } from '../../models/PromoCode';
import { SegmentedControl } from '../SegmentedControl/SegmentedControl';

import styles from './CreatePopup.module.scss';

interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (data: Partial<PromoCode>) => void;
}

export const CreatePromoCodePopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const [values, setValues] = React.useState<Partial<PromoCode>>({applicationLevel: "order"})
  const [error, setError] = React.useState("");

  const style = defaultColorStyle()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name = e.target.name

    if (name === "code" && value.toLowerCase().startsWith("gc")) {
      setError("Promo code can not start with GC");
    } else {
      setError("")
    }
    setValues(values => ({...values, [name]: value}))
  }

  const _onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation();
    if(values.code == null || values.b == null || values.m == null) return;
    if(Number.isNaN(Number.parseFloat(values.m + ""))) return
    if(Number.isNaN(Number.parseInt(values.b + ""))) return
    if( !((values.description?.length || 0) > 0) ) return
    onSubmit(values);
  }

  return <Popup icon={<PlusIcon className={styles.icon} />} onClose={onClose} style={style}>
    <h4>Create Promo Code</h4>
    <form onSubmit={_onSubmit} className={styles.form}>
      <Input name="code" value={values.code} onChange={onChange} title="Code" />
      <Input name="m" value={values.m} onChange={onChange} title="Percentage of total" />
      <Input name="b" value={values.b} onChange={onChange} title="Exact amount discounted (cents)" />
      <Input name="description" value={values.description} onChange={onChange} title="Description" />
      <span className={styles.error}>{error}</span>
      <SegmentedControl name="applicationLevel"
        checkedValue={values.applicationLevel}
        values={[{value: "order", title: "Order"}, {value: "item", title: "Item"}]}
        onChange={onChange} />
      {onSubmit != null && <Button type="submit" className={styles.submit} disabled={!!error}>Create</Button>}
    </form>
  </Popup>
};

export default CreatePromoCodePopup;
