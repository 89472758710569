export interface SizedImage {
  url400: string | undefined
  url750: string | undefined
  url320: string | undefined
  url160: string | undefined
  order: number
}
export class Feature {
  constructor(
    public id: string,
    public name?: string,
    public description?: string,
    public disabled?: boolean,
    public slug?: string,
    public price?: number,
    public oldPrice?: number,
    public order?: number,
    public url?: string,
    public isGroup?: boolean,
    public appearsOnHome?: boolean,
    public autoSet?: boolean,
    public firstSizedImageId?: string,
    public sizedImages?: {[key: string]: SizedImage }
  ) {}

  static from = (data: any) => {
    const id = data.id
    const disabled = Boolean(data.disabled);
    const price = data.price ? Number.parseInt(data.price) : undefined
    const oldPrice = data.oldPrice ? Number.parseInt(data.oldPrice) : undefined
    const order = Number.parseInt(data.order) || 0
    const isGroup = Boolean(data.isGroup);
    const autoSet = Boolean(data.autoSet);
    const appearsOnHome = Boolean(data.appearsOnHome);

    return new Feature(id,
      data.name,
      data.description,
      disabled,
      data.slug,
      price,
      oldPrice,
      order,
      data.url,
      isGroup,
      appearsOnHome,
      autoSet,
      data.firstSizedImageId,
      data.sizedImages);
  }
}