import {Meta} from '../utils'
import {mapValues, sortBy, groupBy, without} from 'lodash'

import { Pattern } from '../models/Pattern.model'

export type ContextPropsState = {
  patternById: { [key: string]: Meta<Pattern> },
  patternIds: Meta<string[]> | undefined,
  patternGroups: string[],
  patternIdsByGroup: { [key: string]: string[] },
}

export const initialState: ContextPropsState = {
  patternById: {},
  patternIds: undefined,
  patternGroups: [],
  patternIdsByGroup: {},
};

type PatternsAction = 
  | { type: "FETCH_PATTERN", id: string }
  | { type: "FETCH_PATTERN_SUCCESS", id: string, data: Pattern }
  | { type: "FETCH_PATTERN_ERROR", id: string, error: any }

  | { type: "FETCH_ALL_PATTERNS" }
  | { type: "FETCH_ALL_PATTERNS_SUCCESS", data: Pattern[] }
  | { type: "FETCH_ALL_PATTERNS_ERROR", error: any }

  | { type: "UPDATE_PATTERN", id: string }
  | { type: "UPDATE_PATTERN_SUCCESS", id: string, data: Pattern }
  | { type: "UPDATE_PATTERN_ERROR", id: string, error: any }

  | { type: "CREATE_PATTERN"}
  | { type: "CREATE_PATTERN_SUCCESS", data: Pattern }
  | { type: "CREATE_PATTERN_ERROR", error: any }

  | { type: "DELETE_PATTERN", id: string }
  | { type: "DELETE_PATTERN_SUCCESS", id: string }
  | { type: "DELETE_PATTERN_ERROR", id: string, error: any }


export const reducer = (state: ContextPropsState, action: PatternsAction):ContextPropsState => {
  // FETCH ONE
  if( "FETCH_PATTERN" === action.type) {
    if(action.id == null) return state;
    
    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        fetching: true
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }
  if( "FETCH_PATTERN_SUCCESS" === action.type) {
    if(action.id == null) return state;
    
    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: action.data,
      meta: {
        ...(currentPattern?.meta || {}),
        fetching: false,
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
    
  }
  if( "FETCH_PATTERN_ERROR" === action.type) {
    if(action.id == null) return state;
    
    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        fetching: false,
        error: true
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }

  // FETCH
  if( "FETCH_ALL_PATTERNS" === action.type) {
    const patternIds = {
      meta: {
        error: false,
        fetching: true
      }
    }
    return {
      ...state,
      patternIds
    }
  }
  if( "FETCH_ALL_PATTERNS_SUCCESS" === action.type) {
    if(action.data == null) return state;
      
  const patternById = mapValues(groupBy(action.data, p => p.id), p => ({data: p[0]}) );
    const patternIds = {
      data: sortBy(action.data, p => p.order).map(p => p.id),
      meta: {
        error: false,
      }
    }
    const _patternGroups = groupBy(action.data, p => p.group);
    const patternGroups = _patternGroups && Object.keys(_patternGroups)
    const patternIdsByGroup = mapValues(_patternGroups, p => sortBy(p, p => p.order).map(p => p.id))
    return {
      ...state,
      patternById,
      patternIds,
      patternGroups,
      patternIdsByGroup
    }
  }
  if( "FETCH_ALL_PATTERNS_ERROR" === action.type) {
    const patternIds = {
      meta: {
        error: true,
        fetching: false
      }
    }
    return {
      ...state,
      patternIds
    }
  }

  // UPDATE
  if("UPDATE_PATTERN" === action.type) {
    if(action.id == null) return state;

    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: true
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }
  if("UPDATE_PATTERN_SUCCESS" === action.type) {
    if(action.id == null) return state
    if(action.data == null) return state

    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: action.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: false
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }

    const _patternGroups = groupBy(state.patternIds?.data, id => newPatternById?.[id].data?.group)
    const newPatternGroups = _patternGroups && Object.keys(_patternGroups)
    const newPatternIdsByGroup = mapValues(_patternGroups, ids => sortBy(ids, id => newPatternById?.[id].data?.order))

    return {
      ...state,
      patternById: newPatternById,
      patternGroups: newPatternGroups,
      patternIdsByGroup: newPatternIdsByGroup,
    }
  }
  if("UPDATE_PATTERN_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: false,
        error: action.error
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }


  // CREATE
  if("CREATE_PATTERN" === action.type) {
    return state;
  }

  if("CREATE_PATTERN_SUCCESS" === action.type) {
    if(action.data == null) return state

    const newPatternById = {
      ...state.patternById,
      [action.data.id]: {
        data: action.data
      }
    }
    const newPatternIds = sortBy(([...(state.patternIds?.data || []), action.data.id]), id => newPatternById?.[id]?.data?.order || "")
    const _patternGroups = groupBy(newPatternIds, id => newPatternById?.[id].data?.group)
    const newPatternGroups = _patternGroups && Object.keys(_patternGroups)
    const newPatternIdsByGroup = mapValues(_patternGroups, ids => sortBy(ids, id => newPatternById?.[id].data?.order))

    return {
      ...state,
      patternById: newPatternById,
      patternIds: {
        ...state.patternIds,
        data: newPatternIds,
      },
      patternGroups: newPatternGroups,
      patternIdsByGroup: newPatternIdsByGroup,
    }
  }
  if("CREATE_PATTERN_ERROR" === action.type) {
    return state;
  }

  // DELETE
  if("DELETE_PATTERN" === action.type) {
    if(action.id == null) return state;
    
    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        deleting: true
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }
  if("DELETE_PATTERN_SUCCESS" === action.type) {
    if(action.id == null) return state;

    const newPatternById = {...state.patternById}
    delete newPatternById[action.id]
    const newPatternIds = without(state.patternIds?.data, action.id)

    const _patternGroups = groupBy(newPatternIds, id => newPatternById?.[id].data?.group)
    const newPatternGroups = _patternGroups && Object.keys(_patternGroups)
    const newPatternIdsByGroup = mapValues(_patternGroups, ids => sortBy(ids, id => newPatternById?.[id].data?.order))

    return {
      ...state,
      patternById: newPatternById,
      patternIds: {
        ...state.patternIds,
        data: newPatternIds
      },
      patternGroups: newPatternGroups,
      patternIdsByGroup: newPatternIdsByGroup,
    }
  }
  if("DELETE_PATTERN_ERROR" === action.type) {
    if(action.id == null) return state;
    
    const currentPattern = state.patternById?.[action.id];
    const newPattern: Meta<Pattern> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        deleting: false,
        error: true,
        errorMsg: action.error
      }
    }
    const newPatternById = {
      ...state.patternById,
      [action.id]: newPattern
    }
    return {
      ...state,
      patternById: newPatternById
    }
  }

  return state;
}
