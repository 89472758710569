import { EventHandler, KeyboardEvent, useEffect } from "react";

const defaultOptions = {
  when: true,
  eventTypes: ["keydown"]
};

export const useKey: (keyList: string[], handler: EventHandler<KeyboardEvent>, opts?: any) => void = (keyList = [], handler, opts) => {
  const options = {...defaultOptions, ...opts};
  const {when, eventTypes} = options;
  const {target} = options;
  let targetNode: any;
  if (typeof window !== "undefined") targetNode = window;
  if (target && target.current) targetNode = target.current;

  useEffect(() => {
    const handle = (e: any) => {
      if (keyList.includes(e.key) || keyList.includes(e.keyCode)) {
        handler(e);
      }
    };
    if (when) {
      eventTypes.forEach((eventType: any) => {
        targetNode.addEventListener(eventType, handle);
      });
      return () => {
        eventTypes.forEach((eventType: any) => {
          targetNode.removeEventListener(eventType, handle);
        });
      };
    }
  }, [when, eventTypes, keyList, targetNode, handler]);
};
