import React from 'react'
import { Popup } from "../Popup/Popup";
import { PlusIcon } from "../../toneIcons";
import { Input } from '../Input/Input';
import {defaultColorStyle} from '../../utils'

import { Button } from '../Button/Button';
import { Feature } from '../../models/Feature.model';

import styles from './CreatePopup.module.scss';

interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (data: Partial<Feature>) => void;
}

export const CreateFeaturePopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const [values, setValues] = React.useState<Partial<Feature>>({disabled: true})

  const style = defaultColorStyle()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const name = e.target.name

      setValues(values => ({...values, [name]: value}))
  }

  const isValid = values.name != null

  const _onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation();
    if(isValid) {
      onSubmit(values);
    }
  }

  return <Popup icon={<PlusIcon className={styles.icon} />} onClose={onClose} style={style}>
    <h4>Create Feature</h4>
    <form onSubmit={_onSubmit} className={styles.form}>
      <Input name="name" value={values.name} onChange={onChange} title="Name" />
      {onSubmit != null && <Button type="submit" disabled={!isValid} className={styles.submit}>Create</Button>}
    </form>
  </Popup>
};

export default CreateFeaturePopup;
