import React from "react";
import Tooltip from 'rc-tooltip'
import { Link, LinkProps } from "react-router-dom"

import styles from "./Button.module.scss";

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {

}

export const Button: React.FC<Props> = ({type, children, title, className, ...props}) => {
  const classList = [
    styles.primaryButton,
    className,
  ]

  if(title) {
    return <Tooltip placement="top" overlay={title}>
      <button className={classList.join(" ")} type={type || "button"} {...props}>
        {children}
      </button>
    </Tooltip>
  } else {
    return <button className={classList.join(" ")} type={type || "button"} {...props}>
      {children}
    </button>
  }
};
interface LinkButtonProps extends LinkProps {
}

export const LinkButton:React.FC<LinkButtonProps> = ({children, className, to, ...props}) => {
  const classList = [
    styles.primaryButton,
    className,
  ]

  return <Link className={classList.join(" ")} to={to} {...props}>
    {children}
  </Link>
};