import React from 'react'

import { useForm } from '../../hooks/useForm'
import { FirebaseDBLink, SidebarHeader } from '../SidebarHeader/SidebarHeader'
import { Input } from '../Input/Input'

import { CheckboxInput } from '../CheckboxInput/CheckboxInput'
import { Scrollable } from '../Scrollable/Scrollable'
import { Pattern } from '../../models/Pattern.model'
import { getFireDataUrl, shouldRefetch } from '../../utils'
import { Button } from '../Button/Button'
import { SegmentedControl } from '../SegmentedControl/SegmentedControl'
import { UpdatableImage } from '../UpdatableImage/UpdatableImage'
import { usePatternsContext } from '../../contexts/patterns.context'

import styles from './PatternSidebar.module.scss'

interface PatternSidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  onClose?: () => void,
  onDelete: (id: string) => void,
  patternId: string,
}

export const PatternSidebar: React.FC<PatternSidebarProps> = ({onClose, onDelete, patternId}) => {
  const [{patternById}, {fetchPattern}] = usePatternsContext()

  const data = patternById?.[patternId]
  const shouldFetch = shouldRefetch(data)

  React.useEffect(() => {
    if(fetchPattern && shouldFetch && patternId != null) {
      fetchPattern(patternId)
    }
  }, [fetchPattern, patternId, shouldFetch])

  return <div className={styles.container}>
    <SidebarHeader title={data?.data?.name?.toUpperCase()} onClose={onClose}>
      <FirebaseDBLink href={getFireDataUrl(`patterns/${patternId}`)} />
    </SidebarHeader>

    {data?.data != null && <Scrollable>
      <PatternSidebarWithData data={data?.data} onDelete={onDelete} />
    </Scrollable>}
  </div>
}

interface PatternSidebarDataProps  extends React.HTMLAttributes<HTMLDivElement> {
  data: Pattern,
  onDelete: (id: string) => void
}

const PatternSidebarWithData: React.FC<PatternSidebarDataProps> = ({data, onDelete}) => {
  const [, {addFile, updatePattern}] = usePatternsContext()
  const patternId = data.id
  const {values, onChange, onDebounceChange} = useForm({
    name: data.name,
    order: data.order,
    hex: data.hex,
    tileWidth: data.tileWidth,
    tileHeight: data.tileHeight,
    smallTileWidth: data.smallTileWidth,
    smallTileHeight: data.smallTileHeight,
    linkedPatternId: data.linkedPatternId,
    group: data.group,
    disabled: [String(data.disabled || "false")],
  }, (_, name, value) => {
    if(name === 'order') {
      const numberValue = Number.parseInt(value)
      updatePattern(patternId, {[name]: Number.isNaN(numberValue) ? -1 : numberValue})
    }
    else if(name === 'disabled') {
      const newValue = value?.indexOf("true") >= 0
      updatePattern(patternId, {[name]: newValue})
    } else if(name === "tileWidth" || name === "tileHeight") {
      updatePattern(patternId, {[name]: Number.parseInt(value)})
    } else updatePattern(patternId, {[name]: value})
  })

  const [isTiled, setIsTiled] = React.useState(true)

  const setTiled = (e: any)  => {
    setIsTiled(e.target.checked)
  }

  const onAddImage = (id: string, file: File | undefined) => {
    if(id != null && file != null) {
      addFile(id, file)
    }
  }

  return <>
    <CheckboxInput title="Is Disabled"
           name="disabled"
           value="true"
           checked={values.disabled?.indexOf("true") >= 0}
           className={styles.input}
           onChange={onChange} />
    <Input title="Name"
           name="name"
           value={values.name}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Order"
           name="order"
           value={values.order}
           className={styles.input}
           onChange={onDebounceChange} />
    <Input title="Hex"
           name="hex"
           value={values.hex}
           className={styles.input}
           onChange={onDebounceChange} />

    
    <Input title="Tile Width (px)"
           name="tileWidth"
           value={values.tileWidth}
           className={styles.input}
           onChange={onDebounceChange}
           disabled />
    <Input title="Tile Height (px)"
           name="tileHeight"
           value={values.tileHeight}
           className={styles.input}
           onChange={onDebounceChange}
           disabled />

    <Input title="Small Tile Width (px)"
           name="tileWidth"
           value={values.smallTileWidth}
           className={styles.input}
           onChange={onDebounceChange}
           disabled />
    <Input title="Small Tile Height (px)"
           name="tileHeight"
           value={values.smallTileHeight}
           className={styles.input}
           onChange={onDebounceChange}
           disabled />

    <Input title="LinkedPatternId"
           name="linkedPatternId"
           value={values.linkedPatternId}
           className={styles.input}
           onChange={onDebounceChange} />

    <SegmentedControl name="group"
        checkedValue={values.group}
        values={[{value: "solids", title: "Solids"}, {value: "patterns", title: "Patterns"}, {value: "skin", title: "Skin Tones"}]}
        onChange={onChange} />

    <UpdatableImage src={data.url} onAddImage={onAddImage} id={data.id} />
    <div>
      <div>Tile</div>
      {data.tileUrl && <div className={styles.tiledBackgroundWrap}>
        <div className={[styles.tiledBackground, isTiled ? styles.tiled : undefined].join(' ')} style={{backgroundImage: `url("${data.tileUrl}")`}} />
        <CheckboxInput title="Is Tiled"
            name="tiled"
            value="true"
            checked={isTiled}
            className={styles.input}
            onChange={setTiled} />
      </div>}
      <div>Small Tile</div>
      {data.smallTileUrl && <div className={styles.tiledBackgroundWrap}>
        <div className={[styles.smallTiledBackground, isTiled ? styles.tiled : undefined].join(' ')} style={{backgroundImage: `url("${data.smallTileUrl}")`}} />
        <CheckboxInput title="Is Tiled"
           name="tiled"
           value="true"
           checked={isTiled}
           className={styles.input}
           onChange={setTiled} />
      </div>}

      <div>Square 88x88</div>
      <div className={styles.square88}>
        {data.s88Url && <img src={data.s88Url} alt="square 88" />}
      </div>
    </div>

    <Button onClick={() => onDelete(data.id)}>Delete</Button>
  </>
}