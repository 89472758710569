import { PromoCode } from '../models/PromoCode'
import { authedDelete, authedGet, authedPost } from "./functions.service";

class PromoCodeService {
  private static instance: PromoCodeService;

  static getInstance() {
    if (!this.instance) {
      this.instance = new PromoCodeService();
    }
    return this.instance;
  }

  public async getAll() {
    const {codes} = await authedGet("/api/codes")

    const promoCodes: PromoCode[] = codes?.map(PromoCode.from) || [];
    promoCodes.sort( (a,b) => a.code.localeCompare(b.code))
    return promoCodes;
  }

  public async update(id: string, data: any) {
    const promoCode = await authedPost(`/api/codes/${id}`, data)
    return PromoCode.from(promoCode)
  }

  public async delete(id: string) {
    return await authedDelete(`/api/codes/${id}`)
  }

  public async create(data: any) {
    const promoCode = await authedPost(`/api/codes`, data)
    return PromoCode.from(promoCode)
  }
}

export const promoCodeService = PromoCodeService.getInstance();
