import React from 'react'
import toast from 'react-hot-toast';

import { ordersService } from "../services/orders.service"
import {Order, ShippoTransaction} from '../models/Order.model'
import { useAuthContext } from './auth.context'
import { reducer, initialState, ContextPropsState } from './orders.reducer'
import { OrderStatus } from '../../../api/src/models/statusHistory'

type ContextPropsActions = {
  fetchOrders: (searchQuery?: string, status?: string, perPage?: number, prevPageId?: string, nextPageId?: string) => void,
  fetchOrder: (orderId: string) => Promise<Order | undefined>,
  // fetchOrderToVerify: () => Promise<Order>,
  deleteOrder: (id: string) => void,
  resendOrderConfirmation:  (id: string) => void,
  resendShippedEmail:  (id: string, force: boolean) => void,
  resendBadSelfieEmail: (id: string) => void,
  resendNoSelfieEmail: (id: string) => void,

  createShippingLabel: (id: string) => Promise<ShippoTransaction>,
  updateOrder: (id: string, data: Partial<Order>) => void,
  cropOrder: (id: string) => void,

  updateOrderProduct: (id: string, productId: string, data: any) => Promise<void>,
  cloneOrderProduct: (id: string, productId: string) => Promise<void>,
  deleteOrderProduct: (id: string, productId: string) => Promise<void>,
  markPrintedOrderProduct: (id: string, productId: string) => Promise<void>,
  approveOrderProduct: (id: string, productId: string) => Promise<void>,
  rejectOrderProduct: (id: string, productId: string, data: any) => Promise<void>,
  unsureOrderProduct: (id: string, productId: string, data: any) => Promise<void>,
  reprintOrderProduct: (id: string, productId: string, data: any) => Promise<void>,

  printifyOrderProduct: (id: string, productId: string) => Promise<void>,
  unprintifyOrderProduct: (id: string, productId: string) => Promise<void>,


  printifyOrder: (id: string) => Promise<void>,
  unPrintifyOrder: (id: string) => Promise<void>,

  markCroppedOrder: (id: string) => Promise<void>,
  undoCroppedOrder: (id: string) => Promise<void>,
  markPrintedOrder: (id: string) => Promise<void>,
  undoPrintedOrder: (id: string) => Promise<void>,
  markPackagedOrder: (id: string) => Promise<void>,
  undoPackagedOrder: (id: string) => Promise<void>,
  markShippedOrder: (id: string) => Promise<void>,
  undoShippedOrder: (id: string) => Promise<void>,
  markEmailedOrder: (id: string) => Promise<void>,
  undoEmailedOrder: (id: string) => Promise<void>,

  setOrderStatus: (id: string, status: OrderStatus) => Promise<void>,
  undoOrderStatus: (id: string, status: OrderStatus) => Promise<void>,
  splitOrder: (id: string, productIds: string[]) => Promise<void>,
  joinOrder: (id: string, joinOrderId: string) => Promise<void>,

  generateImageReupload: (id: string) => Promise<string | undefined>,

  moveImages: (id: string) => Promise<void>,


  eraseRotatedMeta: (id: string, productId: string) => void,
  eraseFaceOval: (id: string, productId: string) => void,

  addFile: (orderId: string, produtId: string, file: File) => Promise<void>,

  sendTextMessage: (orderId: string, phoneNumber: string, message: string) => Promise<void>
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchOrder: () => { return Promise.reject("fetchOrder not implemented") },
  fetchOrders: () => { throw new Error("fetchOrders not implemented") },
  // fetchOrderToVerify: () => { throw new Error("fetchOrderToVerify not implemented") },
  deleteOrder: () => { throw new Error("deleteOrder not implemented") },
  resendOrderConfirmation: () => { throw new Error("resendOrderConfirmation not implemented") },
  resendShippedEmail: () => { throw new Error("resendShippedEmail not implemented")},
  resendBadSelfieEmail: () => { throw new Error("resendBadSelfieEmail not implemented") },
  resendNoSelfieEmail: () => { throw new Error("resendNoSelfieEmail not implemented") },
  updateOrder: () => { throw new Error("updateOrder not implemented") },
  cropOrder: () =>  { throw new Error("cropOrder not implemented") },

  updateOrderProduct: () => { throw new Error("updateOrderProduct not implemented") },
  cloneOrderProduct: () => { throw new Error("cloneOrderProduct not implemented") },
  deleteOrderProduct: () => { throw new Error("deleteOrderProduct not implemented") },
  markPrintedOrderProduct: () => { throw new Error("markPrintedOrderProduct not implemented") },
  approveOrderProduct: () => { throw new Error("approveOrderProduct not implemented") },
  rejectOrderProduct: () => { throw new Error("rejectOrderProduct not implemented") },
  unsureOrderProduct: () => { throw new Error("unsureOrderProduct not implemented") },
  reprintOrderProduct: () => { throw new Error("reprintOrderProduct not implemented") },

  printifyOrderProduct: () => { throw new Error("printifyOrderProduct not implemented") },
  unprintifyOrderProduct: () => { throw new Error("unprintifyOrderProduct not implemented") },

  printifyOrder: () => { throw new Error("printifyOrder not implemented") },
  unPrintifyOrder: () => { throw new Error("unPrintifyOrder not implemented") },
  createShippingLabel: () => { return Promise.reject("createShippingLabel is undefined") },
  addFile: () => { throw new Error("addFile not implemented") },

  markCroppedOrder:  () => { throw new Error("markCroppedOrder not implemented") },
  undoCroppedOrder:  () => { throw new Error("undoCroppedOrder not implemented") },
  markPrintedOrder:  () => { throw new Error("markPrintedOrder not implemented") },
  undoPrintedOrder:  () => { throw new Error("undoPrintedOrder not implemented") },
  markPackagedOrder:  () => { throw new Error("markPackagedOrder not implemented") },
  undoPackagedOrder:  () => { throw new Error("undoPackagedOrder not implemented") },
  markShippedOrder:  () => { throw new Error("markShippedOrder not implemented") },
  undoShippedOrder:  () => { throw new Error("undoShippedOrder not implemented") },
  markEmailedOrder:  () => { throw new Error("markEmailedOrder not implemented") },
  undoEmailedOrder:  () => { throw new Error("undoEmailedOrder not implemented") },

  setOrderStatus: () => { throw new Error("setOrderStatus not implemented") },
  undoOrderStatus: () => { throw new Error("undoOrderStatus not implemented") },
  splitOrder: () => { throw new Error("splitOrder not implemented") },
  joinOrder: () => { throw new Error("joinOrder not implemented") },
  generateImageReupload: () => { throw new Error("generateImageReupload not implemented") },

  moveImages:  () => { throw new Error("moveImages not implemented") },

  eraseRotatedMeta:  () => { throw new Error("eraseRotateMeta not implemented") },
  eraseFaceOval:  () => { throw new Error("eraseFaceOval not implemented") },
  sendTextMessage:  () => { throw new Error("sendTextMessage not implemented") },
}

export const OrdersContext = React.createContext<ContextProps>([initialState, initialActions]);

export const OrdersProvider = ({children}: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [{user}] = useAuthContext()
  const uid = user?.uid;

  const fetchOrders = React.useCallback(async (query?:string, status?: string, perPage?: number, prevPageId?: string, nextPageId?: string) => {
    try {
      dispatch({type: "FETCH_ALL_ORDERS", perPage, prevPageId, nextPageId, status, query})
      const res = await ordersService.getAll(query, status, perPage, prevPageId, nextPageId)
      dispatch({type: "FETCH_ALL_ORDERS_SUCCESS", data: res.orders, status, perPage, prevPageId: res.prevPageId, nextPageId: res.nextPageId, query })
    } catch(error) {
      dispatch({type: "FETCH_ALL_ORDERS_ERROR", error, status, perPage, prevPageId, nextPageId, query})
      toast.error(`error fetching orders`)
    }
  }, [])

  const fetchOrder = React.useCallback(async (orderId: string) => {
    if(orderId == null) return;

    try {
      dispatch({type: "FETCH_ORDER", id: orderId})
      const order = await ordersService.get(orderId)
      dispatch({type: "FETCH_ORDER_SUCCESS", id: orderId, data: order})
      return order
    } catch(error) {
      dispatch({type: "FETCH_ORDER_ERROR", id: orderId, error})
      toast.error(`error fetching order ${orderId}`)
    }
  }, [])

  const updateOrder = React.useCallback(async (id: string, data: any) => {
    if(id == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.update(id, data)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error updating order ${id}`)
    }
  }, [])

  const updateOrderProduct = React.useCallback(async (id: string, productId: string, data: any) => {
    if(id == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.updateProduct(id, productId, data)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error updating order ${id}`)
    }
  }, [])


  const cloneOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.cloneOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error cloning product ${productId} order ${id}`)
    }
  }, [])

  const deleteOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.deleteOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error deleting product ${productId} order ${id}`)
    }
  }, [])

  const approveOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.approveOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error approving product ${productId} order ${id}`)
    }
  }, [])

  const markPrintedOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.markPrintedOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error setting printed product ${productId} order ${id}`)
    }
  }, [])

  const rejectOrderProduct = React.useCallback(async (id: string, productId: string, data: any) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.rejectOrderProduct(id, productId, data)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error rejecting product ${productId} order ${id}`)
    }
  }, [])

  const unsureOrderProduct = React.useCallback(async (id: string, productId: string, data: any) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.unsureOrderProduct(id, productId, data)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error making unsure product ${productId} order ${id}`)
    }
  }, [])

  const reprintOrderProduct = React.useCallback(async (id: string, productId: string, data: any) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.reprintOrderProduct(id, productId, data)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error making reprint product ${productId} order ${id}`)
    }
  }, [])

  const eraseRotatedMeta = React.useCallback(async (id, productId) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.eraseRotatedMeta(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error erasing rotated meta product ${productId} order ${id}`)
    }
  }, [])

  const eraseFaceOval = React.useCallback(async (id, productId) => {
    if(id == null || productId == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.eraseIOSFaceOval(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error erasing face oval product ${productId} order ${id}`)
    }
  }, [])

  const printifyOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.printifyOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error setting printify product ${productId} order ${id}`)
    }
  }, [])

  const unprintifyOrderProduct = React.useCallback(async (id: string, productId: string) => {
    if(id == null || productId == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.unprintifyOrderProduct(id, productId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error setting unprintify product ${productId} order ${id}`)
    }
  }, [])

  const splitOrder = React.useCallback(async (id: string, productIds: string[]) => {
    if(id == null || productIds == null || productIds.length === 0) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const {order, splitOrder} = await ordersService.splitOrder(id, productIds)
      //TODO: make this 1 dispatch
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: order})
      dispatch({type: "CREATE_ORDER_SUCCESS", id: splitOrder.id, data: splitOrder })
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error splitting product ${productIds.join(", ")} order ${id}`)
    }
  }, [])
  const joinOrder = React.useCallback(async (id: string, joinOrderId: string) => {
    if(id == null || joinOrderId == null) return;

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await ordersService.joinOrder(id, joinOrderId)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      toast.error(`error joining order ${joinOrderId} with order ${id}`)
    }
  }, [])

  const generateImageReupload = React.useCallback(async (id: string) => {
    if(id == null) return;

    try {
      // dispatch({type: "UPDATE_ORDER", id})
      const {url} = await ordersService.generateImageReupload(id)
      return url;
      
    } catch(error) {
      // dispatch({type: "UPDATE_ORDER_ERROR", id, error})
    }
  }, [])

  //TODO: FIX THIS to use callback
  async function wrapInOrderUpdating( id: string, fn: (id:string) => Promise<Order> ) {
    if(id == null) return;
    try {
      dispatch({type: "UPDATE_ORDER", id})
      const newOrder = await fn(id)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
    }
  }

  const printifyOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.printifyOrder(id))
  }, [])

  const unPrintifyOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.unprintifyOrder(id))
  }, [])

  const markCroppedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.markCroppedOrder(id))
  }, [])

  const undoCroppedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoCroppedOrder(id))
  }, [])

  const markPrintedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.markPrintedOrder(id))
  }, [])

  const undoPrintedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoPrintedOrder(id))
  }, [])

  const markPackagedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.markPackagedOrder(id))
  }, [])

  const undoPackagedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoPackagedOrder(id))
  }, [])

  const markShippedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.markShippedOrder(id))
  }, [])

  const undoShippedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoShippedOrder(id))
  }, [])

  const setOrderStatus = React.useCallback(async (id:string, status: OrderStatus) => {
    await wrapInOrderUpdating(id, (id) => ordersService.setOrderStatus(id, status))
  }, [])

  const undoOrderStatus = React.useCallback(async (id:string, status: OrderStatus) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoOrderStatus(id, status))
  }, [])

  const markEmailedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.markEmailedOrder(id))
  }, [])

  const undoEmailedOrder = React.useCallback(async (id:string) => {
    await wrapInOrderUpdating(id, (id) => ordersService.undoEmailedOrder(id))
  }, [])

  const cropOrder = React.useCallback( async (id:string) => {
    await ordersService.cropOrder(id)
  }, [])

  const sendTextMessage = React.useCallback(async (id:string, phoneNumber: string, message: string) => {
    try {
      dispatch({type: "UPDATE_ORDER", id: id})
      const newOrder = await ordersService.sendTextMessage(id, phoneNumber, message)
      dispatch({type: "UPDATE_ORDER_SUCCESS", id: id, data: newOrder})
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id: id, error})
      throw error
    }
  }, [])

  const deleteOrder = React.useCallback(async (id:string) => {
    if(id == null) return;
    try {
      dispatch({type: "DELETE_ORDER", id})
      await ordersService.delete(id)
      dispatch({type: "DELETE_ORDER_SUCCESS", id})
    } catch(error) {
      dispatch({type: "DELETE_ORDER_ERROR", id, error})
    }
  }, [])

  const moveImages = React.useCallback(async (id:string) => {
    await ordersService.moveImages(id)
  }, [])

  const resendOrderConfirmation = React.useCallback(async (id:string) => {
    await ordersService.resendOrderConfirmation(id)
  }, [])

  const resendShippedEmail = React.useCallback(async (id:string, force: boolean) => {
    await ordersService.resendShippedEmail(id, force)
  }, [])
  
  const resendBadSelfieEmail = React.useCallback(async (id:string) => {
    await ordersService.resendBadSelfieEmail(id)
  }, [])

  const resendNoSelfieEmail = React.useCallback(async (id:string) => {
    await ordersService.resendNoSelfieEmail(id)
  }, [])

  const createShippingLabel = React.useCallback(async (id:string) => {
    if(id == null) return Promise.reject("id is null");

    try {
      dispatch({type: "UPDATE_ORDER", id})
      const shippoTransaction: ShippoTransaction = await ordersService.createShippingLabel(id, true);
      dispatch({type: "UPDATE_PARTIAL_ORDER_SUCCESS", id, data: {shippoTransaction}})
      return shippoTransaction;
    } catch(error) {
      dispatch({type: "UPDATE_ORDER_ERROR", id, error})
      return Promise.reject(error.message);
    }
  }, []);


  const addFile = React.useCallback(async (orderId: string, productId: string, file: File) => {
    if(uid) {
      await ordersService.uploadFile(orderId, productId, uid, file)
    }
  }, [uid])

  const value:ContextProps = [state, {
    fetchOrder,
    fetchOrders,
    deleteOrder,
    resendOrderConfirmation,
    resendShippedEmail,
    resendBadSelfieEmail,
    resendNoSelfieEmail,
    updateOrder,
    createShippingLabel,

    updateOrderProduct,
    cloneOrderProduct,
    deleteOrderProduct,
    approveOrderProduct,
    markPrintedOrderProduct,
    rejectOrderProduct,
    unsureOrderProduct,
    reprintOrderProduct,

    printifyOrderProduct,
    unprintifyOrderProduct,

    printifyOrder,
    unPrintifyOrder,

    setOrderStatus,
    undoOrderStatus,

    markCroppedOrder,
    undoCroppedOrder,
    markPrintedOrder,
    undoPrintedOrder,
    markPackagedOrder,
    undoPackagedOrder,
    markShippedOrder,
    undoShippedOrder,
    markEmailedOrder,
    undoEmailedOrder,
    splitOrder,
    joinOrder,
    generateImageReupload,

    eraseRotatedMeta,
    eraseFaceOval,

    moveImages,

    cropOrder,
    addFile,
    sendTextMessage
  }]

  return <OrdersContext.Provider value={value}>
    {children}
  </OrdersContext.Provider>
}

export const useOrdersContext = () => React.useContext(OrdersContext)
