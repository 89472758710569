import React from 'react'
import classNames from 'classnames'

import { Point, Point3D } from '../../models/Point'

import styles from './Circle.module.scss'

interface CircleProps extends React.HTMLAttributes<SVGCircleElement> {
  isSelected? : boolean,
  isAnythingSelected? : boolean,
  point: Point | Point3D,
  canvasWidth: number,
  canvasHeight: number,
  selectedFill?: string,
}

export const Circle: React.FC<CircleProps> = ({point, canvasWidth, canvasHeight, isSelected, isAnythingSelected, selectedFill, onClick, onMouseDown}) => {
  // const [offset, setOffset] = React.useState<{[key in "x" | "y"]: number}>()
  const maxSize =  Math.max(canvasWidth,canvasHeight)

  let circleRadius
  let circleStrokeWidth
  let strokeColor
  let fill

  if(onClick || onMouseDown) {
    strokeColor = "rgba(0,0,0,0.2)"
    fill = "rgba(255,255,255,1)"
    circleRadius = maxSize/500
    circleStrokeWidth = maxSize/900
    if(isSelected) {
      circleRadius = maxSize/400
      circleStrokeWidth = maxSize/700
      strokeColor = "rgba(0,0,0,0.9)"
      fill = selectedFill || "var(--secondary-color)"
    } else if(isAnythingSelected) {
      circleStrokeWidth = maxSize/1100
      strokeColor = "rgba(0,0,0,0.2)"
      fill = "rgba(255,255,255,0.8"
    }
  } else {
    strokeColor = "black"
    fill = "red"
    circleRadius = maxSize/500
    circleStrokeWidth = maxSize/900
  }

  const className = classNames(
    styles.circle,
    (onClick || onMouseDown) && styles.clickableCircle
  )

  return <circle cx={point.x} cy={point.y} r={circleRadius} fill={fill} strokeWidth={circleStrokeWidth} stroke={strokeColor} onClick={onClick} onMouseDown={onMouseDown} className={className} />
}
