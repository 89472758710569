import {getPatternsStorage} from "./firebase";
import firebase from "firebase";
import {Pattern} from '../models/Pattern.model'
import { authedDelete, authedGet, authedPost } from "./functions.service";

class PatternsService {
  private static instance: PatternsService;

  get storage(): firebase.storage.Reference {
    return getPatternsStorage().ref()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new PatternsService();
    }
    return this.instance;
  }

  public async fetchAll() {
    const {patterns} = await authedGet(`/api/patterns/admin`);
    return patterns?.map(Pattern.from) || []
  }

  public async fetch(id: string) {
    const pattern = await authedGet(`/api/patterns/admin/${id}`);
    return Pattern.from(pattern)
  }

  public async update(id: string, data: Partial<Pattern>) {
    const pattern = await authedPost(`/api/patterns/admin/${id}`, data);
    return Pattern.from(pattern)
  }

  public async uploadFile(patternId: string, file: File) {
    const childPath = `${patternId}/${file.name}`
    await this.storage.child(childPath).put(file)
    
    return await authedPost(`/api/patterns/admin/createTiles`, {patternId, filename: file.name})
  }

  public async delete(id: string) {
    return await authedDelete(`/api/patterns/admin/${id}`)
  }

  public async create(patternData: Partial<Pattern>) {
    return await authedPost(`/api/patterns/admin`, patternData)
  }
}

export const patternsService = PatternsService.getInstance();
