import React from 'react'
import {Helmet} from "react-helmet";

import Table from '../../components/Table/Table';
import CellHeader from '../../components/CellHeader/CellHeader';
import Cell from '../../components/Cell/Cell';
import { Button } from '../../components/Button/Button';

import { Meta } from '../../utils';
import { PrintGroup } from '../../models/PrintGroup';

import { usePrintGroupsContext } from '../../contexts/printGroups.context';
import { useProductsContext } from '../../contexts/products.context';
import { usePatternsContext } from '../../contexts/patterns.context';

import styles from './PrintGroups.module.scss'

export const PrintGroups = () => {
  const [ { printGroupIds, printGroupById }, {fetchPrintGroups}] = usePrintGroupsContext()
  const [, {fetchProducts}] = useProductsContext()
  const [, {fetchPatterns}] = usePatternsContext()

  React.useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  React.useEffect(() => {
    fetchPatterns()
  }, [fetchPatterns])

  React.useEffect(() => {
    fetchPrintGroups()
  }, [fetchPrintGroups])


  const columns = "max-content max-content max-content max-content max-content max-content max-content minmax(max-content, 1fr) max-content"

  const style = {
    // '--sidepanel-width': (selectedOrderId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;

  return <div style={style} className={styles.container}>
    <Helmet>
      <title>Print Groups - FriendlyFace</title>
    </Helmet>
    <Table columns={columns}>
      <CellHeader sticky>Name</CellHeader>
      <CellHeader sticky>Generating</CellHeader>
      <CellHeader>Status</CellHeader>
      <CellHeader>Count</CellHeader>
      <CellHeader>Full</CellHeader>
      <CellHeader>Category Id</CellHeader>
      <CellHeader>Image</CellHeader>
      <CellHeader>Products</CellHeader>
      <CellHeader>Actions</CellHeader>
      {printGroupIds?.data?.map((id, index) => {
        const metaPrintGroup = printGroupById?.[id];
        if(metaPrintGroup == null) return null;
        return <Cells index={index} data={metaPrintGroup} key={id} />
      })}
    </Table>
  </div>
}


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  index:number
  data: Meta<PrintGroup>
}

const Cells:React.FC<Props> = ({index, data}) => {
  const props = {
    index: index,
    deleting: false,
    className: styles.pointer
  }

  const [, {closeGroup, openGroup, genImage}] = usePrintGroupsContext()

  const printGroup = data?.data

  const printGroupProducts = printGroup?.products != null ? Object.values(printGroup?.products) : []
  return <>
    <Cell {...props}>{printGroup?.id}</Cell>
    <Cell {...props}>{printGroup?.generating === true ? "Yes" : "No"}</Cell>
    <Cell {...props}>{printGroup?.status}</Cell>
    <Cell {...props}>{printGroup?.count}</Cell>
    <Cell {...props}>{printGroup?.full === true ? "Yes" : "No"}</Cell>
    <Cell {...props}>{printGroup?.printType}</Cell>
    <Cell {...props}>{ printGroup?.fileUrl && printGroup?.fileUrl !== "" && <a href={printGroup?.fileUrl} target="_blank" rel="noopener noreferrer">image</a>}</Cell>
    <Cell {...props}>
      <div className={styles.products}>
        {printGroupProducts.map( (product, index) => {
          return <div className={styles.printGrouping} key={index} >
            <div className={styles.orderId}>Order: <span>{product.orderId}</span></div>
            <div className={styles.orderId}>Item: <span>{product.productId}</span></div>
          </div>
        })}
      </div>
    </Cell>
    <Cell {...props}>
      { printGroup?.id && <Button onClick={ () => genImage(printGroup?.id) }>Generate Batch</Button> }
      { printGroup?.id && printGroup?.status === "closed" && <Button onClick={ () => openGroup(printGroup?.id) }>Open</Button> }
      { printGroup?.id && printGroup?.status === "open" && <Button onClick={ () => closeGroup(printGroup?.id) }>Close</Button> }
    </Cell>
  </>
};