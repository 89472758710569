import React from 'react'
import styles from './TableHeader.module.scss'
interface TableHeaderProps extends React.HTMLAttributes<HTMLDivElement> {

}

export const TableHeader: React.FC<TableHeaderProps> = ({children}) => {
  return <div className={styles.container}>
    {children}
  </div>
}