import React from 'react'
import toast from 'react-hot-toast';

import {productsService} from "../services/products.service";
import { Product } from '../models/Product'
import { reducer, ContextPropsState, initialState } from './products.reducer'

type ContextPropsActions = {
  fetchProducts: () => void,
  updateProduct: (id: string, data: Partial<Product>) => void,
  createProduct: (data: any) => void,
  deleteProduct: (id: string) => void,
  addFile: (id: string, file: File) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchProducts: () => { throw new Error("fetchMasks not implemented") },
  updateProduct: () => { throw new Error("updateProduct not implemented") },
  createProduct: () => { throw new Error("createProduct not implemented") },
  deleteProduct: () => { throw new Error("deleteProduct not implemented") },
  addFile: () => { throw new Error("addFile not implemented") },
}

export const ProductsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const ProductsProvider = ({children}: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const fetchProducts = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_PRODUCTS"})
      const products = await productsService.getAll()
      dispatch({type: "FETCH_ALL_PRODUCTS_SUCCESS", products})
    } catch(error) {
      dispatch({type: "FETCH_ALL_PRODUCTS_ERROR", error})
      toast.error("error fetching products")
    }
  }, [])

  const updateProduct = React.useCallback(async (id, data) => {
    try {
      dispatch({type: "UPDATE_PRODUCT", id})
      const newProduct = await productsService.update(id, data)
      dispatch({type: "UPDATE_PRODUCT_SUCCESS", id, data: newProduct})
    } catch(error) {
      dispatch({type: "UPDATE_PRODUCT_ERROR", id, error})
      toast.error(`error updating product ${id}`)
    }
  }, [])

  const createProduct = React.useCallback(async (data) => {
    try {
      dispatch({type: "CREATE_PRODUCT"})
      const mask = await productsService.create(data)
      dispatch({type: "CREATE_PRODUCT_SUCCESS", data: mask})
    } catch(error) {
      dispatch({type: "CREATE_PRODUCT_ERROR", error})
      toast.error(`error creating product`)
    }
  }, [])

  const deleteProduct = React.useCallback(async (id) => {
    try {
      dispatch({type: "DELETE_PRODUCT", id})
      await productsService.delete(id)
      dispatch({type: "DELETE_PRODUCT_SUCCESS", id})
    } catch(error) {
      dispatch({type: "DELETE_PRODUCT_ERROR", id, error})
      toast.error(`error deleting product ${id}`)
    }
  }, [])

  const addFile = React.useCallback(async (id, file) => {
    try {
      dispatch({type: "UPDATE_PRODUCT", id})
      await productsService.uploadFile(id, file)
      const newProduct = await productsService.get(id)
      dispatch({type: "UPDATE_PRODUCT_SUCCESS", id, data: newProduct})
    } catch(error) {
      dispatch({type: "UPDATE_PRODUCT_ERROR", id, error})
      toast.error(`error adding file to product ${id}`)
    }
  }, [])

  const value:ContextProps = [state, {
    fetchProducts,
    updateProduct,
    createProduct,
    deleteProduct,
    addFile,
  }]

  return <ProductsContext.Provider value={value}>
    {children}
  </ProductsContext.Provider>
}

export const useProductsContext = () => React.useContext(ProductsContext)
