import React from 'react'

import styles from './CellHeader.module.scss'

interface Props {
  tagName?: React.FC,
  sticky?: Boolean,
  className?: string,
  colIndex?: number,
}

export const CellHeader: React.FC<Props> = ({tagName, sticky, className, children, colIndex, ...props}) => {
  const Tag = tagName || 'div'

  const classList = [
    styles.header,
    sticky ? styles.sticky : undefined,
    colIndex != null ? styles[`col${colIndex}`] : undefined,
    className,
  ].filter(Boolean);

  return <Tag {...props} className={classList.join(' ')}>{children}</Tag>
}

export default CellHeader;