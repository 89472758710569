import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import { isString } from "lodash"

// in the create react app enviroment the node server is not serving index.html so __FAPI_KEY__ will not be replaced with a real values, hence the odd check
const firebaseApiKey =  (window as any).faipKey === "__FAPI_KEY__" ? (window as any).faipKey_d : (window as any).faipKey

// in the create react app enviroment the node server is not serving index.html so __FPROJ_ID__ will not be replaced with a real values, hence the odd check
export const firebaseProjectId = (window as any).fProjId === "__FPROJ_ID__" ? (window as any).fProjId_d : (window as any).fProjId

const firebaseAppId = (window as any).fAppId === "__FAPP_ID__" ? (window as any).fAppId_d : (window as any).fAppId

const tennantId = (window as any).ftid === "__FTID__" ? (window as any).ftid_d : (window as any).ftid


// {
//   "tmp": "friendly-face-dev-tmp2",
//   "orders": "friendly-face-dev-orders",
//   "dashboard": "friendly-face-dev-dashboard",
//   "masks": "friendly-face-dev-masks",
//   "patterns": "friendly-face-dev-patterns"
// }

interface OutputBuckets {
  tmp?: string
  orders?: string
  dashboard?: string
  masks?: string
  patterns?: string
}

function getFirebaseBuckets() {
  const buckets = (window as any).fStorage as (OutputBuckets | string)

  try {
    if (isString(buckets) || buckets == null) {
      if (buckets === "__STORAGE__" || buckets == null ) {
        // debugger
        const bucketsJson = (window as any).fStorage_d
        return JSON.parse(bucketsJson) as OutputBuckets
      } else {
        return JSON.parse(buckets) as OutputBuckets
      }
    } else {
      return (buckets as OutputBuckets)
    }
  } catch (error) {
    return {}
  }
}
export const firebaseBuckets = getFirebaseBuckets()

class FirebaseClient {
  private static instance: FirebaseClient;

  private app: firebase.app.App;

  private _tmpBucketName: string
  private _ordersBucketName: string
  private _dashboardBucketName: string

  private _patternsBucketName: string
  private _masksBucketName: string

  private _tennantId: string
  get tennantId() {
    return this._tennantId;
  }

  get ordersBucketName() {
    return this._ordersBucketName;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new FirebaseClient();
    }
    return this.instance;
  }

  private constructor() {
    const buckets = getFirebaseBuckets()

    if (buckets == null) { throw new Error("no buckets found") }

    if (buckets?.tmp == null) { throw new Error("no tmp bucket name found") }
    if (buckets?.dashboard == null) { throw new Error("no dashboard bucket name found") }
    if (buckets?.orders == null) { throw new Error("no orders bucket name found") }
    if (buckets?.masks == null) { throw new Error("no masks bucket name found") }
    if (buckets?.patterns == null) { throw new Error("no patterns bucket name found") }

    this._tmpBucketName = buckets?.tmp
    this._ordersBucketName = buckets?.orders
    this._dashboardBucketName = buckets?.dashboard
    this._patternsBucketName = buckets?.patterns
    this._masksBucketName = buckets?.masks
    this._tennantId = tennantId

    this.app = firebase.initializeApp({
      apiKey: firebaseApiKey,
      authDomain: `${firebaseProjectId}.firebaseapp.com`,
      databaseURL: `https://${firebaseProjectId}.firebaseio.com`,
      projectId: firebaseProjectId,
      // storageBucket: `${firebaseProjectId}.appspot.com`,
      // messagingSenderId: "320406947079",
      appId: firebaseAppId
    });

    // eslint-disable-next-line no-restricted-globals
    // if (location.hostname === "localhost") {
    // }
  }

  public firestore() {
    return this.app.firestore()
  }

  public auth() {
    return this.app?.auth()
  }

  public masksStorage() {
    return this.app?.storage( `gs://${this._masksBucketName}`)
  }

  public patternsStorage() {
    return this.app?.storage( `gs://${this._patternsBucketName}`)
  }

  public tmpStorage() {
    return this.app?.storage( `gs://${this._tmpBucketName}`)
  }

  public ordersStorage() {
    return this.app?.storage( `gs://${this._ordersBucketName}`)
  }

  public dashboardStorage() {
    return this.app?.storage( `gs://${this._dashboardBucketName}`)
  }

  // public storage(url?: string) {
  //   return this.app?.storage(url)
  // }

  public functions() {
    return this.app?.functions()
  }
}

export const firebaseClient = FirebaseClient.getInstance();

export const getDB = (): firebase.firestore.Firestore => {
  const db = firebaseClient.firestore()
  db.settings({ignoreUndefinedProperties: true})
  return db
};

export const getBatch = () => {
  return firebaseClient.firestore().batch();
};

export const getMasksStorage = () => {
  return firebaseClient.masksStorage()
};


export const getPatternsStorage = () => {
  return firebaseClient.patternsStorage()
};

export const getTmpStorage = () => {
  return firebaseClient.tmpStorage()
};

export const getOrdersStorage = () => {
  return firebaseClient.ordersStorage()
};

export const getDashboardStorage = () => {
  return firebaseClient.dashboardStorage()
};

export const getOrdersStorageUrls = async (orderId: string, productId?: string) => {
  const folderRef = getOrdersStorage().ref( [orderId, productId].filter(a => a != null).join("/") );
  
  const items: { [key: string]: string } = {};
  const ref = await folderRef.listAll();

  if(ref.items?.length <= 0) {
    const folderRef = getOrdersStorage().ref(orderId);
    await folderRef.listAll();
  }

  const nameDownloadUrlArrP = ref.items.map( async (item) => {
    const name = item.name
    const downloadUrl = await item.getDownloadURL()
    return [name, downloadUrl]
  })
  const nameDownloadUrlArr = await Promise.all(nameDownloadUrlArrP)
  for (const [name, downloadUrl] of nameDownloadUrlArr ) {
    items[name] = downloadUrl
  }
  return items;
};

export const getDashboardStorageUrls = async (orderId: string, productId?: string) => {
  const folderRef = getDashboardStorage().ref("orders").child( [orderId, productId].filter(a => a != null).join("/") );

  const items: { [key: string]: string } = {};
  const ref = await folderRef.listAll();

  if(ref.items?.length <= 0) {
    const folderRef = getOrdersStorage().ref(orderId);
    await folderRef.listAll();
  }

  const nameDownloadUrlArrP = ref.items.map( async (item) => {
    const name = item.name
    const downloadUrl = await item.getDownloadURL()
    return [name, downloadUrl]
  })
  const nameDownloadUrlArr = await Promise.all(nameDownloadUrlArrP)
  for (const [name, downloadUrl] of nameDownloadUrlArr ) {
    items[name] = downloadUrl
  }

  return items;
};
