import React from 'react'
import { SizedImage } from '../../models/Feature.model';

import styles from './UpdatableImage.module.scss'

interface UpdatableImageProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string,
  onAddImage: (id: string, file: File | undefined) => void
  onDeleteImage?: (id: string) => void
}

export const UpdatableImage:React.FC<UpdatableImageProps> = ({id, src, onAddImage, onDeleteImage}) => {
  const onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(onDeleteImage) {
      onDeleteImage(id)
    }
  }
  return <label className={styles.container}>
    {src && <img src={src} alt="large pattern" />}
    {!src && <div className={styles.uploadUplaceholder}>Upload Image</div>}
    <input type="file" name="pattern" onChange={(e) => onAddImage(id, e.target.files?.[0] )} />
    {src && <div className={styles.overlay}>
      <div className={styles.box}>
        <span>Update</span>
      </div>
      {onDeleteImage != null && <div className={styles.box} onClick={onDelete}>
        <span>Delete</span>
      </div>}
    </div>}
  </label>
}


interface AddSizedImageProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onAddImage: (file: File | undefined) => void
}
export const AddSizedImage:React.FC<AddSizedImageProps> = ({onAddImage}) => {
  return <label className={styles.container}>
    <div className={styles.uploadUplaceholder}>Upload Image</div>
    {onAddImage != null && <input type="file" name="pattern" onChange={(e) => onAddImage(e.target.files?.[0] )} />}
  </label>
}

interface UpdatableSizedImageProps extends React.InputHTMLAttributes<HTMLInputElement> {
  data: SizedImage,
  onAddImage?: (file: File | undefined) => void
  onDeleteImage?: () => void
}
export const UpdatableSizedImage:React.FC<UpdatableSizedImageProps> = ({data, onAddImage, onDeleteImage}) => {
  const onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(onDeleteImage) { onDeleteImage() }
  }

  const src = data.url750 || data.url320 || data.url160 || data.url400

  if(src == null) return null

  return <label className={styles.container}>
    <img src={src} alt="large pattern" />
    <div className={styles.sizes}>
      {data.url750 != null && <div className={styles.size}>750</div>}
      {data.url320 != null && <div className={styles.size}>320</div>}
      {data.url160 != null && <div className={styles.size}>160</div>}
      {data.url400 != null && <div className={styles.size}>400</div>}
    </div>
    {onAddImage != null && <input type="file" name="pattern" onChange={(e) => onAddImage(e.target.files?.[0] )} />}
    {src && <div className={styles.overlay}>
      {onDeleteImage != null && <div className={styles.box} onClick={onDelete}>
        <span>Delete</span>
      </div>}
    </div>}
  </label>
}