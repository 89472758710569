type PromoCodeApplicationLevel = "order" | "item"

export class PromoCode {
  id: string
  code: string
  m: number
  b: number
  applicationLevel: PromoCodeApplicationLevel
  description: string
  shippingOff: boolean
  disabled: boolean
  maxUses?: number
  currentUses?: number
  nForOneFree?: number

  constructor(id: string, code: string, m: number, b: number, applicationLevel: PromoCodeApplicationLevel, description: string, shippingOff: boolean, disabled: boolean, maxUses?: number, currentUses?: number, nForOneFree?:number) {
    this.id = id
    this.code = code
    this.m = m
    this.b = b
    this.applicationLevel = applicationLevel
    this.description = description
    this.shippingOff = shippingOff
    this.disabled = disabled
    this.maxUses = maxUses
    this.currentUses = currentUses
    this.nForOneFree = nForOneFree
  }

  static from = (docSnapshot: firebase.firestore.DocumentSnapshot | any): PromoCode => {
    const data = docSnapshot.data ? (docSnapshot.data() as any) : docSnapshot;
    const id = docSnapshot.code || docSnapshot.id
    const shippingOff = Boolean(data.shippingOff);
    const disabled = Boolean(data.disabled);

    const maxUses = data.maxUses != null ? Number.parseInt(data.maxUses) : undefined
    const currentUses = data.currentUses != null ? Number.parseInt(data.currentUses) : undefined
    const nForOneFree = data.nForOneFree != null ? Number.parseInt(data.nForOneFree) : undefined
    

    return new PromoCode(id, data.code, data.m, data.b, data.applicationLevel, data.description, shippingOff, disabled, maxUses, currentUses, nForOneFree)
  }
}