import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';

import styles from './Input.module.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  error?: string | boolean;
  inputClassName?: string,
}

export const Input: React.FC<InputProps> = ({type="text", value, name, title, className, inputClassName, error, disabled, ...props}) => {
  const classList = [
    styles.container,
    error ? styles.error : undefined,
    disabled ? styles.disabled : undefined,
    className
  ].join(' ')
  const inputClassList = [
    styles.input,
    type === "date" && styles.date,
    inputClassName
  ].join(' ')

  return <div className={classList}>
    <input type={type} value={(value != null) ? value : ""} name={name} {...props} className={inputClassList}
           placeholder={title} disabled={disabled}/>
    {title && <div className={styles.title}>
      {title}
      {error && (typeof error) == 'string' && <span> - {error}</span>}
    </div>}
  </div>
}


interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  error?: string | boolean;
  inputClassName?: string,
}

export const Textarea: React.FC<TextareaProps> = ({value, name, title, className, inputClassName, error, disabled, onChange}) => {
  const classList = [
    styles.container,
    error ? styles.error : undefined,
    disabled ? styles.disabled : undefined,
    className
  ].join(' ')
  const inputClassList = [
    styles.input,
    inputClassName
  ].join(' ')

  return <div className={classList}>
    <TextareaAutosize value={(value != null) ? value : ""} name={name} className={inputClassList} placeholder={title} disabled={disabled} onChange={onChange} />
    {title && <div className={styles.title}>
      {title}
      {error && (typeof error) == 'string' && <span> - {error}</span>}
    </div>}
  </div>
};
