import firebase from "firebase";

export const rotateImage = async (orderId: string, productId: string) => {
  return authedPost(`/api/orders/${orderId}/products/${productId}/rotateAsync`, {
    maskify: true,
    crop: true,
    project: true,
    facePoints: true,
  });
}

export const cropImage = async (orderId: string, productId: string, fileName = "raw_face.jpg") => {
  return authedPost(`/api/orders/${orderId}/products/${productId}/cropAsync`, {
    fileName,
    maskify: true,
    project: true,
    facePoints: true,
  });
}

export const maskifyImage = async (orderId: string, productId: string) => {
  return authedPost(`/api/orders/${orderId}/products/${productId}/maskifyAsync`, {
    productId: productId?.toString()
  });
}

const getHeaders = async () => {
  const authToken = await firebase.auth().currentUser?.getIdToken()
  const headers = new Headers([["Content-Type", "application/json"], ['Accept', 'application/json'] ]);  
  if (authToken != null) {
    headers.append('Authorization', 'Bearer ' + authToken);
  }
  // if(path === "/api/codes") {
    // headers.append("X-CRASH", "1")
  // }
  return headers
}

export const authedPost = async (path: string, body: any = {}) => {
  const headers = await getHeaders();
  const res = await fetch(path, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  });
  const jsonResp = await res.json()
  if (!res.ok) { throw jsonResp; }

  return jsonResp;
}

export const authedDelete = async (path: string, body: any = {}) => {
  const headers = await getHeaders();
  const res = await fetch(path, {
    method: "DELETE",
    headers: headers,
    body: JSON.stringify(body)
  });
  const jsonResp = await res.json()
  if (!res.ok) { throw jsonResp; }
  
  return jsonResp;
}

export const authedGet = async (path: string, body: any = {}) => {
  const headers = await getHeaders();
  const queryParams = (params: any) => Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

  const queryParamsString = "?" + queryParams(body);
  const res = await fetch(path + queryParamsString, {
    method: "GET",
    headers: headers,
  });

  const jsonResp = await res.json()
  if (!res.ok) { throw jsonResp; }

  return jsonResp;
}