import React from 'react'
import {groupBy, mapValues} from 'lodash'
import {Meta} from '../utils'
import {GiftCard, GiftCardWId} from "../models/GiftCard.model"
import {giftCardsService} from "../services/giftcards.service"

type ContextPropsState = {
  giftCardsById: { [key: string]: Meta<GiftCardWId> },
  giftCardIds: string[],
}
type ContextPropsActions = {
  fetchGiftCards: () => void,
  createGiftCard: (data: Partial<GiftCard>) => Promise<void>,
  updateGiftCard: (id: string, data: Partial<GiftCard>) => Promise<void>,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialState = {
  giftCardsById: {},
  giftCardIds: [],
};
const initialActions = {
  fetchGiftCards: () => {
    throw new Error("fetchGiftCards not implemented")
  },
  updateGiftCard: () => {
    throw new Error("updateGiftCard not implemented")
  },
  createGiftCard: () => {
    throw new Error("not defined")
  }
}

export const GiftCardsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const GiftCardsProvider = ({children}: any) => {
  const [giftCardsById, setGiftCardsById] = React.useState<{ [key: string]: Meta<GiftCardWId> }>({});
  const [giftCardIds, setGiftCardIds] = React.useState<string[]>([]);

  const fetchGiftCards = React.useCallback(async () => {
    const giftCards = await giftCardsService.getAll()
    if (giftCards) {
      setGiftCardsById(mapValues(groupBy(giftCards, p => p.id), p => ({data: p[0]})));
      setGiftCardIds(giftCards.map(r => r.id));
    }
  }, [setGiftCardsById, setGiftCardIds]);

  const createGiftCard = React.useCallback(async (data) => {
    const created = await giftCardsService.create(data)
    console.log(created)

    const id = created.id
    setGiftCardIds(g => {
      g.unshift(id)
      return [...g]
    })

    setGiftCardsById(giftCardsById => {
      return {
        ...giftCardsById,
        [id]: {
          data: {...created},
          meta: {}
        }
      }
    })
  }, [])

  const updateGiftCard = React.useCallback(async (id, data) => {
    setGiftCardsById(giftCardsById => {
      const currentGiftCard = giftCardsById?.[id]
      return {
        ...giftCardsById,
        [id]: {
          data: currentGiftCard?.data,
          meta: {
            ...(currentGiftCard?.meta || {}),
            updating: true
          }
        }
      }
    })
    await giftCardsService.update(id, data)
    setGiftCardsById(giftCardsById => {
      const currentGiftCard = giftCardsById?.[id]
      return {
        ...giftCardsById,
        [id]: {
          data: {...currentGiftCard.data, ...data},
          meta: {
            ...(currentGiftCard?.meta || {}),
            updating: false
          }
        }
      }
    })
  }, [])

  const value: ContextProps = [{
    giftCardIds: giftCardIds,
    giftCardsById: giftCardsById
  }, {
    fetchGiftCards: fetchGiftCards,
    updateGiftCard: updateGiftCard,
    createGiftCard
  }]

  return <GiftCardsContext.Provider value={value}>
    {children}
  </GiftCardsContext.Provider>
}

export const useGiftCardsContext = () => React.useContext(GiftCardsContext)
