import React from 'react'
import {useLocation} from "react-router-dom";
import queryString from 'query-string'

import {Order, OrderProduct} from '../../models/Order.model'
import {getOrdersStorageUrls, getDashboardStorageUrls, firebaseClient} from "../../services/firebase";
import {cropImage, maskifyImage, rotateImage} from "../../services/functions.service";
import {Button, LinkButton} from "../../components/Button/Button";
import { ProductQuickInfo } from '../ProductQuickInfo/ProductQuickInfo';
import {FaceImage, FaceMissingImage} from '../FaceImage/FaceImage'
import RejectPhotoPopup from '../../popups/RejectPhotoPopup/RejectPhotoPopup';
import UnsurePhotoPopup, { ReprintPhotoPopup } from '../../popups/UnsurePhotoPopup/UnsurePhotoPopup'

import { useOrdersContext } from '../../contexts/orders.context';
import { useProductsContext } from '../../contexts/products.context';

import { Mask, CropRotateIcon, ReCropIcon, TrashCanIcon, CopyIcon, FirebaseStorage } from '../../toneIcons';
import { Input } from '../Input/Input';
import { useForm } from '../../hooks/useForm';
import ClonePopup from '../../popups/ClonePopup/ClonePopup';
import DeletePopup from '../../popups/DeletePopup/DeletePopup';
import { InfoCardHeader, InfoCardHeaderButton, InfoCardHeaderLink, InfoCardRow } from '../InfoCard/InfoCard';
import { getFireStorageUrl, isNotNull, isSomething } from '../../utils';

import styles from './Product.module.scss'
import { Spacer } from '../Spacer/Spacer';
import { useFeaturesContext } from '../../contexts/features.context';

interface Props {
  orderId: string;
  order: Order;
  product: OrderProduct;
  onClickImage: (src: string) => void;
  setError?: (error: any) => void;
  setLoading?: (loading: boolean) => void;
  onUpdate: (data: {[key: string]: string | number | undefined | null | string[]}) => void
}

export const Product: React.FC<Props>  = ({orderId, order, product, setError, setLoading, onClickImage, onUpdate}) => {
  const [, { cloneOrderProduct, deleteOrderProduct, approveOrderProduct, rejectOrderProduct, unsureOrderProduct, reprintOrderProduct, eraseRotatedMeta, eraseFaceOval, addFile, printifyOrderProduct, unprintifyOrderProduct }] = useOrdersContext()
  const [{productById}] = useProductsContext()
  const [{featureById}] = useFeaturesContext()
  const location = useLocation()

  const [images, setImages] = React.useState<any>({});
  const [popup, setPopup] = React.useState<string>();

  const productId = product.id

  const fetchImages = React.useCallback(async (orderId, productId) => {
    const [orderImages, dashboardImages] = await Promise.all( [getOrdersStorageUrls(orderId, productId), getDashboardStorageUrls(orderId, productId)] )
    const images = {
      ...orderImages,
      ...dashboardImages
    }
    setImages(images)
  }, [])

  React.useEffect(() => {
    if (orderId && productId != null) {
      fetchImages(orderId, productId)
    }
  }, [orderId, productId, fetchImages]);

  const {values, onDebounceChange} = useForm({
    correctedMouthDistanceMM: product.correctedMouthDistanceMM,
    correctedRollAngle: product.correctedRollAngle,
    correctedCenterX: product.correctedCenterX,
    correctedEyeBotY: product.correctedEyeBotY,
  }, (_, name, value) => {
    if(["correctedMouthDistanceMM", "correctedRollAngle", "correctedCenterX", "correctedEyeBotY"].indexOf(name) >= 0) {
      const floatValue = Number.parseFloat(value)
      if(value?.length > 0 && isSomething(floatValue)) {
        onUpdate({[name]: floatValue})
      } else {
        onUpdate({[name]: null})
      }
    }
  })

  const arFace = images["ar_raw_face.jpg"];
  const rawFace = images[product.rawFaceFileName || "raw_face.jpg"];
  const rotatedFace = images["rotated_face.jpg"];

  const silhouetteFace = images["silhouette_face.png"];
  const silhouetteProjectedFace = images["silhouette_projected_face.png"];
  const productStatus = product.status
  const rejectionText = product.rejection?.text
  const rejectionResons = product.rejection?.reasons

  const cloneOrderProductHandler = async () => {
    setError?.("");
    setLoading?.(true);
    try {
      await cloneOrderProduct(orderId, productId)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const deleteOrderProductHandler = async () => {
    setError?.("");
    setLoading?.(true);
    try {
      await deleteOrderProduct(orderId, productId)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const acceptImageHandler = async () => {
    setError?.("");
    setLoading?.(true);
    try {
      await approveOrderProduct(orderId, productId)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const unsureImageHandler = async (data: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await unsureOrderProduct(orderId, productId, data)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const rejectImageHandler = async (data: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await rejectOrderProduct(orderId, productId, data)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const reprintImageHandler = async (data: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await reprintOrderProduct(orderId, productId, data)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const printifyImageHandler = async () => {
    setError?.("");
    setLoading?.(true);
    try {
      await printifyOrderProduct(orderId, productId)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }
  const unPrintifyImageHandler = async () => {
    setError?.("");
    setLoading?.(true);
    try {
      await unprintifyOrderProduct(orderId, productId)
      setPopup(undefined)
    } catch(error) {
      setError?.(error.message || error.details || error.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const rotateImageHandler = async (e: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await rotateImage(orderId, productId)
      fetchImages(orderId, productId)
    } catch(error) {
      setError?.(e.message || e.details || e.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const cropImageHandler = async (e: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await cropImage(orderId, productId)
      fetchImages(orderId, productId)
    } catch(error) {
      setError?.(e.message || e.details || e.error ||"Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const maskifyClickHandler = async (e: any) => {
    setError?.("");
    setLoading?.(true);
    try {
      await maskifyImage(orderId, productId)
      fetchImages(orderId, productId)
    } catch(error) {
      setError?.(e.message || e.details || e.error  || "Unknown error occurred");
    } finally {
      setLoading?.(false)
    }
  }

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: File[] = Array.from(e?.target?.files || []);

    e.target.value = "";

    if (!files || files.length < 1) {
      return;
    }

    const file = files[0]
    if(file != null) {
      addFile(orderId, productId, file)
    }
  }
  
  const features = product.featureIds?.map(id => featureById?.[id]).filter(isNotNull)
  const mask = product.maskId ? productById?.[product.maskId] : undefined
  const maskImage = images[`face_withPattern_${product.maskId}.png`];
  const hasMouthDistance = product.mouthDistanceMM != null || product.correctedMouthDistanceMM != null

  const facePointsSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    orderId: orderId,
    editProductId: productId,
    editType: 'points'
  })

  const faceRotationSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    orderId: orderId,
    editProductId: productId,
    editType: 'rotation'
  })


  const canAcceptReject = !product.giftCardType && ["toVerify", "verifying", "unsure", "rejected", "accepted", "reprint"].indexOf(order.status) >= 0
  const canReprint = ( (product.status === "accepted" || product.status === "printed") && (order.status === "printed" || order.status === "packaged" || order.status === "shipped" || order.status === "reprint") )


  
  const hasFeatures = product.featureIds != null;
  const hasSelfie = features?.find(f => f.data?.slug === 'selfie') != null;
  const hasCustomImage = product.categoryId === "custommask"
  const showRotate = (hasFeatures && hasSelfie && rawFace) || (hasFeatures && !hasSelfie) || (!hasFeatures && rawFace != null)
  const showCrop = (hasFeatures && hasSelfie && rotatedFace) || (hasFeatures && !hasSelfie) || (!hasFeatures && rotatedFace)
  const showMaskify = (hasFeatures && hasSelfie && silhouetteProjectedFace) || (hasFeatures && !hasSelfie) || (!hasFeatures && silhouetteProjectedFace)
  const canHaveImage = !hasFeatures || hasSelfie

  const showButtons = (!hasFeatures && hasMouthDistance)
    || (hasFeatures && hasSelfie && hasMouthDistance)
    || (hasFeatures && !hasSelfie)


  const bucketUrl = getFireStorageUrl(firebaseClient.ordersBucketName, `${orderId}/${product.id}`)

  return <div className={styles.container}>
    <InfoCardHeader>
      <div className={styles.itemHeader}>
        <span>Item #{product.id}</span>
      </div>
      <Spacer/>
      <InfoCardHeaderLink title="Bucket Link" href={bucketUrl}>
        <FirebaseStorage />
      </InfoCardHeaderLink>
      <InfoCardHeaderButton title="Clone Product" onClick={() => setPopup('clone')}>
        <CopyIcon/>
      </InfoCardHeaderButton>
      <InfoCardHeaderButton title="Delete Product" onClick={() => setPopup('delete')}>
        <TrashCanIcon/>
      </InfoCardHeaderButton>
    </InfoCardHeader>
    {product.giftCardType &&
    <div>
      Gift Card type: {product.giftCardType}
      &nbsp; <a href={`/admin/gift-cards?id=${product.giftCardId}`} target="_blank" rel="noopener noreferrer">(open in
      new tab)</a>
    </div>
    }
    <div className={styles.content}>
      <ProductQuickInfo orderProduct={product} className={styles.quickInfo} onUpdate={onUpdate}/>
      {canHaveImage && <LinkButton to={facePointsSearch} className={styles.edit}>Edit Outline</LinkButton>}
      {canHaveImage && <LinkButton to={faceRotationSearch} className={styles.edit}>Edit Rotation</LinkButton>}

      {canAcceptReject && <div className={styles.processImageButtons}>
        <Button className={styles.accept} onClick={acceptImageHandler}>Accept</Button>
        {product.status !== "reprint" &&
        <Button className={styles.unsure} onClick={() => setPopup("unsure")}>Unsure</Button>}
        {product.status !== "reprint" &&
        <Button className={styles.reject} onClick={() => setPopup("reject")}>Reject</Button>}
      </div>}

      { canReprint && <div className={styles.processImageButtons}>
        <Button className={styles.reprint} onClick={() => setPopup("reprint")}>RePrint</Button>
      </div> }

      { ( product.status === "reprint") && <div className={styles.processImageButtons}>
        <Button className={styles.reprint} onClick={() => printifyImageHandler()}>Prinitfy Product</Button>
        <Button className={styles.reprint} onClick={() => unPrintifyImageHandler()}>Unprintify Product</Button>

      </div> }

      {canHaveImage && <>
        <Button onClick={() => eraseRotatedMeta(orderId, productId)}>Erase Rotated Meta</Button>
        <Button onClick={() => eraseFaceOval(orderId, productId)}>Erase Face Oval</Button>
      </>}

      <div className={styles.status}>Item status: {productStatus}</div>
      {product.combinedError != null && <div className={styles.status}>{product.combinedError}</div>}

      { rejectionText && <div>Why: {rejectionText}</div> }
      { rejectionResons && <div>Reasons: {rejectionResons?.join(", ")}</div> }

      {product.featureIds && <div>
        HAS FEATURES: {product.featureIds.join(", ")}
      </div>}
      {rawFace && hasCustomImage && <FaceImage src={rawFace} name="Custom Image" onClickImage={onClickImage} onUpload={onUpload} />}
      {canHaveImage && <>
        <div className={styles.row}>
          {arFace && <FaceImage src={arFace} name="AR Face" onClickImage={onClickImage} />}
          {rawFace && <FaceImage src={rawFace} name="Raw Face" onClickImage={onClickImage} onUpload={onUpload} />}
          {!rawFace && <FaceMissingImage name="Add Raw Face" onUpload={onUpload} />}
          {rotatedFace && <FaceImage src={rotatedFace} name="Rotated Face" onClickImage={onClickImage} />}
        </div>
        <div className={styles.row}>
          {silhouetteFace && <FaceImage src={silhouetteFace} name="Silhouette Face" onClickImage={onClickImage} />}
          {silhouetteProjectedFace && <FaceImage src={silhouetteProjectedFace} name="Silhouette Projected Face" onClickImage={onClickImage} />}
        </div>
      </>}

      {maskImage && <div className={styles.faces}>
        {mask && <FaceImage src={maskImage} name={mask?.data?.name} onClickImage={onClickImage} />}
      </div>}

      {canHaveImage && <>
        <Input title="Corrected Mouth Distance (mm)"
            name="correctedMouthDistanceMM"
            value={values.correctedMouthDistanceMM}
            className={styles.input}
            onChange={onDebounceChange} />
        {/* <Input title="Corrected Center X"
            name="correctedCenterX"
            value={values.correctedCenterX}
            className={styles.input}
            onChange={onDebounceChange} /> */}
        <Input title="Corrected Eye Bot Y"
            name="correctedEyeBotY"
            value={values.correctedEyeBotY}
            className={styles.input}
            onChange={onDebounceChange} />
      </>}

      {(product.hasMeasurements() || product.customImage?.position) && <>
        <InfoCardHeader>Measurements</InfoCardHeader>
        <InfoCardRow text="Position" value={product.customImage?.position} hideIfNullOrEmptyValue />
        <InfoCardRow text="Mouth (mm)" value={product.mouthDistanceMMPretty()} hideIfNullOrEmptyValue />
        <InfoCardRow text="Mouth (px)" value={product.mouthDistancePXPretty()} hideIfNullOrEmptyValue />
        <InfoCardRow text="Distance to Mouth (mm)" value={product.distanceToMouthMMPretty()} hideIfNullOrEmptyValue />

        <InfoCardRow text="Nose middle to tip (mm)" value={product.noseMiddleToTipDistanceMMPretty()} hideIfNullOrEmptyValue />
        <InfoCardRow text="Nose tip to chin (mm)" value={product.noseTipToChinDistanceMMPretty()} hideIfNullOrEmptyValue />
        <InfoCardRow text="Nose top to middle (mm)" value={product.noseTopToMiddleDistanceMMPretty()} hideIfNullOrEmptyValue />

        <InfoCardRow text="Scene (WxH)" value={product.sceneDimensionsPretty()} hideIfNullOrEmptyValue />
        <InfoCardRow text="AR (WxH)" value={product.arDimensionsPretty()} hideIfNullOrEmptyValue />
      </>}

      {<>
        <InfoCardHeader>Derived Measurements</InfoCardHeader>
        {isSomething(product.rotatedMeta?.rollAngle) && <InfoCardRow text="Roll Angle" value={product.rotatedMeta?.rollAngle.toFixed(3)} />}
        {isSomething(product.correctedRollAngle) && <InfoCardRow text="Corrected Roll Angle" value={product.correctedRollAngle.toFixed(3)} />}
        {isSomething(product.rotatedMeta?.centerX) && <InfoCardRow text="Center X" value={product.rotatedMeta?.centerX.toFixed(3)} />}
        {isSomething(product.rotatedMeta?.eyeBotY) && <InfoCardRow text="Eye Bot Y" value={product.rotatedMeta?.eyeBotY.toFixed(3)} />}
      </>}

      {!hasMouthDistance && canHaveImage && <div>No mouth measurements found</div>}
      {showButtons && <div className={styles.processImageButtons}>
        {showRotate && <Button title="Rotate, Crop, Create Mask" className={styles.actionButton} onClick={rotateImageHandler}>
          <CropRotateIcon/>
          <Mask/>
        </Button>}
        {showCrop && <Button title="Crop, Create Mask" className={styles.actionButton} onClick={cropImageHandler}>
          <ReCropIcon/>
          <Mask/>
          </Button>}
        {showMaskify && <Button title="Create Mask" className={styles.actionButton} onClick={maskifyClickHandler}>
          <Mask/>
        </Button>}
      </div>}
    </div>
    {popup === 'reject' && <RejectPhotoPopup onClose={() => setPopup(undefined)} onSubmit={rejectImageHandler} />}
    {popup === 'unsure' && <UnsurePhotoPopup onClose={() => setPopup(undefined)} onSubmit={unsureImageHandler} />}
    {popup === 'reprint' && <ReprintPhotoPopup onClose={() => setPopup(undefined)} onSubmit={reprintImageHandler} />}
    {popup === 'clone' && <ClonePopup onClose={() => setPopup(undefined)} onSubmit={cloneOrderProductHandler} />}
    {popup === 'delete' && <DeletePopup onClose={() => setPopup(undefined)} onSubmit={deleteOrderProductHandler} />}
  </div>
}
