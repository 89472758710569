import React from "react";
import {Helmet} from "react-helmet";
import Cell from "../../components/Cell/Cell";
import {Meta} from "../../utils";
import {useReviewsContext} from "../../contexts/reviews.context";
import Table from "../../components/Table/Table";
import CellHeader from "../../components/CellHeader/CellHeader";
import {Review} from "../../models/Review";
import {Button} from "../../components/Button/Button";
import classNames from "classnames";
import DeletePopup from "../../popups/DeletePopup/DeletePopup";

import styles from "./Reviews.module.scss";

export const Reviews: React.FC<React.HTMLAttributes<HTMLDivElement>> = () => {
  const [{reviewIds, reviewsById}, {fetchReviews, updateReview, deleteReview}] = useReviewsContext();
  const [reviewIdToDelete, setReviewIdToDelete] = React.useState<string | undefined>();

  React.useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  const changeApproval = React.useCallback((id: string, approval: boolean) => {
    updateReview(id, {isApproved: approval})
  }, [updateReview]);

  const deleteConfirmationHandler = async (id: string) => {
    await deleteReview(id);
    setReviewIdToDelete(undefined);
  };

  const columns = "max-content 200px 300px max-content max-content 1fr"

  return <div className={styles.container}>
    <Helmet>
      <title>Reviews - FriendlyFace</title>
    </Helmet>
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader>Name</CellHeader>
        <CellHeader>Title</CellHeader>
        <CellHeader>Description</CellHeader>
        <CellHeader>Approved</CellHeader>
        <CellHeader>Reports</CellHeader>
        <CellHeader>Actions</CellHeader>

        {reviewIds?.map((id, index) => {
          const review = reviewsById?.[id];
          if (review == null) return null;
          return <ReviewCells index={index}
                              metaReview={review}
                              key={id}
                              changeApproval={changeApproval}
                              onDelete={() => setReviewIdToDelete(review.data?.id)}/>
        })}

      </Table>
    </div>

    {reviewIdToDelete && <DeletePopup onSubmit={() => deleteConfirmationHandler(reviewIdToDelete)} onClose={() => setReviewIdToDelete(undefined)} />}

  </div>
};

interface ReviewCellsProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number,
  metaReview: Meta<Review>,
  onDelete: any,
  changeApproval: (id: string, approval: boolean) => void
}


const ReviewCells: React.FC<ReviewCellsProps> = ({index, metaReview, onDelete, changeApproval}) => {
  const props = {
    index: index,
    deleting: false,
    className: styles.cell
  }

  const review = metaReview.data;

  if (!review) return null;

  return <>
    <Cell {...props} className={classNames(styles.cell, styles.columns)}>
      <div>{review?.name || "Default"}</div>
      <div>{review?.email}</div>
      <div>{"⭐".repeat(review.rating)}</div>
    </Cell>
    <Cell {...props}>{review?.title}</Cell>
    <Cell {...props}>{review?.description}</Cell>
    <Cell {...props} className={classNames(styles.cell, styles.center)}>{review?.isApproved ? "✅" : "❌"}</Cell>
    <Cell {...props} className={classNames(styles.cell, styles.center)}>{review?.reportedCount}</Cell>
    <Cell {...props}>
      <Button onClick={() => {
        changeApproval(review.id, !review.isApproved)
      }}>{review.isApproved ? "UnApprove" : "Approve"}</Button>
      <Button onClick={() => onDelete()}>Delete</Button>
    </Cell>
  </>
};
