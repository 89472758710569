import {getDB} from "./firebase";
import firebase from "firebase";
import {Review} from "../models/Review";

class ReviewsService {
  private static instance: ReviewsService;

  get collection(): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
    return getDB().collection("reviews");
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ReviewsService();
    }
    return this.instance;
  }

  public async getAll() {
    const res = await this.collection
      .orderBy("createdAt", "desc")
      .get();

    return res.docs.map(doc => {
      return {id: doc.id, ...doc.data()} as Review
    });
  }

  public async update(id: string, data: any) {
    return this.collection.doc(id).update(data)
  }

  public async delete(id: string) {
    return this.collection.doc(id).delete()
  }
}

export const reviewsService = ReviewsService.getInstance();
