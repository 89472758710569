import React from 'react'
import { Popup } from "../../components/Popup/Popup";
import classNames from 'classnames'

import {defaultColorStyle, Meta} from '../../utils'
import { usePatternsContext } from '../../contexts/patterns.context';
import { Pattern } from '../../models/Pattern.model';

import styles from './PatternPopup.module.scss';


interface Props {
  selectedPatternId?: string
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (id: string) => void;
}

export const PatternPopup: React.FC<Props> = ({onClose, onSubmit, selectedPatternId}) => {
  const [{patternById, patternIds}] = usePatternsContext()

  const style = defaultColorStyle()
  return <Popup onClose={onClose} style={style}>
    <div className={styles.patterns}>
      {patternIds?.data?.map(id => <PatternCell key={id} pattern={patternById[id]} isSelected={id === selectedPatternId} onSelectPattern={onSubmit} />)}
    </div>
  </Popup>
};

interface PatternCellProps extends React.HTMLAttributes<HTMLDivElement> {
  pattern: Meta<Pattern>,
  isSelected: boolean,
  onSelectPattern: (id: string) => void,
}

const PatternCell: React.FC<PatternCellProps> = ({pattern, isSelected, onSelectPattern}) => {
  if(pattern == null) return null;
  const url = pattern.data?.smallTileUrl
  const hex = pattern.data?.hex
  const name = pattern.data?.name
  const id = pattern.data?.id;
  return <div className={classNames(styles.pattern, isSelected ? styles.selected : undefined )} onClick={id ? () => onSelectPattern(id) : undefined}>
    {hex && <div className={styles.image} style={{background: hex}} />}
    {url && <img src={url} className={styles.image} alt={name} />}
    <div className={styles.name}>
      <span>{name}</span>
    </div>
  </div>
}

export default PatternPopup;
