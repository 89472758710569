import firebase from "firebase";
import _ from "lodash"
import { CountryCode } from 'libphonenumber-js'
import {OrderedPoint, Point, Point3D} from "./Point";
import {ItemPriceSummary} from "../../../api/src/models/promoCode";

export type RejectReason = "lighting" | "tooMuchLight" | "tooDark" | "obstuctedFace" | "imageQuality" | "cameraPosition" | "needMeasurements" | "turnedHead" | "tooClose" | "blueFace" | "dirtyCamera" | "glasses" | "movedAfterMeasuring" | "waitingOnImages"


export interface RejectionExplanation {
  text: String, reasons: RejectReason[]
}
export interface Rejection {
  text?: string,
  reasons?: RejectReason[]
}

interface StatusHistoryParent {
  date: any
  uid: string
  email: string,
  description?: string,
  rejection?: RejectionExplanation
}

export interface StatusHistory extends StatusHistoryParent {
  old: ProductStatus
  new: ProductStatus
}

export interface OrderStatusHistory extends StatusHistoryParent {
  old: OrderStatus
  new: OrderStatus
}

export type OrderStatus = "unpaid" | "verifying" | "cropped" | "printed" | "packaged" | "shipped" | "doNotPrint" | "new" | "emailed" | "reprint" | "notPrintable" | ProductStatus
export type ProductStatus =
  "waitingOnImages"
  | "toVerify"
  | "accepted"
  | "unsure"
  | "rejected"
  | "resubmissionRequested"
  | "none"


export const OrderStatusDisplayNames: { [K in OrderStatus]: string} = {
  "toVerify": "To Verify",
  "verifying": "Verifying",
  "accepted": "Accepted",
  "rejected": "Rejected",
  "unsure": "Unsure",
  "resubmissionRequested": "Waiting on updated images",
  "waitingOnImages": "Waiting on images",
  "cropped": "Cropped",
  "printed": "Printed",
  "packaged": "Packaged",
  "shipped": "Shipped",
  "doNotPrint": "Do Not Print",
  "emailed": "Emailed",
  "reprint": "Reprint",
  "new": "New",
  "none": "None",
  "unpaid": "Unpaid",
  "notPrintable": "Not Printable (Gift Cards)"
}

export type GenerationStatus = "crop" | "cropError" | "maskify" | "maskifyError"

export interface ShippoTransaction {
  label_url: string;
  tracking_url_provider: string;
  // todo -> add more fields here
}

export interface PriceCalculationResult {
  // total price of all the items in cents before the dicount is applied
  itemsPrice: number,
  // total price of all the items in cents after the dicount is applied
  itemsDiscountPrice: number,
  // amount of cents dicounts subtracts from total price of items
  itemsDiscountAmount: number,
  // a detailed split of prices for each item in the order
  items: { [key: string]: ItemPriceSummary & { id: string } },
  // a detailed split of prices for the extra items
  extras: { [key: string]: ItemPriceSummary & { id: string } }

  // the shipping price in cents
  shippingPrice: number,
  // the shipping price in cents after all the dicounts are applied
  shippingDiscountPrice: number,
  // the amount in cents the dicounts suptract from the shipping price
  shippingDiscountAmount: number,
  // the amount required for free shipping
  minForFreeShipping: number,

  // the amount of tax collected for the provided shipping address
  tax: number
  // the total tax rate for the provided shipping address
  taxRate: number

  // the total price in cents the user will be charged
  total: number
}

export interface IQuntifiedExtrasParam {
  name: string,
  id: string,
  quantity: number
  url?: string,
  description?: string,
}

export class FacePoints {
  eyeL: Point3D
  eyeR: Point3D
  eyeLBot: Point3D
  eyeRBot: Point3D
  mouthL: Point3D
  mouthR: Point3D
  cEyeL: Point3D
  cEyeR: Point3D
  cMouthL: Point3D
  cMouthR: Point3D

  centerX: number
  eyeBotY: number

  faceOval: {[key: string]: OrderedPoint }

  rollAngle?:number
  mouthScale?:number


  constructor(
    eyeL: Point3D,
    eyeR: Point3D,
    eyeLBot: Point3D,
    eyeRBot: Point3D,
    mouthL: Point3D,
    mouthR: Point3D,
    cEyeL: Point3D,
    cEyeR: Point3D,
    cMouthL: Point3D,
    cMouthR: Point3D,
    centerX: number,
    eyeBotY: number,
    faceOval: {[key: string]: OrderedPoint},
    rollAngle?:number,
    mouthScale?:number) {
    this.eyeL = eyeL
    this.eyeR = eyeR

    this.eyeLBot = eyeLBot
    this.eyeRBot = eyeRBot

    this.mouthL = mouthL
    this.mouthR = mouthR

    this.cEyeL = cEyeL
    this.cEyeR = cEyeR
    this.cMouthL = cMouthL
    this.cMouthR = cMouthR
    this.centerX = centerX

    this.eyeBotY = eyeBotY

    this.faceOval = faceOval
    this.rollAngle = rollAngle
    this.mouthScale = mouthScale
  }

  static from = (docSnapshot: firebase.firestore.DocumentSnapshot | {[key: string] : any}) => {
    const data = docSnapshot.data ? docSnapshot.data() as any : docSnapshot

    return new FacePoints(
      data.eyeL,
      data.eyeR,
      data.eyeLBot,
      data.eyeRBot,
      data.mouthL,
      data.mouthR,
      data.cEyeL,
      data.cEyeR,
      data.cMouthL,
      data.cMouthR,
      data.centerX,
      data.eyeBotY,
      data.faceOval,
      data.rollAngle,
      data.mouthScale
    )
  }
}

export interface OrderMessages {
  [smsId: string]: {
    body: string,
    sender: "us" | "them",
    status: string
    timestamp: number
  }
}

export class Order {
  id: string
  createdOn: Date
  name: string
  email: string
  customerId: string
  paymentMethodId: string
  status: OrderStatus
  uid: string
  shipping?: OrderShipping
  billing?: OrderShipping
  products: { [key: string]: OrderProduct }
  chargedPriceDetails?: PriceCalculationResult
  shippoTransaction?: ShippoTransaction
  shippoError?: string;
  shipmentStatus?: string;
  extras?: { [key: string]: IQuntifiedExtrasParam }
  codes?: string[];
  promoCode?: string
  phone?: string
  statusHistory?: StatusHistory[]
  isWebOrder?: boolean;
  notes?: string;
  messages?: OrderMessages;

  constructor(
    id: string,
    createdOn: Date,
    name: string,
    email: string,
    customerId: string,
    paymentMethodId: string,
    status: OrderStatus,
    uid: string,
    shipping: OrderShipping | undefined,
    billing: OrderShipping | undefined,
    products: { [key: string]: OrderProduct },
    chargedPriceDetails?: PriceCalculationResult,
    shippoTransaction?: ShippoTransaction,
    shippoError?: string,
    shipmentStatus?: string,
    extras?: { [key: string]: IQuntifiedExtrasParam },
    promoCode?: string,
    codes?: string[],
    phone?: string,
    statusHistory?: StatusHistory[],
    isWebOrder?: boolean,
    notes?: string,
    messages?: OrderMessages
  ) {
    this.id = id;
    this.createdOn = createdOn;
    this.name = name;
    this.email = email;
    this.customerId = customerId;
    this.paymentMethodId = paymentMethodId;
    this.status = status;
    this.uid = uid;
    this.shipping = shipping;
    this.billing = billing;
    this.products = products;
    this.chargedPriceDetails = chargedPriceDetails;
    this.shippoTransaction = shippoTransaction;
    this.shippoError = shippoError
    this.shipmentStatus = shipmentStatus;
    this.extras = extras;
    this.promoCode = promoCode;
    this.codes = codes;
    this.phone = phone
    this.statusHistory = statusHistory
    this.isWebOrder = isWebOrder
    this.notes = notes
    this.messages = messages
  }

  static from = (docSnapshot: firebase.firestore.DocumentSnapshot | {[key: string] : any}) => {
    const data = docSnapshot.data ? docSnapshot.data() as any : docSnapshot

    const createdOn = new Date( Number.isInteger(data.createdOn) ? data.createdOn : data.createdOn.toMillis())
    const id = docSnapshot.id

    const shipping = OrderShipping.from(data?.shipping);
    const billing = OrderShipping.from(data?.billing);

    const shippoTransaction = data.shippoTransaction;
    const shippoError = data.shippoError;
    const shipmentStatus = data.shipmentStatus;
    const extras = data.extras;
    const chargedPriceDetails = data.chargedPriceDetails;
    const promoCode = data.promoCode
    const codes = data.codes;

    const products = data.products ? _.mapValues(data.products, p => OrderProduct.from(p)) : {}
    const statusHistory = data.statusHistory

    const isWebOrder = data.isWebOrder;

    return new Order(
      id,
      createdOn,
      data.name,
      data.email,
      data.customerId,
      data.paymentMethodId,
      data.status,
      data.uid,
      shipping,
      billing,
      products,
      chargedPriceDetails,
      shippoTransaction,
      shippoError,
      shipmentStatus,
      extras,
      promoCode,
      codes,
      data.phone,
      statusHistory,
      isWebOrder,
      data.notes,
      data.messages
    )
  }
}

export class OrderShipping {
  city: string
  country: string
  line1: string
  line2?: string
  postal_code: string
  state: string
  countryCode?: CountryCode

  constructor(city:string, country:string, line1:string, postal_code:string, state:string, line2:string) {
    this.city = city;
    this.country = country;
    this.line1 = line1;
    this.postal_code = postal_code;
    this.state = state
    this.line2 = line2

    if(country.length === 2) {
      this.countryCode = country as any
    }
  }

  static from = (data: {[key: string] : string}) => {
    if (data == null) {
      return undefined
    }
    return new OrderShipping(data.city, data.country, data.line1, data.postal_code, data.state, data.line2)
  }
}

export interface ProcessingObj {
  start?: Date,
  end?: Date,
  running?: boolean,
  error?: String
}

export interface ProcessingData {
  crop: ProcessingObj,
  maskify: ProcessingObj,
}

export type ProcceesAction = keyof ProcessingData

export interface CustomImage {
  url: string,
  position: "cover" | "contain"
}

export class OrderProduct {
  combinedError: string | undefined
  activeProcessing: string | undefined

  constructor(
    public id:string,
    public enhance: boolean,
    public maskId?: string,
    public categoryId?: string,
    public mouthDistanceMM?: number,
    public correctedMouthDistanceMM?: number,
    public mouthDistancePX?: number,
    public distanceToMouthMM?: number,
    public noseMiddleToTipDistanceMM?: number,
    public noseTipToChinDistanceMM?: number,
    public noseTopToMiddleDistanceMM?: number,
    public patternId?: string,
    public productType?: string,
    public sceneWidth?:number,
    public sceneHeight?: number,
    public arkitWidth?: number,
    public arkitHeight?: number,
    public faceOval?: Point[],
    public faceOvalEdit?: {[key: string]: OrderedPoint},
    public status?: OrderStatus,
    public rejection?: Rejection,
    public generationStatus?: GenerationStatus,
    public error?: string,
    public processing?: ProcessingData,
    public rawFaceFileName?: string,
    public rotatedMeta?: FacePoints,
    public arMeta?: FacePoints,
    public giftCardType?: string,
    public giftCardId?: string,
    public correctedRollAngle?: number,
    public correctedCenterX?: number,
    public correctedEyeBotY?: number,
    public featureIds?: string[],
    public customImage?: CustomImage) {
    this.combinedError = this._calcCombinedError()
    this.activeProcessing = this._calcActiveProcessing()
    
  }

  private _calcCombinedError() {
    const cropError = this.processing?.crop?.error
    const maskifyError = this.processing?.crop?.error

    if (cropError != null) { return "crop: " + cropError }
    else if (maskifyError != null) { return "maskify: " + maskifyError }
    else { return undefined }
  }

  private _calcActiveProcessing() {
    const cropRunning = this.processing?.crop?.running
    const maskifyRunning = this.processing?.maskify?.running

    if (cropRunning) { return "croping" }
    else if (maskifyRunning) { return "maskifying" }
    else return undefined
  }

  hasMeasurements() {
    // if(this.correctedMouthDistanceMM != null) return true
    if(this.mouthDistanceMM != null) return true
    if(this.mouthDistancePX != null) return true
    if(this.noseMiddleToTipDistanceMM != null) return true
    if(this.noseTipToChinDistanceMM != null) return true
    if(this.noseTopToMiddleDistanceMM != null) return true
    if(this.sceneHeight != null && this.sceneWidth != null) return true
    if(this.arkitHeight != null && this.arkitWidth != null) return true
    return false
  }

  mouthDistanceMMPretty = () => this.mouthDistanceMM?.toFixed(3)
  correctedMouthDistanceMMPretty = () => this.correctedMouthDistanceMM?.toFixed(3)
  mouthDistancePXPretty = () => this.mouthDistancePX?.toFixed(3)
  distanceToMouthMMPretty = () => this.distanceToMouthMM?.toFixed(3)
  noseMiddleToTipDistanceMMPretty = () => this.noseMiddleToTipDistanceMM?.toFixed(3)
  noseTopToMiddleDistanceMMPretty = () => this.noseTopToMiddleDistanceMM?.toFixed(3)
  noseTipToChinDistanceMMPretty = () => this.noseTipToChinDistanceMM?.toFixed(3)
  sceneDimensionsPretty = () => {
    if(this.sceneHeight != null && this.sceneWidth != null) {
      return `${this.sceneWidth}x${this.sceneHeight}`
    } else {
      return undefined
    }
  }
  arDimensionsPretty = () => {
    if(this.arkitHeight != null && this.arkitWidth != null) {
      return `${this.arkitWidth}x${this.arkitHeight}`
    } else {
      return undefined
    }
  }

  static from = (data: {[key: string] : any}) => {
    const enhance = Boolean(data.enhance);
    const mouthDistanceMM = data.mouthDistanceMM != null ? Number.parseFloat(data.mouthDistanceMM) : undefined;
    const correctedMouthDistanceMM = data.correctedMouthDistanceMM != null ? Number.parseFloat(data.correctedMouthDistanceMM) : undefined;
    const mouthDistancePX = data.mouthDistancePX != null ? Number.parseFloat(data.mouthDistancePX) : undefined;
    const distanceToMouthMM = data.distanceToMouthMM != null ? Number.parseFloat(data.distanceToMouthMM) : undefined;
    const noseMiddleToTipDistanceMM = data.noseMiddleToTipDistanceMM != null ? Number.parseFloat(data.noseMiddleToTipDistanceMM) : undefined;
    const noseTipToChinDistanceMM = data.noseTipToChinDistanceMM != null ? Number.parseFloat(data.noseTipToChinDistanceMM) : undefined;
    const noseTopToMiddleDistanceMM = data.noseTopToMiddleDistanceMM != null ? Number.parseFloat(data.noseTopToMiddleDistanceMM) : undefined;

    const sceneWidth = data.sceneWidth != null ? Number.parseInt(data.sceneWidth) : undefined;
    const sceneHeight = data.sceneHeight != null ? Number.parseInt(data.sceneHeight) : undefined;
    const arkitWidth = data.arkitWidth != null ? Number.parseInt(data.arkitWidth) : undefined;
    const arkitHeight = data.arkitHeight != null ? Number.parseInt(data.arkitHeight) : undefined;

    const correctedCenterX = data.correctedCenterX != null ? Number.parseFloat(data.correctedCenterX) : undefined;
    const correctedEyeBotY = data.correctedEyeBotY != null ? Number.parseFloat(data.correctedEyeBotY) : undefined;

    const status = data.status as OrderStatus
    const rejection = data.rejection as Rejection
    const processing = data.processing as ProcessingData


    const rotatedMeta = data.rotatedMeta ? FacePoints.from(data.rotatedMeta) : undefined
    const arMeta = data.arMeta ? FacePoints.from(data.arMeta) : undefined

    return new OrderProduct(
      data.id,
      enhance,
      data.maskId,
      data.categoryId,
      mouthDistanceMM,
      correctedMouthDistanceMM,
      mouthDistancePX,
      distanceToMouthMM,
      noseMiddleToTipDistanceMM,
      noseTipToChinDistanceMM,
      noseTopToMiddleDistanceMM,
      data.patternId,
      data.productType,
      sceneWidth,
      sceneHeight,
      arkitWidth,
      arkitHeight,
      data.faceOval,
      data.faceOvalEdit,
      status,
      rejection,
      data.generationStatus,
      data.error,
      processing,
      data.rawFaceFileName,
      rotatedMeta,
      arMeta,
      data.giftCardType,
      data.giftCardId,
      data.correctedRollAngle,
      correctedCenterX,
      correctedEyeBotY,
      data.featureIds,
      data.customImage
    )
  }
}
