import React, {useRef} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import classNames from "classnames";
import queryString from 'query-string'
import LoadingBar from "react-top-loading-bar";
import Tooltip from "rc-tooltip";
import {Helmet} from "react-helmet";

import Table from "../../components/Table/Table";
import CellHeader from "../../components/CellHeader/CellHeader";
import Cell from "../../components/Cell/Cell";
import {Button} from "../../components/Button/Button";
import {DateTimeAgo} from "../../components/DateTimeAgo/DateTimeAgo";
import {SidebarContainer} from "../../components/SidebarContainer/SidebarContainer";
import {OrderSidebar} from "../../components/OrderSidebar/OrderSidebar";
import { TablePaginator } from "../../components/TablePaginator/TablePaginator";
import {ProductQuickInfo} from "../../components/ProductQuickInfo/ProductQuickInfo";
import {TableHeader} from "../../components/TableHeader/TableHeader";


import {Order, OrderStatusDisplayNames} from '../../models/Order.model'

import {useOrdersContext} from "../../contexts/orders.context";
import {useProductsContext} from "../../contexts/products.context";
import {usePatternsContext} from "../../contexts/patterns.context";

import ResendEmailPopup from "../../popups/ResendEmailPopup/ResendEmailPopup";
import {ShippingLabelPopup} from "../../popups/ShippingLabelPopup/ShippingLabelPopup";
import DeletePopup from "../../popups/DeletePopup/DeletePopup";

import {Meta, shouldRefetch} from '../../utils'
import {EmailIcon, Print, ReCropIcon, ShippingIcon, TrashCanIcon, XMarkCircleFill} from "../../toneIcons";
import {useQuery} from '../../hooks/useQuery'


import { SquareSelect } from "../../components/SquareSelect/SquareSelect";
import { parsePhoneNumber } from "libphonenumber-js";
import { Spacer } from "../../components/Spacer/Spacer";
import { SquareQuery } from "../../components/SquareQuery/SquareQuery";
import { FacePointsContainer } from "../FacePoints/FacePoints";

import styles from "./dashboard.module.scss";
import { CenterXSelfieContainer, RotateSelfieContainer } from "../../components/RotateSelfie/RotateSelfie";
import { FaceInteractorNav } from "../../components/FaceInteractorNav/FaceInteractorNav";
import { Page404 } from "../../components/Page404/Page404";
import { useFeaturesContext } from "../../contexts/features.context";

export const Dashboard = () => {
  const location = useLocation()
  const query = useQuery()

  const history = useHistory();
  const [{patternIds}, {fetchPatterns}] = usePatternsContext()
  const [{featureIds}, {fetchFeatures}] = useFeaturesContext()
  const [{orderIds, orderById}, {fetchOrder, fetchOrders, deleteOrder, updateOrderProduct, cropOrder, resendOrderConfirmation, resendBadSelfieEmail, resendNoSelfieEmail, createShippingLabel}] = useOrdersContext()
  const [{productIds}, {fetchProducts}] = useProductsContext()

  const selectedOrderId = query.get('orderId') || undefined
  const nextPageId = query.get('nextPageId') || undefined
  const prevPageId = query.get('prevPageId') || undefined
  const status = query.get('status') || undefined;
  const searchQuery = query.get('q') || undefined;
  const perPage = Number.parseInt(query.get('perPage') || "50")
  const selectedOrder = selectedOrderId ? orderById?.[selectedOrderId] : undefined
  const editProductId = query.get('editProductId') || undefined
  const editType = query.get('editType') || undefined

  const shouldFetchPatterns = shouldRefetch(patternIds)
  React.useEffect(() => {
    if(fetchPatterns && shouldFetchPatterns) { fetchPatterns(); }
  }, [fetchPatterns, shouldFetchPatterns])

  const shouldFetchProducts = shouldRefetch(productIds)
  React.useEffect(() => {
    if(fetchProducts && shouldFetchProducts) { fetchProducts(); }
  }, [fetchProducts, shouldFetchProducts])

  const [loading] = React.useState(false);
  const [orderToBeDeleted, setOrderToBeDeleted] = React.useState<Meta<Order> | undefined>();
  const [orderToBeEmailed, setOrderToBeEmailed] = React.useState<Meta<Order> | undefined>();
  const [orderToHaveShippingLabel, setOrderToHaveShippingLabel] = React.useState<Meta<Order> | undefined>();
  const [badSelfieToBeEmailed, setBadSelfieToBeEmailed] = React.useState<Meta<Order> | undefined>();
  const [noSelfieToBeEmailed, setNoSelfieToBeEmailed] = React.useState<Meta<Order> | undefined>();

  const ref = useRef(null);

  React.useEffect(() => {
    const loader: any = ref.current;
    if (loader) {
      if (loading) {
        loader.continuousStart();
      } else {
        loader.complete();
      }
    }
  }, [loading, ref]);

  const isQueryDifferent = orderIds.meta?.queryPrevPageId !== prevPageId
                     || orderIds.meta?.queryNextPageId !== nextPageId
                     || orderIds.meta?.perPage !== perPage
                     || orderIds.query?.status !== status
                     || orderIds.query?.query !== searchQuery
  const shouldRefetchData = shouldRefetch(orderIds) || (isQueryDifferent || orderIds.data == null)

  React.useEffect(() => {
    if(shouldRefetchData) {
      fetchOrders(searchQuery, status, perPage, prevPageId, nextPageId )
    }
  }, [fetchOrders, searchQuery, status, perPage, prevPageId, nextPageId, shouldRefetchData])

  React.useEffect(() => {
    if(fetchOrder && selectedOrderId) {
      fetchOrder(selectedOrderId)
    }
  }, [fetchOrder, selectedOrderId])

  const shouldFetchFeatures = shouldRefetch(featureIds)
  React.useEffect(() => {
    if(shouldFetchFeatures) {
      fetchFeatures()
    }
  }, [fetchFeatures, shouldFetchFeatures])


  const deleteConfirmationHandler = async () => {
    const id = orderToBeDeleted?.data?.id
    if(id != null) {
      await deleteOrder(id)

      const search = "?" + queryString.stringify({
        ...queryString.parse(location.search),
        orderId: undefined,
      })

      history.push({pathname: history.location.pathname, search})
      setOrderToBeDeleted(undefined)
    }
  };

  const orderToHaveShippingLabelHandler = async () => {
    const id = orderToHaveShippingLabel?.data?.id

    if(id != null) {
      await createShippingLabel(id);
      setOrderToHaveShippingLabel(undefined)
    }
  };

  const resendEmailConfirmationHandler = async () => {
    const id = orderToBeEmailed?.data?.id
    if (id != null) {
      await resendOrderConfirmation(id)
      setOrderToBeEmailed(undefined)
    }
  };

  const resendBadSelfieConfirmationHandler = async () => {
    const id = badSelfieToBeEmailed?.data?.id
    if (id != null) {
      await resendBadSelfieEmail(id)
      setBadSelfieToBeEmailed(undefined)
    }
  };

  const resendNoSelfieConfirmationHandler = async () => {

    const id = noSelfieToBeEmailed?.data?.id
    if (id != null) {
      await resendNoSelfieEmail(id)
      setNoSelfieToBeEmailed(undefined)
    }
  };

  const updateProduct = async (orderId: string, productId: string, data: { [key: string]: string }) => {
    // const updateObj = mapKeys(data, (_, key) => `products.${productId}.${key}`)
    await updateOrderProduct(orderId, productId, data)
  }

  const style = {
    '--sidepanel-width': (selectedOrderId) ? 'var(--sidepanel-width-opened)' : '0px'
  } as React.CSSProperties;
  const columns = "max-content min(150px) max-content max-content max-content minmax(max-content, 1fr) max-content max-content"

  const setStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const search = "?" + queryString.stringify({
      ...queryString.parse(location.search),
      orderId: undefined,
      status: e.target.value,
      nextPageId: undefined,
      prevPageId: undefined
    })

    history.push({pathname: history.location.pathname, search})
  }

  const closeSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    orderId: undefined,
  })

  const onSubmitQuery = (q: string | undefined) => {
    const search = "?" + queryString.stringify({
      ...queryString.parse(location.search),
      q
    })

    history.push({pathname: history.location.pathname, search: search})
  }

  const backSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    orderId: selectedOrderId,
    editProductId: undefined,
    editType: undefined,
  })

  return <>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
    <LoadingBar height={2} color='#2B98F0' ref={ref}/>
    <div style={style} className={styles.container}>
      <TableHeader>
        <SquareQuery value={searchQuery} onQuerySubmit={onSubmitQuery} />
        <Spacer />
        <SquareSelect value={status} onChange={setStatus} className={styles.selectLabel}>
          <option value="">All</option>
          {Object.entries(OrderStatusDisplayNames).map(([key, value]) =>
            <option value={key} key={key}>{value}</option>
          )}
        </SquareSelect>

        <TablePaginator nextPageId={orderIds.meta?.nextPageId} prevPageId={orderIds.meta?.prevPageId} perPage={perPage} />
      </TableHeader>

      <div className={styles.content}>
        {orderIds?.meta?.error && <Page404 msg={orderIds?.meta?.errorMsg} />}
        {orderIds.meta?.fetching && <div>Loading...</div>}
        {!orderIds.meta?.fetching && <Table columns={columns}>
          <CellHeader sticky>Details</CellHeader>
          <CellHeader>Posted On</CellHeader>
          <CellHeader>
            <span role="img" aria-label="computer or mobile">💻 / 📱</span>
          </CellHeader>
          <CellHeader>Items</CellHeader>
          <CellHeader>Actions</CellHeader>
          <CellHeader/>
          <CellHeader>Shipping</CellHeader>
          <CellHeader>Status</CellHeader>

          {
            orderIds?.data?.map((id, index) => {
              const order = orderById?.[id];
              if (order == null) return null;

              const search = "?" + queryString.stringify({
                ...queryString.parse(location.search),
                orderId: id
              })
              return <OrderCells index={index}
                                 order={order}
                                 key={id}
                                 cropOrder={cropOrder}
                                 onClickRow={() => history.push({pathname: location.pathname, search})}
                                 active={selectedOrderId === id}
                                 onDelete={setOrderToBeDeleted}
                                 emailOrderConfirmation={setOrderToBeEmailed}
                                 resendNoSelfieConfirmation={setNoSelfieToBeEmailed}
                                 emailBadSelfie={setBadSelfieToBeEmailed}
                                 onCreateShippingLabel={setOrderToHaveShippingLabel}
              />
            })
          }
        </Table>}
      </div>

      {selectedOrderId && <SidebarContainer updating={selectedOrder?.meta?.updating}>
        <OrderSidebar key={selectedOrderId}
                      data={selectedOrder}
                      updateProduct={updateProduct}
                      onClose={() => history.push({pathname: history.location.pathname, search: closeSearch})}/>
      </SidebarContainer>}
    </div>
    {orderToBeDeleted &&
    <DeletePopup onSubmit={deleteConfirmationHandler} onClose={() => setOrderToBeDeleted(undefined)}/>}
    {orderToBeEmailed &&
    <ResendEmailPopup onSubmit={resendEmailConfirmationHandler} onClose={() => setOrderToBeEmailed(undefined)}
                      text={"Resend order confirmation email"} order={orderToBeEmailed.data}/>}
    {badSelfieToBeEmailed &&
    <ResendEmailPopup onSubmit={resendBadSelfieConfirmationHandler} onClose={() => setBadSelfieToBeEmailed(undefined)}
                      text={"Resend bad selfies email"} order={badSelfieToBeEmailed.data}/>}
    {noSelfieToBeEmailed &&
    <ResendEmailPopup onSubmit={resendNoSelfieConfirmationHandler} onClose={() => setNoSelfieToBeEmailed(undefined)}
                      text={"Resend no selfies email"} order={noSelfieToBeEmailed.data}/>}

    {orderToHaveShippingLabel && <ShippingLabelPopup onSubmit={orderToHaveShippingLabelHandler}
                                                     onClose={() => setOrderToHaveShippingLabel(undefined)}
                                                     text={"Create shipping label"}
                                                     order={orderToHaveShippingLabel.data}/>}

    {selectedOrderId && editProductId && editType === "points" && <div className={styles.overlay}>
      <FacePointsContainer orderId={selectedOrderId} productId={editProductId} />
    </div>}

    {selectedOrderId && editProductId && editType != null && ['rotation', 'eyeBot', 'center'].indexOf(editType) >= 0 && <div className={styles.overlay}>
      <FaceInteractorNav  />
      <div className={styles.overlayContent}>
        {editType === 'rotation' && <RotateSelfieContainer orderId={selectedOrderId} productId={editProductId} />}
        {editType === 'center' && <CenterXSelfieContainer orderId={selectedOrderId} productId={editProductId} />}

        <Link className={styles.backButton} to={backSearch}>
          <XMarkCircleFill />
        </Link>
      </div>
    </div>}
  </>;
};

interface OrderCellsProps extends React.HTMLAttributes<HTMLDivElement> {
  index: number,
  order: Meta<Order>,
  onClickRow: any,
  active: boolean,
  onDelete: any,
  cropOrder: any,
  emailOrderConfirmation: React.Dispatch<React.SetStateAction<Meta<Order> | undefined>>,
  onCreateShippingLabel: React.Dispatch<React.SetStateAction<Meta<Order> | undefined>>,
  resendNoSelfieConfirmation: React.Dispatch<React.SetStateAction<Meta<Order> | undefined>>,
  emailBadSelfie: React.Dispatch<React.SetStateAction<Meta<Order> | undefined>>,
}


const OrderCells: React.FC<OrderCellsProps> = ({index, order, onClickRow, active, onDelete, cropOrder, emailOrderConfirmation, resendNoSelfieConfirmation, emailBadSelfie, onCreateShippingLabel}) => {
  const [{productById}] = useProductsContext()

  const props = {
    index: index,
    active: active,
    deleting: order.meta?.deleting,
    onClick: () => onClickRow(),
    className: styles.pointer
  }

  const _onDelete = (e: any) => {
    e.stopPropagation();
    onDelete(order)
  }

  const _cropOrder = (e: any) => {
    e.stopPropagation();
    cropOrder(order.data?.id)
  }

  const _emailOrderConfirmation = (e: any) => {
    e.stopPropagation();
    emailOrderConfirmation(order)
  }

  const _resendNoSelfieConfirmation = (e: any) => {
    e.stopPropagation();
    resendNoSelfieConfirmation(order)
  }

  const _emailBadSelfie = (e: any) => {
    e.stopPropagation();
    emailBadSelfie(order)
  }

  const _confirmShipment = (e: any) => {
    e.stopPropagation();
    onCreateShippingLabel(order)
  }

  const date = order.data?.createdOn
  const productsArray = order.data?.products ? Object.values(order.data?.products) : []

  const containsGiftCard = !!productsArray.find((p) => {
    const mask = p.maskId ? productById?.[p.maskId] : undefined
    return mask?.data?.categoryId === "giftCard"
  })
  const phoneNumber = (order.data?.phone && order.data?.shipping?.countryCode) && parsePhoneNumber(order.data.phone, order.data?.shipping?.countryCode)

  const canHaveReceipt = (order?.data?.id && ["accepted", "printed", "shipped", "packaged"].indexOf(order.data?.status) > 0) || containsGiftCard

  const hasOnlyGiftCards = productsArray.find(p => p.categoryId !== "giftCard") == null

  return <>
    <Cell sticky {...props}>
      <div className={styles.columnLayout}>
        <div className={styles.orderName}>{order.data?.name}</div>
        {order.data?.email && <a href={`mailto:${order.data?.email}`}>{order.data?.email}</a>}
        {phoneNumber && <div>{phoneNumber?.formatInternational()}</div>}
        <div>{order.data?.id}</div>

        {
          (order?.data?.codes || []).join(", ")
        }
        {order.data?.promoCode && <div>{order.data?.promoCode}</div>}
      </div>
    </Cell>
    <Cell {...props}>
      {date && <DateTimeAgo date={date}/>}
    </Cell>
    <Cell {...props}>
      {order.data?.isWebOrder ? "💻" : "📱"}
    </Cell>
    <Cell {...props}>
      <div className={styles.products}>
        {productsArray.map((product, index) => <ProductQuickInfo className={styles.product} orderProduct={product}
                                                                 key={index} hideTitles/>)}
      </div>
    </Cell>
    <Cell {...props} className={styles.actions}>
      {
        (order.data?.status === "printed" || order.data?.status === "shipped" || order.data?.status === "packaged")
          && !hasOnlyGiftCards
          && <Button className={styles.actionBtn} onClick={_confirmShipment} title="Create shipping label">
          <ShippingIcon/>
        </Button>
      }

      {
        canHaveReceipt && <Tooltip placement="top" overlay="Create Receipt">
          <Link className={styles.actionBtn} to={`/admin/receipt/?orderId=${order?.data?.id}`} rel="noopener noreferrer" onClick={e => e.stopPropagation()} target="_blank">
            <Print />
          </Link>
        </Tooltip>
      }

      {
        order.data?.status === "new" && <Button className={styles.actionBtn} onClick={_cropOrder} title="re-crop">
          <ReCropIcon/>
        </Button>
      }

      {order.data?.status === "new" && <ButtonWithIcon onClick={_emailOrderConfirmation} icon={<EmailIcon/>} text="Order Confirmation" />}
      {order.data?.status === "waitingOnImages" &&
      <ButtonWithIcon onClick={_resendNoSelfieConfirmation} icon={<EmailIcon/>} text="Ask for selfies"/>}
      {order.data?.status === "resubmissionRequested" &&
      <ButtonWithIcon onClick={_emailBadSelfie} icon={<EmailIcon/>} text="Bad selfie"/>}

      <Button className={classNames(styles.actionBtn)} onClick={_onDelete} title="Delete">
        <TrashCanIcon className={styles.actionItemIcon}/>
      </Button>
    </Cell>

    <Cell {...props} />

    <Cell {...props}>
      <div className={styles.columnLayout}>

        <div>
          {order.data?.shipmentStatus || ""}
        </div>

        {
          order.data?.shippoError && <div className={styles.error}>
            {order.data?.shippoError}
          </div>
        }

        {
          order.data?.shippoTransaction?.label_url && <div>
            <a href={order.data?.shippoTransaction?.label_url} target="_blank" rel="noopener noreferrer">Shipping Label</a>
          </div>
        }

        {
          order.data?.shippoTransaction?.tracking_url_provider && <div>
            <a href={order.data?.shippoTransaction?.tracking_url_provider} target="_blank" rel="noopener noreferrer">Track Package</a>
          </div>
        }
      </div>

    </Cell>

    <Cell {...props} className={styles[order.data?.status || ""]}>
      {order.data?.status}
    </Cell>
  </>
};


interface ButtonWithIconProps extends React.ButtonHTMLAttributes<HTMLButtonElement>  {
  text: string,
  icon: any
}
const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({icon, text, onClick}) => {
  return <Button className={styles.actionBtn} onClick={onClick}>
    {icon}
    {text}
  </Button>
}
