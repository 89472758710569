import React, { useReducer } from 'react'
import toast from 'react-hot-toast';

import { reducer, initialState, ContextPropsState } from './features.reducer'

import {featuresService} from "../services/features.service";
import { Feature } from '../models/Feature.model'


type ContextPropsActions = {
  fetchFeature: (id: string) => void,
  fetchFeatures: () => void,
  updateFeature: (id: string, data: Partial<Feature>) => void,
  createFeature: (data:any) => void,
  deleteFeature: (id: string) => void,
  addImage: (id: string, file: File) => void,
  deleteImage: (featureId: string, imageId: string) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchFeature: () => { throw new Error("fetchFeature not implemented") },
  fetchFeatures: () => { throw new Error("fetchFeatures not implemented") },
  updateFeature: () => { throw new Error("updateFeature not implemented") },
  createFeature: () => { throw new Error("createFeature not implemented") },
  deleteFeature: () => { throw new Error("createFeature not implemented") },
  addImage: () => { throw new Error("addImage not implemented") },
  deleteImage: () =>  { throw new Error("deleteImage not implemented") },
}

export const FeaturesContext = React.createContext<ContextProps>([initialState, initialActions]);

export const FeaturesProvider = ({children}: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchFeature = React.useCallback(async (id: string) => {
    try {
      dispatch({type: "FETCH_FEATURE", id})
      const feature = await featuresService.fetch(id)
      dispatch({type: "FETCH_FEATURE_SUCCESS", id, data: feature})
    } catch(error) {
      dispatch({type: "FETCH_FEATURE_ERROR", id, error})
    }
  }, [])

  const fetchFeatures = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_FEATURES"})
      const features = await featuresService.fetchAll()
      dispatch({type: "FETCH_ALL_FEATURES_SUCCESS", data: features})
    } catch(error) {
      dispatch({type: "FETCH_ALL_FEATURES_ERROR", error})
    }
  }, [])

  const updateFeature = React.useCallback(async (id, data) => {
    try {
      dispatch({type: "UPDATE_FEATURE", id})
      const newFeature = await featuresService.update(id, data)
      dispatch({type: "UPDATE_FEATURE_SUCCESS", id, data: newFeature})
    } catch(error) {
      dispatch({type: "UPDATE_FEATURE_ERROR", id, error})
    }
  }, [])

  const createFeature = React.useCallback(async (data) => {
    try {
      dispatch({type: "CREATE_FEATURE"})
      const feature = await featuresService.create(data)
      dispatch({type: "CREATE_FEATURE_SUCCESS", data: feature})
    } catch(error) {
      dispatch({type: "CREATE_FEATURE_ERROR", error})
    }
  }, [])

  const deleteFeature = React.useCallback(async (id) => {
    try {
      dispatch({type: "DELETE_FEATURE", id})
      await featuresService.delete(id)
      dispatch({type: "DELETE_FEATURE_SUCCESS", id})
    } catch(error) {
      dispatch({type: "DELETE_FEATURE_ERROR", id, error})
    }
  }, [])

  const addImage = React.useCallback(async (id, file) => {
    try {
      dispatch({type: "UPDATE_FEATURE", id})
      const data = await featuresService.uploadFile(id, file)
      dispatch({type: "UPDATE_FEATURE_SUCCESS", id, data})
    } catch(error) {
      dispatch({type: "UPDATE_FEATURE_ERROR", id, error})
      toast.error(`error adding file to product ${id}`)
    }
  }, [])

  const deleteImage = React.useCallback(async (id, imageId) => {
    try {
      dispatch({type: "UPDATE_FEATURE", id})
      const data = await featuresService.deleteImage(id, imageId)
      dispatch({type: "UPDATE_FEATURE_SUCCESS", id, data})
    } catch(error) {
      dispatch({type: "UPDATE_FEATURE_ERROR", id, error})
      toast.error(`error adding file to product ${id}`)
    }
  }, [])

  const value:ContextProps = [state, {
    fetchFeature,
    fetchFeatures,
    updateFeature,
    createFeature,
    deleteFeature,
    addImage,
    deleteImage,
  }]

  return <FeaturesContext.Provider value={value}>
    {children}
  </FeaturesContext.Provider>
}

export const useFeaturesContext = () => React.useContext(FeaturesContext)