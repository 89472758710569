import React from 'react'
import {Helmet} from "react-helmet";

import Table from '../../components/Table/Table';
import CellHeader from '../../components/CellHeader/CellHeader';
import {formatPrice, Meta} from '../../utils';

import styles from './ReferralsPage.module.scss'
import {Page404} from '../../components/Page404/Page404';
import {useReferralsContext} from "../../contexts/referrals.context";
import {Referral} from "../../models/referral";
import {Cell} from '../../components/Cell/Cell';
import {Link} from "react-router-dom";
import {TimeAgo} from "../../components/DateTimeAgo/DateTimeAgo";
import classNames from "classnames";

export const ReferralsPage = () => {
  const [{referralByCode, referralCodes}, {fetchReferrals}] = useReferralsContext();
  const totalNoOfReferralCodes = referralCodes?.data?.length;

  React.useEffect(() => {
    if (!totalNoOfReferralCodes) {
      fetchReferrals();
    }
  }, [fetchReferrals, totalNoOfReferralCodes]);


  const columns = "max-content max-content max-content max-content max-content max-content minmax(max-content, 1fr)"

  return <div className={styles.container}>
    <Helmet>
      <title>Referrals - FriendlyFace</title>
    </Helmet>
    {referralCodes.meta?.error && <Page404 msg={referralCodes?.meta?.errorMsg}/>}
    <div className={styles.content}>
      <Table columns={columns}>
        <CellHeader sticky>Code</CellHeader>
        <CellHeader>Owner Email</CellHeader>
        <CellHeader>Current Credits</CellHeader>
        <CellHeader>Total credits earned</CellHeader>
        <CellHeader>Purchased In orderId</CellHeader>
        <CellHeader>Credits Earn History</CellHeader>
        <CellHeader>Credits Consumption History</CellHeader>
        {referralCodes?.data?.map((code, index) => {
          const referralMeta = referralByCode?.[code];
          if (referralMeta == null) return null;
          return <Cells index={index} data={referralMeta} key={code}/>
        })}
      </Table>
    </div>
  </div>
}


interface Props extends React.HTMLAttributes<HTMLDivElement> {
  index: number
  data: Meta<Referral>,
}

const Cells: React.FC<Props> = ({index, data}) => {
  const referral = data?.data;

  const props = {
    index: index,
    deleting: data.meta?.deleting,
    className: classNames(styles.pointer, styles.columns, referral?.currentCredits != null && referral?.currentCredits <= 0 && styles.faded)
  }

  return <>
    <Cell sticky {...props}>{referral?.code}</Cell>
    <Cell {...props}>{referral?.ownerEmail}</Cell>
    <Cell {...props}>${formatPrice(referral?.currentCredits)}</Cell>
    <Cell {...props}>${formatPrice(referral?.totalCreditsEarned)}</Cell>
    <Cell {...props}>{referral?.purchasedInOrder}</Cell>
    <Cell {...props}>
      {
        referral?.creditsEarnHistory.map((history) => {
          return <div className={styles.usageArea} key={history.orderId}>
            <p><b>Order Id: </b> <Link to={`/admin?q=${history.orderId}`}>{history.orderId}</Link></p>
            <p><b>Referral Email: </b> {history.email}</p>
            <p><b>Credits Earned: </b> <span>${formatPrice(history.creditsEarned)}</span></p>
            <p><b>When: </b> <TimeAgo date={history.timestamp.toDate()}/></p>
          </div>
        })
      }
    </Cell>
    <Cell {...props}>
      {
        referral?.creditsConsumptionHistory.map((history) => {
          return <div className={styles.usageArea} key={history.orderId}>
            <p><b>Order Id: </b> <Link to={`/admin?q=${history.orderId}`}>{history.orderId}</Link></p>
            <p><b>Credits Earned: </b> <span>${formatPrice(history.creditsConsumed)}</span></p>
            <p><b>When: </b> <TimeAgo date={history.timestamp.toDate()}/></p>
          </div>
        })
      }
    </Cell>
  </>
};
