import React from 'react';
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import {Helmet} from "react-helmet";

import {Dashboard} from "../../pages/dashboard/dashboard";
import {PromoCodes} from '../../pages/PromoCodes/PromoCodes'
import {PrintGroups} from '../../pages/PrintGroups/PrintGroups'
import {Patterns} from '../../pages/Patterns/Patterns'
import {Products} from '../../pages/Products/Products';
import {ReceiptPage} from "../../pages/ReceiptPage/ReceiptPage";
import {AddOrder} from '../../pages/AddOrder/AddOrder';
import {Features} from '../../pages/Features/Features';

import {PatternsProvider} from '../../contexts/patterns.context'
import {OrdersProvider} from '../../contexts/orders.context'
import {PrintGroupsProvider} from '../../contexts/printGroups.context';
import {ProductsProvider} from '../../contexts/products.context';
import {PromoCodesProvider} from '../../contexts/promoCodes.context';
import {LeftNav, useLeftNavOpened} from '../LeftNav/LeftNav';
import {Verifier} from '../Verifier/Verifier';

import {Reviews} from "../../pages/Reviews/Reviews";
import {ReviewsProvider} from "../../contexts/reviews.context";
import {GiftCardsPage} from "../../pages/GiftCardsPage/GiftCardsPage"
import {GiftCardsProvider} from "../../contexts/giftCard.context"

import styles from './Admin.module.scss'
import {ToastProvider} from '../../contexts/toast.context';
import {FeaturesProvider} from '../../contexts/features.context';
import {ReferralsPage} from "../../pages/ReferralsPage/ReferralsPage";
import {ReferralsProvider} from "../../contexts/referrals.context";

const Providers: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = ({children}) => {
  return <ToastProvider>
    <FeaturesProvider>
      <OrdersProvider>
        <PatternsProvider>
          <ProductsProvider>
            <PromoCodesProvider>
              <PrintGroupsProvider>
                <ReviewsProvider>
                  <GiftCardsProvider>
                    <ReferralsProvider>
                      {children}
                    </ReferralsProvider>
                  </GiftCardsProvider>
                </ReviewsProvider>
              </PrintGroupsProvider>
            </PromoCodesProvider>
          </ProductsProvider>
        </PatternsProvider>
      </OrdersProvider>
    </FeaturesProvider>
  </ToastProvider>
}

interface AminProps extends RouteComponentProps {

}

export const Admin: React.FC<AminProps> = ({match}) => {
  const [isLeftNavOpened] = useLeftNavOpened()

  const classList = [
    styles.content,
    isLeftNavOpened ? styles.opened : undefined,
  ].join(" ")

  return <Providers>
    <Helmet>
      <title>Admin - FriendlyFace</title>
    </Helmet>
    <LeftNav />
    <div className={classList}>
      <Switch>
        <Route path={`${match.path}/`} exact>
          <Dashboard />
        </Route>
        <Route path={`${match.path}/receipt`} exact>
          <ReceiptPage />
        </Route>
        <Route path={`${match.path}/printGroups`} exact>
          <PrintGroups/>
        </Route>
        <Route path={`${match.path}/patterns`} exact>
          <Patterns/>
        </Route>
        <Route path={`${match.path}/products`} exact>
          <Products/>
        </Route>
        <Route path={`${match.path}/reviews`} exact>
          <Reviews/>
        </Route>
        <Route path={`${match.path}/gift-cards`} exact>
          <GiftCardsPage/>
        </Route>
        <Route path={`${match.path}/addOrder`}>
          <AddOrder/>
        </Route>
        <Route path={`${match.path}/promoCodes`} exact>
          <PromoCodes/>
        </Route>

        <Route path={`${match.path}/referrals`} exact>
          <ReferralsPage/>
        </Route>

        <Route path={`${match.path}/features`} exact>
          <Features/>
        </Route>

        <Route path={`${match.path}/verifier`}>
          <Verifier/>
        </Route>

        <Route>
          <Redirect from='*' to={`${match.path}`}/>
        </Route>
      </Switch>
    </div>
  </Providers>
}
