import React from "react";
import classNames from "classnames";

import styles from "./Price.module.scss";
interface Props {
  className?: string;
  cut?: boolean;
  prefix?: string;
}

export const Price: React.FC<Props> = ({children, className, cut, prefix}) => {
  return <span className={classNames(styles.container, cut && styles.cut, className)}>
    <span className={styles.prefix}>{prefix}</span>
    <sup className={styles.super}>$</sup>
    {children}
  </span>
};