import React from 'react'
import { Card } from '../../components/Card/Card'

import styles from './AddOrder.module.scss'


export const AddOrder = () => {
  return <div className={styles.container}>
    AddOrder
    <h4>Shipping</h4>
    <Card>
      <div>HERE</div>
    </Card>
  </div>
}