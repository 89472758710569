import React from 'react'
import Tooltip from 'rc-tooltip'

import { usePatternsContext } from '../../contexts/patterns.context'
import { RoundedIconText } from '../RoundedIconText/RoundedIconText'
import { useProductsContext } from '../../contexts/products.context'

import {EnhanceIcon, WarningIcon2} from '../../toneIcons'
import { OrderProduct } from '../../models/Order.model'
import PatternPopup from '../../popups/PatternPopup/PatternPopup'
import RetouchPopup from '../../popups/RetouchPopup/RetouchPopup'
import MaskSizePopup from '../../popups/MaskSizePopup/MaskSizePopup'

import styles from './ProductQuickInfo.module.scss'
import FeaturesPopup from '../../popups/FeaturesPopup/FeaturesPopup'
import { useFeaturesContext } from '../../contexts/features.context'

interface Props {
  className?: string
  orderProduct: OrderProduct;
  hideTitles?: boolean;
  onUpdate?: (data: {[key: string]: string | number | undefined | null | string[]}) => void
}

export const ProductQuickInfo: React.FC<Props> = ({orderProduct, className, hideTitles, onUpdate}) => {
  const [{patternById}] = usePatternsContext()
  const [{productById}] = useProductsContext()
  const [{featureById}] = useFeaturesContext()
  const [changing, setChanging] = React.useState<'pattern' | 'enhance' | 'size' | "features" | undefined>();

  const selectedPattern = orderProduct?.patternId ? patternById[orderProduct?.patternId] : undefined
  const product = orderProduct.maskId ? productById?.[orderProduct.maskId] : undefined

  const onSelectPattern = (patternId: string) => {
    onUpdate?.({patternId})
    setChanging(undefined)
  }

  const onSelectEnhance = (enhance: any) => {
    onUpdate?.({enhance})
    setChanging(undefined)
  }
  const onSelectSize = (maskId: string) => {
    onUpdate?.({maskId})
    setChanging(undefined)
  }

  const onSelectFeatures = (featureIds: string[] | undefined) => {
    onUpdate?.({featureIds})
    setChanging(undefined)
  }

  const editable = onUpdate != null;

  const features = product?.data?.featureIds?.map(id => featureById?.[id]);
  const canHavePattern = features?.find(f => f?.data?.slug === 'patterns') != null
  const canHaveFace = features?.find(f => f?.data?.slug === 'selfie') != null

  return <div className={[styles.container, className].join(' ')}>
    {canHavePattern && <RoundedIconText title={hideTitles ? undefined : selectedPattern?.data?.name || "None"}
      src={selectedPattern?.data?.tileUrl}
      background={selectedPattern?.data?.hex}
      className={styles.box}
      onClick={editable ? () => setChanging('pattern') : undefined} />}
    <RoundedIconText title={hideTitles ? undefined : product?.data?.shortName || "None"}
      src={product?.data?.url}
      className={styles.box}
      onClick={editable ? () => setChanging('size') : undefined} />

    {canHaveFace && <>
      {orderProduct.enhance && <RoundedIconText title={hideTitles ? undefined : "Retouching"} className={styles.box} onClick={editable ? () => setChanging('enhance') : undefined} >
        <div className={styles.enhancingBox}><EnhanceIcon /></div>
      </RoundedIconText>}
      {!orderProduct.enhance && <RoundedIconText title={hideTitles ? undefined : "Retouching"} className={styles.box} text="NO" onClick={editable ? () => setChanging('enhance') : undefined}  />}
    </>}

    {!hideTitles && <RoundedIconText title={hideTitles ? undefined : "Features"} className={styles.box} onClick={editable ? () => setChanging('features') : undefined} >
      <div className={styles.enhancingBox}>F</div>
    </RoundedIconText>}

    {orderProduct.combinedError && <RoundedIconText className={styles.box} title={hideTitles ? undefined : orderProduct.status}>
      <Tooltip placement="top" overlay={orderProduct.combinedError}>
        <div className={styles.warning}><WarningIcon2 /></div>
      </Tooltip>
    </RoundedIconText>}

    {editable && changing === 'pattern' && <PatternPopup onClose={() => setChanging(undefined)} onSubmit={ onSelectPattern} selectedPatternId={orderProduct.patternId} />}
    {editable && changing === 'enhance' && <RetouchPopup onClose={() => setChanging(undefined)} onSubmit={ onSelectEnhance} selectedRetouch={orderProduct.enhance} />}
    {editable && changing === 'size' && <MaskSizePopup onClose={() => setChanging(undefined)} onSubmit={ onSelectSize} selectedMaskId={orderProduct.maskId} selectedProductType={orderProduct.productType} />}
    {editable && changing === 'features' && <FeaturesPopup onClose={() => setChanging(undefined)} onSubmit={ onSelectFeatures} orderProduct={orderProduct} />}
  </div>
}