import React from 'react'

import { OrderShipping } from '../../models/Order.model'

import { InfoCard, InfoCardHeader, InfoCardRow } from '../InfoCard/InfoCard'

interface ShippingBillingInfoProps  extends React.HTMLAttributes<HTMLDivElement> {
  shipping: OrderShipping | undefined,
  billing: OrderShipping | undefined,
}

export const ShippingBillingInfo: React.FC<ShippingBillingInfoProps> = ({shipping, billing}) => {
  let isBillingDifferent = false
  if(shipping?.line1 !== billing?.line1) isBillingDifferent = true
  if(shipping?.line2 !== billing?.line2) isBillingDifferent = true
  if(shipping?.city !== billing?.city) isBillingDifferent = true
  if(shipping?.state !== billing?.state) isBillingDifferent = true
  if(shipping?.postal_code !== billing?.postal_code) isBillingDifferent = true
  if(shipping?.country !== billing?.country) isBillingDifferent = true

  return <>
    <InfoCard>
      {isBillingDifferent && <InfoCardHeader>SHIPPING ADDRESS</InfoCardHeader>}
      {!isBillingDifferent && <InfoCardHeader>SHIPPING/BILLING ADDRESS</InfoCardHeader>}
      <InfoCardRow text="Line 1" value={shipping?.line1} hideIfNullOrEmptyValue />
      <InfoCardRow text="Line 2" value={shipping?.line2} hideIfNullOrEmptyValue />
      <InfoCardRow text="City" value={shipping?.city} hideIfNullOrEmptyValue />
      <InfoCardRow text="State" value={shipping?.state} hideIfNullOrEmptyValue />
      <InfoCardRow text="Zip" value={shipping?.postal_code} hideIfNullOrEmptyValue />
      <InfoCardRow text="Country" value={shipping?.country} hideIfNullOrEmptyValue />
    </InfoCard>
    {isBillingDifferent && <InfoCard>
      <InfoCardHeader>BILLING ADDRESS</InfoCardHeader>
      <InfoCardRow text="Line 1" value={billing?.line1} hideIfNullOrEmptyValue />
      <InfoCardRow text="Line 2" value={billing?.line2} hideIfNullOrEmptyValue />
      <InfoCardRow text="City" value={billing?.city} hideIfNullOrEmptyValue />
      <InfoCardRow text="State" value={billing?.state} hideIfNullOrEmptyValue />
      <InfoCardRow text="Zip" value={billing?.postal_code} hideIfNullOrEmptyValue />
      <InfoCardRow text="Country" value={billing?.country} hideIfNullOrEmptyValue />
    </InfoCard>}
  </>
}


export default ShippingBillingInfo;