import React from "react";

import {Popup} from "../../components/Popup/Popup";
import {WarningIcon} from "../../toneIcons";
import {Order} from "../../models/Order.model";

import styles from "./ShippingLabelPopup.module.scss";
interface Props {
  text: string
  order?: Order
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: React.MouseEventHandler;
}


export const ShippingLabelPopup: React.FC<Props> = ({text, order, onClose, onSubmit, children}) => {
  const labelUrl = order?.shippoTransaction?.label_url;

  return <Popup icon={<WarningIcon className={styles.warningIcon}/>} onClose={onClose}>

    {
      labelUrl ?
        <h3 className={styles.heading}>A <a href={labelUrl} target="_blank" rel="noopener noreferrer">Shipping Label</a> was already created for
          this order.</h3>
        :
        <h3 className={styles.heading}>A Shipping Label would be created for this order.</h3>
    }

    <div className={styles.tasks}>
      <p><span role="img" aria-label="check mark button">✅</span> Shipping label would be created</p>
      <p><span role="img" aria-label="no entry emoji">⛔</span> Shipping email would NOT be sent</p>

    </div>

    {onSubmit != null &&
    <div className={styles.confirmationButton} onClick={onSubmit}>Create Label</div>}
  </Popup>;
};
