import React from 'react'
import { Popup } from "../../components/Popup/Popup";

import { Input } from '../../components/Input/Input';
import { WarningIcon } from "../../toneIcons";
import { useForm } from '../../hooks/useForm';

import styles from './UnsurePhotoPopup.module.scss';
interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (data: any) => void;
}

export const UnsurePhotoPopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const {values, onDebounceChange} = useForm({
    usureText: ""
  })

  return <Popup icon={<WarningIcon className={styles.warningIcon} />}  onClose={onClose}>
    <div>
      <Input name="usureText" placeholder="Why?" title="Why?" onChange={onDebounceChange} value={values.usureText} />
    </div>

    {onSubmit != null && <div className={styles.confirmationButton} onClick={() => onSubmit(values)}>Yes, I'm unsure</div>}
  </Popup>
};

export default UnsurePhotoPopup;



export const ReprintPhotoPopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const {values, onDebounceChange} = useForm({
    usureText: ""
  })

  return <Popup icon={<WarningIcon className={styles.warningIcon} />}  onClose={onClose}>
    <div>
      <Input name="usureText" placeholder="Why?" title="Why?" onChange={onDebounceChange} value={values.usureText} />
    </div>

    {onSubmit != null && <div className={styles.confirmationButton} onClick={() => onSubmit(values)}>Yes, Re-print</div>}
  </Popup>
};