import React from "react";
import styles from "./GiftCardForm.module.scss";
import {useGiftCardsContext} from "../../../contexts/giftCard.context"
import {GiftCardWId} from "../../../models/GiftCard.model"

interface Props {
  giftCard?: GiftCardWId
}

export const CheckboxFields: React.FC<Props> = ({giftCard}) => {
  const [, {updateGiftCard}] = useGiftCardsContext()

  if (!giftCard) return null

  const onChangeExpired = async (e: any) => {
    await updateGiftCard(giftCard.id, {expired: e.target.checked})
  }

  const onChangeShipped = async (e: any) => {
    if (giftCard.type === "classic") {
      await updateGiftCard(giftCard.id, {shipped: e.target.checked})
    }
  }

  const onChangeExcludePromo = async (e: any) => {
    await updateGiftCard(giftCard.id, {excludePromo: e.target.checked})
  }

  return <>
    <div className={styles.checkboxRow}>
      <label>
        Expired
        <input type="checkbox" onChange={onChangeExpired} checked={giftCard.expired}/>
      </label>
    </div>

    {
      giftCard.type === "classic" && <div className={styles.checkboxRow}>
        <label>
          Shipped
          <input type="checkbox" onChange={onChangeShipped} checked={giftCard.shipped}/>
        </label>
      </div>
    }

    {
      <div className={styles.checkboxRow}>
        <label>
          Exclude Promo
          <input type="checkbox" onChange={onChangeExcludePromo} checked={giftCard.excludePromo}/>
        </label>
      </div>
    }
  </>
};
