import React from "react";
import classNames from "classnames"
import moment from "moment"

import {Order} from "../../../models/Order.model"
import {Textarea} from "../../Input/Input"
import {Button} from "../../Button/Button"
import {useOrdersContext} from "../../../contexts/orders.context"

import styles from "./MessagingSection.module.scss";
interface Props {
  order: Order
}

export const MessagingSection: React.FC<Props> = ({order}) => {
  const [, {sendTextMessage}] = useOrdersContext()
  const [messageError, setMessageError] = React.useState("");
  const [messageText, setMessageText] = React.useState("");

  const submitHandler = (e: any) => {
    e.preventDefault();

    if (!messageText) {
      setMessageError("This can't be empty broooo!")
      return;
    }

    order.phone && sendTextMessage(
      order.id,
      order?.phone,
      messageText
    ).then(() => {
      setMessageText("")
      setMessageError("")
    }).catch(r => {
      setMessageError(r.error || r.message)
    })
  }

  const sortedMessages = Object.entries(order.messages || {}).sort((obj1, obj2) => {
    const bdate = obj2[1].timestamp
    const adate = obj1[1].timestamp
    return adate - bdate
  })

  const textChangeHandler = (e: any) => {
    setMessageError("")
    setMessageText(e.target.value)
  }

  const setDefaultText = () => {
    setMessageText(`Hi ${order.name}\n - FriendlyFace team here. You ordered from us a while ago, but we requested a better image to get you a high quality mask. We don’t like shipping products that aren’t high quality, but we do want to get you what you purchased. So we have three options available to proceed. Please reply, letting us know which you would like.\n\n
1. We print the mask with the chosen pattern, without the selfie
2. You send an image that fits our guidelines in the next 3 days so we can ship you a fantastic mask
3. We can proceed with what we have, but please know that it may not be a great match to your face\n
Please reply with options 1, 2, or 3. If we don’t hear back from you in three days, we’ll go with option 1.\n
Thank you and have a lovely day!`);
  }

  return <div className={styles.container}>
    {
      order.phone ?
        <details>
          <summary>Messaging</summary>
          {
            <form onSubmit={submitHandler} className={styles.form}>
              <div className={styles.row}>
                <label>New Message:</label>
                <label className={styles.textBtn} onClick={setDefaultText}>Set Default</label>
              </div>
              <Textarea onChange={textChangeHandler}
                        value={messageText}
                        rows={5}
                        title="New Message"
              />
              <span className={styles.error}>{messageError}</span>
              <Button type={"submit"}>Send</Button>
            </form>
          }

          <div className={styles.lastMessageDetails}>
            {
              sortedMessages.map(([ssid, message]) => {
                const classes = classNames(styles.message, message.sender === "us" ? styles.messageSent : styles.messageReceived)

                return <div className={classes} key={ssid}>
                  {message.body}
                  <span className={styles.metadata}>
                    {message.status} {moment(message.timestamp).fromNow()}
                  </span>
                </div>
              })
            }
          </div>

        </details>
        : <span>Phone number not specified</span>
    }
  </div>
};
