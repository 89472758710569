import React from 'react'

import { printGroupsService } from '../services/printGroups.service'
import {ContextPropsState, initialState, reducer} from './printGroups.reducer'

type ContextPropsActions = {
  fetchPrintGroups: () => void
  closeGroup: (id: string) => void,
  openGroup: (id: string) => void,
  genImage: (id: string) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchPrintGroups: () => { throw new Error("closeGroup not implemented") },
  closeGroup: () => { throw new Error("closeGroup not implemented") },
  openGroup: () => { throw new Error("openGroup not implemented") },
  genImage: () => { throw new Error("genImage not implemented") },
}

export const PrintGroupsContext = React.createContext<ContextProps>([initialState, initialActions]);

export const PrintGroupsProvider = ({children}: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const closeGroup = React.useCallback(async (id) => {
    try {
      dispatch({type: "UPDATE_PRINTGROUP", id})
      const newGroup = await printGroupsService.closeGroup(id)
      dispatch({type: "UPDATE_PRINTGROUP_SUCCESS", id, data: newGroup})
    } catch(error) {
      dispatch({type: "UPDATE_PRINTGROUP_ERROR", id, error})
    }
  }, [])

  const openGroup = React.useCallback(async (id) => {
    try {
      dispatch({type: "UPDATE_PRINTGROUP", id})
      const newGroup = await printGroupsService.openGroup(id)
      dispatch({type: "UPDATE_PRINTGROUP_SUCCESS", id, data: newGroup})
    } catch(error) {
      dispatch({type: "UPDATE_PRINTGROUP_ERROR", id, error})
    }

  }, [])

  const genImage = React.useCallback(async (id) => {
    try {
      dispatch({type: "UPDATE_PRINTGROUP", id})
      const newGroup = await printGroupsService.genPrintGroupAsync(id);
      dispatch({type: "UPDATE_PRINTGROUP_SUCCESS", id, data: newGroup})
    } catch(error) {
      dispatch({type: "UPDATE_PRINTGROUP_ERROR", id, error})
    }

  }, [])

  const fetchPrintGroups = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_PRINTGROUPS"})
      const printGroups = await printGroupsService.getAll()
      dispatch({type: "FETCH_ALL_PRINTGROUPS_SUCCESS", data: printGroups})
    } catch(error) {
      dispatch({type: "FETCH_ALL_PRINTGROUPS_ERROR", error})
    }
  }, [])

  const value:ContextProps = [state, {
    fetchPrintGroups,
    closeGroup,
    openGroup,
    genImage
  }]

  return <PrintGroupsContext.Provider value={value}>
    {children}
  </PrintGroupsContext.Provider>
}

export const usePrintGroupsContext = () => React.useContext(PrintGroupsContext)