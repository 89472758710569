import React from 'react'
import Tooltip from 'rc-tooltip';
import { NavLink } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage';

import {TogglePanel, Box, Print, Pattern, Mask, Coupon, Verifier, Taxonomy, StarFill, GiftCard, ReferralCode} from '../../toneIcons'

import styles from './LeftNav.module.scss'
import { Scrollable } from '../Scrollable/Scrollable';

interface RowProps {
  icon?: JSX.Element,
  text: string,
  to?: any,
  exact?: boolean
}

const Row: React.FC<RowProps> = ({icon, text, exact, to}) => {
  let TagName: (keyof JSX.IntrinsicElements | any) = "div";
  let props:any = {}
  if(to != null) {
    TagName = NavLink
    props.to = to;
    props.activeClassName = styles.active;
    props.exact = exact;
  }

  if(text?.length > 0) {
    return <Tooltip placement="right" overlay={text}>
      <TagName className={styles.row} {...props}>
        {icon != null && <div className={styles.rowIcon}>{icon}</div>}
        <div className={styles.rowtext}>{text}</div>
      </TagName>
    </Tooltip>
  } else {
    return <TagName className={styles.row} {...props}>
      {icon != null && <div className={styles.rowIcon}>{icon}</div>}
      <div className={styles.rowtext}>{text}</div>
    </TagName>
  }
}

export const useLeftNavOpened: () => [boolean, (isOpened: boolean) => void] = () => {
  const [navMinimized, setNavMinimized] = useLocalStorage('navMinimized', false);
  const isOpened = !navMinimized

  const setIsOpened = (isOpened: boolean) => {
    setNavMinimized(!isOpened)
  }

  return [isOpened, setIsOpened ]
}

export const LeftNav = () => {
  const [isOpened, setIsOpened] = useLeftNavOpened()

  const classList = [
    styles.container,
    isOpened ? styles.opened : undefined,
  ].join(' ')

  return <div className={classList}>
    <Row text="" />
    <Scrollable>
      <Row text="Orders" to="/admin" exact icon={<Box />} />
      <Row text="Patterns" to="/admin/patterns" exact icon={<Pattern />} />
      <Row text="Groups" to="/admin/printGroups" exact icon={<Print />} />
      <Row text="Features" to="/admin/features" exact icon={<Taxonomy />} />
      <Row text="Products" to="/admin/products" exact icon={<Mask />} />
      <Row text="Promo Codes" to="/admin/promoCodes" exact icon={<Coupon />} />
      <Row text="Referrals" to="/admin/referrals" exact icon={<ReferralCode />} />
      <Row text="Gift Cards" to="/admin/gift-cards" exact icon={<GiftCard />} />
      <Row text="Verifier" to="/admin/verifier" exact icon={<Verifier />} />
      <Row text="Reviews" to="/admin/reviews" exact icon={<StarFill />} />
    </Scrollable>

    <div className={styles.footer}>
      <Row text="" />
      <div className={styles.toggle} onClick={() => setIsOpened(!isOpened)}>
        <TogglePanel />
      </div>
    </div>
  </div>
}
