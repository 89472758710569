import React from 'react'
import { Product } from '../../models/Product'
import { Pattern } from '../../models/Pattern.model'
import { Meta } from '../../utils'

interface MaskLayoutDataProps extends React.HTMLAttributes<HTMLDivElement> {
  data: Product,
  patternById?: {[key: string]: Meta<Pattern>}
  patternIdBySlot: {[key: number]: string}
}

const PPI = 300

export const MaskLayout: React.FC<MaskLayoutDataProps> = ({data, patternById, patternIdBySlot}) => {
  if(data.width == null || data.height == null) return null;
  if(data.seamTop == null || data.seamRight == null || data.seamBottom == null || data.seamLeft == null) return null;

  const width = data.width * PPI
  const height = data.height * PPI

  const seamTop = data.seamTop * PPI;
  const seamRight = data.seamRight * PPI;
  const seamBottom = data.seamBottom * PPI;
  const seamLeft = data.seamLeft * PPI;

  const face = {
    width: 2124,
    height: 1167,
    path: "M266.650135,574.135723 C116.499037,385.105888 27.9489917,194.060647 1,1 L2122.75,1 C2095.80101,194.060647 2007.25096,385.105888 1857.09987,574.135723 C1573.74108,930.864814 1283.45379,1161.35648 1068.3696,1165.93064 L1061.875,1166 C846.161303,1166 552.871131,934.468138 266.650135,574.135723 Z",
  }

  let faceOffsetX = 0
  let faceOffsetY = 0
  if(data.faceAnchor) {
    if(data.faceAnchor.horizontal === 'center') faceOffsetX = face.width/2;
    else if(data.faceAnchor.horizontal === 'end') faceOffsetX = face.width;

    if(data.faceAnchor.vertical === 'center') faceOffsetY = face.height/2;
    else if(data.faceAnchor.vertical === 'end') faceOffsetY = face.height; 
  }

  let maskOffsetX = 0
  let maskOffsetY = 0
  if(data.maskAnchor) {
    if(data.maskAnchor.horizontal === 'center') maskOffsetX = width/2;
    else if(data.maskAnchor.horizontal === 'end') maskOffsetX = width;

    if(data.maskAnchor.vertical === 'center') maskOffsetY = height/2;
    else if(data.maskAnchor.vertical === 'end') maskOffsetY = height; 
  }

  const sewStroke = 3
  return <svg viewBox={`0 0 ${width + seamLeft + seamRight} ${height + seamTop + seamBottom}`}>
    <defs>
      {data.patternSlots?.map((slot, index) => {
        const patternId = patternIdBySlot[index] || patternIdBySlot[0]
        const pattern = patternId ? patternById?.[patternId] : undefined;
        if(pattern == null || pattern.data == null) return null;
        
        return <pattern id={`img${index}`} patternUnits="userSpaceOnUse" width={pattern.data.tileWidth || 0} height={pattern.data.tileHeight || 0} key={index}>
          <image href={pattern.data.tileUrl} />
        </pattern>
      })}

      <mask id="mask">
        <rect x={seamLeft} y={0} width={width} height={height} fill="white" />
      </mask>
    </defs>

    <g transform={`translate(${sewStroke/2}, ${sewStroke/2})`}>
      <rect fill="transparent" width={width + seamLeft + seamRight - sewStroke} height={height + seamTop + seamBottom - sewStroke} stroke="black" strokeWidth={sewStroke} />
    </g>

    {data.patternSlots?.map((slot, index) => {
      const patternId = patternIdBySlot[index]
      const pattern = patternId ? patternById?.[patternId] : undefined;
      if(pattern == null || pattern.data == null) {
        return null;
      }

      let patternOffsetX = 0
      let patternOffsetY = 0
      if(slot?.anchor) {
        if(slot.anchor.horizontal === 'center') patternOffsetX = width/2;
        else if(slot.anchor?.horizontal === 'end') patternOffsetX = width;

        if(slot.anchor.vertical === 'center') patternOffsetY = height/2;
        else if(slot.anchor.vertical === 'end') patternOffsetY = height; 
      }

      const fill = `url(#img${index})`
      const transform = [
        `translate(${seamLeft + patternOffsetX}, ${seamTop + patternOffsetY})`,
        slot.transform
      ].filter(a => a)
      return <g transform={transform.join(' ')} key={index}>
        {pattern.data.tileUrl && <rect fill={fill} width={width} height={height} />}
        {pattern.data.hex && <rect fill={pattern.data.hex} width={width} height={height} />}
      </g>
    })}
    
    <g transform={`translate(${seamLeft}, ${seamTop})`}>
      <g transform={`translate(${maskOffsetX-faceOffsetX}, ${maskOffsetY-faceOffsetY})`}>
        <path d={face.path} fill="#E5AF90" />
      </g>
    </g>

    <g transform={`translate(${seamLeft}, ${seamTop})`}>
      <circle r={width*0.01} cx={maskOffsetX} cy={maskOffsetY} style={{fill: "var(--secondary-color)"}} />
    </g>
  </svg>
}