import React from 'react'
import { OrderShipping } from '../models/Order.model';

type ContextProps = [
  {
    twitterLink: string,
    youtubeLink: string,
    instagramLink: string,
    facebookLink: string,
    iosAppLink: string,
    shippingHq: OrderShipping,
    companyName: string
  }
];


const defaultValue: ContextProps = [
  {
    twitterLink: "https://twitter.com/getfriendlyface",
    youtubeLink: "https://www.youtube.com/channel/UCNzRPa2IWKwdH8l0W7lnLsA?disable_polymer=true",
    facebookLink: "https://www.facebook.com/wearfriendlyface",
    instagramLink: "https://www.instagram.com/wearfriendlyface/",
    iosAppLink: "https://apps.apple.com/us/app/friendlyface/id1531406645",
    shippingHq: new OrderShipping("Seattle", "USA", "999 3rd Ave", "98104", "WA", "Ste 3300"),
    companyName: "Friendly Atelier LLC",
  }
];

const SocialContext = React.createContext<ContextProps>(defaultValue);

export const SocialProvider: React.FC<React.ReactHTMLElement<HTMLDivElement>> = ({children}) => {
  return <SocialContext.Provider value={defaultValue}>
    {children}
  </SocialContext.Provider>
};

export const useSocialContext = () => React.useContext(SocialContext);
