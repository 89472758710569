import React from 'react'

import styles from './SegmentedControl.module.scss'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string
  checkedValue?: string
  error?: string | boolean
  values?: {[key in "value" | "title"]: string}[]
}

export const SegmentedControl: React.FC<InputProps> = ({values, name, title, className, error, checkedValue, onChange, ...props}) => {
  const classList = [
    styles.container,
    error ? styles.error : undefined,
    className
  ].join(' ')

  return <div className={classList}>
    {title && <div className={styles.title}>
      {title}
      {error && (typeof error) == 'string' && <span> - {error}</span>}
    </div>}
    <div className={styles.content}>
      {values?.map(({value, title}, index) => {
        const checked = checkedValue === value
        return <label key={index} className={[styles.label, checked ? styles.checked : null].join(" ")}>
          <input className={styles.input} type="radio" value={value || ""} name={name} placeholder={title} checked={checked || false} onChange={onChange} />
          <span>{title}</span>
        </label>
      })}
    </div>
  </div>
}