import React from 'react'
import Tooltip from 'rc-tooltip'

import { isSomething } from '../../utils'

import styles from './InfoCard.module.scss'

interface InfoCardProps extends React.HTMLAttributes<HTMLDivElement> {
}

export const InfoCard: React.FC<InfoCardProps> = ({children}) => {
  return <div className={styles.container}>
    {children}
  </div>
}

export const InfoCardHeader: React.FC<InfoCardProps> = ({children}) => {
  return <div className={styles.header}>
    {children}
  </div>
}

export const InfoCardHeaderButton: React.FC<InfoCardProps> = ({children, onClick, title}) => {
  if(title != null) {
    return <Tooltip placement="top" overlay={title}>
      <div className={styles.button} onClick={onClick}>
        {children}
      </div>
    </Tooltip>
  } else {
    return <div className={styles.button} onClick={onClick}>
      {children}
    </div>
  }
}

interface InfoCardHeaderLinkProps extends React.HTMLProps<HTMLAnchorElement> {
}
export const InfoCardHeaderLink: React.FC<InfoCardHeaderLinkProps> = ({children, href, title}) => {
  if(title != null) {
    return <Tooltip placement="top" overlay={title}>
      <a className={styles.button} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </Tooltip>
  } else {
    return <a className={styles.button} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  }
}


interface InfoCardRowProps {
  text: string, 
  value?: number | string | undefined,
  hideIfNullOrEmptyValue?: boolean
}

export const InfoCardRow: React.FC<InfoCardRowProps> = ({text, value, children, hideIfNullOrEmptyValue = false}) => {
  if(hideIfNullOrEmptyValue && (!isSomething(value) || value === "")) return null
  return <div className={styles.row}>
    <div className={styles.text}>{text}</div>
    <div className={styles.line} />
    {children == null && value != null && <div className={styles.value}>{value}</div>}
    {children != null && <div className={styles.value}>{children}</div>}
  </div>
}