import React from 'react'

import styles from './Card.module.scss'

interface Props {
  title?: String
}

export const Card: React.FC<Props> = ({title, children}) => {
  return <>
    {title && <div className={styles.title}>{title}</div>}
    <div className={styles.container}>
      {children}
    </div> 
  </>
}