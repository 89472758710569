import React from 'react'
import {Pattern as PatternType} from '../../models/Pattern.model'

import {Meta} from '../../utils'


import styles from './Pattern.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isSelected? : boolean,
  isAnythingSelected? : boolean,
  data: Meta<PatternType>,
}

export const Pattern: React.FC<Props> = ({data, className, onClick, isSelected, isAnythingSelected}) => {
  const pattern = data.data
  const classList = [
    className,
    styles.container,
    isSelected ? styles.selected : undefined,
    isAnythingSelected ? styles.somethingSelected : undefined,
    pattern?.disabled ? styles.disabled : undefined,
  ]

  const url = pattern?.tileUrl || pattern?.url
  return <div className={classList.join(' ')} onClick={onClick}>
    {pattern?.hex && <div className={styles.image} style={{background: pattern?.hex}} />}
    {url && <img src={url} className={styles.image} alt={pattern?.name} />}
    {pattern?.name && <div className={styles.name}>{pattern?.name}</div>}
    {pattern?.order != null && <div className={styles.order}>{pattern?.order}</div>}
    {pattern?.disabled && <div className={styles.disabledLabel}>disabled</div>}
    {(pattern?.tileUrl || pattern?.smallTileUrl) && <div className={styles.hasTilesWrap}>
      {pattern?.tileUrl && <div className={styles.hasTiles} />}
      {pattern?.smallTileUrl && <div className={styles.hasSmallTiles} />}
      {pattern?.s88Url && <div className={styles.hasS88} />}
    </div>}
  </div>
}

interface CreateProps extends React.HTMLAttributes<HTMLDivElement> {
}

export const CreatePattern: React.FC<CreateProps> = ({className, onClick}) => {
  const classList = [
    className,
    styles.container,
    styles.create
  ]
  return <div className={classList.join(" ")} onClick={onClick}>
    Create + 
  </div>
}

export const SmallPattern: React.FC<Props> = ({data, className, onClick, isSelected, isAnythingSelected}) => {
  const pattern = data.data
  const classList = [
    className,
    styles.container,
    styles.smallContainer,
    isSelected ? styles.selected : undefined,
    isAnythingSelected ? styles.somethingSelected : undefined,
    pattern?.disabled ? styles.disabled : undefined,
  ]

  const url = pattern?.url
  return <div className={classList.join(' ')} onClick={onClick}>
    {pattern?.hex && <div className={styles.image} style={{background: pattern?.hex}} />}
    {url && <img src={url} className={styles.image} alt={pattern?.name} />}
  </div>
}