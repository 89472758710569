import React from 'react'
import { Popup } from "../../components/Popup/Popup";

import { WarningIcon } from "../../toneIcons";
import { defaultColorStyle } from '../../utils';

import styles from './ClonePopup.module.scss';
interface Props {
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: () => void;
}

export const ClonePopup: React.FC<Props> = ({onClose, onSubmit}) => {
  const style = defaultColorStyle()

  return <Popup icon={<WarningIcon className={styles.warningIcon} />} style={style} onClose={onClose}>
    <div>
          You sure you want to clone it?
    </div>

    {onSubmit != null && <div className={styles.confirmationButton} onClick={() => onSubmit()}>Yes, clone it</div>}
  </Popup>
};

export default ClonePopup;