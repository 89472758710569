import React from 'react'
import toast from 'react-hot-toast';
import { ContextPropsState, initialState, reducer } from './promoCodes.reducer'

import { promoCodeService } from '../services/promoCode.service'
import { PromoCode } from '../models/PromoCode'
import { toastErrorOptions } from '../utils';

type ContextPropsActions = {
  fetchPromoCodes: () => void,
  updatePromoCode: (id: string, data: Partial<PromoCode>) => void,
  createPromoCode: (data:any) => void,
  deletePromoCode: (id: string) => void,
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialActions = {
  fetchPromoCodes: () => { throw new Error("fetchPromoCodes not implemented") },
  updatePromoCode: () => { throw new Error("updatePromoCode not implemented") },
  createPromoCode: () => { throw new Error("createPromoCode not implemented") },
  deletePromoCode: () => { throw new Error("deletePromoCode not implemented") },
}

export const PromoCodesContext = React.createContext<ContextProps>([initialState, initialActions]);

export const PromoCodesProvider = ({children}: any) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const fetchPromoCodes = React.useCallback(async () => {
    try {
      dispatch({type: "FETCH_ALL_PROMOCODES"})
      const promoCodes = await promoCodeService.getAll()
      dispatch({type: "FETCH_ALL_PROMOCODES_SUCCESS", data: promoCodes})
    } catch(error) {
      dispatch({type: "FETCH_ALL_PROMOCODES_ERROR", error})
      toast.error(`error fetching promo codes`, toastErrorOptions)
    }
  }, [])

  const updatePromoCode = React.useCallback(async (id, data) => {
    try {
      dispatch({type: "UPDATE_PROMOCODE", id})
      const newData = await promoCodeService.update(id, data)
      dispatch({type: "UPDATE_PROMOCODE_SUCCESS", id, data: newData})
    } catch(error) {
      dispatch({type: "UPDATE_PROMOCODE_ERROR", id, error})
      toast.error(`error updating promo code ${id}`, toastErrorOptions)
    }
  }, [])

  const createPromoCode = React.useCallback(async (data) => {
    try {
      dispatch({type: "CREATE_PROMOCODE"})
      const promoCode = await promoCodeService.create(data)
      dispatch({type: "CREATE_PROMOCODE_SUCCESS", data: promoCode})
    } catch(error) {
      dispatch({type: "CREATE_PROMOCODE_ERROR", error})
      toast.error(`error creating promo code`, toastErrorOptions)
    }
  }, [])

  const deletePromoCode = React.useCallback(async (id) => {
    try {
      dispatch({type: "DELETE_PROMOCODE", id})
      await promoCodeService.delete(id)
      dispatch({type: "DELETE_PROMOCODE_SUCCESS", id})
    } catch(error) {
      dispatch({type: "DELETE_PROMOCODE_ERROR", id, error})
      toast.error(`error deleting promo code ${id}`, toastErrorOptions)
    }
  }, []) 

  const value:ContextProps = [state, {
    fetchPromoCodes,
    updatePromoCode,
    createPromoCode,
    deletePromoCode,
  }]

  return <PromoCodesContext.Provider value={value}>
    {children}
  </PromoCodesContext.Provider>
}

export const usePromoCodesContext = () => React.useContext(PromoCodesContext)