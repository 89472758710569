import React from 'react'
import {Link} from 'react-router-dom'
import firebase from "firebase/app";
import 'firebase/auth';        // for authentication
import 'firebase/database';    // for realtime database

import { Input } from '../../components/Input/Input';
import { Button } from '../../components/Button/Button';
import { firebaseClient } from '../../services/firebase';

import styles from './Auth.module.scss'
interface LoginInput {
  email?: string,
  password?: string,
}
interface SignupErrorInput {
  global?: string,
  email?: string | boolean,
  password?: string | boolean,
}

export const Login = () => {
  const [values, setValues] = React.useState<LoginInput>({});
  const [errors, setErrors] = React.useState<SignupErrorInput>({});

  const onChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;

    setValues(prev => ({...prev, [name]: value}))
  }

  const onSubmit = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    e.stopPropagation();

    if(values.email && values.password) {
      try {
        const tennantId = firebaseClient.tennantId
        firebase.auth().tenantId = tennantId as string
        await firebase.auth().signInWithEmailAndPassword(values.email, values.password)
      } catch(error) {
        if (error.code === 'auth/wrong-password') {
          setErrors({password: "Invalid"});
        } else if (error.code === 'auth/invalid-email') {
          setErrors({email: "Invalid"});
        } else if (error.code === 'auth/user-not-found') {
          setErrors({email: true, password: true, global: error.message});
        }
      }
    }
  }

  let isSubmitEnabled = true
  if(values.email == null || values.email.length === 0) isSubmitEnabled = false
  if(values.password == null || values.password.length === 0) isSubmitEnabled = false

  return <div className={styles.container} onSubmit={onSubmit}>
    <h1>Login</h1>
    <form className={styles.authBox} onSubmit={onSubmit}>
      <Input name="email"
        value={values.email}
        error={errors.email}
        title="Email"
        type="email"
        onChange={onChange}
        className={styles.input} />
      <Input name="password"
        value={values.password}
        error={errors.password}
        title="Password"
        type="password"
        onChange={onChange}
        className={styles.input} />
      <Button type="submit" className={styles.submit} disabled={!isSubmitEnabled}>Login</Button>
      <Link to="/signup">Don't have an account?</Link>
    </form>
  </div>
}