import React from 'react'
import { Popup } from "../../components/Popup/Popup";
import classNames from 'classnames'

import {defaultColorStyle} from '../../utils'
import { EnhanceIcon } from '../../toneIcons';

import styles from './RetouchPopup.module.scss';

interface Props {
  selectedRetouch?: boolean
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (enhance: boolean) => void;
}

export const RetouchPopup: React.FC<Props> = ({onClose, onSubmit, selectedRetouch}) => {
  const style = defaultColorStyle()
  return <Popup onClose={onClose} style={style}>
    <div className={styles.boxes}>
      <div className={classNames(styles.enhancingBox, selectedRetouch && styles.selected)} onClick={() => onSubmit(true)}><EnhanceIcon /></div>
      <div className={classNames(styles.enhancingBoxDisabled, !selectedRetouch && styles.selected)} onClick={() => onSubmit(false)}>No</div>
    </div>
  </Popup>
};

export default RetouchPopup;
