import moment from "moment";
import React from "react";

import styles from "./DateTimeAgo.module.css";

interface Props {
  date: number | Date;
  className?: string;
}

export const DateTimeAgo: React.FC<Props> = ({date, className}) => {
  const now = moment();
  const nowYear = now.year();
  const historyMoment = moment(date);
  const dateFormat = (historyMoment.year() === nowYear) ? 'M/D h:mma' : 'M/D/YY h:mma';

  return <div className={[styles.container, className].filter(Boolean).join(" ")}>
    <div className={styles.lastClicked}>{historyMoment.format(dateFormat)}</div>
    <div className={styles.timeAgo}>{historyMoment.fromNow()}</div>
  </div>
};

export const TimeAgo: React.FC<Props> = ({date, className}) => {
  const historyMoment = moment(date);

  return <div className={[styles.container, className].filter(Boolean).join(" ")}>
    <div>{historyMoment.fromNow()}</div>
  </div>
};
