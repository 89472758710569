import React from 'react'
import { Popup } from "../../components/Popup/Popup";

import { useProductsContext } from '../../contexts/products.context';
import { MaskType } from '../../components/MaskType/MaskType';

import styles from './MaskSizePopup.module.scss';
import { useFeaturesContext } from '../../contexts/features.context';


interface Props {
  selectedMaskId?: string
  selectedProductType?: string
  onClose: React.EventHandler<React.MouseEvent | React.KeyboardEvent>;
  onSubmit: (maskId: string) => void;
}

export const MaskSizePopup: React.FC<Props> = ({onClose, onSubmit, selectedMaskId}) => {
  const [{productById, productIds}] = useProductsContext()
  const [{featureIds, featureById}] = useFeaturesContext()

  return <Popup onClose={onClose}>
    <div className={styles.categories}>
      {featureIds?.data?.map(id => {
        const feature = featureById[id]
        if(feature == null || feature.data == null) return null;
        if(!feature.data.isGroup) return null;

        return <div key={id}>
          <div>{feature?.data?.name}</div>
          <div className={styles.masks}>
            {productIds?.data?.map( productId => {
              const product = productById[productId];
              if(product.data?.categoryId !== feature.data?.slug) return null;
              const image = product.data?.url
              const name = product.data?.name || ""

              return <MaskType key={productId} title={name} src={image} className={styles.mask} selected={selectedMaskId === productId} onClick={() => onSubmit(productId)} />
            })}
          </div>
        </div>
      })}
    </div>
  </Popup>
};

export default MaskSizePopup;