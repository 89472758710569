import React from 'react'
import { v4 as uuidv4 } from "uuid";
import { Toast } from '../components/Toast/Toast';

import {Toast as ToastModel} from '../models/Toast.model'

type ContextPropsActions = {
  showToast: (text: string) => void
}

type ContextProps = [ContextPropsActions]

const initialActions = {
  showToast: () => { throw new Error("showToast not implemented")}
}

export const ToastContext = React.createContext<ContextProps>([initialActions]);

export const ToastProvider = ({children}: any) => {
  const [toasts, setToasts] = React.useState<ToastModel[]>([]);
  const [shownIds, setShownIds] = React.useState<string[]>([]);
  const [hiddenIds, setHiddenIds] = React.useState<string[]>([]);


  const showToast = React.useCallback((text: string) => {
    const toast = new ToastModel(uuidv4(), text)
    const id = toast.id;
    setToasts(toasts => [...toasts, toast])
    setTimeout(() => {
      setShownIds(ids => ([...ids, id]));
    }, 0)
  }, [setToasts])

  React.useEffect(() => {
    const refs = Array.from(shownIds).map(id => {
      return window.setTimeout(() => {
        setShownIds(ids => ids.filter(fId => fId !== id))
        setHiddenIds(ids => [...ids, id])
      }, 2000)
    })

    return () => {
      refs.forEach(id => {
        if(id) window.clearTimeout(id);
      })
    }
  }, [shownIds, setShownIds])

  React.useEffect(() => {
    if(hiddenIds.length > 0) {
      const ref = window.setTimeout(() => {
        setToasts(toasts => toasts.filter(t => hiddenIds.indexOf(t.id) < 0 ))
        setHiddenIds([])
      }, 200)

      return () => {
        if(ref) window.clearTimeout(ref);
      }
    }
  }, [hiddenIds, setHiddenIds])

  const value: ContextProps = [
    { showToast }
  ];
  return <ToastContext.Provider value={value}>
    <>
      {children}
      {toasts?.map(t => <Toast key={t.id} data={t} shown={shownIds?.indexOf(t.id) >= 0}  />)}
    </>
  </ToastContext.Provider>
};

export const useToastContext = () => React.useContext(ToastContext);
