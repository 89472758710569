import {Meta} from '../utils'

import { PrintGroup } from '../models/PrintGroup'
import { groupBy, mapValues } from 'lodash';

export type ContextPropsState = {
  printGroupById: {[key: string]: Meta<PrintGroup>},
  printGroupIds: Meta<string[]>
}
export const initialState: ContextPropsState = {
  printGroupById: {},
  printGroupIds: {},
};

type PatternsAction = 
  | { type: "FETCH_ALL_PRINTGROUPS" }
  | { type: "FETCH_ALL_PRINTGROUPS_SUCCESS", data: PrintGroup[] }
  | { type: "FETCH_ALL_PRINTGROUPS_ERROR", error: any }

  | { type: "UPDATE_PRINTGROUP", id: string }
  | { type: "UPDATE_PRINTGROUP_SUCCESS", id: string, data: PrintGroup }
  | { type: "UPDATE_PRINTGROUP_ERROR", id: string, error: any }

export const reducer = (state: ContextPropsState, action: PatternsAction):ContextPropsState => {
  // FETCH
  if( "FETCH_ALL_PRINTGROUPS" === action.type) {
    const printGroupIds: Meta<string[]> = {
      data: state.printGroupIds.data,
      meta: {
        ...state.printGroupIds.meta,
        fetching: true
      }
    }
    return {
      ...state,
      printGroupIds
    }
  }
  if( "FETCH_ALL_PRINTGROUPS_SUCCESS" === action.type) {
    if(action.data == null) return state;
      
    const printGroupById = mapValues(groupBy(action.data, p => p.id), p => ({data: p[0]}) );
    const printGroupIds: Meta<string[]> = {
      data: action.data.map(p => p.id),
      meta: {
        fetching: false
      }
    }
    return {
      ...state,
      printGroupById,
      printGroupIds
    }
  }
  if( "FETCH_ALL_PRINTGROUPS_ERROR" === action.type) {
    const printGroupIds: Meta<string[]> = {
      data: state.printGroupIds.data,
      meta: {
        ...state.printGroupIds.meta,
        fetching: false
      }
    }
    return {
      ...state,
      printGroupIds
    }
  }

  // UPDATE
  if("UPDATE_PRINTGROUP" === action.type) {
    if(action.id == null) return state;

    const currentPattern = state.printGroupById?.[action.id];
    const newPattern: Meta<PrintGroup> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: true
      }
    }
    const newPrintGroupById = {
      ...state.printGroupById,
      [action.id]: newPattern
    }
    return {
      ...state,
      printGroupById: newPrintGroupById
    }
  }
  if("UPDATE_PRINTGROUP_SUCCESS" === action.type) {
    if(action.id == null) return state
    if(action.data == null) return state

    const currentPattern = state.printGroupById?.[action.id];
    const newPrintGroup: Meta<PrintGroup> = {
      data: action.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: false
      }
    }
    const newPrintGroupById = {
      ...state.printGroupById,
      [action.id]: newPrintGroup
    }

    return {
      ...state,
      printGroupById: newPrintGroupById,
    }
  }
  if("UPDATE_PRINTGROUP_ERROR" === action.type) {
    if(action.id == null) return state;

    const currentPattern = state.printGroupById?.[action.id];
    const newPattern: Meta<PrintGroup> = {
      data: currentPattern?.data,
      meta: {
        ...(currentPattern?.meta || {}),
        updating: false,
        error: action.error
      }
    }
    const newPrintGroupById = {
      ...state.printGroupById,
      [action.id]: newPattern
    }
    return {
      ...state,
      printGroupById: newPrintGroupById
    }
  }
  
  return state;
}
