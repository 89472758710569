import React from 'react'
import queryString from 'query-string'
import {Link, useLocation} from "react-router-dom";
import classNames from "classnames";

import { useQuery } from '../../hooks/useQuery'

import styles from './FaceInteractorNav.module.scss'

export const FaceInteractorNav = () => {
  const location = useLocation()
  const query = useQuery()
  const editType = query.get('editType') || undefined
  
  const rotationSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    editType: 'rotation'
  })
  const centerSearch = "?" + queryString.stringify({
    ...queryString.parse(location.search),
    editType: 'center'
  })
  // const eyeBotSearch = "?" + queryString.stringify({
  //   ...queryString.parse(location.search),
  //   editType: 'eyeBot'
  // })

  return <div className={styles.container}>
    <Link to={rotationSearch} className={classNames(styles.button, editType === 'rotation' ? styles.selected : undefined)}>Rotation</Link>
    <Link to={centerSearch} className={classNames(styles.button, editType === 'center' ? styles.selected : undefined)}>Center X</Link>
    {/* <Link to={eyeBotSearch} className={classNames(styles.button, editType === 'eyeBot' ? styles.selected : undefined)}>Eye Bot Y</Link> */}
  </div>
}