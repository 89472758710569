import React from "react";
import {Helmet} from "react-helmet";


import {FirebaseDBLink, SidebarHeader} from "../../../components/SidebarHeader/SidebarHeader"
import {Scrollable} from "../../../components/Scrollable/Scrollable"
import {getFireDataUrl, Meta, splitCard} from "../../../utils"
import {GiftCard, GiftCardWId} from "../../../models/GiftCard.model"
import {GiftCardForm} from "../GiftCardForm/GiftCardForm"
import {useGiftCardsContext} from "../../../contexts/giftCard.context"

import styles from "./GiftCardsSidebar.module.scss";
interface Props {
  onClose?: () => void,
  data?: Meta<GiftCardWId>
}

export const GiftCardsSidebar: React.FC<Props> = ({onClose, data}) => {
  const [, {updateGiftCard, createGiftCard}] = useGiftCardsContext()

  const onSaveValues = async (values: Partial<GiftCard>) => {
    if (data?.data?.id) {
      await updateGiftCard(data?.data?.id, values)
    } else {
      await createGiftCard(values)
    }
    onClose && onClose();
  }

  const splitGiftCardCode = data?.data?.id ? splitCard(data?.data?.id.toUpperCase()) : undefined

  const dataId = data?.data?.id
  return <div className={styles.container}>
    <Helmet>
      {splitGiftCardCode && <title>Gift Card {splitGiftCardCode}</title>}
      {!splitGiftCardCode && <title>Gift Card Create</title>}
    </Helmet>
    {splitGiftCardCode && <SidebarHeader title={dataId ? splitGiftCardCode : ""} onClose={onClose}>
      <FirebaseDBLink href={dataId ? getFireDataUrl(`giftCards/${dataId}`) : undefined} />  
    </SidebarHeader>}
    {!splitGiftCardCode && <SidebarHeader title="Create Gift Card" onClose={onClose}/>}

    <Scrollable className={styles.scrollable}>
      <GiftCardForm giftCard={data?.data} onSuccess={onSaveValues} key={data?.data?.id} />
    </Scrollable>
  </div>
};
