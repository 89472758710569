import React from 'react'
import firebase from 'firebase/app'
import { useAuthState } from 'react-firebase-hooks/auth';


type ContextPropsState = {
  user?: firebase.User,
  initialising: boolean
}
type ContextPropsActions = {
  
}

type ContextProps = [ContextPropsState, ContextPropsActions]

const initialState = {
  user: undefined,
  initialising: false
};

export const AuthContext = React.createContext<ContextProps>([initialState, {}]);



export const AuthProvider = ({ children }: any) => {
  const [user, initialising] = useAuthState(firebase.auth());
  const value:ContextProps = [{user, initialising}, {}]

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
}

export const useAuthContext = () => React.useContext(AuthContext)