import firebase from "firebase";

export type ProductAnchorValue = "start" | "center" | "end"
type ProductAnchorKey = "vertical" | "horizontal"
export type ProductAchor =  {[key in ProductAnchorKey]: ProductAnchorValue }

export type PrintType = "mask" | "gaiter" | "mug" | "giftCard" | "custommask"

export const getPrintType = (maybePrintType: string | undefined): PrintType | undefined => {
  if(maybePrintType === "mask") return maybePrintType
  if(maybePrintType === "gaiter") return maybePrintType
  if(maybePrintType === "mug") return maybePrintType
  if(maybePrintType === "giftCard") return maybePrintType
  if(maybePrintType === "custommask") return maybePrintType
  return undefined
}

export interface PatternSlot {
  transform?: string,
  anchor: ProductAchor
}

export class Product {
  categoryId?: string
  static PPI = 300

  constructor(
    public id: string,
    public name: string,
    public displayName: string,
    public shortName: string,
    public description: string | undefined,
    public price: number | undefined,
    public oldPrice: number | undefined,
    public value: number | undefined,
    public size: string,
    public disabled: boolean,
    public allowsShrinkPatternToFit: boolean,
    public width?: number,
    public height?: number,
    public seamTop?: number,
    public seamRight?: number,
    public seamBottom?: number,
    public seamLeft?: number,
    public insetTop?: number,
    public insetRight?: number,
    public insetBottom?: number,
    public insetLeft?: number,
    public order?: number,
    public faceAnchor?: ProductAchor,
    public maskAnchor?: ProductAchor,
    public patternSlots?: PatternSlot[],
    public url?: string,
    public printType?: PrintType,
    public weight?: number,
    public eyeLine?: string,
    public taxCode?: string,
    public featureIds?: string[]) {
      this.categoryId = printType
  }

  widthPX = () => this.width ? Math.floor(this.width * Product.PPI) : undefined
  heightPX = () => this.height ? Math.floor(this.height * Product.PPI) : undefined
  seamTopPX = () => this.seamTop ? Math.floor(this.seamTop * Product.PPI) : undefined
  seamRightPX = () => this.seamRight ? Math.floor(this.seamRight * Product.PPI) : undefined
  seamBottomPX = () => this.seamBottom ? Math.floor(this.seamBottom * Product.PPI) : undefined
  seamLeftPX = () => this.seamLeft ? Math.floor(this.seamLeft * Product.PPI) : undefined

  insetTopPX = () => this.insetTop ? Math.floor(this.insetTop * Product.PPI) : undefined
  insetRightPX = () => this.insetRight ? Math.floor(this.insetRight * Product.PPI) : undefined
  insetBottomPX = () => this.insetBottom ? Math.floor(this.insetBottom * Product.PPI) : undefined
  insetLeftPX = () => this.insetLeft ? Math.floor(this.insetLeft * Product.PPI) : undefined
  
  static from = (docSnapshot: firebase.firestore.DocumentSnapshot | any) => {
    const data = docSnapshot.data ? docSnapshot.data() as any : docSnapshot
    const order = data.order != null ? Number.parseInt(data.order) : undefined;
    const price = data.price != null ? Number.parseInt(data.price) : undefined;
    const oldPrice = data.oldPrice != null ? Number.parseInt(data.oldPrice) : undefined;
    const value = data.value != null ? Number.parseInt(data.value) : undefined;
    const disabled =  Boolean(data.disabled);
    const allowsShrinkPatternToFit = Boolean(data.allowsShrinkPatternToFit);

    const width = data.width != null ? Number.parseFloat(data.width) : undefined
    const height = data.height != null ? Number.parseFloat(data.height) : undefined
    const seamTop = data.seamTop != null ? Number.parseFloat(data.seamTop) : undefined
    const seamRight = data.seamRight != null ? Number.parseFloat(data.seamRight) : undefined
    const seamBottom = data.seamBottom != null ? Number.parseFloat(data.seamBottom) : undefined
    const seamLeft = data.seamLeft != null ? Number.parseFloat(data.seamLeft) : undefined
    const insetTop = data.insetTop != null ? Number.parseFloat(data.insetTop) : undefined
    const insetRight = data.insetRight != null ? Number.parseFloat(data.insetRight) : undefined
    const insetBottom = data.insetBottom != null ? Number.parseFloat(data.insetBottom) : undefined
    const insetLeft = data.insetLeft != null ? Number.parseFloat(data.insetLeft) : undefined
    const id = docSnapshot.id
    const weight = data.weight != null ? Number.parseFloat(data.weight) : undefined

    const printType = data.printType as PrintType

    return new Product(id,
                    data.name,
                    data.displayName,
                    data.shortName,
                    data.description,
                    price,
                    oldPrice,
                    value,
                    data.size,
                    disabled,
                    allowsShrinkPatternToFit,
                    width,
                    height,
                    seamTop,
                    seamRight,
                    seamBottom,
                    seamLeft,
                    insetTop,
                    insetRight,
                    insetBottom,
                    insetLeft,
                    order,
                    data.faceAnchor,
                    data.maskAnchor,
                    data.patternSlots,
                    data.url,
                    printType,
                    weight,
                    data.eyeLine,
                    data.taxCode,
                    data.featureIds )
  }
}