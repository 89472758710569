import React from 'react'

import styles from './Cell.module.scss'

interface Props {
  tagName?: React.FC,
  sticky?: Boolean,
  className?: string,
  deleting?: Boolean,
  active?: Boolean,
  index: number,
  colIndex?: number,
}

export const Cell: React.FC<Props>  = ({tagName, index, deleting, sticky, className, active, children, colIndex, ...props}) => {
  const Tag = tagName || 'div'

  const classList = [
    styles.cell,
    sticky ? styles.sticky : undefined,
    index % 2 === 0 ? styles.even : undefined,
    deleting ? styles.deleting : undefined,
    active ? styles.active : undefined,
    colIndex != null ? styles[`col${colIndex}`] : undefined,
    className,
  ].filter(a=>a);

  return <Tag {...props} className={classList.join(' ')}>
    {children}
  </Tag>
}

export default Cell;